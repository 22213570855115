import PropTypes from 'prop-types';
import { NavBar } from 'src/components/navBar/NavBar';
import { AdminsPages } from 'src/constants/constants';

export const AdminLayout = ({ children }) => {
  return (
    <div>
      <NavBar pages={AdminsPages} />
      {children}
    </div>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node,
};
