import Box from '@mui/material/Box';
import { useGetMyTests } from 'src/hooks';

import { RequestHandler } from '../requestHandler/requestHandler';
import { CustomerMyTestsHeader } from './customerMyTestsHeader';
import { style } from './style';
import { MyTestsTable } from './table/MyTestsTable';

export const CustomerMyTestsContainer = () => {
  const { data, loading } = useGetMyTests({
    isTester: false,
  });

  return (
    <Box sx={style.container}>
      <RequestHandler loading={loading}>
        <CustomerMyTestsHeader testsCompletedCounter={data?.getMyTests?.competedTestCount || 0} />
        <MyTestsTable data={data} />
      </RequestHandler>
    </Box>
  );
};
