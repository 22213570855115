import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { theme } from '../../../theme';

const variantToStyle = {
  in_test: {
    backgroundColor: theme.colors.success,
  },
  open: {
    backgroundColor: theme.colors.success,
  },
  closed: {
    backgroundColor: theme.colors.neutral.dark.gray,
    color: theme.colors.neutral.white,
  },
  received: {
    backgroundColor: theme.colors.neutral.light.extra,
    border: `1px solid ${theme.colors.neutral.light.normal}`,
  },
  available: {
    backgroundColor: theme.colors.neutral.light.extra,
    border: `1px solid ${theme.colors.neutral.light.normal}`,
  },
  not_available: {
    backgroundColor: theme.colors.neutral.light.extra,
    border: `1px solid ${theme.colors.neutral.light.normal}`,
  },
};

const convertValueToTitle = (value) => {
  switch (value) {
    case 'in_test':
      return 'In Test';
    case 'not_available':
      return 'NotAvailable';
    default:
      return value;
  }
};

const Styled = styled('div')({
  padding: '6px 12px',
  borderRadius: 3,
  textTransform: 'capitalize',
});

export const Status = ({ status, statuses = [] }) => {
  const renderStatus = useCallback(() => {
    return statuses.length
      ? statuses.find?.((item) => item.value === status)?.title
      : convertValueToTitle(status?.toLowerCase());
  }, [statuses, status]);

  return (
    <Styled
      sx={[
        variantToStyle[status?.toLowerCase()],
        {
          width: 'max-content',
          '& .MuiTypography-root': {
            fontSize: '14px',
            lineHeight: '20px',
          },
        },
      ]}
    >
      <Typography
        sx={{
          verticalAlign: 'middle',
          textTransform: 'capitalize',
        }}
      >
        {renderStatus()}
      </Typography>
    </Styled>
  );
};

Status.propTypes = {
  status: PropTypes.string,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};
