import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from 'react';
import { FiltersMenuModal } from 'src/components/common/filtersMenu/FiltersMenuModal';
import { FilterIcon, SearchIcon } from 'src/components/UI/icons';
import { useFilter } from 'src/hooks';
import { filterForm, filterMenuModal, openedFilterModal } from 'src/styles';

import { WrapperFlex } from './style';

const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 34px;
`;

export const ControlBar = ({
  header,
  addButtonLabel,
  handleAddButtonClick,
  filterComponent,
  schema = null,
  isButtonVisiable = true,
}) => {
  const searchBarRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const { filters, setFilters } = useFilter();

  const [search, setSearch] = useState(filters.search);

  const handleChangeSearchValue = ({ target }) => {
    setSearch(target.value);
  };

  const toggleModal = useCallback(() => setIsOpen((prev) => !prev), []);

  const handleSearch = useCallback(
    (evt) => {
      evt.preventDefault();

      const { search: searchFilter, ...other } = filters;

      setFilters({
        ...other,
        ...(search
          ? {
              search,
            }
          : {}),
      });

      return isOpen && toggleModal();
    },
    [search, filters],
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (e.keyCode === 13) handleSearch(e);
    },
    [search, filters],
  );

  const searchBarPosition = useMemo(() => {
    if (!searchBarRef.current) return false;
    const { offsetLeft, offsetHeight, offsetTop } = searchBarRef.current;

    return {
      offsetLeft,
      offsetHeight,
      offsetTop,
    };
  }, [searchBarRef.current?.offsetLeft]);

  return (
    <StyledBox>
      <Typography variant='h3'>{header}</Typography>
      <WrapperFlex>
        <Paper
          component='form'
          sx={{
            ...filterForm,
          }}
        >
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <IconButton
              sx={{
                p: '10px',
              }}
              aria-label='search'
              onClick={handleSearch}
            >
              <SearchIcon />
            </IconButton>
            {isOpen ? (
              <FiltersMenuModal
                sx={filterMenuModal}
                isOpen={isOpen}
                position={searchBarPosition}
                toggle={toggleModal}
                schema={schema}
              >
                {filterComponent}
              </FiltersMenuModal>
            ) : null}
          </Box>
          <InputBase
            sx={{
              ml: 1,
              flex: 1,
            }}
            placeholder={`Find ${header}`}
            inputProps={{
              'aria-label': `find ${header}`,
            }}
            value={search}
            onChange={handleChangeSearchValue}
            onKeyDown={handleKeyPress}
          />
          {filterComponent && (
            <IconButton
              sx={
                isOpen
                  ? openedFilterModal
                  : {
                      p: '10px',
                    }
              }
              aria-label='filter'
              onClick={toggleModal}
            >
              <FilterIcon isOpen={isOpen} />
            </IconButton>
          )}
        </Paper>
      </WrapperFlex>
      {isButtonVisiable ? (
        <Button
          sx={{
            borderRadius: 6,
            height: '32px',
            ml: 4,
          }}
          variant='contained'
          onClick={handleAddButtonClick}
        >
          <Typography>{addButtonLabel}</Typography>
        </Button>
      ) : null}
    </StyledBox>
  );
};

ControlBar.propTypes = {
  header: PropTypes.string,
  addButtonLabel: PropTypes.string,
  handleAddButtonClick: PropTypes.func,
  filterComponent: PropTypes.node,
  schema: PropTypes.instanceOf(Object),
  isButtonVisiable: PropTypes.bool,
};
