import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserRoles } from 'src/constants/constants';
import { useAuthContext } from 'src/hooks';

import {
  CURRENT_USER,
  GET_ADMIN_BY_ID,
  GET_ADMINS,
  GET_AVAILABLE_TESTERS,
  GET_BRANDS,
  GET_CANDIDATES_TO_TEST,
  GET_CITIES,
  GET_COUNTRIES,
  GET_CUSTOMERS,
  GET_INVITE_TEST_CANDIDATES,
  GET_MY_TEST,
  GET_MY_TESTS,
  GET_POSSIBLE_VALUES_TEST,
  GET_STATES,
  GET_TEST,
  GET_TEST_BY_CANDIDATE_TOKEN,
  GET_TEST_BY_USER_ID,
  GET_TEST_PAGE_INFO,
  GET_TESTER_BY_ID,
  GET_TESTER_CITIES,
  GET_TESTER_COUNTRIES,
  GET_TESTER_STATES,
  GET_TESTER_VALUES,
  GET_TESTERS,
  GET_TESTERS_TO_TEST,
  GET_TESTS,
  GET_USER_BY_ID,
} from '../graphql/queries';

export const useCurrentUserQuery = () => {
  const [isError, setIsError] = useState(false);

  const { data, loading, refetch, client } = useQuery(CURRENT_USER, {
    onCompleted: (res) => (res?.currentUser ? setIsError(false) : new Error()),
    onError: () => setIsError(true),
  });

  return {
    user: isError ? null : data?.currentUser,
    loading,
    refetch,
    client,
  };
};

export const useGetTestersQuery = (options) => {
  const { data, loading, fetchMore, refetch } = useQuery(GET_TESTERS, options);

  const testers = useMemo(() => data?.getTesters?.rows || [], [data?.getTesters]);

  const testersCount = useMemo(() => data?.getTesters?.count || 0, [data?.getTesters?.count]);

  return {
    testers,
    loading,
    testersCount,
    fetchMore,
    refetch,
  };
};
export const useGetTesterValues = () => {
  const { data } = useQuery(GET_TESTER_VALUES);
  const possibleValues = useMemo(() => data?.getPossibleValuesTesters || {}, [data]);
  return possibleValues;
};

export const useGetTesterById = () => {
  const { id } = useParams();

  const [getTester, { data: tester, loading, refetch }] = useLazyQuery(GET_TESTER_BY_ID);

  useEffect(() => {
    if (id) {
      getTester({
        variables: {
          testerId: id,
        },
      });
    }
  }, [id]);

  return {
    tester: tester?.getTesterById,
    refetch,
    getTester,
    loading,
  };
};

export const useGetCountries = () => useQuery(GET_COUNTRIES);

export const useGetStates = () => useLazyQuery(GET_STATES);

export const useGetCities = () => useLazyQuery(GET_CITIES);

export const useGetTestsByUserId = () => {
  const { id } = useParams();

  return useQuery(GET_TEST_BY_USER_ID, {
    variables: {
      userId: id,
    },
    skip: !id,
  });
};
export const useGetCustomers = (options) => useQuery(GET_CUSTOMERS, options);
export const useGetBrands = () => {
  const [getBrands, { data, loading }] = useLazyQuery(GET_BRANDS);
  const brands = useMemo(() => data?.getBrands || [], [data]);

  useEffect(() => {
    getBrands();
  }, []);

  return {
    getBrands,
    brands,
    loading,
  };
};

export const useGetAvailableTestersQuery = (options) =>
  useLazyQuery(GET_AVAILABLE_TESTERS, {
    ...options,
  });

export const useGetTestersToTestQuery = (testId) =>
  useQuery(GET_TESTERS_TO_TEST, {
    variables: {
      testId,
    },
  });

export const useGetTestPageInfoQuery = (testId) =>
  useQuery(GET_TEST_PAGE_INFO, {
    variables: {
      testId,
    },
    skip: !testId,
  });

export const useGetTesterValuesQuery = () => useQuery(GET_TESTER_VALUES);

export const useGetCandidatesToTestQuery = () => {
  const { id: testId } = useParams();
  return useQuery(GET_CANDIDATES_TO_TEST, {
    variables: {
      testId,
    },
  });
};

export const useGetTests = () => {
  const { user } = useAuthContext();
  return useLazyQuery(GET_TESTS, {
    skip: !user?.role,
    fetchPolicy: 'network-only',
  });
};

export const useGetTest = () => {
  const { id: testId } = useParams();
  const { data, loading, refetch } = useQuery(GET_TEST, {
    variables: {
      testId,
    },
    skip: !testId,
  });

  const testData = useMemo(() => data?.getTest || {}, [data?.getTest]);

  return {
    testData,
    loading,
    refetch,
  };
};
export const useGetPossibleValuesTest = () => {
  const { data } = useQuery(GET_POSSIBLE_VALUES_TEST);
  const testPossibleValues = useMemo(() => data?.getPossibleValuesTest || {}, [data]);
  return testPossibleValues;
};

export const useGetUserById = () => {
  const { id: userId } = useParams();

  const {
    data: user,
    refetch,
    loading,
  } = useQuery(GET_USER_BY_ID, {
    variables: {
      userId,
    },
  });

  return {
    user: user?.getUserById || {},
    refetch,
    loading,
  };
};

export const useGetTesterGeoValues = () => {
  const { data: countries } = useQuery(GET_TESTER_COUNTRIES);
  const { data: states } = useQuery(GET_TESTER_STATES);
  const { data: cities } = useQuery(GET_TESTER_CITIES);

  return {
    countries: countries?.getTesterGroupedCountries || [],
    states: states?.getTesterGroupedStates || [],
    cities: cities?.getTesterGroupedCities || [],
  };
};

export const useGetIviteTestCandidates = () => useLazyQuery(GET_INVITE_TEST_CANDIDATES);

export const useGetTestByCandidateToken = () => useLazyQuery(GET_TEST_BY_CANDIDATE_TOKEN);

export const useGetMyTestById = () => {
  const { id: testId } = useParams();
  const { user } = useAuthContext();
  const { data, loading, refetch } = useQuery(GET_MY_TEST, {
    variables: {
      testId,
      isGetCustomerInfo: user.role === UserRoles.CUSTOMER || user.role === UserRoles.ADMIN,
      isGetTesterInfo: user.role === UserRoles.TESTER,
    },
    skip: !testId,
  });

  const test = useMemo(() => data?.getMyTest, [data]);

  return {
    test,
    loading,
    refetch,
  };
};

export const useGetMyTests = ({ isTester = true } = {}) => {
  return useQuery(GET_MY_TESTS, {
    variables: {
      isGetTesterInfo: isTester,
    },
  });
};

export const useGetAdmins = (options) => useQuery(GET_ADMINS, options);

export const useGetAdminById = (options) => useQuery(GET_ADMIN_BY_ID, options);
