import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  HeaderTitleContainer,
  StyledModal,
  StyledPaper,
} from 'src/components/common/CommonStyledComponents';
import { FiltersMenuModal } from 'src/components/common/filtersMenu/FiltersMenuModal';
import { TestersFilters } from 'src/components/testers';
import { FilterIcon, SearchIcon } from 'src/components/UI';
import { EyeClosedIcon } from 'src/components/UI/icons/EyeClosedIcon';
import { EyeOpenIcon } from 'src/components/UI/icons/EyeOpenIcon';
import { TesterTestStatus } from 'src/constants/constants';
import { useAddCandidates, useFilter, useTestInfo } from 'src/hooks';
import { filterMenuModal, openedFilterModal } from 'src/styles';

import { InfiniteScrollTestersTable } from '../InfiniteScrollTestersTable/InfiniteScrollTestersTable';

const StyledDivider = styled(Divider)`
  margin: 0 24px 32px 24px;
`;

const StyledBox = styled(Box)`
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledBottomBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: 18px 16px;
  background-color: #f1f1f2;
`;

const StyledTableInfoBox = styled(Box)`
  margin: 16px 24px;
  display: flex;
  justify-content: space-between;
  width: 60.88%;
`;
const StyledPaperForm = styled(Paper)`
  padding: 1px;
  display: flex;
  align-items: center;
  width: 584px;
  box-shadow: none;
  border: 1px solid #dedede;
`;

export const AddTestersModal = ({ open, toggleModal }) => {
  const { id } = useParams();

  const { useRefetchTestersData } = useTestInfo();

  const [testerTestStatus, setTesterTestStatus] = useState(TesterTestStatus.AVAILABLE);

  const [addCandidates] = useAddCandidates();

  const AVAILABLE_STATUS = testerTestStatus === TesterTestStatus.AVAILABLE;
  const IN_TEST_STATUS = testerTestStatus === TesterTestStatus.IN_TEST;

  const { filters, setFilters, clearFilters } = useFilter();

  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [selectedTestersIDs, setTestersIDs] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState(filters.search);

  useEffect(() => {
    if (open) {
      setFilters({
        ...filters,
        testStatus: testerTestStatus,
      });
    }
    if (!open && Object.keys(filters).length) {
      clearFilters();
    }
  }, [testerTestStatus, open]);

  const handleCloseModal = () => {
    setTestersIDs([]);
    toggleModal();
  };

  const handleChangeSearchValue = ({ target }) => {
    setSearch(target.value);
  };

  const handleSearch = useCallback(
    (evt) => {
      evt.preventDefault();
      setFilters({
        ...filters,
        search,
      });
    },
    [search, filters],
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (e.keyCode === 13) handleSearch(e);
    },
    [search, filters],
  );

  const handleClickFinished = useCallback(async () => {
    const data = await addCandidates({
      variables: {
        testId: id,
        userIds: selectedTestersIDs,
      },
    });

    handleCloseModal();

    if (data?.data?.addCandidates?.status) {
      useRefetchTestersData();
    }
  }, [selectedTestersIDs]);

  const handleSetAvailableStatus = () => {
    if (
      testerTestStatus === TesterTestStatus.IN_TEST ||
      testerTestStatus === TesterTestStatus.NOT_AVAILABLE
    ) {
      setTesterTestStatus(TesterTestStatus.AVAILABLE);
    } else {
      setTesterTestStatus(TesterTestStatus.NOT_AVAILABLE);
    }
  };

  const handleSetInTestStatus = () => {
    if (
      testerTestStatus === TesterTestStatus.AVAILABLE ||
      testerTestStatus === TesterTestStatus.NOT_AVAILABLE
    ) {
      setTesterTestStatus(TesterTestStatus.IN_TEST);
    } else {
      setTesterTestStatus(TesterTestStatus.NOT_AVAILABLE);
    }
  };

  const toggleHandler = useCallback(
    (testId) => () => {
      if (selectedTestersIDs.includes(testId)) {
        setTestersIDs((prev) => prev.filter((_id) => _id !== testId));
      } else {
        setTestersIDs((prev) => [...prev, testId]);
      }
    },
    [selectedTestersIDs],
  );

  const checkIsAdded = useCallback(
    (testId) => selectedTestersIDs.includes(testId),
    [selectedTestersIDs],
  );

  const handleClickFilterIcon = useCallback(() => {
    setFilterModalOpen((prev) => !prev);
  }, []);

  return (
    <StyledModal open={open} aria-labelledby='modal-title'>
      <StyledPaper
        sx={{
          width: '956px',
          maxWidth: '956px',
          height: '732px',
        }}
      >
        <Box
          sx={{
            padding: '20px 24px',
          }}
        >
          <HeaderTitleContainer>
            <Typography variant='modalTitle' id='modal-title'>
              ADD TESTERS
            </Typography>
          </HeaderTitleContainer>
        </Box>
        <StyledDivider />
        <StyledBox
          sx={{
            height: 'min-content',
          }}
        >
          <Box
            sx={{
              margin: '0 auto',
            }}
          >
            <StyledPaperForm component='form'>
              <Box
                sx={{
                  position: 'relative',
                }}
              >
                <IconButton
                  type='submit'
                  sx={{
                    p: '10px',
                  }}
                  aria-label='search'
                  onClick={handleSearch}
                >
                  <SearchIcon />
                </IconButton>
                <FiltersMenuModal
                  sx={{
                    ...filterMenuModal,
                    height: '590px',
                  }}
                  isOpen={isFilterModalOpen}
                  toggle={handleClickFilterIcon}
                >
                  <TestersFilters />
                </FiltersMenuModal>
              </Box>
              <InputBase
                sx={{
                  ml: 1,
                  flex: 1,
                }}
                placeholder='Find testers'
                inputProps={{
                  'aria-label': 'findTesters',
                }}
                value={search}
                onChange={handleChangeSearchValue}
                onKeyDown={handleKeyPress}
              />
              <IconButton
                aria-label='filter'
                sx={
                  isFilterModalOpen
                    ? openedFilterModal
                    : {
                        p: '10px',
                      }
                }
                onClick={handleClickFilterIcon}
              >
                <FilterIcon isOpen={isFilterModalOpen} />
              </IconButton>
            </StyledPaperForm>
          </Box>
          <StyledTableInfoBox>
            <Typography>{`${count} Results`}</Typography>
            <Box>
              <Chip
                onClick={handleSetAvailableStatus}
                clickable
                sx={{
                  borderRadius: '4px',
                  height: '28px',
                  width: '108px',
                  marginRight: '6px',
                }}
                icon={<EyeOpenIcon color={AVAILABLE_STATUS ? 'white' : '#676767'} />}
                label='Available'
                variant={AVAILABLE_STATUS ? 'filled' : 'outlined'}
                color={AVAILABLE_STATUS ? 'primary' : 'default'}
              />
              <Chip
                onClick={handleSetInTestStatus}
                clickable
                sx={{
                  borderRadius: '4px',
                  height: '28px',
                  width: '92px',
                }}
                icon={<EyeClosedIcon color={IN_TEST_STATUS ? 'white' : '#676767'} />}
                label='In Test'
                variant={IN_TEST_STATUS ? 'filled' : 'outlined'}
                color={IN_TEST_STATUS ? 'primary' : 'default'}
              />
            </Box>
          </StyledTableInfoBox>
        </StyledBox>
        <Box
          sx={{
            height: '100%',
            maxHeight: '475px',
          }}
        >
          <InfiniteScrollTestersTable
            setCount={setCount}
            toggleHandler={toggleHandler}
            checkIsAdded={checkIsAdded}
          />
        </Box>
        <StyledBottomBox>
          <Button
            sx={{
              marginRight: 4,
              height: '28px',
              fontWeight: '400',
              color: '#676767',
            }}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            variant='contained'
            sx={{
              width: '103px',
              height: '28px',
            }}
            onClick={handleClickFinished}
          >
            Finished
          </Button>
        </StyledBottomBox>
      </StyledPaper>
    </StyledModal>
  );
};

AddTestersModal.propTypes = {
  open: PropTypes.bool,
  toggleModal: PropTypes.func,
};
