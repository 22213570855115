export const CalendarIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.8333 18.3333H4.16667C3.2475 18.3333 2.5 17.5858 2.5 16.6667V5.00001C2.5 4.08084 3.2475 3.33334 4.16667 3.33334H5.83333V1.66667H7.5V3.33334H12.5V1.66667H14.1667V3.33334H15.8333C16.7525 3.33334 17.5 4.08084 17.5 5.00001V16.6667C17.5 17.5858 16.7525 18.3333 15.8333 18.3333ZM15.8342 16.6667L15.8333 6.66667H4.16667V16.6667H15.8342ZM7.50004 9.16667H5.83337V10.8333H7.50004V9.16667ZM7.50004 12.5H5.83337V14.1667H7.50004V12.5ZM10.8334 9.16667H9.16671V10.8333H10.8334V9.16667ZM10.8334 12.5H9.16671V14.1667H10.8334V12.5ZM14.1667 9.16667H12.5V10.8333H14.1667V9.16667ZM14.1667 12.5H12.5V14.1667H14.1667V12.5Z'
      fill='#676767'
    />
  </svg>
);
