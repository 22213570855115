import { theme } from 'src/theme';

export const datePickerStyle = {
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiIconButton-root': {
    padding: '0px !important',
  },
  '& .MuiOutlinedInput-root': {
    paddingLeft: '11px',
  },
  '& .MuiOutlinedInput-input': {
    paddingY: '0 !important',
  },
};

export const mediaSectionStyles = {
  header: {
    justifyContent: 'space-between',
    width: '100%',
    mb: 5,
    zIndex: 10,
    position: 'relative',
  },
  mediaList: {
    flexDirection: 'column',
    alignItems: 'start',
    gap: 10,
  },
  button: {
    upload: {
      cursor: 'pointer',
      background: theme.colors.neutral.black,
      color: theme.colors.neutral.white,
      fontSize: 14,
      fontWeight: 400,
      borderRadius: 6,
      py: 2,
      px: 4,
    },
  },
};
