import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { LogoIconWithText } from 'src/components';
import { Flex } from 'src/components/common/CommonStyledComponents';

import { styles } from './styles';

export const NonAuthorizatedLayout = ({ children }) => (
  <>
    <Box sx={[styles.headerContainer, styles.nonAuthHeaderContainer]}>
      <Flex sx={styles.headerContent}>
        <LogoIconWithText />
        <Box component='a' href='https://mountainerg.com/'>
          Back to home
        </Box>
      </Flex>
    </Box>
    {children}
  </>
);

NonAuthorizatedLayout.propTypes = {
  children: PropTypes.node,
};
