import { useCallback, useState } from 'react';
import { ControlBar, TestsTable } from 'src/components';
import { StyledPageContainer } from 'src/components/common/CommonStyledComponents';
import { CreateTestForm } from 'src/components/testsOverview/createTest/CreateTestForm';
import { TestsFilters } from 'src/components/testsOverview/TestsFilters';
import { testsFilterSchema } from 'src/utils/formSchemas';

const Tests = () => {
  const [createTestModalIsOpen, setCreateTestModalIsOpen] = useState(false);
  const toggleModal = useCallback(() => {
    setCreateTestModalIsOpen((prev) => !prev);
  }, []);

  return (
    <StyledPageContainer>
      <ControlBar
        header='Tests'
        addButtonLabel='Create Test'
        handleAddButtonClick={toggleModal}
        schema={testsFilterSchema}
        filterComponent={<TestsFilters />}
      />
      <TestsTable />
      <CreateTestForm open={createTestModalIsOpen} toggleModal={toggleModal} />
    </StyledPageContainer>
  );
};

export default Tests;
