import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BackButton, Loader, OptionsMenu, RemoveModal, Status } from 'src/components';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { FormikWrapperRightPanel } from 'src/components/customerDetail';
import { TestersTestsTable } from 'src/components/testers';
import { useGetTestsByUserId, useGetUserById, useRemoveUser } from 'src/hooks';
import { theme } from 'src/theme';
import { isEmpty } from 'src/utils/helpers';

import { PageNotMatch } from './PageNotMatch';

const StyledBox = styled(Flex)({
  padding: '40px 48px',
  margin: '0 auto',
  maxWidth: 1360,
  flexDirection: 'column',
  alignItems: 'center',
});

const Grid = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  gap: 77,
  maxWidth: 1285,
  width: '100%',
  marginTop: 40,
});

const CustomerDetail = () => {
  const { user: customer, loading } = useGetUserById();
  const { data: userTests, refetch } = useGetTestsByUserId();
  const removeUser = useRemoveUser();
  const [removedUser, setRemovedUser] = useState(null);
  const toggleModal = useCallback(() => {
    setRemovedUser((prev) => !prev);
  }, []);

  const { id } = useParams();

  const navigate = useNavigate();

  const acceptRemoveUser = useCallback(() => {
    removeUser({
      id: removedUser,
    }).then(() => {
      navigate('/customers');
    });
  }, [removedUser]);

  const options = useMemo(
    () => [
      {
        title: 'Remove User',
        callback: () => {
          setRemovedUser(id);
        },
      },
    ],
    [],
  );

  const showInitials = useMemo(() => {
    if (customer?.firstName && customer?.lastName) {
      return `${customer.firstName[0].toUpperCase() + customer.lastName[0].toUpperCase()}`;
    }
    return customer?.email?.[0].toUpperCase();
  }, [customer?.lastName, customer?.firstName, customer?.email]);

  if (loading) return <Loader loading={loading} />;
  if (isEmpty(customer)) return <PageNotMatch />;

  return (
    <StyledBox>
      <Flex
        sx={{
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <BackButton navigateStep={1} />
        <Flex>
          <Box mr={3}>
            <Status status={customer.testStatus} />
          </Box>
          <OptionsMenu options={options} />
        </Flex>
      </Flex>
      <Grid>
        <Box>
          <Flex
            sx={{
              alignItems: 'center',
              mb: 6,
            }}
          >
            <Avatar
              sx={{
                width: 60,
                height: 60,
                backgroundColor: theme.colors.blue,
              }}
              alt='avatar'
              src={`${process.env.REACT_APP_DOMAIN}/${customer.imageProfileLink}`}
            >
              {showInitials}
            </Avatar>
            <Typography
              variant='h3'
              sx={{
                ml: 5,
              }}
            >
              {customer.firstName} {customer.lastName}
            </Typography>
          </Flex>
          <Flex justifyContent='space-between' mb={6}>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              Tests
            </Typography>
            <Flex>
              <Flex>
                <Typography
                  sx={{
                    fontWeight: 700,
                    mr: 2,
                  }}
                >
                  Tests Completed
                </Typography>
                <Typography>{userTests?.getTestsByUserId?.competedTestCount}</Typography>
              </Flex>
              <Flex
                sx={{
                  ml: 5,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    mr: 2,
                  }}
                >
                  Ongoing Tests
                </Typography>
                <Typography>{userTests?.getTestsByUserId?.ongoingTestCount}</Typography>
              </Flex>
            </Flex>
          </Flex>
          <TestersTestsTable data={userTests?.getTestsByUserId?.rows} refetch={refetch} />
        </Box>
        <Box>
          <FormikWrapperRightPanel customer={customer} />
        </Box>
      </Grid>
      <RemoveModal
        title='Are you sure you want to remove this user?'
        submitLabel='Yes, Remove User'
        open={!!removedUser}
        callback={acceptRemoveUser}
        closeModal={toggleModal}
        itemId={removedUser}
      />
    </StyledBox>
  );
};

export default CustomerDetail;
