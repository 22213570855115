import Box from '@mui/material/Box';
import { func, instanceOf } from 'prop-types';
import { DetailInput, DetailLabel } from 'src/components/UI';

export const AdminInfoTab = ({ values, handleChange }) => (
  <Box>
    <DetailLabel label='Email'>
      <DetailInput
        name='email'
        handleChange={handleChange}
        value={values.email}
        placeholder='Enter Email'
        submitOnValueChange={true}
      />
    </DetailLabel>
  </Box>
);

AdminInfoTab.propTypes = {
  values: instanceOf(Object),
  handleChange: func,
};
