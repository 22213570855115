import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { NoteIcon, StyledTable } from 'src/components/UI';
import { TableHeader } from 'src/components/UI/tables';
import { EmptyTableBanner } from 'src/components/UI/tables/EmptyTableBanner';
import { CandidateStatus } from 'src/constants/constants';
import { useTestInfo } from 'src/hooks';

import { CandidateTableStatus } from '../candidateTableStatus/candidateTableStatus';

const Text = styled(Typography)(() => ({
  fontSize: '14px',
  color: 'black',
}));

const COLUMNS = [
  {
    title: 'Name',
    sortKey: 'NAME',
  },
  {
    title: 'Group',
    sortKey: 'TESTER_GROUP',
  },
  {
    title: 'Location',
    sortKey: 'LOCATION',
  },
];

const Header = ({ handleRequestSort, orderBy, order }) => {
  return (
    <TableHeader
      columns={COLUMNS}
      orderBy={orderBy}
      order={order}
      createSortHandler={handleRequestSort}
    ></TableHeader>
  );
};

Header.propTypes = {
  onSelectAllClick: PropTypes.func.isRequired,
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  checkSameStatus: PropTypes.bool.isRequired,
  handleRequestSort: PropTypes.func,
  orderBy: PropTypes.string,
  order: PropTypes.string,
};

export const CandidatesTableSection = ({
  selected,
  setSelected,
  sentInviteToCandidate,
  reinviteToCandidate,
  removeCandidate,
  handleEnrollCandidate,
  handleRejectCandidate,
}) => {
  const { candidatesList, useRefetchTestersData } = useTestInfo();

  const [orderBy, setOrderBy] = useState('NAME');
  const [order, setOrder] = useState('ASC');

  useEffect(() => {
    useRefetchTestersData({
      candidatesSort: {
        type: order,
        field: orderBy,
      },
    });
  }, [order, orderBy]);

  const handleRequestSort = useCallback(
    (property) => () => {
      const isAsc = orderBy === property && order === 'ASC';
      setOrder(isAsc ? 'DESC' : 'ASC');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const checkSameStatus = candidatesList.some(
    (candidate) => candidate.candidateStatus === CandidateStatus.ADDED,
  );

  const rowCount = candidatesList.filter((n) => n.candidateStatus === CandidateStatus.ADDED);

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelecteds = candidatesList
          .filter((n) => n.candidateStatus === CandidateStatus.ADDED)
          .map((item) => item?.id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    [candidatesList],
  );

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const justifyContentStyle = (status) =>
    status === CandidateStatus.DECLINED || status === CandidateStatus.UN_ENROLLED
      ? 'flex-start'
      : 'space-between';

  if (candidatesList?.length === 0 || !candidatesList) {
    return <EmptyTableBanner text='Add dem testers, gurl' />;
  }

  return (
    <StyledTable>
      <Header
        onSelectAllClick={handleSelectAllClick}
        numSelected={selected.length}
        rowCount={rowCount.length}
        checkSameStatus={!checkSameStatus}
        handleRequestSort={handleRequestSort}
        orderBy={orderBy}
        order={order}
      />
      <TableBody>
        {candidatesList?.map((candidate) => {
          const isItemSelected = isSelected(candidate?.id);

          return (
            <TableRow key={candidate.id} selected={isItemSelected} aria-checked={isItemSelected}>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <NoteIcon />
                  <Text
                    sx={{
                      fontWeight: 700,
                      color: '#000000',
                      marginLeft: '8px',
                    }}
                  >
                    {candidate.user.contactName}
                  </Text>
                </Box>
              </TableCell>
              <TableCell>
                <Text>{candidate.user.tester.testerGroup}</Text>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: justifyContentStyle(candidate.candidateStatus),
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <Text>{candidate.user.tester.state?.name}</Text>
                    <Text
                      sx={{
                        fontSize: '12px',
                      }}
                    >
                      {candidate.user.tester.city}
                    </Text>
                  </Box>
                  <Box>
                    <CandidateTableStatus
                      isCityVisible={
                        !!candidate?.user?.tester?.state || !!candidate?.user?.tester?.city
                      }
                      candidateId={candidate.id}
                      sentInviteToCandidate={sentInviteToCandidate}
                      updateDate={candidate.lastUpdated}
                      candidateStatus={candidate.candidateStatus}
                      reinviteToCandidate={reinviteToCandidate}
                      removeCandidate={removeCandidate}
                      handleEnrollCandidate={handleEnrollCandidate}
                      handleRejectCandidate={handleRejectCandidate}
                    />
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

CandidatesTableSection.propTypes = {
  selected: PropTypes.instanceOf(Array).isRequired,
  setSelected: PropTypes.func.isRequired,
  sentInviteToCandidate: PropTypes.func.isRequired,
  reinviteToCandidate: PropTypes.func.isRequired,
  removeCandidate: PropTypes.func.isRequired,
  handleEnrollCandidate: PropTypes.func.isRequired,
  handleRejectCandidate: PropTypes.func.isRequired,
};
