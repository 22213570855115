export const styles = {
  headerContainer: {
    padding: '12px 0',
    background: '#F8F8F8',
    boxShadow: 'none',
    '& .MuiToolbar-root': {
      minHeight: '80px',
    },
  },
  nonAuthHeaderContainer: {
    background: '#FFFFFF',
    padding: '12px 122px 12px 36px',
  },
  headerContent: {
    maxWidth: '1352px',
    justifyContent: 'space-between',
    width: '100%',
    margin: '0px auto',
    padding: '0px 0px 0px 4px',
    '& img': {
      maxWidth: '326px',
      maxHeight: '86px',
    },
    '& a': {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      textTransform: 'uppercase',
      textDecoration: 'none',
      color: '#003048',
    },
  },
};
