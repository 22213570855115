import { Backdrop } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { func } from 'prop-types';
import { useState } from 'react';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { styles } from 'src/components/testers/createTesterForm/styles';
import { testerCreationForm } from 'src/styles';

import { detailsTabStyles } from './backDropStyles';

export const DetailsInseam = ({ handleChangeInchesToCm, handleChangeCm }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { values = {}, errors = {}, handleSubmit } = useFormikContext();

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      handleSubmit();
    }
  };

  const closeAndSubmit = () => {
    setIsEditMode((prev) => !prev);
    handleSubmit();
  };

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };

  return (
    <Box>
      {isEditMode ? (
        <Box sx={styles.twoColumns}>
          <Backdrop
            open={isEditMode}
            invisible={false}
            onClick={closeAndSubmit}
            sx={detailsTabStyles.backDropStyle}
          />
          <StyledLabel label='Inseam (in)' sx={detailsTabStyles.styledLabelFont}>
            <TextField
              type='number'
              sx={testerCreationForm.input}
              size='small'
              name='inseamInches'
              onInput={handleChangeInchesToCm('inseamCm')}
              value={values.inseamInches}
              error={!!errors.inseamInches}
              helperText={errors.inseamInches}
              placeholder='Inches'
              onKeyDown={keyDownHandler}
            />
          </StyledLabel>
          <StyledLabel label='Inseam (cm)' sx={detailsTabStyles.styledLabelFont}>
            <TextField
              type='number'
              sx={testerCreationForm.input}
              size='small'
              name='inseamCm'
              onInput={handleChangeCm('inseamInches')}
              value={values.inseamCm}
              error={!!errors.inseamCm}
              helperText={errors.inseamCm}
              placeholder='cms'
              onKeyDown={keyDownHandler}
            />
          </StyledLabel>
        </Box>
      ) : (
        <Box onClick={changeViewBox}>
          <StyledLabel label='Inseam' sx={detailsTabStyles.styledLabelFont} />
          <Box sx={detailsTabStyles.styleForResultDetail}>
            {values.inseamInches !== null || values.inseamCm !== null ? (
              `${values.inseamInches || '0'}''
              (${values.inseamCm || '0'}cm)`
            ) : (
              <Box color='#444'>Enter inseam</Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

DetailsInseam.propTypes = {
  handleChangeInchesToCm: func,
  handleChangeCm: func,
};
