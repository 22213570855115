import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import { func, node, number } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { SuccessIcon } from 'src/components';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { theme } from 'src/theme';

const Ctx = React.createContext();

const ToastContainer = styled(Box)({
  position: 'fixed',
  left: '50%',
  top: 0,
  transform: 'translateX(-50%)',
  zIndex: 9999,
});

const StyledToast = styled(Flex)({
  fontSize: 16,
  margin: 10,
  padding: '12px 36px',
  borderRadius: 2,
});

const DefaultToast = ({ message, onClick, id }) => (
  <StyledToast
    sx={{
      backgroundColor: theme.colors.neutral.black,
      color: theme.colors.neutral.white,
    }}
    onClick={onClick}
    tabIndex={id}
  >
    {message}
  </StyledToast>
);

const SuccessToast = ({ message, onClick, id }) => (
  <StyledToast
    sx={{
      backgroundColor: theme.colors.neutral.black,
      color: theme.colors.neutral.white,
    }}
    onClick={onClick}
    tabIndex={id}
  >
    <Box
      sx={{
        marginRight: 4,
      }}
    >
      <SuccessIcon />
    </Box>
    {message}
  </StyledToast>
);

const ErrorToast = ({ message, onClick, id }) => (
  <StyledToast
    sx={{
      backgroundColor: theme.colors.error,
      color: theme.colors.neutral.white,
    }}
    onClick={onClick}
    tabIndex={id}
  >
    {message}
  </StyledToast>
);

const toastVariants = {
  success: SuccessToast,
  error: ErrorToast,
  default: DefaultToast,
};

let toastCount = 0;

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = React.useState([]);

  const removeToast = useCallback(
    (id) => {
      const newToasts = toasts.filter((t) => t.id !== id);
      setToasts(newToasts);
    },
    [toasts],
  );

  const onDismiss = useCallback((id) => () => removeToast(id), []);

  const autoRemove = (id, timer) => setTimeout(() => removeToast(id), timer);

  const addToast = ({ timer = null, variant = 'default', message = '' }) => {
    const toast = {
      id: toastCount,
      variant,
      message,
    };

    setToasts((prev) => [...prev, toast]);

    if (timer) {
      autoRemove(toast.id, timer);
    }

    toastCount += 1;
  };

  const ErrorMessage = (message) => {
    addToast({
      message,
      variant: 'error',
      timer: 6000,
    });
  };

  const SuccessMessage = (message) => {
    addToast({
      message,
      variant: 'success',
      timer: 6000,
    });
  };

  const value = useMemo(
    () => ({
      addToast,
      removeToast,
      ErrorMessage,
      SuccessMessage,
    }),
    [],
  );

  return (
    <Ctx.Provider value={value}>
      {children}
      <ToastContainer>
        {toasts.map((toast) => {
          const Toast = toastVariants[toast.variant];
          return <Toast key={toast.id} onClick={onDismiss(toast.id)} {...toast} />;
        })}
      </ToastContainer>
    </Ctx.Provider>
  );
};

ToastProvider.propTypes = {
  children: node,
};

const toastProps = {
  message: node,
  onClick: func,
  id: number,
};

DefaultToast.propTypes = toastProps;
SuccessToast.propTypes = toastProps;
ErrorToast.propTypes = toastProps;

export const useToasts = () => React.useContext(Ctx);
