import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import { Flex, StyledPaper } from 'src/components/common/CommonStyledComponents';

export const ConfirmStyledPaper = styled(StyledPaper)({
  borderRadius: '12px',
  height: 'auto',
  width: '435px',
  minWidth: '435px',
});

export const WrapperBox = styled(Box)({
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const BoxContainer = styled(Box)({
  margin: '12px',
});

export const BoxContainerStyled = styled(Box)({
  display: 'flex',
  borderRadius: '12px',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '24px',
  background: 'black',
});

export const LabelText = styled(Typography)({
  color: 'white',
  textAlign: 'center',
  fontSize: '14px',
});

export const FlexStyled = styled(Flex)({
  marginTop: '27px',
  minWidth: 'auto',
  background: 'black',
  flexDirection: 'row',
  justifyContent: 'center',
});

export const ButtonStyled = styled(Button)({
  height: '29px',
  fontWeight: '400',
  color: '#676767',
});

export const StyledBottomBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: 18px 16px;
  background-color: #f1f1f2;
`;

export const StyledDivider = styled(Divider)`
  margin: 14px 0px 32px;
`;
