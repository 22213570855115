import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';

export const BlackButton = styled(Button)`
  width: 103px;
  height: 28px;
  font-size: 14px;
`;

export const DefaultButton = styled(Button)`
  font-size: 14px;
  height: 28px;
`;
export const DefaultText = styled(Typography)`
  font-size: 14px;
`;
export const DateText = styled(Typography)`
  font-size: 12px;
`;
