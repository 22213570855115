import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { ForgotPasswordStep } from './ForgotPasswordStep';
import { ResetLinkStep } from './ResetLinkStep';

export const ForgotPasswordForm = ({ currentStep, handleBackToLogin }) => {
  return useMemo(() => {
    const steps = {
      0: <ForgotPasswordStep handleBackToLogin={handleBackToLogin} />,
      1: <ResetLinkStep handleBackToLogin={handleBackToLogin} />,
    };

    return steps[currentStep];
  }, [currentStep]);
};

ForgotPasswordForm.propTypes = {
  handleBackToLogin: PropTypes.func,
  currentStep: PropTypes.number,
};
