import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { saveAs } from 'file-saver';
import { bool, func, instanceOf } from 'prop-types';
import { useCallback } from 'react';
import { ArrowLeft, ArrowRight, CloseIcon, DownloadIcon } from 'src/components/UI';

export const MediaPreview = ({ open, onClose, currentMediaInfo, onClickPrev, onClickNext }) => {
  const onDownloadClick = useCallback(() => {
    const currentMedia = currentMediaInfo.fileUrl;
    const filename = currentMedia.substring(currentMedia.lastIndexOf('/') + 1);
    saveAs(`${process.env.REACT_APP_DOMAIN}/${currentMedia}`, filename);
  }, [currentMediaInfo.fileUrl]);

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': {
          maxWidth: '1200px',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflowY: 'visible',
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <IconButton
          sx={{
            width: '64px',
            height: '64px',
            marginTop: '40px',
          }}
          onClick={onClickPrev}
        >
          <ArrowLeft changeOpacity={currentMediaInfo.isFirst} />
        </IconButton>
        <Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton
              onClick={onDownloadClick}
              sx={{
                width: '40px',
                height: '40px',
              }}
            >
              <DownloadIcon color='#ffff' />
            </IconButton>
            <IconButton
              onClick={onClose}
              sx={{
                width: '40px',
                height: '40px',
              }}
            >
              <CloseIcon width={23} height={23} color='#ffff' />
            </IconButton>
          </Box>
          <Box
            component='img'
            sx={{
              borderRadius: '8px',
              maxHeight: '90vh',
              width: '100%',
            }}
            alt='fileUrl'
            // currentMediaInfo.fileUrl
            src={`${process.env.REACT_APP_DOMAIN}/${currentMediaInfo.fileUrl}`}
          />
        </Box>
        <IconButton
          sx={{
            width: '64px',
            height: '64px',
            marginTop: '40px',
          }}
          onClick={onClickNext}
        >
          <ArrowRight changeOpacity={currentMediaInfo.isLast} />
        </IconButton>
      </Box>
    </Dialog>
  );
};

MediaPreview.propTypes = {
  currentMediaInfo: instanceOf(Object),
  open: bool,
  onClose: func.isRequired,
  onClickPrev: func,
  onClickNext: func,
};
