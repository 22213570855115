import SvgIcon from '@mui/material/SvgIcon';

export const ChevronRight = () => (
  <SvgIcon viewBox='-5 -5 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.600006 1.57541L4.0846 5.05999L0.600006 8.54458L1.67538 9.62L6.23539 5.05999L1.67538 0.499994L0.600006 1.57541Z'
      fill='#676767'
    />
  </SvgIcon>
);
