export const styles = {
  btnInvite: {
    width: '72px',
    height: '32px',
    border: '1px solid #000000',
    boxSizing: 'border-box',
    borderRadius: '24px',
    marginRight: '16px',
  },
  modalInvite: {
    '& .MuiPaper-root.MuiDialog-paper': {
      maxWidth: '496px',
      width: '100%',
      boxShadow: '0px 8px 12px 0px #00000033',
    },
    '& .MuiDialogContent-root': {
      padding: '0px',
    },
    '& .MuiDialogActions-root': {
      backgroundColor: '#F1F1F2',
      padding: '18px 16px',
      marginTop: '32px',
    },
    '& h2.MuiTypography-root.MuiDialogTitle-root': {
      textTransform: 'uppercase',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: ' 20px',
      textAlign: 'left',
      padding: '20px 0px',
      margin: '0px 24px',
      borderBottom: '1px solid #DEDEDE',
      color: '#000000',
      marginBottom: '12px',
    },
    '& h5.MuiTypography-root': {
      marginTop: '20px',
    },
  },
  inputWrapper: {
    '& svg': {
      visibility: 'hidden',
    },
    '&:hover svg': {
      visibility: 'visible',
    },
    '& .MuiInputBase-root.MuiInput-root': {
      width: '100%',
      border: '1px solid #DEDEDE',
      borderRadius: '3px',
      maxHeight: '40px',
      '&.Mui-error': {
        borderColor: '#d32f2f',
      },
      '& svg': {
        marginRight: '7px',
        cursor: 'pointer',
      },
    },
    '& .MuiInputBase-root.MuiInput-root:before, & .MuiInputBase-root.MuiInput-root:after, & .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled):before':
      {
        borderBottom: 0,
      },
    '& .MuiInputBase-input.MuiInput-input, & .MuiInputBase-input.MuiInput-input::placeholder': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      color: '#000000',
    },
    '& .MuiInputBase-input.MuiInput-input::placeholder': {
      color: '#676767',
      opacity: 1,
    },
    '& .MuiInputBase-input.MuiInput-input': {
      padding: '10px 11px 10px 15px',
      height: 'unset',
    },
  },
  helpBlock: {
    '&.MuiTypography-root': {
      margin: '4px 0px 8px 0px',
      fontSize: ' 12px',
      fontWeight: 400,
      lineHeight: '16px',
      color: '#676767',
    },
  },
  error: {
    '&': {
      color: '#d32f2f',
      fontSize: '0.75rem',
      lineHeight: 1.66,
      margin: '3px 14px 0px 14px',
    },
  },
  btnCancel: {
    '&.MuiButtonBase-root.MuiButton-root': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      color: '#676767',
      padding: 0,
      '&:hover': {
        background: 'none',
        color: '#000000',
      },
    },
  },
  btnSend: {
    '&.MuiButtonBase-root.MuiButton-root': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      borderRadius: '4px',
      background: '#000000',
      color: '#ffffff',
      padding: '4px 16px',
      '&.Mui-disabled': {
        backgroundColor: '#DEDEDE',
        color: '#FFFFFF',
      },
    },
  },
  btnClose: {
    maxHeight: 18,
  },
  fieldWrapper: {
    '&': {
      padding: '0px 48px 0px 48px',
    },
  },
  selectRole: {
    '&': {
      width: '100%',
    },
    '& legend>span': {
      display: 'none',
    },
    '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select': {
      padding: '10px 32px 10px 12px',
      minHeight: 'unset',
      maxHeight: '40px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      color: '#000000',
    },
    '& .chevronWrapper': {
      position: 'absolute',
      right: '14px',
      top: 0,
      bottom: 0,
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
      borderColor: '#DEDEDE',
      borderRadius: '3px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderColor: '#DEDEDE',
        borderWidth: '1px',
      },
    '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d32f2f',
    },
    '&.Mui-focused': {
      backgroundColor: '#F1F1F2',
    },
  },
  selectRoleMenu: {
    '&': {
      boxShadow: ' 0px 8px 12px 0px #00000033',
      marginTop: '2px',
    },
    '& ul > li.MuiButtonBase-root.MuiMenuItem-root': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      color: '#000000',
    },
  },
  selectPlaceholder: {
    '&': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      color: '#676767',
    },
  },
  autocomplete: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
    },
    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      color: '#000000',
      padding: '10px 16px 10px 12px',
      '&::placeholder': {
        color: '#676767',
      },
    },
    '& .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator': {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
      right: '10px',
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DEDEDE',
      borderRadius: '3px',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderColor: '#DEDEDE',
        borderWidth: '1px',
      },
    '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d32f2f',
    },
  },
  autocompleteMenu: {
    '&': {
      boxShadow: ' 0px 8px 12px 0px #00000033',
      marginTop: '2px',
    },
    '& .MuiButtonBase-root.MuiMenuItem-root': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      color: '#000000',
    },
  },
  addBrand: {
    fontWeight: '700 !important',
    color: '#9B51E0 !important',
  },
  inviteSendWrapper: {
    width: '100%',
    position: 'absolute',
    top: '60px',
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  inviteSendContainer: {
    width: '187px',
    height: '48px',
    backgroundColor: '#444444',
    borderRadius: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    '& svg': {
      marginRight: '20px',
    },
  },
  inviteBox: {
    display: 'inline',
  },
};
