import { useState } from 'react';

export const useCallbackWithLoader = (cb) => {
  const [loading, setLoading] = useState(false);

  const callbackWithLoading = (...args) => {
    setLoading(true);

    cb(...args).finally(() => {
      setLoading(false);
    });
  };

  return {
    callback: callbackWithLoading,
    loading,
  };
};
