import Button from '@mui/material/Button';
import { bool, string } from 'prop-types';
import { useCallback } from 'react';
import { useReInviteUser } from 'src/hooks';
import { useToasts } from 'src/hooks/useToasts';
import { theme } from 'src/theme';

export const ResendInviteButton = ({ visible = false, userId, isInvited = false }) => {
  const [resendInvite] = useReInviteUser();
  const { addToast } = useToasts();

  const handleClick = useCallback(() => {
    resendInvite({
      variables: {
        userId,
      },
      onCompleted: (res) => {
        if (res.reInviteUser.status) {
          addToast({
            message: 'Invite Sent',
            variant: 'success',
            timer: 6000,
          });
        } else {
          addToast({
            message: 'Error! Please, try again.',
            variant: 'error',
            timer: 6000,
          });
        }
      },
      onError: () => {
        addToast({
          message: 'Error! Please, try again.',
          variant: 'error',
          timer: 6000,
        });
      },
    });
  }, []);

  if (!visible) return null;
  return (
    <Button
      sx={{
        backgroundColor: 'none',
        border: ` 1px solid ${theme.colors.neutral.black}`,
      }}
      onClick={handleClick}
    >
      {isInvited ? 'Resend Invite' : 'Send Invite'}
    </Button>
  );
};

ResendInviteButton.propTypes = {
  visible: bool,
  userId: string,
  isInvited: bool,
};
