import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { StatusChip } from 'src/components/common/StatusChip';
import { BackButton } from 'src/components/UI';
import { TestStatus } from 'src/constants/constants';

import { OptionsMenu } from '../../UI/OptionsMenu';
import { ConfirmModal } from '../confirmModal/confirmModal';

const TitleBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 28px 12px;
  margin-right: 44px;
`;

export const TitleBar = ({ title, status, closeTest, duplicateTest, navigateStep }) => {
  const [duplicateModalIsOpen, setDuplicateModalIsOpen] = useState(false);
  const [closeTestModalIsOpen, setCloseTestModalIsOpen] = useState(false);

  const toggleModalDuplicate = () =>
    useCallback(
      setDuplicateModalIsOpen((prev) => !prev),
      [],
    );

  const toggleModalCloseTest = () =>
    useCallback(
      setCloseTestModalIsOpen((prev) => !prev),
      [],
    );

  const options =
    status === TestStatus.CLOSED
      ? [
          {
            title: 'Duplicate',
            callback: toggleModalDuplicate,
          },
        ]
      : [
          {
            title: 'Duplicate',
            callback: toggleModalDuplicate,
          },
          {
            title: 'Close Test',
            callback: toggleModalCloseTest,
          },
        ];

  return (
    <TitleBox>
      <BackButton title={title} navigateStep={navigateStep} />
      <Flex>
        <StatusChip status={status} />
        <OptionsMenu
          options={options}
          wrapperOptions={{
            marginLeft: '8px',
          }}
        />
        <ConfirmModal
          open={duplicateModalIsOpen}
          toggleModal={toggleModalDuplicate}
          callback={duplicateTest}
          labalTest='Are you sure you want to duplicate this test?'
          agreeButtonTex='Yes, Duplicate it'
        />
        <ConfirmModal
          open={closeTestModalIsOpen}
          toggleModal={toggleModalCloseTest}
          callback={closeTest}
          labalTest='Are you sure you want to close and archive this test?'
          agreeButtonTex='Yes, Close Test'
        />
      </Flex>
    </TitleBox>
  );
};

TitleBar.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  closeTest: PropTypes.func,
  duplicateTest: PropTypes.func,
  navigateStep: PropTypes.number,
};
