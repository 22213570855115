import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChangeAvatarModal, OptionsMenu, RefreshIcon, RemoveModal } from 'src/components';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { UserName } from 'src/components/userProfile';
import { avatarStyle, wrapperStyle } from 'src/components/userProfile/styles';
import UserInfoFormikWrapper from 'src/components/userProfile/UserInfoFormikWrapper';
import { UserRoles } from 'src/constants/constants';
import { useAuthContext, useRemoveMyAccount } from 'src/hooks';

export const UserProfile = () => {
  const { user, handleLogout } = useAuthContext();
  const removeMyAccount = useRemoveMyAccount();
  const navigate = useNavigate();

  const [isChangeAvatarModalOpen, setIsChangeAvatarModalOpen] = useState(false);
  const toggleChangeAvatarModal = useCallback(() => {
    setIsChangeAvatarModalOpen((prev) => !prev);
  }, []);

  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const closeModal = () => setIsOpenRemoveModal(null);

  const showInitials = useMemo(() => {
    if (user?.firstName && user?.lastName) {
      return `${user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}`;
    }
    return user?.email[0].toUpperCase();
  }, [user?.lastName, user?.firstName, user?.email]);

  const acceptRemoveUser = useCallback(() => {
    removeMyAccount().then((res) => {
      if (res?.data?.removeMyAccount?.status) {
        handleLogout();
        navigate('/good-bye');
      }
    });
  }, [user]);

  const options = useMemo(
    () =>
      user?.role === UserRoles.TESTER && [
        {
          title: 'Delete Account',
          callback: () => setIsOpenRemoveModal(true),
        },
      ],
    [user],
  );

  if (!user) return null;
  return (
    <Box sx={wrapperStyle}>
      <Flex
        sx={{
          justifyContent: 'space-between',
          mb: 7,
        }}
      >
        <Flex
          sx={{
            gap: 5,
          }}
        >
          <Avatar
            sx={avatarStyle}
            alt='avatar'
            src={`${process.env.REACT_APP_DOMAIN}/${user.imageProfileLink}`}
          >
            {showInitials}
          </Avatar>
          <Box>
            <UserName user={user} />
            <Flex
              sx={{
                gap: 2,
                cursor: 'pointer',
              }}
              role='button'
              onClick={toggleChangeAvatarModal}
            >
              <RefreshIcon />
              <Typography fontSize={14}>Change Photo</Typography>
            </Flex>
          </Box>
        </Flex>
        {options.length && <OptionsMenu options={options} />}
      </Flex>
      <UserInfoFormikWrapper />
      <RemoveModal
        title='Sure you want to delete your account? You can’t undo this.'
        sx={{
          maxWidth: 340,
        }}
        submitLabel='Yes, Delete it'
        open={isOpenRemoveModal}
        callback={acceptRemoveUser}
        closeModal={closeModal}
      />
      <ChangeAvatarModal
        open={isChangeAvatarModalOpen}
        onClose={toggleChangeAvatarModal}
        user={user}
      />
    </Box>
  );
};
