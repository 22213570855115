import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  FooterContainer,
  HeaderTitleContainer,
  ScrollContainer,
  StyledModal,
  StyledPaper,
} from 'src/components/common/CommonStyledComponents';
import { useCreateTest } from 'src/hooks';
import { useToasts } from 'src/hooks/useToasts';
import { theme } from 'src/theme';
import { createTestSchema } from 'src/utils/formSchemas';

import { TestPropertiesSection } from './testPropertiesSection';

const initialValues = {
  brand: null,
  style: '',
  purpose: '',
  productUse: '',
  testType: '',
  brief: '',
  duration: '',
  quantity: '',
  colorSizeGender: '',
  endDate: null,
  itemType: '',
};

const StyledCancelButton = styled(Button)`
  margin-right: 16px;
  height: 28px;
  font-weight: 400;
`;

const StyledSubmitButton = styled(Button)({
  '&.MuiButtonBase-root.MuiButton-root': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    borderRadius: '4px',
    background: '#000000',
    color: '#ffffff',
    padding: '4px 16px',
    '&.Mui-disabled': {
      backgroundColor: '#DEDEDE',
      color: '#FFFFFF',
    },
  },
});

const Header = () => (
  <Box
    sx={{
      padding: '20px 24px',
    }}
  >
    <HeaderTitleContainer>
      <Typography variant='modalTitle' id='modal-title'>
        Create Test
      </Typography>
    </HeaderTitleContainer>
  </Box>
);

const Footer = ({ handleClickCancel, handleClickSubmit, disabled }) => (
  <FooterContainer>
    <Typography
      sx={{
        fontWeight: 700,
      }}
    >
      <span
        style={{
          color: theme.colors.error,
          marginRight: 2,
        }}
      >
        *
      </span>
      Required
    </Typography>
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <StyledCancelButton onClick={handleClickCancel}>Cancel</StyledCancelButton>
      <StyledSubmitButton onClick={handleClickSubmit} disabled={disabled}>
        Create Test
      </StyledSubmitButton>
    </Box>
  </FooterContainer>
);

Footer.propTypes = {
  handleClickCancel: PropTypes.func,
  handleClickSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

const transformValues = (values) => {
  const { brand, style, ...rest } = values;
  return {
    ...rest,
    brandId: brand,
    styleDescription: style,
  };
};

export const CreateTestForm = ({ open, toggleModal }) => {
  const [createTest] = useCreateTest();
  const { SuccessMessage, ErrorMessage } = useToasts();
  const navigate = useNavigate();

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    setFieldError,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: createTestSchema,
    onSubmit: () => {
      createTest({
        variables: {
          input: transformValues(values),
        },
      })
        .then((response) => {
          if (response?.data?.createTest?.id) {
            resetForm();
            toggleModal();
            SuccessMessage(' New test created ');
            navigate(`/tests/${response.data.createTest.id}`);
          }
        })
        .catch(() => {
          ErrorMessage('Create Test Error. Try again.');
        });
    },
  });
  return (
    <StyledModal open={open} onClose={toggleModal} aria-labelledby='modal-title'>
      <StyledPaper>
        <Header />
        <Divider
          sx={{
            margin: '0 24px 32px 24px',
          }}
        />
        <ScrollContainer>
          <TestPropertiesSection
            errors={errors}
            values={values}
            handleChangeValue={handleChange}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            touched={touched}
            handleBlur={handleBlur}
          />
        </ScrollContainer>
        <Footer
          handleClickSubmit={handleSubmit}
          handleClickCancel={toggleModal}
          disabled={!values.brand || !values.style}
        />
      </StyledPaper>
    </StyledModal>
  );
};
CreateTestForm.propTypes = {
  open: PropTypes.bool,
  toggleModal: PropTypes.func,
};
