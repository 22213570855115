import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { Field, Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import Chevron from 'src/components/common/Chevron';
import { BrandAutocomplete } from 'src/components/customers/BrandAutocomplete';
import { CloseIcon } from 'src/components/UI/icons';
import { useInviteUser } from 'src/hooks';
import { useToasts } from 'src/hooks/useToasts';
import { inviteUserSchema } from 'src/utils/formSchemas';

import { styles } from './styles';

const ROLE_MAP = {
  TESTER: 'Tester',
  CUSTOMER: 'Customer',
  ADMIN: 'MERG Admin',
};

const initialValues = {
  emails: '',
  role: '',
  brand: null,
};

export const InviteDialogSection = () => {
  const [open, setOpen] = useState(false);

  const { SuccessMessage } = useToasts();

  const { inviteUsers, loading } = useInviteUser();

  const toggleModal = useCallback(() => setOpen((prev) => !prev), []);

  const handleUpdate = useCallback(
    (updateFunction, name, value) => () => {
      updateFunction(name, value);
    },
    [],
  );

  const renderSelectValue = useCallback((selected) => {
    if (!selected) {
      return <Box sx={styles.selectPlaceholder}>Select Role</Box>;
    }

    return ROLE_MAP[selected];
  }, []);

  const isInviteDisabled = useCallback((values) => {
    if (values.role !== 'Customer') {
      return !(values.emails && values.role);
    }
    // if role === 'Customer'
    return !(values.emails && values.role && values.brand);
  }, []);

  const onFormSubmit = async (values) => {
    const inviteResult = await inviteUsers({
      emails: values.emails?.split(',').map((i) => i.trim()),
      role: values.role,
      brandId: values.brand,
    });

    if (inviteResult?.data?.inviteUsers?.status) {
      toggleModal();
      SuccessMessage('Invite sent');
    }
  };

  return (
    <Box sx={styles.inviteBox}>
      <Button variant='outlined' sx={styles.btnInvite} onClick={toggleModal}>
        Invite
      </Button>
      <Dialog open={open} onClose={toggleModal} sx={styles.modalInvite}>
        <DialogTitle>Invite user</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            onSubmit={onFormSubmit}
            validationSchema={inviteUserSchema}
          >
            {({ values, setFieldValue, touched, errors, handleBlur }) => (
              <Form>
                <Field name='emails'>
                  {({ field, meta }) => {
                    const showErrorMessage = (message) =>
                      Array.isArray(message)
                        ? message.filter((error) => error !== null).join(', ')
                        : message;

                    return (
                      <Box sx={styles.fieldWrapper}>
                        <Typography variant='h5'>Email</Typography>
                        <Typography sx={styles.helpBlock}>
                          Separate multiple address with a comma
                        </Typography>
                        <Box sx={styles.inputWrapper}>
                          <Input
                            placeholder='Email Address'
                            type='text'
                            endAdornment={
                              values.emails && (
                                <Box
                                  onClick={handleUpdate(setFieldValue, field.name, '')}
                                  sx={styles.btnClose}
                                >
                                  <CloseIcon />
                                </Box>
                              )
                            }
                            {...field}
                            error={Boolean(meta.touched && meta.error)}
                          />
                          {meta.touched && meta.error && (
                            <Box sx={styles.error}>{showErrorMessage(meta.error)}</Box>
                          )}
                        </Box>
                      </Box>
                    );
                  }}
                </Field>
                <Field name='role'>
                  {({ field, meta }) => (
                    <Box sx={styles.fieldWrapper}>
                      <Typography variant='h5'>Role</Typography>
                      <Typography sx={styles.helpBlock}>
                        Role will be applied to all emails
                      </Typography>
                      <Select
                        {...field}
                        displayEmpty
                        sx={styles.selectRole}
                        IconComponent={Chevron}
                        renderValue={renderSelectValue}
                        MenuProps={{
                          PaperProps: {
                            sx: styles.selectRoleMenu,
                          },
                        }}
                        error={Boolean(meta.touched && meta.error)}
                      >
                        <MenuItem value='TESTER'>Tester</MenuItem>
                        <MenuItem value='CUSTOMER'>Customer</MenuItem>
                        <MenuItem value='ADMIN'>MERG Admin</MenuItem>
                      </Select>
                      {meta.touched && meta.error && <Box sx={styles.error}>{meta.error}</Box>}
                    </Box>
                  )}
                </Field>
                {values.role === 'CUSTOMER' && (
                  <Box sx={styles.fieldWrapper}>
                    <Typography variant='h5'>Brand</Typography>
                    <Typography sx={styles.helpBlock}>
                      Select a brand to associate with this customer
                    </Typography>
                    <BrandAutocomplete
                      name='brand'
                      value={values.brand}
                      setFieldValue={setFieldValue}
                      error={touched.brand && !!errors.brand}
                      onBlur={handleBlur}
                      helperText={touched.brand && errors.brand}
                    />
                  </Box>
                )}
                <DialogActions>
                  <Button onClick={toggleModal} sx={styles.btnCancel}>
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    sx={styles.btnSend}
                    disabled={isInviteDisabled(values) || loading}
                  >
                    Send Invite
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
