import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetIviteTestCandidates, useRemoveCandidates, useTestInfo } from 'src/hooks';
import { parserHTMLToText, stringifyHTMLToText } from 'src/utils/helpers';

import {
  useEnrollCandidate,
  useInviteCandidates,
  useReInviteCandidate,
  useRejectCandidate,
} from './useMutations';

export const useCandidatesTabPanel = () => {
  const [inviteCandidates] = useInviteCandidates();
  const [reinviteCandidates] = useReInviteCandidate();
  const [getCandidates] = useGetIviteTestCandidates();
  const [removeCandidates] = useRemoveCandidates();
  const [enrollCandidate] = useEnrollCandidate();
  const [rejectCandidate] = useRejectCandidate();

  const { id } = useParams();

  const [sendInviteModalIsOpen, setSendInviteModalIsOpen] = useState(false);

  const [selected, setSelected] = useState([]);
  const [сandidateId, setCandidateId] = useState(null);
  const [subjectText, setSubjectText] = useState('');
  const [bodyText, setBodyText] = useState('');

  const { useRefetchTestersData } = useTestInfo();

  const toggleModal = () => setSendInviteModalIsOpen((prev) => !prev);

  const textIviteEvent = (data) => {
    if (data) {
      setSubjectText(data?.subject || '');
      setBodyText(parserHTMLToText(data?.text || ''));
      toggleModal();
    }
  };

  const handleChangeSubjectText = (event) => {
    setSubjectText(event.target.value);
  };

  const handleSubmit = async () => {
    const data = await inviteCandidates({
      variables: {
        testCandidateIds: selected.length > 0 ? selected : [сandidateId],
        emailSubject: subjectText,
        emailText: stringifyHTMLToText(bodyText),
      },
    });
    if (data.data?.inviteCandidates?.status) {
      setCandidateId(null);
      toggleModal();
      useRefetchTestersData();
      setSelected([]);
    }
  };

  const handleChangeBodyText = (event) => {
    setBodyText(event.target.value);
  };

  const removeAllSelectedCandidates = async () => {
    const data = await removeCandidates({
      variables: {
        testId: id,
        testUserIds: selected,
      },
    });
    if (data.data?.removeCandidates?.status) {
      useRefetchTestersData();
      setSelected([]);
    }
  };

  const removeCandidate = async (сandidateID) => {
    const data = await removeCandidates({
      variables: {
        testId: id,
        testUserIds: [сandidateID],
      },
    });
    if (data.data?.removeCandidates?.status) {
      useRefetchTestersData();
    }
  };

  const sentInviteToCandidate = async (сandidateID) => {
    const data = await getCandidates({
      variables: {
        testCandidateIds: [сandidateID],
      },
    });
    if (data.data?.getTextInvitedTestCandidates) {
      setCandidateId(сandidateID);
    }
    textIviteEvent(data.data?.getTextInvitedTestCandidates);
  };

  const sentInviteToAllSelectedCandidates = async () => {
    const data = await getCandidates({
      variables: {
        testCandidateIds: selected,
      },
    });
    setCandidateId(null);
    textIviteEvent(data.data?.getTextInvitedTestCandidates);
  };

  const reinviteToCandidate = async (сandidateID) => {
    await reinviteCandidates({
      variables: {
        testUserId: сandidateID,
      },
    });
  };

  const handleEnrollCandidate = async (сandidateID) => {
    const data = await enrollCandidate({
      variables: {
        testUserId: сandidateID,
      },
    });
    if (data.data?.enrollCandidate?.status) {
      useRefetchTestersData();
    }
  };

  const handleRejectCandidate = async (сandidateID) => {
    const data = await rejectCandidate({
      variables: {
        testUserId: сandidateID,
      },
    });
    if (data.data?.rejectCandidate?.status) {
      useRefetchTestersData();
    }
  };

  const value = useMemo(
    () => ({
      selected,
      setSelected,
      sentInviteToAllSelectedCandidates,
      sentInviteToCandidate,
      removeAllSelectedCandidates,
      removeCandidate,
      sendInviteModalIsOpen,
      toggleModal,
      subjectText,
      bodyText,
      handleChangeSubjectText,
      handleChangeBodyText,
      handleSubmit,
      reinviteToCandidate,
      handleEnrollCandidate,
      handleRejectCandidate,
    }),
    [
      selected,
      setSelected,
      sentInviteToAllSelectedCandidates,
      sentInviteToCandidate,
      removeAllSelectedCandidates,
      removeCandidate,
      sendInviteModalIsOpen,
      toggleModal,
      subjectText,
      bodyText,
      handleChangeSubjectText,
      handleChangeBodyText,
      handleSubmit,
      reinviteToCandidate,
      handleEnrollCandidate,
      handleRejectCandidate,
    ],
  );

  return value;
};
