import { theme } from 'src/theme';

export const styled = {
  emptyTableBanner: {
    container: {
      width: '100%',
      height: '330px',
      justifyContent: 'center',
      background: theme.colors.neutral.light.superExtra,
      border: `1px solid ${theme.colors.neutral.light.normal}`,
      borderRadius: 1,
    },
    text: {
      color: '#000000',
      fontSize: '16px',
    },
  },
};
