import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { func, instanceOf } from 'prop-types';
import { DetailInput, DetailLabel, DetailSelect } from 'src/components/UI';

import { styles } from './styles';

export const Overview = ({ values, handleChange, possibleValues }) => {
  const { touched } = useFormikContext();

  return (
    <Box>
      <Box sx={styles.requiredWrapper}>
        <span>*</span> Required
      </Box>
      <DetailLabel
        label={
          touched.relatesOutdoorProductTestingDescription ||
          !values?.relatesOutdoorProductTestingDescription?.length ? (
            <Box sx={styles.required}>
              Tell us about yourself, your recreation and travel, as it relates to outdoor product
              testing<span>*</span>
            </Box>
          ) : (
            <Box sx={styles.required}>
              About <span>*</span>
            </Box>
          )
        }
      >
        <DetailInput
          multiline
          name='relatesOutdoorProductTestingDescription'
          handleChange={handleChange}
          value={values.relatesOutdoorProductTestingDescription}
          submitOnValueChange
          placeholder='Tell us about yourself'
        />
      </DetailLabel>
      <DetailLabel
        label={
          <Box sx={styles.required}>
            Test Frequency <span>*</span>
          </Box>
        }
      >
        <DetailSelect
          label='Test Frequency'
          name='participationInTests'
          handleChange={handleChange}
          value={values.participationInTests}
          possibleValues={possibleValues.participationInTests}
          submitOnValueChange
          placeholder='Select frequency'
        />
      </DetailLabel>
    </Box>
  );
};

Overview.propTypes = {
  handleChange: func,
  values: instanceOf(Object),
  possibleValues: instanceOf(Object),
};
