import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { bool, instanceOf } from 'prop-types';
import { useMemo } from 'react';
import { Ages } from 'src/constants/constants';
import { useGetTesterValues } from 'src/hooks';
import { rightPanel } from 'src/styles';

import { DetailTabs } from '../UI';
import { About, Activities, Contact, Details, Overview } from './tabs';

export const RightPanelTesterDetailContent = ({ testerData = {}, loading }) => {
  const { values, handleChange, setFieldError, setFieldValue, setValues } = useFormikContext();

  const testerPossibleValues = useGetTesterValues();
  const possibleValues = useMemo(() => {
    return (
      {
        ...testerPossibleValues,
        Ages,
      } || {}
    );
  }, [testerPossibleValues]);

  const tabs = useMemo(
    () => [
      {
        name: 'overview',
        children: (
          <Overview
            values={values}
            possibleValues={possibleValues}
            handleChange={handleChange}
            setFieldError={setFieldError}
          />
        ),
      },
      {
        name: 'activities',
        children: (
          <Activities
            possibleValues={possibleValues}
            activities={possibleValues.activities}
            otherActivities={testerData?.tester?.otherActivities}
            isWorkForAnOutdoorCompany={testerData?.tester?.isWorkForAnOutdoorCompany}
            isHaveDog={testerData?.tester?.isHaveDog}
            isRecreateWithKids={testerData?.tester?.isRecreateWithKids}
            values={values}
            frequencyValues={possibleValues.frequencyValues}
            handleChange={handleChange}
          />
        ),
      },
      {
        name: 'details',
        children: (
          <Details
            values={values}
            possibleValues={possibleValues}
            shoeSizeAdditionalInfo={testerData?.tester?.shoeSizeAdditionalInfo}
            clothingSizeAdditionalInfo={testerData?.tester?.clothingSizeAdditionalInfo}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        ),
      },
      {
        name: 'about',
        children: <About values={values} handleChange={handleChange} />,
      },
      {
        name: 'contact',
        children: <Contact values={values} handleChange={handleChange} setValues={setValues} />,
      },
    ],
    [possibleValues, values],
  );

  return (
    <Box sx={rightPanel.root}>
      <DetailTabs
        tabs={tabs}
        scrollBoxStyle={{
          paddingBottom: 12,
          overflowY: 'scroll',
          maxHeight: '630px',
        }}
        loading={loading}
      />
    </Box>
  );
};

RightPanelTesterDetailContent.propTypes = {
  testerData: instanceOf(Object),
  loading: bool,
};
