import Chip from '@mui/material/Chip';
import { string } from 'prop-types';
import { capitalizeFirstLetter } from 'src/utils/helpers';

export const StatusChip = ({ status }) => (
  <Chip
    sx={{
      borderRadius: '3px',
      fontSize: '14px',
    }}
    variant='contained'
    color={status?.toLowerCase()}
    label={capitalizeFirstLetter(status)}
  />
);

StatusChip.propTypes = {
  status: string,
};
