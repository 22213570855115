import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import { ChevronBottom } from '../UI/icons/ChevronBottom';

const Chevron = (props) => {
  const { className } = props;

  const isUp = className.includes('MuiSelect-iconOpen');

  return (
    <Box
      className='chevronWrapper'
      {...(isUp && {
        sx: {
          transform: 'rotate(180deg)',
        },
      })}
    >
      <ChevronBottom />
    </Box>
  );
};

Chevron.propTypes = {
  className: PropTypes.string,
};

export default Chevron;
