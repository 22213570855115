import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { func, instanceOf } from 'prop-types';
import { Label, MultipleSelect } from 'src/components/common/filtersMenu/Components';
import { useGetTesterGeoValues, useGetTesterValues } from 'src/hooks/useQueries';
import { filtersMenu, testerCreationForm } from 'src/styles';
import {
  convertMultipleCmToInches,
  convertMultipleInchesToCm,
  toSelectValues,
} from 'src/utils/helpers';

import { styles } from './styles';

export const menuItemSx = (isSelected, sizeObj) => ({
  ...styles.wrapItem,
  backgroundColor: isSelected(sizeObj) ? '#9B51E0' : '#fff',
  color: isSelected(sizeObj) ? '#fff' : '#000',
  '&:hover': {
    backgroundColor: isSelected(sizeObj) && '#9B51E0',
  },
});

export const TestersFilters = ({ handleChange, setFieldValue, values = {} }) => {
  const possibleValues = useGetTesterValues();
  const { states, cities } = useGetTesterGeoValues();

  const handleChangeInchesToCm = (pairName) => (e) => {
    handleChange(e);
    setFieldValue(
      pairName,
      convertMultipleInchesToCm({
        inches: e.target.value,
      }),
    );
  };

  const handleChangeCm = (pairName) => (e) => {
    handleChange(e);
    setFieldValue(
      pairName,
      convertMultipleCmToInches({
        cm: e.target.value,
      }),
    );
  };

  const renderGroupsOptions = (options, name, handleSelectItem, isSelected) => {
    const womanOptions = options.woman?.map((size) => {
      const sizeObj = {
        title: size.code,
        value: size.id,
      };
      return (
        <MenuItem
          sx={menuItemSx(isSelected, sizeObj)}
          value={size.id}
          key={size.id}
          onClick={handleSelectItem(sizeObj)}
        >
          {size.name}
        </MenuItem>
      );
    });

    const womanUSOptions = options.womanUS?.map((size) => {
      const sizeObj = {
        title: size.code,
        value: size.id,
      };
      return (
        <MenuItem
          sx={menuItemSx(isSelected, sizeObj)}
          value={size.id}
          key={size.id}
          onClick={handleSelectItem(sizeObj)}
        >
          {size.name}
        </MenuItem>
      );
    });

    const manOptions = options.man?.map((size) => {
      const sizeObj = {
        title: size.code,
        value: size.id,
      };
      return (
        <MenuItem
          sx={menuItemSx(isSelected, sizeObj)}
          value={size.id}
          key={size.id}
          onClick={handleSelectItem(sizeObj)}
        >
          {size.name}
        </MenuItem>
      );
    });

    const manUSOptions = options.manUS?.map((size) => {
      const sizeObj = {
        title: size.code,
        value: size.id,
      };
      return (
        <MenuItem
          sx={menuItemSx(isSelected, sizeObj)}
          value={size.id}
          key={size.id}
          onClick={handleSelectItem(sizeObj)}
        >
          {size.name}
        </MenuItem>
      );
    });

    return [
      <ListSubheader style={styles.dropdownTitleBox} key='woman-label'>
        <div style={styles.dropdownTitleText}>Women’s</div>
      </ListSubheader>,
      ...womanOptions,
      ...(name === 'bottomSizes'
        ? [
            <ListSubheader style={styles.dropdownTitleBox} key='woman-us-label'>
              <div style={styles.dropdownSubTitleText}>US</div>
            </ListSubheader>,
            ...womanUSOptions,
          ]
        : []),
      <ListSubheader style={styles.dropdownTitleBox} key='man-label'>
        <div style={styles.dropdownTitleText}>Men’s</div>
      </ListSubheader>,
      ...manOptions,
      ...(name === 'bottomSizes'
        ? [
            <ListSubheader style={styles.dropdownTitleBox} key='man-us-label'>
              <div style={styles.dropdownSubTitleText}>US</div>
            </ListSubheader>,
            ...manUSOptions,
          ]
        : []),
    ];
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <MultipleSelect
        name='testerGroup'
        label='Group'
        placeholder='Select Group'
        handleChange={handleChange}
        value={values.testerGroup}
        options={toSelectValues(possibleValues?.testerGroups)}
      />
      <MultipleSelect
        name='testerStatusInternal'
        label='Status'
        placeholder='Select Status'
        sublabel='Internal'
        handleChange={handleChange}
        value={values.testerStatusInternal || []}
        options={possibleValues?.testerStatusInternals}
      />
      <MultipleSelect
        name='testerStatusExternal'
        label='Status'
        placeholder='Select Status'
        sublabel='External'
        handleChange={handleChange}
        value={values.testerStatusExternal || []}
        options={possibleValues?.testerStatusExternals}
      />
      <MultipleSelect
        name='activity'
        label='Activity'
        placeholder='Select Activity'
        handleChange={handleChange}
        value={values.activity || []}
        error={!!values.frequency?.length && !values.activity?.length}
        options={possibleValues?.activities}
      />
      <MultipleSelect
        name='frequency'
        label='Frequency'
        placeholder='Select Frequency'
        handleChange={handleChange}
        error={!!values.activity?.length && !values.frequency?.length}
        value={values.frequency || []}
        options={possibleValues?.frequencyValues}
      />
      <MultipleSelect
        name='city'
        label='City'
        placeholder='Select City'
        handleChange={handleChange}
        value={values.city || []}
        options={toSelectValues(cities)}
      />
      <MultipleSelect
        name='stateId'
        label='State'
        placeholder='Select State'
        handleChange={handleChange}
        value={values.stateId || []}
        options={toSelectValues(states, {
          titleKey: 'name',
          valueKey: 'id',
        })}
      />
      <Label sx={filtersMenu.label} label='Age'>
        <Box sx={styles.twoColumns}>
          <TextField
            type='number'
            sx={testerCreationForm.input}
            size='small'
            name='ageFrom'
            onInput={handleChange}
            value={values.ageFrom}
            placeholder='Age Min'
          />
          <TextField
            type='number'
            sx={testerCreationForm.input}
            size='small'
            name='ageTo'
            onInput={handleChange}
            value={values.ageTo}
            placeholder='Age Max'
          />
        </Box>
      </Label>
      <MultipleSelect
        name='preference'
        label='Preference'
        placeholder='Select Preference'
        handleChange={handleChange}
        value={values.preference || []}
        options={possibleValues?.preferences}
      />
      <MultipleSelect
        name='generalShoeSizes'
        label='General Shoe Size'
        placeholder='Select Shoe Size'
        handleChange={handleChange}
        value={values.generalShoeSizes || []}
        options={possibleValues.shoeSizeOptions}
        renderGroupsOptions={renderGroupsOptions}
      />
      <MultipleSelect
        name='runningShoeSizes'
        label='Running Shoe Size'
        placeholder='Select Shoe Size'
        handleChange={handleChange}
        value={values.runningShoeSizes || []}
        options={possibleValues.shoeSizeOptions}
        renderGroupsOptions={renderGroupsOptions}
      />
      <MultipleSelect
        name='hikingShoeSizes'
        label='Hiking Shoe Size'
        placeholder='Select Shoe Size'
        handleChange={handleChange}
        value={values.hikingShoeSizes || []}
        options={possibleValues.shoeSizeOptions}
        renderGroupsOptions={renderGroupsOptions}
      />
      <MultipleSelect
        name='sandalShoeSizes'
        label='Sandal Size'
        placeholder='Select Shoe Size'
        handleChange={handleChange}
        value={values.sandalShoeSizes || []}
        options={possibleValues.shoeSizeOptions}
        renderGroupsOptions={renderGroupsOptions}
      />
      <MultipleSelect
        name='shoeWidth'
        label='Shoe Width'
        placeholder='Select Shoe Size'
        handleChange={handleChange}
        value={values.shoeWidth || []}
        error={!!values.shoeWidth?.length && !values.shoeWidth?.length}
        options={toSelectValues(possibleValues?.shoeWidth)}
      />
      <MultipleSelect
        name='topSizes'
        label='Top Size'
        placeholder='Select Top Size'
        handleChange={handleChange}
        value={values.topSizes || []}
        options={possibleValues.topSizeOptions}
        renderGroupsOptions={renderGroupsOptions}
      />
      <MultipleSelect
        name='bottomSizes'
        label='Bottom Size'
        placeholder='Select Bottom Size'
        handleChange={handleChange}
        value={values.bottomSizes || []}
        options={possibleValues.bottomSizeOptions}
        renderGroupsOptions={renderGroupsOptions}
      />
      <Label sx={filtersMenu.label} label='Chest Size'>
        <Box sx={styles.twoColumns}>
          <TextField
            type='text'
            sx={testerCreationForm.input}
            size='small'
            name='chestInches'
            onInput={handleChangeInchesToCm('chestCm')}
            value={values.chestInches}
            placeholder='Inches'
          />
          <TextField
            type='text'
            sx={testerCreationForm.input}
            size='small'
            name='chestCm'
            onInput={handleChangeCm('chestInches')}
            value={values.chestCm}
            placeholder='cm'
          />
        </Box>
      </Label>
      <Label sx={filtersMenu.label} label='Waist at Navel'>
        <Box sx={styles.twoColumns}>
          <TextField
            type='text'
            sx={testerCreationForm.input}
            size='small'
            name='waistAtNavelInches'
            onInput={handleChangeInchesToCm('waistAtNavelCm')}
            value={values.waistAtNavelInches}
            placeholder='Inches'
          />
          <TextField
            type='text'
            sx={testerCreationForm.input}
            size='small'
            name='waistAtNavelCm'
            onInput={handleChangeCm('waistAtNavelInches')}
            value={values.waistAtNavelCm}
            placeholder='cm'
          />
        </Box>
      </Label>
      <Label sx={filtersMenu.label} label='Waist at Pants'>
        <Box sx={styles.twoColumns}>
          <TextField
            type='text'
            sx={testerCreationForm.input}
            size='small'
            name='waistAtPantsInches'
            onInput={handleChangeInchesToCm('waistAtPantsCm')}
            value={values.waistAtPantsInches}
            placeholder='Inches'
          />
          <TextField
            type='text'
            sx={testerCreationForm.input}
            size='small'
            name='waistAtPantsCm'
            onInput={handleChangeCm('waistAtPantsInches')}
            value={values.waistAtPantsCm}
            placeholder='cm'
          />
        </Box>
      </Label>
      <Label sx={filtersMenu.label} label='Hips'>
        <Box sx={styles.twoColumns}>
          <TextField
            type='text'
            sx={testerCreationForm.input}
            size='small'
            name='hipsInches'
            onInput={handleChangeInchesToCm('hipsCm')}
            value={values.hipsInches}
            placeholder='Inches'
          />
          <TextField
            type='text'
            sx={testerCreationForm.input}
            size='small'
            name='hipsCm'
            onInput={handleChangeCm('hipsInches')}
            value={values.hipsCm}
            placeholder='cm'
          />
        </Box>
      </Label>
      <Label sx={filtersMenu.label} label='Inseam'>
        <Box sx={styles.twoColumns}>
          <TextField
            type='text'
            sx={testerCreationForm.input}
            size='small'
            name='inseamInches'
            onInput={handleChangeInchesToCm('inseamCm')}
            value={values.inseamInches}
            placeholder='Inches'
          />
          <TextField
            type='text'
            sx={testerCreationForm.input}
            size='small'
            name='inseamCm'
            onInput={handleChangeCm('inseamInches')}
            value={values.inseamCm}
            placeholder='cm'
          />
        </Box>
      </Label>
    </Box>
  );
};

TestersFilters.propTypes = {
  handleChange: func,
  setFieldValue: func,
  values: instanceOf(Object),
};
