import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OptionsMenu } from 'src/components';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { ConfirmModal } from 'src/components/testOverview/confirmModal/confirmModal';
import AdminInfoFormikWrapper from 'src/components/userProfile/adminTabs/AdminInfoFormikWrapper';
import { AdminUserName } from 'src/components/userProfile/adminTabs/AdminUserName';
import { avatarStyle, wrapperStyle } from 'src/components/userProfile/styles';
import { useGetAdminById, useRemoveUser } from 'src/hooks';

export const AdminsProfile = () => {
  const remove = useRemoveUser();

  const navigate = useNavigate();
  const { id: adminId } = useParams();
  const { data: user } = useGetAdminById({
    variables: {
      id: adminId,
    },
  });
  const [removeModalIsOpen, setRemoveModalIsOpen] = useState(false);

  const showInitials = useMemo(() => {
    if (user?.getUsersWithRoleAdminById?.firstName && user?.getUsersWithRoleAdminById?.lastName) {
      return `${
        user.getUsersWithRoleAdminById.firstName[0].toUpperCase() +
        user.getUsersWithRoleAdminById.lastName[0].toUpperCase()
      }`;
    }
    return user?.getUsersWithRoleAdminById?.email[0].toUpperCase();
  }, [
    user?.getUsersWithRoleAdminById?.lastName,
    user?.getUsersWithRoleAdminById?.firstName,
    user?.getUsersWithRoleAdminById?.email,
  ]);

  const toggleRemoveUser = () => {
    setRemoveModalIsOpen((prev) => !prev);
  };

  const handleRemoveUser = useCallback(() => {
    remove({
      id: adminId,
    }).then((res) => res.data?.removeUser?.status && navigate(`/admins`));
  }, [adminId]);

  const options = [
    {
      title: 'Remove User',
      callback: toggleRemoveUser,
    },
  ];

  if (!user) return null;
  return (
    <Box sx={wrapperStyle}>
      <Flex
        sx={{
          justifyContent: 'space-between',
          mb: 7,
        }}
      >
        <Flex
          sx={{
            gap: 5,
          }}
        >
          <Avatar
            sx={avatarStyle}
            alt='avatar'
            src={`${process.env.REACT_APP_DOMAIN}/${user?.getUsersWithRoleAdminById?.imageProfileLink}`}
          >
            {showInitials}
          </Avatar>
          <Box>
            <AdminUserName user={user?.getUsersWithRoleAdminById} id={adminId} />
          </Box>
        </Flex>
        {options.length && <OptionsMenu options={options} />}
      </Flex>
      <AdminInfoFormikWrapper user={user?.getUsersWithRoleAdminById} id={adminId} />
      <ConfirmModal
        isError
        open={removeModalIsOpen}
        toggleModal={toggleRemoveUser}
        callback={handleRemoveUser}
        labalTest='Sure you want to delete this account?'
        agreeButtonTex='Yes, Remove them'
      />
    </Box>
  );
};
