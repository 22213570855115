export const styles = {
  filterChipsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  clearAll: {
    color: '#9B51E0',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
};
