import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { useFormikContext } from 'formik';
import { StyledLabel, StyledSelect } from 'src/components/common/CommonStyledComponents';
import { useGetTesterValues } from 'src/hooks';

export const Testing = () => {
  const possibleValues = useGetTesterValues();

  const { values = {}, errors = {}, handleChange } = useFormikContext();

  return (
    <Box
      sx={{
        width: '75%',
      }}
    >
      <StyledLabel label='How often would you like to participate in tests?'>
        <StyledSelect
          name='participationInTests'
          onChange={handleChange}
          error={!!errors.participationInTests}
          value={values.participationInTests}
          helperText={errors.participationInTests}
          placeholder='Select Frequency'
        >
          {possibleValues.participationInTests?.map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledLabel>
      <StyledLabel label='Tester Group'>
        <StyledSelect
          name='testerGroup'
          onChange={handleChange}
          error={!!errors.testerGroup}
          value={values.testerGroup}
          helperText={errors.testerGroup}
          placeholder='Select group'
        >
          {possibleValues.testerGroups?.map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledLabel>
      <StyledLabel label='Status - Internal'>
        <StyledSelect
          name='testerStatusInternal'
          onChange={handleChange}
          error={!!errors.testerStatusInternal}
          value={values.testerStatusInternal}
          helperText={errors.testerStatusInternal}
          placeholder='Select Internal Status'
        >
          {possibleValues.testerStatusInternals.map((item) => (
            <MenuItem
              value={item.value}
              key={item.value}
              sx={{
                textTransform: 'uppercase',
              }}
            >
              {item.title}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledLabel>

      <StyledLabel label='Status - External'>
        <StyledSelect
          name='testerStatusExternal'
          onChange={handleChange}
          value={values.testerStatusExternal}
          error={!!errors.testerStatusExternal}
          helperText={errors.testerStatusExternal}
          placeholder='Select External Status'
        >
          {possibleValues.testerStatusExternals.map((item) => (
            <MenuItem
              value={item.value}
              key={item.value}
              sx={{
                textTransform: 'uppercase',
              }}
            >
              {item.title}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledLabel>
    </Box>
  );
};
