import { useCallback } from 'react';
import { FilterChips } from 'src/components/common/filtersMenu/filterChips/FilterChips';
import { useGetTesterGeoValues, useGetTesterValues } from 'src/hooks';

const filterNames = {
  testerStatusExternal: 'Tester Status External',
  testerStatusInternal: 'Tester Status Internal',
  ageFrom: 'Age From',
  ageTo: 'Age To',
  testerGroup: 'Tester Group',
  topSize: 'TopSize',
  bottomSize: 'Bottom Size',
  shoeSize: 'Shoe Size',
  stateId: 'State',
  countryId: 'Country',
  generalShoeSizes: 'General Shoe Size',
  runningShoeSizes: 'Running Shoe Size',
  hikingShoeSizes: 'Hiking Shoe Size',
  sandalShoeSizes: 'Sandal Size',
  bottomSizes: 'Bottom Size',
  topSizes: 'Top Size',
};

const getCodeSizes = (options, id) =>
  Object.values(options)
    .flat()
    .filter(Boolean)
    .find((item) => item.id === id)?.code;

export const TesterChips = () => {
  const {
    activities,
    preferences,
    frequencyValues,
    shoeSizeOptions,
    bottomSizeOptions,
    topSizeOptions,
  } = useGetTesterValues();
  const { countries, states } = useGetTesterGeoValues();
  const getFilterName = useCallback((key) => filterNames[key] || key, []);
  const getFilterValue = useCallback(
    (key, value) => {
      const valuesToMap = {
        activity: () => activities?.find((activity) => activity.value === value)?.title,
        countryId: () => countries?.find(({ id }) => id === value)?.name,
        stateId: () => states?.find(({ id }) => id === value)?.name,
        preference: () => preferences?.find((preference) => preference.value === value)?.title,
        frequency: () => frequencyValues?.find((freq) => freq.value === value)?.title,
        testerStatusExternal: () => value.toLowerCase(),
        testerStatusInternal: () => value.toLowerCase(),
        generalShoeSizes: () => shoeSizeOptions && getCodeSizes(shoeSizeOptions, value),
        runningShoeSizes: () => shoeSizeOptions && getCodeSizes(shoeSizeOptions, value),
        hikingShoeSizes: () => shoeSizeOptions && getCodeSizes(shoeSizeOptions, value),
        sandalShoeSizes: () => shoeSizeOptions && getCodeSizes(shoeSizeOptions, value),
        bottomSizes: () => bottomSizeOptions && getCodeSizes(bottomSizeOptions, value),
        topSizes: () => topSizeOptions && getCodeSizes(topSizeOptions, value),
      };

      return valuesToMap[key]?.() || value;
    },
    [
      activities,
      preferences,
      states,
      countries,
      frequencyValues,
      shoeSizeOptions,
      bottomSizeOptions,
      topSizeOptions,
    ],
  );

  return <FilterChips getFilterValue={getFilterValue} getFilterName={getFilterName} />;
};
