import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
// eslint-disable-next-line object-curly-newline
import { bool, func, instanceOf, node, oneOfType, string } from 'prop-types';
import { useCallback } from 'react';
import { Flex, StyledSelect } from 'src/components/common/CommonStyledComponents';
import ControlledSelect from 'src/components/testers/createTesterForm/ControlledSelect';
import { filtersMenu } from 'src/styles';

export const Label = ({ children, label, sublabel }) => (
  <Flex sx={filtersMenu.label.root}>
    <Box>
      <Typography sx={filtersMenu.label.main}>{label}</Typography>
      <Typography sx={filtersMenu.label.sub}>{sublabel}</Typography>
    </Box>
    {children}
  </Flex>
);

Label.propTypes = {
  children: node,
  label: string,
  sublabel: string,
};

export const MultipleSelect = ({
  name,
  handleChange,
  label,
  sublabel,
  value,
  error = false,
  options = [],
  renderGroupsOptions,
  placeholder,
}) => {
  const handleSelect = (v) => {
    handleChange(name)({
      target: {
        value: v,
      },
    });
  };

  return (
    <Label sx={filtersMenu.label} label={label} sublabel={sublabel}>
      <ControlledSelect
        name={name}
        renderCustomOptions={renderGroupsOptions}
        options={options}
        placeholder={placeholder}
        value={value}
        error={error}
        onChange={handleSelect}
        controlledSelectWrapper={{
          minWidth: '436px',
        }}
        paperContainer={{
          minWidth: '428px',
        }}
      />
    </Label>
  );
};

MultipleSelect.propTypes = {
  name: string,
  handleChange: func,
  label: string,
  error: bool,
  sublabel: string,
  value: oneOfType([string, instanceOf(Array)]),
  options: instanceOf(Object),
  multiple: bool,
};

export const AddressField = ({ name, handleChange, label, value, options = [] }) => {
  const renderValue = useCallback(
    () => options.find((option) => option.id === value)?.name,
    [value, options],
  );
  return (
    <Label sx={filtersMenu.label} label={label}>
      <StyledSelect
        sx={filtersMenu.field}
        name={name}
        onChange={handleChange}
        value={value}
        placeholder={`Select ${label}`}
        renderValue={renderValue}
      >
        {options.map((item) => (
          <MenuItem value={item.id} key={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </StyledSelect>
    </Label>
  );
};

AddressField.propTypes = {
  name: string,
  handleChange: func,
  label: string,
  value: oneOfType([string, instanceOf(Array)]),
  options: instanceOf(Object),
};
