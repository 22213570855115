import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { bool, func, instanceOf, string } from 'prop-types';
import { useCallback } from 'react';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { theme } from 'src/theme';

import styles from './style';

export const RemoveModal = ({
  open,
  title,
  itemId,
  closeModal,
  callback,
  submitLabel = 'Yes, Remove it',
  sx,
}) => {
  const handleRemove = useCallback(() => {
    callback(itemId);
  }, [itemId]);

  return (
    <Modal open={open} sx={styles.modal} onClose={closeModal}>
      <Paper sx={[styles.paper, sx]}>
        <Box sx={styles.container}>
          <Typography color={theme.colors.neutral.white}>{title}</Typography>
          <Flex
            sx={{
              justifyContent: 'center',
              mt: 6,
            }}
          >
            <Button onClick={closeModal} sx={styles.button.cancel}>
              Cancel
            </Button>
            <Button variant='filled' onClick={handleRemove} sx={styles.button.submit}>
              {submitLabel}
            </Button>
          </Flex>
        </Box>
      </Paper>
    </Modal>
  );
};

RemoveModal.propTypes = {
  open: bool,
  title: string,
  itemId: string,
  closeModal: func,
  callback: func,
  submitLabel: string,
  sx: instanceOf(Object),
};
