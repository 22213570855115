import Typography from '@mui/material/Typography';
import { instanceOf, node, string } from 'prop-types';
import { Flex } from 'src/components/common/CommonStyledComponents';

export const DetailText = ({ value, adornment, units = '', textTransform = '', textStyle }) => {
  return (
    <Flex>
      {adornment}
      <Typography
        sx={[
          {
            overflowWrap: 'anywhere',
            ml: adornment ? 3 : 0,
            textTransform,
            whiteSpace: 'break-spaces',
          },
          textStyle,
        ]}
      >
        {value ? `${value} ${units}` : 'none'}
      </Typography>
    </Flex>
  );
};

DetailText.propTypes = {
  value: string,
  adornment: node,
  units: string,
  textTransform: string,
  textStyle: instanceOf(Object),
};
