import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import PropTypes from 'prop-types';
import { FilterIcon } from 'src/components/UI';

const StyledDiv = styled('div')`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  margin-bottom: 16px;
`;
export const TitleSection = ({ title, sx }) => (
  <StyledDiv sx={sx}>
    <FilterIcon />
    <Typography
      sx={{
        fontWeight: 700,
        fontSize: '14px',
        marginLeft: '19px',
      }}
    >
      {title}
    </Typography>
  </StyledDiv>
);

TitleSection.propTypes = {
  title: PropTypes.string,
  sx: PropTypes.objectOf(PropTypes.string),
};
