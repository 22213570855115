import Link from '@mui/material/Link';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { instanceOf } from 'prop-types';
import { StyledTable, StyledTableRow } from 'src/components';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { EmptyTableBanner } from 'src/components/UI/tables/EmptyTableBanner';
import { formatDate } from 'src/utils/helpers';

export const ReportsTab = ({ reports }) => {
  if (!reports?.length) return <EmptyTableBanner text='You don’t have any reports available yet' />;

  return (
    <StyledTable>
      <TableBody>
        {reports?.map((report) => (
          <StyledTableRow key={report.id}>
            <Link href={report.link} underline='none'>
              <Flex component={TableCell} justifyContent='space-between'>
                <Typography fontSize={14}>{report.linkName}</Typography>
                <Typography fontSize={14}>{formatDate(report.dateCreate)}</Typography>
              </Flex>
            </Link>
          </StyledTableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};

ReportsTab.propTypes = {
  reports: instanceOf(Array),
};
