import { bool } from 'prop-types';

export const ArrowRight = ({ changeOpacity = false }) => (
  <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M22.3999 18.3021L36.3383 32.2405L22.3999 46.1788L26.7014 50.4805L44.9414 32.2405L26.7014 14.0005L22.3999 18.3021Z'
      fill='white'
      fillOpacity={changeOpacity ? '0.25' : '1'}
    />
  </svg>
);

ArrowRight.propTypes = {
  changeOpacity: bool,
};
