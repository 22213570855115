export const ErrorIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.6355 9.99999L1.59999 17.0355L2.96449 18.4L9.99999 11.3645L17.0355 18.4L18.4 17.0355L11.3645 9.99999L18.4 2.96449L17.0355 1.59999L9.99999 8.6355L2.96449 1.59999L1.59999 2.96449L8.6355 9.99999Z'
      fill='#DE4141'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.6355 9.99999L1.59999 2.96449L2.96449 1.59999L9.99999 8.6355L17.0355 1.59999L18.4 2.96449L11.3645 9.99999L18.4 17.0355L17.0355 18.4L9.99999 11.3645L2.96449 18.4L1.59999 17.0355L8.6355 9.99999ZM9.99999 12.213L2.96449 19.2485L0.751465 17.0355L7.78697 9.99999L0.751465 2.96449L2.96449 0.751465L9.99999 7.78697L17.0355 0.751465L19.2485 2.96449L12.213 9.99999L19.2485 17.0355L17.0355 19.2485L9.99999 12.213Z'
      fill='#DE4141'
    />
  </svg>
);
