export const styles = {
  agreementLink: {
    '&.MuiTypography-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '304px',
      marginLeft: '7.5px',
    },
  },
  linkIcon: {
    width: '21px',
    height: '21px',
  },
  description: {
    '& .MuiTypography-root, &.MuiTypography-root': {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  requiredWrapper: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    marginBottom: '20px',
    marginTop: '8px',
    span: {
      color: '#DE4141',
    },
  },
  required: {
    span: {
      color: '#DE4141',
    },
  },
};
