import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useGetTesterValues } from 'src/hooks';
import { select } from 'src/styles';

import { theme } from '../../../theme';

const color = (status) =>
  status === 'SUSPENDED' || status === 'INACTIVE' ? theme.colors.error : '';

export const StatusSelect = ({ value, onChange }) => {
  const { testerStatusInternals } = useGetTesterValues();

  return (
    <Select
      value={value || ''}
      sx={select.root}
      onChange={onChange}
      renderValue={(val) => (
        <Typography fontSize={14} color={color(value)}>
          {val.toLowerCase()}
        </Typography>
      )}
    >
      {testerStatusInternals?.map((status) => (
        <MenuItem
          key={status.value}
          value={status.value}
          sx={{
            ...select.item,
            color: color(status.value),
          }}
        >
          {status.title}
        </MenuItem>
      ))}
    </Select>
  );
};

StatusSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};
