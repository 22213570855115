import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'src/components/common/CustomDateRangePicker/DateRangeStyle.css';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns';
import { bool, func, instanceOf, oneOfType, string } from 'prop-types';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-date-range';
import { CloseIcon } from 'src/components/UI/icons';
import { CalendarIcon } from 'src/components/UI/icons/CalendarIcon';
import { datePickerInput } from 'src/styles';
import { convertDateRangeToString } from 'src/utils/helpers';

import { DateRangePickerStyledContainer } from './DateRangePickerStyledContainer';

export const CustomDateRangePicker = ({
  value,
  setFieldValue,
  name,
  minDate = '01-01-2020',
  maxDate = '01-01-2025',
  placeholder,
  disableFuture = false,
  sx,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen((prev) => !prev);
  const closeDropdown = () => setIsOpen(false);

  const selectionRange = useMemo(() => {
    if (!value) {
      return {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      };
    }

    const splitted = value?.split(':');

    return {
      startDate: new Date(splitted[0]),
      endDate: new Date(splitted[1]),
      key: 'selection',
    };
  }, [value]);

  const clearValue = () => {
    setFieldValue(name, null);
  };

  const dateChangeHandler = ({ selection }) => {
    return setFieldValue(name, convertDateRangeToString(selection));
  };

  const showDatePlaceholder = useMemo(() => {
    if (!value) {
      return '';
    }
    const splitted = value?.split(':');

    const [monthStart, yearStart, dayStart] = format(new Date(splitted[0]), 'MMM-yyyy-dd').split(
      '-',
    );
    const [monthEnd, yearEnd, dayEnd] = format(new Date(splitted[1]), 'MMM-yyyy-dd').split('-');

    return `${monthStart} ${dayStart}, ${yearStart} - ${monthEnd} ${dayEnd}, ${yearEnd}`;
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <ClickAwayListener onClickAway={closeDropdown}>
          <Box>
            <TextField
              sx={[datePickerInput, sx]}
              value={showDatePlaceholder || placeholder}
              onClick={toggleDropdown}
              adornment={<CalendarIcon />}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position='start'
                    component='div'
                    sx={{
                      paddingLeft: '-14px',
                    }}
                    disablePointerEvents
                  >
                    <CalendarIcon />
                  </InputAdornment>
                ),
                endAdornment: value && (
                  <InputAdornment
                    position='end'
                    component='div'
                    onClick={clearValue}
                    sx={{
                      cursor: 'pointer',
                      width: 12,
                    }}
                  >
                    <CloseIcon />
                  </InputAdornment>
                ),
              }}
            />
            <DateRangePickerStyledContainer
              sx={{
                display: isOpen ? 'block' : 'none',
              }}
            >
              <DateRange
                maxDate={disableFuture ? new Date() : new Date(maxDate)}
                minDate={new Date(minDate)}
                showDateDisplay={false}
                onChange={dateChangeHandler}
                ranges={[selectionRange]}
                showMonthAndYearPickers={false}
              />
            </DateRangePickerStyledContainer>
          </Box>
        </ClickAwayListener>
      </Box>
    </LocalizationProvider>
  );
};

CustomDateRangePicker.propTypes = {
  value: oneOfType([string, instanceOf(Object)]),
  setFieldValue: func,
  name: string,
  placeholder: string,
  disableFuture: bool,
  sx: instanceOf(Object),
  minDate: string,
  maxDate: string,
};
