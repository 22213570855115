import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Flex, FooterContainer } from 'src/components/common/CommonStyledComponents';
import { StyledSubmitButton } from 'src/components/UI';
import { theme } from 'src/theme';

import { steps } from './mock';

export const Footer = ({
  handleClickNext,
  handleClickPrev,
  handleClickSubmit,
  currentStep,
  handleClickCancel,
  isNextButtonDisabled,
  isFinishButtonDisabled,
}) => (
  <FooterContainer>
    <Typography
      sx={{
        fontWeight: 700,
      }}
    >
      <span
        style={{
          color: theme.colors.error,
          marginRight: 2,
        }}
      >
        *
      </span>
      Required
    </Typography>
    <Flex>
      <Button
        sx={{
          marginRight: 4,
        }}
        onClick={handleClickCancel}
      >
        Cancel
      </Button>
      <Button
        onClick={handleClickPrev}
        sx={{
          marginRight: 4,
          height: 28,
        }}
        variant='outlined'
        disabled={currentStep <= 0}
      >
        Prev
      </Button>
      {currentStep < steps.length - 1 ? (
        <StyledSubmitButton
          disabled={isNextButtonDisabled}
          onClick={handleClickNext}
          variant='contained'
        >
          Next
        </StyledSubmitButton>
      ) : (
        <StyledSubmitButton
          disabled={isFinishButtonDisabled}
          onClick={handleClickSubmit}
          variant='contained'
        >
          Finish and Notify Tester
        </StyledSubmitButton>
      )}
    </Flex>
  </FooterContainer>
);

Footer.propTypes = {
  handleClickNext: PropTypes.func,
  handleClickPrev: PropTypes.func,
  currentStep: PropTypes.number,
  handleClickSubmit: PropTypes.func,
  handleClickCancel: PropTypes.func,
  isNextButtonDisabled: PropTypes.bool,
  isFinishButtonDisabled: PropTypes.bool,
};
