export const styles = {
  agreementLink: {
    '&.MuiTypography-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '304px',
      marginLeft: '7.5px',
    },
  },
  linkIcon: {
    width: '21px',
    height: '21px',
  },
  description: {
    '& .MuiTypography-root, &.MuiTypography-root': {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  selectField: {
    width: 'min-content',
    marginBottom: '0px !important',
  },
  typography: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
};
