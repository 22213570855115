import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import styled from '@mui/system/styled';
import { bool, instanceOf, node, string } from 'prop-types';
import { useCallback, useState } from 'react';
import { RequestHandler } from 'src/components/requestHandler/requestHandler';

import { detailTab } from './styles';

const Wrapper = styled(Box)({
  height: '100%',
});

const ScrollBox = styled(Box)({
  paddingTop: 12,
  overflow: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  height: '100%',
  boxSizing: 'border-box',
});

const TabPanel = ({ children, name, active }) => (
  <Box role='tabpanel' hidden={!active} id={`tabpanel-${name}`} aria-labelledby={`tab-${name}`}>
    {children}
  </Box>
);

TabPanel.propTypes = {
  children: node,
  name: string,
  active: bool,
};

export const DetailTabs = ({ tabs, scrollBoxStyle, loading }) => {
  const [currentTab, setCurrentTab] = useState(tabs[0]?.name);

  const handleChange = useCallback((event, value) => {
    event.preventDefault();
    setCurrentTab(value);
  }, []);

  return (
    <Wrapper>
      <RequestHandler loading={loading}>
        <Box sx={detailTab.container}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            aria-label='testerTabs'
            sx={{
              minHeight: 0,
            }}
            indicatorColor='secondary'
          >
            {tabs?.map((tab) => (
              <Tab
                label={tab.name}
                key={tab.name}
                value={tab.name}
                aria-controls={`testTabPanel-${tab.name}`}
                sx={detailTab.tap}
              />
            ))}
          </Tabs>
        </Box>
        <ScrollBox sx={scrollBoxStyle}>
          {tabs?.map(({ name, children }) => (
            <TabPanel key={name} name={name} active={currentTab === name}>
              {children}
            </TabPanel>
          ))}
        </ScrollBox>
      </RequestHandler>
    </Wrapper>
  );
};

DetailTabs.propTypes = {
  tabs: instanceOf(Array),
  scrollBoxStyle: instanceOf(Object),
  loading: bool,
};
