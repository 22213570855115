import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { func, number } from 'prop-types';
import { useCallback } from 'react';
import { ChevronRight } from 'src/components';
import {
  Flex,
  HeaderTitleContainer,
  StepsContainer,
} from 'src/components/common/CommonStyledComponents';
import { theme } from 'src/theme';

import { steps } from './mock';

export const Header = ({ currentStep, setCurrentStep, handleFileUpload }) => {
  const handleStepClick = useCallback((step) => () => setCurrentStep(step), []);

  const renderSteps = useCallback(
    () =>
      steps.map((step, index) => (
        <Flex key={step}>
          <Typography
            sx={{
              color:
                currentStep === index ? theme.colors.neutral.black : theme.colors.neutral.dark.mid,
              fontWeight: currentStep === index ? 700 : 400,
              textTransform: 'capitalize',
              marginRight: 2,
              cursor: 'pointer',
            }}
            onClick={handleStepClick(index)}
          >
            {step}
          </Typography>
          {step === 'notes' || (
            <ChevronRight
              size={155}
              sx={{
                marginRight: 12,
              }}
            />
          )}
        </Flex>
      )),
    [currentStep, steps],
  );

  return (
    <Box
      sx={{
        padding: '20px 24px',
      }}
    >
      <HeaderTitleContainer>
        <Typography variant='modalTitle' id='modal-title'>
          Add Tester
        </Typography>
        <label htmlFor='file'>
          <Typography
            sx={{
              color: theme.colors.tertiary,
              fontSize: 14,
              fontWeight: 700,
              cursor: 'pointer',
            }}
          >
            Upload CSV
          </Typography>
          <input type='file' id='file' hidden onChange={handleFileUpload} />
        </label>
      </HeaderTitleContainer>
      <StepsContainer>{renderSteps()}</StepsContainer>
    </Box>
  );
};

Header.propTypes = {
  currentStep: number,
  setCurrentStep: func,
  handleFileUpload: func,
};
