import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { saveAs } from 'file-saver';
import { arrayOf, instanceOf } from 'prop-types';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { DownloadIcon } from 'src/components/UI';
import { useTestInfo, useUploadTestMedia } from 'src/hooks';

export const UploadMediaSection = ({ mediaList = [] }) => {
  const [uploadTestMedia] = useUploadTestMedia();
  const { useRefetchTestersData } = useTestInfo();
  const { id } = useParams();

  const handleFileUpload = async ({ target }) => {
    const [file] = target.files;

    const uploadMedia = [file, URL.createObjectURL(file)];

    await uploadTestMedia({
      variables: {
        file: uploadMedia[0],
        input: {
          testId: id,
        },
      },
    });
    useRefetchTestersData();
    // eslint-disable-next-line no-param-reassign
    target.value = null;
  };

  const downloadAllMedia = useCallback(() => {
    mediaList.forEach((el) => {
      const filename = el.fileUrl.substring(el.fileUrl.lastIndexOf('/') + 1);
      saveAs(`${process.env.REACT_APP_DOMAIN}/${el.fileUrl}`, filename);
    });
  }, [mediaList]);

  return (
    <Box>
      <form onSubmit={() => {}}>
        <Flex justifyContent='space-between'>
          <Button
            variant='contained'
            color='primary'
            size='small'
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              borderRadius: '24px',
              height: '32px',
              width: '85px',
            }}
            component='label'
            htmlFor='file'
            disabled={mediaList?.length >= 10}
          >
            Upload
            {mediaList?.length >= 10 || (
              <input type='file' id='file' hidden onChange={handleFileUpload} />
            )}
          </Button>
          {mediaList.length !== 0 ? (
            <IconButton onClick={downloadAllMedia}>
              <DownloadIcon color='#676767' />
            </IconButton>
          ) : null}
        </Flex>
      </form>
    </Box>
  );
};

UploadMediaSection.propTypes = {
  mediaList: arrayOf(instanceOf(Object)),
};
