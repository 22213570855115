/* eslint-disable object-curly-newline */
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { StyledSelect } from 'src/components/common/CommonStyledComponents';

const prepareToSelect = (value) => {
  if (value === '') return value;
  return value ? 'Yes' : 'No';
};

const prepareForForm = (value) => {
  if (value === '') return '';
  return value === 'Yes';
};

// We can receive true, false or empty string
export const SelectYesOrNot = ({
  name,
  onChange,
  values = {},
  placeholder = 'Select yes or no',
  sx,
}) => {
  const selectValue = values[name];

  const internalOnChange = (e) => {
    const {
      target: { value: eventValue },
    } = e;
    onChange({
      ...e,
      target: {
        ...e.target,
        value: prepareForForm(eventValue),
      },
    });
  };

  return (
    <StyledSelect
      value={prepareToSelect(selectValue)}
      name={name}
      onChange={internalOnChange}
      placeholder={placeholder}
      sx={sx}
    >
      <MenuItem value='Yes'>Yes</MenuItem>
      <MenuItem value='No'>No</MenuItem>
    </StyledSelect>
  );
};

SelectYesOrNot.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  values: PropTypes.instanceOf(Object),
  placeholder: PropTypes.string,
  sx: PropTypes.instanceOf(Object),
};
