import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { HeaderTitleContainer } from '../common/CommonStyledComponents';

const FormHeader = () => (
  <Box
    sx={{
      padding: '20px 24px',
    }}
  >
    <HeaderTitleContainer>
      <Typography variant='modalTitle' id='modal-title'>
        Add Customer
      </Typography>
    </HeaderTitleContainer>
  </Box>
);

export default FormHeader;
