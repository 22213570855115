export const reportStyle = {
  root: {
    container: {
      margin: '8px 10px',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  },
  modal: {
    paper: {
      outline: 'none',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      minWidth: 384,
    },
    container: {
      padding: '0 24px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      marginBottom: 4,
    },
  },
  button: {
    fontSize: 14,
    cursor: 'pointer',
    add: {
      fontSize: '14px',
      padding: '4px 16px',
      lineHeight: '20px',
      height: 28,
    },
    cancel: {
      height: 28,
    },
  },
};
