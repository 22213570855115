import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { Flex } from '../common/CommonStyledComponents';
import { CheckIconDefault } from '../UI';
import { style } from './style';

export const TesterMyTestsHeader = ({
  testsCompletedCounter,
  ongoingTestsCounter,
  setActiveStatus,
  setInactiveStatus,
  isAvailable,
}) => (
  <Flex
    sx={{
      justifyContent: 'space-between',
      paddingBottom: '20px',
    }}
  >
    <Flex>
      <Button
        variant={isAvailable ? 'contained' : 'outlined'}
        color={isAvailable ? 'primary' : undefined}
        onClick={setActiveStatus}
        sx={style.button}
      >
        {'Active'}
      </Button>
      <Button
        startIcon={!isAvailable ? <CheckIconDefault /> : null}
        variant={!isAvailable ? 'contained' : 'outlined'}
        color={!isAvailable ? 'primary' : undefined}
        sx={{
          ...style.button,
          margin: '0px 8px',
        }}
        onClick={setInactiveStatus}
      >
        {'Inactive'}
      </Button>
      <Typography
        sx={{
          color: '#676767',
        }}
      >
        {'Let us know your availability'}
      </Typography>
    </Flex>
    <Flex>
      <Flex>
        <Typography sx={style.textCount}>{'Tests Completed'}</Typography>
        <Typography>{testsCompletedCounter}</Typography>
      </Flex>
      <Flex
        sx={{
          marginLeft: '20px',
        }}
      >
        <Typography sx={style.textCount}>{'Ongoing Tests'}</Typography>
        <Typography>{ongoingTestsCounter}</Typography>
      </Flex>
    </Flex>
  </Flex>
);

TesterMyTestsHeader.propTypes = {
  testsCompletedCounter: PropTypes.number.isRequired,
  ongoingTestsCounter: PropTypes.number.isRequired,
  isAvailable: PropTypes.string,
  setActiveStatus: PropTypes.func.isRequired,
  setInactiveStatus: PropTypes.func.isRequired,
};
