/* eslint-disable */
import { string } from 'prop-types';

export const EyeOpenIcon = ({ color = 'white' }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.6854 7.69545C15.6627 7.64426 15.1149 6.42914 13.9054 5.21945C12.2897 3.60358 10.2476 2.74945 8.00001 2.74945C5.75239 2.74945 3.71032 3.60358 2.0947 5.21945C0.885073 6.42914 0.337323 7.64426 0.314698 7.69545C0.272081 7.79134 0.250061 7.89511 0.250061 8.00005C0.250061 8.10498 0.272081 8.20875 0.314698 8.30464C0.337385 8.35576 0.885135 9.57064 2.0947 10.7801C3.71039 12.3956 5.75239 13.2495 8.00001 13.2495C10.2476 13.2495 12.2896 12.3956 13.9053 10.7801C15.1149 9.5707 15.6626 8.35576 15.6854 8.30464C15.728 8.20875 15.75 8.10498 15.75 8.00005C15.75 7.89511 15.728 7.79134 15.6854 7.69545ZM12.8071 9.7567C11.4666 11.079 9.84926 11.7495 8.00001 11.7495C6.15076 11.7495 4.53339 11.079 3.19289 9.7567C2.66575 9.23514 2.21219 8.64414 1.84476 8.00001C2.21216 7.35569 2.66572 6.76447 3.19289 6.2427C4.53357 4.92008 6.15082 4.24951 8.00001 4.24951C9.8492 4.24951 11.4666 4.92014 12.8071 6.2427C13.3342 6.76444 13.7878 7.35561 14.1552 7.99989C13.7878 8.64407 13.3343 9.23512 12.8071 9.7567ZM8.00001 5.25001C7.45611 5.25001 6.92443 5.4113 6.47219 5.71347C6.01996 6.01565 5.66748 6.44514 5.45934 6.94763C5.2512 7.45013 5.19674 8.00307 5.30285 8.53651C5.40896 9.06996 5.67087 9.55996 6.05547 9.94456C6.44006 10.3292 6.93007 10.5911 7.46351 10.6972C7.99696 10.8033 8.54989 10.7488 9.05239 10.5407C9.55489 10.3325 9.98438 9.98007 10.2866 9.52783C10.5887 9.0756 10.75 8.54391 10.75 8.00001C10.7492 7.27092 10.4592 6.57193 9.94364 6.05638C9.4281 5.54084 8.7291 5.25084 8.00001 5.25001ZM8.00001 9.25001C7.75278 9.25001 7.51111 9.1767 7.30555 9.03935C7.09999 8.902 6.93977 8.70678 6.84516 8.47837C6.75055 8.24996 6.7258 7.99863 6.77403 7.75615C6.82226 7.51368 6.94131 7.29095 7.11613 7.11613C7.29094 6.94132 7.51367 6.82226 7.75615 6.77403C7.99862 6.7258 8.24996 6.75055 8.47837 6.84516C8.70677 6.93977 8.902 7.09999 9.03935 7.30555C9.1767 7.51111 9.25001 7.75279 9.25001 8.00001C9.24965 8.33142 9.11784 8.64916 8.88349 8.8835C8.64915 9.11784 8.33142 9.24965 8.00001 9.25001Z"
          fill={color} />
  </svg>
);

EyeOpenIcon.propTypes = {
  color: string,
};
