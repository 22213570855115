import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { LinkOut } from 'src/components';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { DatePicker } from 'src/components/common/DatePicker';
import { testerCreationForm } from 'src/styles';

export const Notes = () => {
  const { values = {}, errors = {}, setFieldValue, handleChange } = useFormikContext();

  return (
    <Box
      sx={{
        width: '75%',
      }}
    >
      <StyledLabel label='Notes (for internal use only)'>
        <TextField
          sx={testerCreationForm.input}
          minRows={6}
          size='small'
          name='notesInternal'
          multiline
          value={values.notesInternal}
          onInput={handleChange}
          error={!!errors.notesInternal}
          helperText={errors.notesInternal}
          placeholder='Eg. I travel to Alaska every summer to teach polar bear tennis and ski the barren tundra.'
        />
      </StyledLabel>
      <StyledLabel label='Agreement Signed'>
        <DatePicker
          sx={testerCreationForm.input}
          name='agreementSigned'
          setFieldValue={setFieldValue}
          value={values.agreementSigned}
          placeholder='Select Date'
          hideLabel
        />
      </StyledLabel>
      <StyledLabel label='Link to Agreement'>
        <TextField
          sx={testerCreationForm.input}
          name='agreementLink'
          onInput={handleChange}
          error={!!errors.agreementLink}
          value={values.agreementLink}
          onChange={handleChange}
          helperText={errors.agreementLink}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <LinkOut />
              </InputAdornment>
            ),
            style: {
              height: 40,
            },
          }}
          placeholder='Add Link'
        />
      </StyledLabel>
    </Box>
  );
};
