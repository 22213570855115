import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import PropTypes from 'prop-types';

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'direction',
})(({ direction }) => ({
  display: 'flex',
  flexDirection: direction,
  width: '100%',
}));

const Span = styled('span')(({ theme }) => ({
  color: theme.colors.neutral.dark.extra,
  fontWeight: 700,
}));

const Required = styled('span')(
  ({ theme }) => `
    color: ${theme.colors.error}
`,
);

export const Label = ({
  label = '',
  children,
  sx,
  required,
  direction = 'column',
  mb,
  className,
  ...rest
}) => (
  <Wrapper sx={sx} direction={direction} className={className} {...rest}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: mb || (direction === 'column' ? 2 : 0),
      }}
    >
      <Span>
        {label}
        {required && <Required> *</Required>}
      </Span>
    </Box>
    {children}
  </Wrapper>
);

Label.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.instanceOf(Object),
  required: PropTypes.bool,
  direction: PropTypes.string,
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};
