import AdapterDayjs from '@mui/lab/AdapterDayjs';
import MUIDatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { bool, func, instanceOf, oneOfType, string } from 'prop-types';
import { useCallback, useMemo } from 'react';
import { CalendarIcon } from 'src/components/UI/icons/CalendarIcon';
import { datePickerInput } from 'src/styles';
import { formatDate } from 'src/utils/helpers';

export const DatePicker = ({
  value = null,
  setFieldValue,
  name,
  setFieldError,
  hideLabel = false,
  placeholder,
  touched,
  onBlur,
  onClose,
  sx,
}) => {
  const dateChangeHandler = (newValue) => {
    if (!newValue) return false;

    const date = formatDate(newValue, 'af-NA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    return setFieldValue(name, date);
  };

  const onErrorHandler = useCallback(
    (error) => {
      if (touched && error) {
        setFieldError(name, error);
      }
    },
    [touched],
  );

  const renderHandler = useCallback(
    (pl) => (params) =>
      (
        <TextField
          {...params}
          sx={[datePickerInput, sx]}
          onBlur={onBlur}
          inputProps={{
            ...params.inputProps,
            placeholder: pl,
            readOnly: true,
          }}
        />
      ),
    [],
  );

  const pickerValue = useMemo(() => {
    if (value) {
      dayjs(value).toDate();
    }

    return value;
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MUIDatePicker
        label={hideLabel ? '' : 'Select Date Range'}
        value={pickerValue}
        openTo='day'
        inputFormat='MM/DD/YYYY'
        mask='__/__/____'
        onError={onErrorHandler}
        onChange={dateChangeHandler}
        renderInput={renderHandler(placeholder)}
        components={{
          OpenPickerIcon: CalendarIcon,
        }}
        InputAdornmentProps={{
          position: 'start',
        }}
        onBlur={onBlur}
        onClose={onClose}
      />
    </LocalizationProvider>
  );
};

DatePicker.propTypes = {
  value: oneOfType([string, instanceOf(Object)]),
  setFieldValue: func,
  setFieldError: func,
  name: string,
  hideLabel: bool,
  placeholder: string,
  touched: bool,
  onBlur: func,
  onClose: func,
  sx: instanceOf(Object),
};
