import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { bool, node, string } from 'prop-types';

import { Flex } from '../common/CommonStyledComponents';
import { Loader } from '../UI';

export const RequestHandler = ({ loading, errorMessage, children }) => {
  if (errorMessage) {
    return (
      <Flex
        sx={{
          justifyContent: 'center',
        }}
      >
        <Typography>{errorMessage}</Typography>
      </Flex>
    );
  }

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Box>{children}</Box>
    </>
  );
};

RequestHandler.propTypes = {
  loading: bool,
  children: node,
  errorMessage: string,
};
