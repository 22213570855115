import { theme } from 'src/theme';

export const uploadModalStyles = {
  failedRows: {
    fontSize: 14,
    backgroundColor: theme.colors.neutral.light.extra,
    padding: '4px 8px',
    color: theme.colors.neutral.dark.mid,
    mt: 2,
  },
  uploadResultRow: {
    ml: 2.5,
    fontSize: 14,
  },
  headerTitle: {
    borderBottom: `1px solid ${theme.colors.neutral.light.normal}`,
    padding: '20px 24px',
  },
};

export const styles = {
  emptyText: {
    color: '#000000',
    fontSize: '24px',
    padding: '16px 0px 12px',
  },
  twoColumns: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
    maxWidth: '436px',
    width: '100%',
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      height: '40px',
      boxSizing: 'border-box',
      '&::-webkit-input-placeholder': {
        color: '#676767',
        opacity: 1,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0000003b',
    },
  },
  wrapItem: {
    border: 'solid 1px black',
    margin: 1,
    borderRadius: '4px',
    padding: '2px 12px 2px 12px',
  },
  dropdownTitleBox: {
    marginLeft: '5px',
    width: '100%',
    border: 'none',
    padding: 0,
  },
  dropdownTitleText: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#000',
  },
  dropdownSubTitleText: {
    fontSize: '12px',
    color: '#000',
  },
};
