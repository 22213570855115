import Typography from '@mui/material/Typography';
import { Flex, StyledPageContainer } from 'src/components/common/CommonStyledComponents';

const UserRemovedGoodBye = () => (
  <StyledPageContainer
    sx={{
      maxWidth: 824,
      paddingY: 24,
    }}
  >
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'start',
        gap: 4,
      }}
    >
      <Typography variant='h2'>Thanks for letting us know</Typography>
      <Typography fontSize={20}>We’re sad to see you go.</Typography>
      <Typography fontSize={20}>Happy trails!</Typography>
    </Flex>
  </StyledPageContainer>
);

export default UserRemovedGoodBye;
