import Box from '@mui/material/Box';
import { func, instanceOf } from 'prop-types';

export const ExpandIcon = ({ sx, onClick }) => (
  <Box
    onClick={onClick}
    className='expandedIcon'
    sx={{
      width: '55px',
      height: '55px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      ...sx,
    }}
  >
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M33.3334 8.33333C33.3334 7.8913 33.1578 7.46738 32.8453 7.15482C32.5327 6.84226 32.1088 6.66666 31.6667 6.66666H23.3334C22.8914 6.66666 22.4675 6.84226 22.1549 7.15482C21.8423 7.46738 21.6667 7.8913 21.6667 8.33333C21.6667 8.77536 21.8423 9.19928 22.1549 9.51184C22.4675 9.8244 22.8914 10 23.3334 10H27.6167L22.1501 15.4833C21.9939 15.6383 21.8699 15.8226 21.7853 16.0257C21.7006 16.2288 21.6571 16.4466 21.6571 16.6667C21.6571 16.8867 21.7006 17.1045 21.7853 17.3076C21.8699 17.5107 21.9939 17.6951 22.1501 17.85C22.305 18.0062 22.4894 18.1302 22.6925 18.2148C22.8955 18.2994 23.1134 18.343 23.3334 18.343C23.5534 18.343 23.7713 18.2994 23.9744 18.2148C24.1775 18.1302 24.3618 18.0062 24.5167 17.85L30.0001 12.3667V16.6667C30.0001 17.1087 30.1757 17.5326 30.4882 17.8452C30.8008 18.1577 31.2247 18.3333 31.6667 18.3333C32.1088 18.3333 32.5327 18.1577 32.8453 17.8452C33.1578 17.5326 33.3334 17.1087 33.3334 16.6667V8.33333ZM17.8501 22.15C17.6951 21.9938 17.5108 21.8698 17.3077 21.7852C17.1046 21.7006 16.8868 21.657 16.6667 21.657C16.4467 21.657 16.2289 21.7006 16.0258 21.7852C15.8227 21.8698 15.6384 21.9938 15.4834 22.15L10.0001 27.6167V23.3333C10.0001 22.8913 9.82449 22.4674 9.51193 22.1548C9.19936 21.8423 8.77544 21.6667 8.33341 21.6667C7.89139 21.6667 7.46746 21.8423 7.1549 22.1548C6.84234 22.4674 6.66675 22.8913 6.66675 23.3333V31.6667C6.66675 32.1087 6.84234 32.5326 7.1549 32.8452C7.46746 33.1577 7.89139 33.3333 8.33341 33.3333H16.6667C17.1088 33.3333 17.5327 33.1577 17.8453 32.8452C18.1578 32.5326 18.3334 32.1087 18.3334 31.6667C18.3334 31.2246 18.1578 30.8007 17.8453 30.4881C17.5327 30.1756 17.1088 30 16.6667 30H12.3667L17.8501 24.5167C18.0063 24.3617 18.1303 24.1774 18.2149 23.9743C18.2995 23.7712 18.3431 23.5533 18.3431 23.3333C18.3431 23.1133 18.2995 22.8955 18.2149 22.6924C18.1303 22.4893 18.0063 22.3049 17.8501 22.15Z'
        fill='#ffff'
      />
    </svg>
  </Box>
);

ExpandIcon.propTypes = {
  sx: instanceOf(Object),
  onClick: func,
};
