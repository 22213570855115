import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useAddTester, useGetTesterValues } from 'src/hooks';
import { getCreateTesterSchema } from 'src/utils/formSchemas';

import { CreateTester } from './CreateTester';
import { initialValues } from './createTesterForm/mock';

export const FormikWrapperCreateTester = ({ tester = {}, toggleModal, open, duplicate }) => {
  const addTester = useAddTester();
  const possibleValues = useGetTesterValues();
  const activities = possibleValues.activities || [];

  const initialTester = useMemo(() => {
    const result = {};

    const { tester: testerInfo, contactName, ...other } = tester;
    const reorderedTester = {
      fullName: contactName,
      ...testerInfo,
      ...other,
      email: '',
    };

    Object.entries(reorderedTester).forEach(([key, value]) => {
      if (typeof initialValues[key] !== 'undefined') result[key] = value;
    });

    return result;
  }, [tester]);

  const clearFromDefaultValues = (data) => {
    const result = {};
    Object.entries(data).forEach(([key, value]) => {
      if (value === '') result[key] = null;
      else result[key] = value;
    });
    return result;
  };

  const submitForm = async (data) => {
    const {
      fullName,
      email,
      weightLbs,
      weightKgs,
      heightMetric,
      heightImperialInch,
      heightImperialFeet,
      chestInches,
      chestCm,
      waistAtNavelInches,
      waistAtNavelCm,
      waistAtPantsInches,
      waistAtPantsCm,
      hipsInches,
      hipsCm,
      inseamInches,
      inseamCm,
      phoneNumber,
      country,
      state,
      age,
      generalShoeSizes,
      runningShoeSizes,
      hikingShoeSizes,
      sandalShoeSizes,
      shoeWidth,
      ...testerInfo
    } = clearFromDefaultValues(data);

    testerInfo.favoriteActivities = testerInfo?.favoriteActivities?.map((item) => item.value);

    await addTester({
      input: {
        fullName,
        email,
        testerInfo: {
          ...testerInfo,
          countryId: country?.id,
          stateId: state?.id,
          phoneNumber: phoneNumber?.toString(),
          weightLbs: weightLbs?.toString(),
          weightKgs: weightKgs?.toString(),
          heightMetric: heightMetric?.toString(),
          heightImperialInch: heightImperialInch?.toString(),
          heightImperialFeet: heightImperialFeet?.toString(),
          chestInches: chestInches?.toString(),
          chestCm: chestCm?.toString(),
          waistAtNavelInches: waistAtNavelInches?.toString(),
          waistAtNavelCm: waistAtNavelCm?.toString(),
          waistAtPantsInches: waistAtPantsInches?.toString(),
          waistAtPantsCm: waistAtPantsCm?.toString(),
          hipsInches: hipsInches?.toString(),
          hipsCm: hipsCm?.toString(),
          inseamInches: inseamInches?.toString(),
          inseamCm: inseamCm?.toString(),
          age: age?.toString(),
          generalShoeSizes,
          runningShoeSizes,
          hikingShoeSizes,
          sandalShoeSizes,
          shoeWidth,
        },
      },
    });
    await toggleModal();
  };

  return (
    <Formik
      initialValues={{
        ...initialTester,
        ...initialValues,
      }}
      onSubmit={submitForm}
      enableReinitialize={true}
      validationSchema={getCreateTesterSchema(activities)}
    >
      <Form>
        <CreateTester toggleModal={toggleModal} open={open} duplicate={duplicate} />
      </Form>
    </Formik>
  );
};

FormikWrapperCreateTester.propTypes = {
  tester: PropTypes.instanceOf(Object),
  toggleModal: PropTypes.func,
  open: PropTypes.bool,
  duplicate: PropTypes.bool,
};
