import { Form, Formik } from 'formik';
import { func } from 'prop-types';
import { createPasswordSchema } from 'src/utils/formSchemas';

import { CreatePasswordForm } from './CreatePasswordForm';

const initialValues = {
  password: '',
  repassword: '',
};

export const CreatePasswordFormWrapper = ({ onSubmit, handleBackToLogin }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={createPasswordSchema}
    >
      <Form>
        <CreatePasswordForm handleBackToLogin={handleBackToLogin} />
      </Form>
    </Formik>
  );
};

CreatePasswordFormWrapper.propTypes = {
  onSubmit: func,
  handleBackToLogin: func,
};
