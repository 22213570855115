import SvgIcon from '@mui/material/SvgIcon';

export const SuccessIcon = () => (
  <SvgIcon
    width='21'
    height='16'
    viewBox='0 0 21 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M21.0401 0.54668C21.4254 0.910972 21.6503 1.41338 21.6653 1.94345C21.6803 2.47352 21.4842 2.98784 21.1201 3.37335L9.78675 15.3733C9.60306 15.5675 9.38234 15.7228 9.1376 15.8302C8.89287 15.9376 8.62909 15.9949 8.36186 15.9986C8.09462 16.0023 7.82935 15.9525 7.58172 15.8519C7.33408 15.7514 7.10911 15.6023 6.92008 15.4133L0.920079 9.41335C0.566799 9.03421 0.37447 8.53276 0.383612 8.01462C0.392754 7.49648 0.602653 7.00212 0.969088 6.63569C1.33552 6.26925 1.82988 6.05936 2.34802 6.05021C2.86615 6.04107 3.36761 6.2334 3.74675 6.58668L8.29341 11.1307L18.2134 0.62668C18.5777 0.241339 19.0801 0.0164442 19.6102 0.00144232C20.1402 -0.0135596 20.6546 0.18256 21.0401 0.54668Z'
      fill='#90E16A'
    />
  </SvgIcon>
);
