export const styles = {
  smallField: {
    maxWidth: '228px',
    width: '100%',
  },
  middleField: {
    maxWidth: '400px',
    width: '100%',
  },
  createTestContainer: {
    '& input.MuiInputBase-input.MuiOutlinedInput-input, & textarea.MuiInputBase-input.MuiOutlinedInput-input':
      {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        color: '#000000',
      },
    '& .MuiInputBase-input.MuiOutlinedInput-input::-webkit-input-placeholder, & .MuiSelect-nativeInput::-webkit-input-placeholder':
      {
        opacity: 'unset',
        color: '#676767',
        fontSize: '14px',
        lineHeight: '20px',
      },
    '& .mb-20': {
      marginBottom: '20px',
    },
  },
};
