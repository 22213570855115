import { StyledPageContainer } from 'src/components/common/CommonStyledComponents';
import { InviteToTestContainer } from 'src/components/inviteToTest/inviteToTestContainer';

const InviteToTest = () => {
  return (
    <StyledPageContainer>
      <InviteToTestContainer />
    </StyledPageContainer>
  );
};

export default InviteToTest;
