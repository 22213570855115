import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { HeaderTitleContainer, StyledModal } from 'src/components/common/CommonStyledComponents';
import { DetailLabel } from 'src/components/UI';

import {
  ButtonStyled,
  ConfirmStyledPaper,
  StyledBottomBox,
  StyledDivider,
  WrapperBox,
} from './styles';

export const UnenrollModal = ({ open, toggleModal, callback, user }) => {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClickFinished = () => {
    callback(value);
    toggleModal();
  };

  const handleClickCancel = () => {
    toggleModal();
  };

  return (
    <StyledModal
      sx={{
        alignItems: 'center',
      }}
      open={open}
      aria-labelledby='modal-title'
      onClose={toggleModal}
    >
      <ConfirmStyledPaper>
        <WrapperBox>
          <Box
            sx={{
              padding: '20px 24px',
            }}
          >
            <HeaderTitleContainer>
              <Typography
                variant='modalTitle'
                id='modal-title'
                sx={{
                  textTransform: 'uppercase',
                }}
              >
                Unenroll tester
              </Typography>
            </HeaderTitleContainer>
            <StyledDivider />
            <DetailLabel label='Name'>
              <Typography>{user?.contactName}</Typography>
            </DetailLabel>
            <DetailLabel label='Note (Optional)'>
              <TextField
                sx={{
                  marginTop: '8px',
                }}
                id='standard-multiline-flexible'
                label='Add a note'
                multiline
                rows={5}
                value={value}
                onChange={handleChange}
                variant='outlined'
              />
            </DetailLabel>
          </Box>
          <StyledBottomBox>
            <ButtonStyled
              sx={{
                marginRight: 4,
              }}
              onClick={handleClickCancel}
            >
              Cancel
            </ButtonStyled>
            <ButtonStyled
              color='primary'
              variant='contained'
              sx={{
                width: '103px',
                color: 'white',
              }}
              onClick={handleClickFinished}
            >
              Unenroll
            </ButtonStyled>
          </StyledBottomBox>
        </WrapperBox>
      </ConfirmStyledPaper>
    </StyledModal>
  );
};

UnenrollModal.propTypes = {
  open: PropTypes.bool,
  toggleModal: PropTypes.func,
  callback: PropTypes.func,
  user: PropTypes.instanceOf(Object),
};
