import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { InviteStatus } from 'src/constants/constants';

import { style } from './style';

export const InviteToTestResponceContainer = () => {
  const { search } = useLocation();

  const { status } = queryString.parse(search) || {};

  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate('/login');
  };

  if (status === InviteStatus.ACCEPTED) {
    return (
      <Box
        sx={{
          ...style.container,
          paddingTop: '96px',
        }}
      >
        <Typography sx={style.headerTextRecponce}>{'Thank You!'}</Typography>
        <Typography sx={style.textRecponce}>
          {'Your test enrollment is pending and we’ll be in touch soon.'}
        </Typography>
        <Button variant='contained' color='primary' sx={style.button} onClick={navigateToLogin}>
          {'Log In'}
        </Button>
      </Box>
    );
  }

  if (status === InviteStatus.DECLINED) {
    return (
      <Box
        sx={{
          ...style.container,
          paddingTop: '96px',
        }}
      >
        <Typography sx={style.headerTextRecponce}>{'Thanks for letting us know!'}</Typography>
        <Typography sx={style.textRecponce}>
          {'We’ll let you if you’re a good fit for another test in the future.'}
        </Typography>
        <Button variant='contained' color='primary' sx={style.button} onClick={navigateToLogin}>
          {'Log In'}
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={style.container}>
      <Typography sx={style.headerTextRecponce}>{'Error'}</Typography>
    </Box>
  );
};
