import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { StatusChip } from 'src/components/common/StatusChip';
import {
  PaginationSection,
  StyledTable,
  StyledTableRow,
  TablePagination,
} from 'src/components/UI/tables';
import { EmptyTableBanner } from 'src/components/UI/tables/EmptyTableBanner';
import { formatDate } from 'src/utils/helpers';

import { TableHeader } from './TableHeader';

const COLUMNS = [
  {
    title: 'Brand',
    sortKey: 'brand',
  },
  {
    title: 'Style',
    sortKey: 'styleDescription',
  },
  {
    title: 'Start Date',
    sortKey: 'startDate',
  },
  {
    title: 'End Date',
    sortKey: 'endDate',
  },
  {
    title: 'Status',
    sortKey: 'status',
  },
];

function areEqual(prevProps, nextProps) {
  if (prevProps?.data === nextProps?.data) {
    return true;
  }
  return false;
}

export const MyTestsTable = React.memo(({ data, fetchMore }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(16);
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState('DESC');
  const navigate = useNavigate();

  const tests = data?.getMyTests?.rows || [];

  useEffect(() => {
    fetchMore({
      variables: {
        limit: rowsPerPage,
        offset: rowsPerPage * (currentPage - 1),
        sort: {
          type: order,
          field: orderBy,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          getMyTests: fetchMoreResult.getMyTests,
        };
      },
    });
  }, [currentPage, rowsPerPage, order, orderBy]);

  const handleChangePage = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleChangeRowsPerPage = useCallback((value) => {
    setRowsPerPage(value);
    setCurrentPage(1);
  }, []);

  const handleRequestSort = useCallback(
    (property) => () => {
      const isAsc = orderBy === property && order === 'ASC';
      setOrder(isAsc ? 'DESC' : 'ASC');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  if (tests?.length === 0 || !tests) {
    return <EmptyTableBanner text='You don’t have any tests available yet' />;
  }

  const handleItemClick = (id) => (evt) => {
    const { tagName } = evt.target;

    if (tagName === 'TR' || tagName === 'TD') {
      navigate(`/tests/${id}`);
    }
  };

  return (
    <>
      <TablePagination
        page={currentPage}
        rowsPerPage={rowsPerPage}
        count={data?.getMyTests?.count}
        currentPageRowsCount={tests.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <StyledTable
        sx={{
          mt: 6,
        }}
      >
        <TableHeader
          columns={COLUMNS}
          orderBy={orderBy}
          order={order}
          createSortHandler={handleRequestSort}
        />
        <TableBody>
          {tests?.map((test) => (
            <StyledTableRow key={test.id} onClick={handleItemClick(test.id)}>
              <TableCell>{test.brand.name}</TableCell>
              <TableCell
                sx={{
                  minWidth: 200,
                }}
              >
                {test.styleDescription}
              </TableCell>
              <TableCell>{formatDate(test.startDate)}</TableCell>
              <TableCell>{formatDate(test.endDate)}</TableCell>
              <TableCell
                sx={{
                  width: 150,
                }}
              >
                <Flex justifyContent='space-between'>
                  <StatusChip status={test.status} />
                </Flex>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
      <PaginationSection
        page={currentPage}
        count={data?.getMyTests?.count && Math.ceil(data.getMyTests.count / rowsPerPage)}
        onChange={handleChangePage}
      />
    </>
  );
}, areEqual);

MyTestsTable.propTypes = {
  data: PropTypes.instanceOf(Object),
  fetchMore: PropTypes.func,
};
