import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { bool, func } from 'prop-types';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { RequestHandler } from 'src/components/requestHandler/requestHandler';
import { PlusIcon } from 'src/components/UI/icons';
import { StyledTable, StyledTableHead } from 'src/components/UI/tables';
import { useFilter, useGetTestersQuery, useTestInfo } from 'src/hooks';
import { prepareFilterData } from 'src/utils/helpers';

const columns = [
  {
    title: 'Name',
  },
  {
    title: 'Group',
  },
  {
    title: 'Location',
  },
  {
    title: 'Bio',
  },
  {
    title: 'Notes',
  },
];

const Header = () => (
  <StyledTableHead
    sx={{
      height: '60px',
    }}
  >
    <TableRow>
      <TableCell
        sx={{
          width: '108px',
        }}
      />
      {columns.map((item) => (
        <TableCell key={item.title}>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 700,
            }}
          >
            {item.title}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  </StyledTableHead>
);

const AddCellSection = ({ isTesterAdded, toggleHandler }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    {isTesterAdded ? (
      <Chip onClick={toggleHandler} label='Added' color='primary' />
    ) : (
      <Button onClick={toggleHandler} startIcon={<PlusIcon />} variant='outlined'>
        Add
      </Button>
    )}
  </Box>
);

AddCellSection.propTypes = {
  isTesterAdded: bool,
  toggleHandler: func,
};
export const InfiniteScrollTestersTable = React.memo(
  ({ toggleHandler, checkIsAdded, setCount }) => {
    const tableEl = useRef();
    const [currentPage, setCurrentPage] = useState(1);
    const [distanceBottom, setDistanceBottom] = useState(0);
    const rowsPerPage = 16;

    const { filters } = useFilter();
    const { candidatesList, enrolledList, loading: loadingExcludeUser } = useTestInfo();

    const excludeUserIds = useMemo(
      () => [
        ...(candidatesList ? candidatesList.map((item) => item.user.id) : []),
        ...(enrolledList ? enrolledList.map((item) => item.user.id) : []),
      ],
      [enrolledList, candidatesList],
    );

    const { testers, fetchMore, testersCount, loading } = useGetTestersQuery({
      variables: {
        options: {
          doNotShowUnregistered: true,
          excludeUserIds,
          limit: rowsPerPage,
          offset: 0,
        },
      },
    });

    useEffect(() => {
      setCount(testers?.length);
    }, [testers?.length]);

    useEffect(() => {
      const { search, ...other } = filters;
      // eslint-disable-next-line no-unused-expressions
      !loadingExcludeUser &&
        fetchMore({
          variables: {
            options: {
              limit: rowsPerPage,
              offset: rowsPerPage * (currentPage - 1),
              testerFilter: prepareFilterData(other),
              doNotShowUnregistered: true,
              excludeUserIds,
              search,
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }

            if (currentPage === 1) {
              return prev;
            }

            return {
              getTesters: {
                ...prev.getTesters,
                rows: [
                  ...(prev.getTesters.rows || []),
                  ...(fetchMoreResult?.getTesters?.rows || []),
                ],
              },
            };
          },
        });
    }, [currentPage]);

    useEffect(() => {
      const { search, ...other } = filters;
      setDistanceBottom(0);
      setCurrentPage(1);
      // eslint-disable-next-line no-unused-expressions
      fetchMore({
        variables: {
          options: {
            limit: rowsPerPage,
            offset: 0,
            testerFilter: prepareFilterData(other),
            doNotShowUnregistered: true,
            excludeUserIds,
            search,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          return {
            getTesters: {
              ...fetchMoreResult.getTesters,
            },
          };
        },
      });
    }, [filters]);

    const loadMore = () => {
      setCurrentPage((prev) => prev + 1);
    };

    const scrollListener = () => {
      const bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;

      if (!distanceBottom) {
        // calculate distanceBottom that works for you
        setDistanceBottom(Math.round((bottom / 100) * 20));
      }

      if (
        tableEl.current.scrollTop > bottom - distanceBottom &&
        rowsPerPage * currentPage < testersCount &&
        !loading
      ) {
        loadMore();
      }
    };

    useLayoutEffect(() => {
      const tableRef = tableEl?.current;
      tableRef?.addEventListener('scroll', scrollListener);
      return () => {
        tableRef?.removeEventListener('scroll', scrollListener);
      };
    }, [scrollListener]);

    if (!testers.length) {
      return (
        <Flex
          sx={{
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Typography>No Testers...</Typography>
        </Flex>
      );
    }

    return (
      <TableContainer
        sx={{
          height: '470px',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            width: 8,
            height: 8,
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#F1F1F2',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#201D1D',
            borderRadius: 2,
          },
        }}
        ref={tableEl}
      >
        <RequestHandler loading={loading || loadingExcludeUser}>
          <StyledTable>
            <Header />
            <TableBody>
              {testers?.map((tester) => {
                return (
                  <TableRow key={tester.id}>
                    <TableCell
                      sx={{
                        minWidth: '108px',
                      }}
                    >
                      <AddCellSection
                        isTesterAdded={checkIsAdded(tester.id)}
                        toggleHandler={toggleHandler(tester.id)}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: '214px',
                        color: '#000000',
                      }}
                    >
                      {tester?.contactName}
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: '88px',
                      }}
                    >
                      {tester?.tester?.testerGroup}
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: '136px',
                      }}
                    >
                      <Box>
                        <Typography>{tester?.tester?.state?.name}</Typography>
                        <Typography
                          sx={{
                            color: '#676767',
                            fontSize: '12px',
                          }}
                        >
                          {tester?.tester?.city}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: '320px',
                      }}
                    >
                      <Typography
                        sx={{
                          display: 'block',
                          width: '320px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {tester?.tester?.relatesOutdoorProductTestingDescription}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: '240px',
                      }}
                    >
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          height: '96px',
                        }}
                      >
                        {tester?.tester?.notesInternal}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </RequestHandler>
      </TableContainer>
    );
  },
);

InfiniteScrollTestersTable.propTypes = {
  toggleHandler: func,
  checkIsAdded: func,
  setCount: func,
};
