import { theme } from 'src/theme';

export const userInfoStyle = {
  root: {
    borderRadius: '8px',
    border: '1px solid #DEDEDE',
    paddingX: 5,
    paddingY: 1,
  },
};

export const avatarStyle = {
  width: 60,
  height: 60,
  backgroundColor: theme.colors.blue,
};

export const wrapperStyle = {
  maxWidth: 796,
  paddingY: 7,
  mx: 'auto',
};

export const styles = {
  userNameWrapper: {
    display: 'flex',
    '& .edit-icon': {
      marginLeft: '8px',
      minWidth: '20px',
      minHeight: '20px',
    },
    '.userNameForm': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '4px',
    },
    '& .MuiFormControl-root.MuiTextField-root': {
      maxWidth: '200px',
      '&:first-of-type': {
        marginRight: '10px',
      },
    },
  },
  editBtn: {
    padding: '0px',
    minWidth: 'unset',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  button: {
    submit: {
      color: theme.colors.tertiary,
      fontWeight: 700,
      fontSize: 14,
      cursor: 'pointer',
      padding: '0px',
      minWidth: 'unset',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    cancel: {
      color: theme.colors.neutral.dark.mid,
      fontSize: 14,
      cursor: 'pointer',
      margin: '0px 8px',
    },
    close: {
      cursor: 'pointer',
    },
  },
  fullnameInput: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      height: '28px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      padding: '0px 8px',
      height: 'unset',
    },
  },
};
