import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EmptyTableBanner,
  Loader,
  OptionsMenu,
  RemoveModal,
  ResendInviteButton,
} from 'src/components/UI/';
import { PaginationSection } from 'src/components/UI/tables';
import { useFilter, useGetCustomers, useRemoveUser } from 'src/hooks';
import { theme } from 'src/theme';

import { Flex } from '../common/CommonStyledComponents';
import { StyledTable, StyledTableRow, TableHeader, TablePagination } from '../UI/tables';

const COLUMNS = [
  {
    title: 'Brand',
    name: 'brand',
    sortKey: 'brand',
  },
  {
    title: 'Contact Name',
    name: 'contactName',
    sortKey: 'contactName',
  },
  {
    title: 'Email',
    name: 'email',
    sortKey: 'email',
  },
  {
    name: 'status',
  },
];

const CustomerTable = () => {
  const removeUser = useRemoveUser();
  const { filters } = useFilter();

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(16);
  const [orderBy, setOrderBy] = useState('contactName');
  const [order, setOrder] = useState('DESC');

  const queryVariables = {
    limit: rowsPerPage,
    offset: rowsPerPage * (currentPage - 1),
    search: filters.search,
    sortType: {
      type: order,
      field: orderBy,
    },
  };

  const { data, loading, refetch, fetchMore } = useGetCustomers({
    variables: queryVariables,
  });
  const customers = useMemo(() => data?.getCustomers?.rows || [], [data?.getCustomers?.rows]);

  const [removedUser, setRemovedUser] = useState(null);
  const closeModal = useCallback(() => {
    setRemovedUser(null);
  }, []);

  const handleChangePage = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleChangeRowsPerPage = useCallback((value) => {
    setRowsPerPage(value);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    fetchMore({
      variables: queryVariables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          getCustomers: fetchMoreResult.getCustomers,
        };
      },
    });
  }, [queryVariables]);

  const handleRequestSort = useCallback(
    (property) => () => {
      const isAsc = orderBy === property && order === 'ASC';
      setOrder(isAsc ? 'DESC' : 'ASC');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const handleItemClick = useCallback((id) => (evt) => {
    const { tagName } = evt.target;

    if (tagName === 'TR' || tagName === 'TD') {
      navigate(`/customers/${id}`);
    }
  });

  const acceptRemoveUser = useCallback(() => {
    removeUser({
      id: removedUser,
    }).then((res) => {
      if (res?.data?.removeUser?.status) {
        refetch(queryVariables);
      }

      return closeModal();
    });
  }, [queryVariables, removedUser]);

  const handleRemoveUser = ({ id }) => {
    setRemovedUser(id);
  };

  const options = useMemo(
    () => [
      {
        title: 'Remove User',
        callback: handleRemoveUser,
      },
    ],
    [],
  );

  if (loading) return <Loader loading />;
  if (!customers?.length)
    return <EmptyTableBanner text='You don’t have any customers available yet' />;

  return (
    <>
      <TablePagination
        currentPageRowsCount={customers.length}
        count={data?.getCustomers?.count}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <StyledTable
        sx={{
          marginTop: 6,
        }}
      >
        <TableHeader
          columns={COLUMNS}
          orderBy={orderBy}
          order={order}
          createSortHandler={handleRequestSort}
        />
        <TableBody>
          {customers.map((item) => (
            <StyledTableRow key={item.id} onClick={handleItemClick(item.id)}>
              <TableCell>
                <Typography variant='span'>{item.customer.brand.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant='span'
                  sx={{
                    textTransform: 'capitalize ',
                  }}
                >
                  {item.contactName}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  color: theme.colors.tertiary,
                  fontWeight: 700,
                }}
              >
                <Typography variant='span'>{item.email}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: 200,
                }}
              >
                <Flex justifyContent={!item.isRegister ? 'space-between' : 'flex-end'}>
                  <ResendInviteButton
                    visible={!item.isRegister}
                    isInvited={!!item.isInvited}
                    userId={item.id}
                  />
                  <OptionsMenu options={options} target={item} />
                </Flex>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
      <PaginationSection
        page={currentPage}
        count={data?.getCustomers?.count && Math.ceil(data.getCustomers.count / rowsPerPage)}
        onChange={handleChangePage}
      />
      <RemoveModal
        title='Are you sure you want to remove this user?'
        submitLabel='Yes, Remove User'
        open={!!removedUser}
        callback={acceptRemoveUser}
        closeModal={closeModal}
        itemId={removedUser}
      />
    </>
  );
};

export default CustomerTable;
