import Box from '@mui/material/Box';
import { func, oneOf } from 'prop-types';
import { useMemo } from 'react';
import { Label, MultipleSelect } from 'src/components/common/filtersMenu/Components';
import { useGetBrands, useGetPossibleValuesTest, useGetTesterValues } from 'src/hooks/useQueries';
import { filtersMenu } from 'src/styles';

import { CustomDateRangePicker } from '../common/CustomDateRangePicker/CustomDateRangePicker';

export const TestsFilters = ({ handleChange, values = {}, setFieldValue }) => {
  const { brands } = useGetBrands();
  const testerPossibleValues = useGetTesterValues();
  const testPossibleValues = useGetPossibleValuesTest();

  const brandOptions = useMemo(() => {
    return brands.map(({ id, name }) => ({
      value: id,
      title: name,
    }));
  }, [brands]);

  const testTypesOptions = useMemo(() => {
    return testPossibleValues?.testType?.map((item) => ({
      value: item,
      title: item,
    }));
  }, [testPossibleValues?.testType]);

  return (
    <Box
      sx={{
        width: '100%',
        overflowY: 'scroll',
      }}
    >
      <MultipleSelect
        name='brandsId'
        label='Brand'
        handleChange={handleChange}
        value={values.brandsId}
        options={brandOptions}
      />
      <MultipleSelect
        name='testTypes'
        label='Type'
        handleChange={handleChange}
        value={values.testTypes}
        options={testTypesOptions}
      />
      <Label sx={filtersMenu.label} label='Start Date'>
        <CustomDateRangePicker
          sx={filtersMenu.field}
          name='startDate'
          setFieldValue={setFieldValue}
          value={values.startDate}
          placeholder='Select Date Range'
        />
      </Label>
      <Label sx={filtersMenu.label} label='End Date'>
        <CustomDateRangePicker
          sx={filtersMenu.field}
          name='endDate'
          setFieldValue={setFieldValue}
          value={values.endDate}
          placeholder='Select Date Range'
        />
      </Label>
      <MultipleSelect
        name='testerStatusExternals'
        label='Tester Status'
        sublabel='External'
        handleChange={handleChange}
        value={values.testerStatusExternals}
        options={testerPossibleValues?.testerStatusExternals}
      />
      <MultipleSelect
        name='testerStatusInternals'
        label='Tester Status'
        sublabel='Internal'
        handleChange={handleChange}
        value={values.testerStatusInternals}
        options={testerPossibleValues?.testerStatusInternals}
      />
      <Label sx={filtersMenu.label} label='Created'>
        <CustomDateRangePicker
          sx={filtersMenu.field}
          disableFuture
          name='dateCreate'
          setFieldValue={setFieldValue}
          value={values.dateCreate}
          placeholder='Select Date Range'
        />
      </Label>
      <MultipleSelect
        name='testStatus'
        label='Test Status'
        handleChange={handleChange}
        value={values.testStatus}
        options={testPossibleValues?.testStatus}
      />
    </Box>
  );
};

TestsFilters.propTypes = {
  handleChange: func,
  values: oneOf([Object]),
  setFieldValue: func,
};
