import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
// eslint-disable-next-line object-curly-newline
import { bool, func, instanceOf, number, oneOfType, string } from 'prop-types';
import { useCallback, useMemo } from 'react';
import { rightPanel } from 'src/styles';

import { CheckIcon } from './icons/CheckIcon';

export const AddressSelect = ({
  value,
  name,
  possibleValues,
  handleChange,
  handleBlur,
  error,
  helperText,
  sx,
  inputStyle,
  placeholder,
}) => {
  const options = useMemo(
    () => (placeholder ? [...possibleValues] : possibleValues),
    [placeholder, possibleValues],
  );

  const onChange = (evt, val) => {
    evt.preventDefault();
    handleChange(name, val);
  };

  const handleClear = useCallback(() => {
    handleChange(name, '');
  }, []);

  return (
    <Autocomplete
      disablePortal
      sx={sx}
      name={name}
      onChange={onChange}
      value={value || ''}
      options={options}
      isOptionEqualToValue={(option, val) => option?.id === val?.id}
      renderInput={(params) => (
        <TextField
          sx={inputStyle}
          {...params}
          size='small'
          placeholder={placeholder}
          onInput={handleClear}
          onBlur={handleBlur}
          error={error}
          helperText={helperText}
        />
      )}
      getOptionLabel={(option) => option.name || ''}
      renderOption={(props, option) => (
        <MenuItem value={option?.id} {...props} sx={rightPanel.selectItem}>
          {value?.id === option?.id && (
            <Box className='check-icon' sx={rightPanel.checkIcon}>
              <CheckIcon />
            </Box>
          )}
          {option.name}
        </MenuItem>
      )}
    />
  );
};

AddressSelect.propTypes = {
  value: oneOfType([string, number, bool, instanceOf(Object)]),
  name: string,
  possibleValues: instanceOf(Object),
  handleChange: func,
  handleBlur: func,
  label: string,
  sx: instanceOf(Object),
  inputStyle: instanceOf(Object),
  placeholder: string,
  helperText: string,
  error: bool,
};
