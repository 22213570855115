import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'src/components';
import { FullScreenPageContainer } from 'src/components/common/CommonStyledComponents';
import { CreatePasswordFormWrapper } from 'src/components/login/CreatePassword/CreatePasswordFormWrapper';
import { signUpByToken } from 'src/hooks';
import Cookies from 'universal-cookie';

export const CreateAccount = () => {
  const navigate = useNavigate();
  const { signUp, loading } = signUpByToken();
  const cookies = new Cookies();

  const handleBackToLogin = useCallback(() => navigate('/login'), []);

  const onSubmit = async ({ password }) => {
    const result = await signUp({
      password,
    });
    if (result.data?.signUpByToken?.status) {
      cookies.set('token', result.data.signUpByToken?.token, {
        path: '/',
        maxAge: 86400,
      });
      navigate(`/profile`);
    }
  };

  return (
    <FullScreenPageContainer>
      <CreatePasswordFormWrapper onSubmit={onSubmit} handleBackToLogin={handleBackToLogin} />
      <Loader loading={loading} />
    </FullScreenPageContainer>
  );
};
