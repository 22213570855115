import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { bool, func } from 'prop-types';
import { StyledSubmitButton } from 'src/components/UI';
import { form } from 'src/styles';
import { theme } from 'src/theme';

import { FooterContainer } from '../common/CommonStyledComponents';

const FormFooter = ({ handleClickCancel, handleClickSubmit, submitButtonDisabled }) => (
  <FooterContainer>
    <Typography
      sx={{
        fontWeight: 700,
      }}
    >
      <Box
        component='span'
        sx={{
          color: theme.colors.error,
          marginRight: 1,
        }}
      >
        *
      </Box>
      Required
    </Typography>
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Button sx={form.button.cancel} onClick={handleClickCancel}>
        Cancel
      </Button>
      <StyledSubmitButton disabled={submitButtonDisabled} onClick={handleClickSubmit}>
        Add Customer
      </StyledSubmitButton>
    </Box>
  </FooterContainer>
);

FormFooter.propTypes = {
  handleClickCancel: func,
  handleClickSubmit: func,
  submitButtonDisabled: bool,
};

export default FormFooter;
