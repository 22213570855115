import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Flex } from 'src/components/common/CommonStyledComponents';

import { styled } from './styled';

export const EmptyTableBanner = ({ text = '' }) => {
  return (
    <Flex sx={styled.emptyTableBanner.container}>
      <Typography sx={styled.emptyTableBanner.text}>{text}</Typography>
    </Flex>
  );
};

EmptyTableBanner.propTypes = {
  text: PropTypes.string.isRequired,
};
