import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { styleForButton } from 'src/components/login/styles';

export const ResetLinkStep = ({ handleBackToLogin }) => (
  <>
    <Typography variant='h3' mb={3}>
      Password Reset Link Sent
    </Typography>
    <Typography>
      If we have an email associated with this password, you should receive a reset link shortly.
      Please check your inbox.
    </Typography>
    <Button
      sx={{
        ...styleForButton,
        mt: 8,
      }}
      size='small'
      variant='outlined'
      onClick={handleBackToLogin}
    >
      Return to Login
    </Button>
  </>
);

ResetLinkStep.propTypes = {
  handleBackToLogin: PropTypes.func,
};
