import PropTypes from 'prop-types';
import React from 'react';
import { StyledModal } from 'src/components/common/CommonStyledComponents';

import {
  BoxContainer,
  BoxContainerStyled,
  ButtonStyled,
  ConfirmStyledPaper,
  FlexStyled,
  LabelText,
  style,
  WrapperBox,
} from './styles';

export const ConfirmModal = ({
  open,
  toggleModal,
  callback,
  labalTest,
  agreeButtonTex,
  isError,
}) => {
  const onClick = () => {
    callback();
    toggleModal();
  };

  return (
    <StyledModal
      sx={{
        alignItems: 'center',
      }}
      open={open}
      aria-labelledby='modal-title'
      onClose={toggleModal}
    >
      <ConfirmStyledPaper>
        <WrapperBox>
          <BoxContainer>
            <BoxContainerStyled>
              <LabelText>{labalTest}</LabelText>
              <FlexStyled>
                <ButtonStyled
                  sx={{
                    color: 'white',
                  }}
                  onClick={toggleModal}
                >
                  Cancel
                </ButtonStyled>
                <ButtonStyled
                  sx={{
                    background: 'white',
                    ml: '20px',
                    ...(isError ? style.button.error : style.button.default),
                  }}
                  onClick={onClick}
                >
                  {agreeButtonTex}
                </ButtonStyled>
              </FlexStyled>
            </BoxContainerStyled>
          </BoxContainer>
        </WrapperBox>
      </ConfirmStyledPaper>
    </StyledModal>
  );
};

ConfirmModal.propTypes = {
  open: PropTypes.bool,
  labalTest: PropTypes.string,
  agreeButtonTex: PropTypes.string,
  toggleModal: PropTypes.func,
  callback: PropTypes.func,
  isError: PropTypes.bool,
};
