import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';
import { PlusIcon } from 'src/components/UI';
import { useTestInfo } from 'src/hooks';

import { AddTestersModal } from '../addTestersModal/AddTestersModal';
import { CandidatesTabPanel } from '../candidatesTabPanel/candidatesTabPanel';
import { EnrolledTabPanel } from '../enrolledTabPanel/enrolledTabPanel';

export const TestersNav = () => {
  const [value, setValue] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    candidatesStats,
    enrolledStats,
    enrolledList,
    candidatesList,
    called,
    loading,
    previousData,
  } = useTestInfo();

  const isFetchedFirstTime = called && !loading && !previousData;

  useEffect(() => {
    if (isFetchedFirstTime) {
      if (enrolledList.length) return setValue(0);
      if (candidatesList.length) return setValue(1);
      return setValue(0);
    }
    return false;
  }, [enrolledList, candidatesList, isFetchedFirstTime]);

  const handleToggleModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);

  const onOpenAddTesterModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const a11yProps = useCallback(
    (index) => ({
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    }),
    [],
  );

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs indicatorColor='secondary' value={value} onChange={handleChange} aria-label='tabs'>
          <Tab label='Enrolled' {...a11yProps(0)} />
          <Tab label='Candidates' {...a11yProps(1)} />
        </Tabs>
        <Box>
          <Button
            onClick={onOpenAddTesterModal}
            sx={{
              padding: '0 1px',
            }}
          >
            <PlusIcon />
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: '400',
                marginLeft: '8px',
              }}
            >
              Add Testers
            </Typography>
          </Button>
        </Box>
      </Box>
      <EnrolledTabPanel enrolledStats={enrolledStats} value={value} />
      <CandidatesTabPanel candidatesStats={candidatesStats} value={value} />
      <AddTestersModal open={isModalOpen} toggleModal={handleToggleModal} />
    </Box>
  );
};
