import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import { useFormikContext } from 'formik';
import { instanceOf } from 'prop-types';
import { useState } from 'react';
import { DetailLabel } from 'src/components/index';
import { styles } from 'src/components/testers/createTesterForm/styles';
import { DetailSelectMultiple } from 'src/components/UI/detail/DetailSelectMultiple';
import {
  handleSetMultipleValues,
  handleShoeSizeValue,
  handleShoeSizeValueOrFallback,
} from 'src/utils/helpers';

import { detailsTabStyles } from './backDropStyles';

export const DetailsBottomSize = ({ possibleValues, submitOnValueChange = true }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { values = {}, setFieldValue } = useFormikContext();
  const { bottomSizeOptions } = possibleValues;

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };

  if (!bottomSizeOptions) return null;

  return (
    <DetailLabel label='Bottom Size'>
      <Box onClick={changeViewBox}>
        <Backdrop open={isEditMode} invisible={false} sx={detailsTabStyles.backDropStyle} />
        <DetailSelectMultiple
          name='bottomSizes'
          handleChange={handleSetMultipleValues(setFieldValue)}
          value={values.bottomSizes}
          placeholder='Select bottom size'
          renderTextValue={handleShoeSizeValueOrFallback(bottomSizeOptions, 'Select bottom size')}
          renderValue={handleShoeSizeValue(bottomSizeOptions)}
          wrapItems
          multiple
          submitOnValueChange={submitOnValueChange}
        >
          <ListSubheader style={styles.label}>Select all that apply</ListSubheader>
          <ListSubheader style={styles.dropdownTitleBox}>
            <div style={styles.dropdownTitleText}>Women’s</div>
          </ListSubheader>
          {bottomSizeOptions.woman?.map((size) => {
            return (
              <MenuItem value={size.id} key={size.id}>
                {size.name}
              </MenuItem>
            );
          })}

          <ListSubheader style={styles.dropdownTitleBox}>
            <div style={styles.dropdownSubTitleText}>US</div>
          </ListSubheader>
          {bottomSizeOptions.womanUS?.map((size) => {
            return (
              <MenuItem value={size.id} key={size.id}>
                {size.name}
              </MenuItem>
            );
          })}

          <ListSubheader style={styles.dropdownTitleBox}>
            <div style={styles.dropdownTitleText}>Men’s</div>
          </ListSubheader>
          {bottomSizeOptions.man?.map((size) => {
            return (
              <MenuItem value={size.id} key={size.id}>
                {size.name}
              </MenuItem>
            );
          })}

          <ListSubheader style={styles.dropdownTitleBox}>
            <div style={styles.dropdownSubTitleText}>US</div>
          </ListSubheader>
          {bottomSizeOptions.manUS?.map((size) => {
            return (
              <MenuItem value={size.id} key={size.id}>
                {size.name}
              </MenuItem>
            );
          })}
        </DetailSelectMultiple>
      </Box>
    </DetailLabel>
  );
};

DetailsBottomSize.propTypes = {
  possibleValues: instanceOf(Object),
};
