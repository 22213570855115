import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { useFormikContext } from 'formik';
import { instanceOf } from 'prop-types';
import { useState } from 'react';
import { DetailLabel } from 'src/components/index';
import { DetailSelectMultiple } from 'src/components/UI/detail/DetailSelectMultiple';
import {
  handleCommaSeparatedValues,
  handleCommaSeparatedValuesOrNone,
  handleSetMultipleValues,
} from 'src/utils/helpers';

import { detailsTabStyles } from '../DetailsTab/backDropStyles';

export const ActivitiesTypeGear = ({ possibleValues, submitOnValueChange = true, label }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { values = {}, setFieldValue } = useFormikContext();

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };

  const { typeGearWouldTest } = possibleValues;

  return (
    <DetailLabel label={label}>
      <Box onClick={changeViewBox}>
        <Backdrop open={isEditMode} invisible={false} sx={detailsTabStyles.backDropStyle} />
        <DetailSelectMultiple
          name='typeGearWouldTest'
          handleChange={handleSetMultipleValues(setFieldValue)}
          value={values.typeGearWouldTest}
          placeholder='Select gear'
          renderTextValue={handleCommaSeparatedValuesOrNone}
          renderValue={handleCommaSeparatedValues}
          wrapItems
          multiple
          submitOnValueChange={submitOnValueChange}
        >
          {typeGearWouldTest?.map((type) => (
            <MenuItem value={type} key={type}>
              {type}
            </MenuItem>
          ))}
        </DetailSelectMultiple>
      </Box>
    </DetailLabel>
  );
};

ActivitiesTypeGear.propTypes = {
  possibleValues: instanceOf(Object),
};
