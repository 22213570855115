import Box from '@mui/material/Box';
import { func, instanceOf } from 'prop-types';
import { DetailBrand, DetailInput, DetailLabel } from 'src/components';

export const Details = ({ values, handleChange }) => (
  <Box>
    <DetailLabel label='Brand'>
      <DetailBrand name='brandId' value={values.brand} handleChange={handleChange} />
    </DetailLabel>
    <DetailLabel label='Style'>
      <DetailInput
        name='styleDescription'
        handleChange={handleChange}
        value={values.styleDescription}
      />
    </DetailLabel>
    <DetailLabel label='Quantity'>
      <DetailInput name='quantity' handleChange={handleChange} value={values.quantity} />
    </DetailLabel>
    <DetailLabel label='Color/Size/Gender'>
      <DetailInput
        name='colorSizeGender'
        handleChange={handleChange}
        value={values.colorSizeGender}
        multiline
      />
    </DetailLabel>
    <DetailLabel label='Item Type'>
      <DetailInput name='itemType' handleChange={handleChange} value={values.itemType} multiline />
    </DetailLabel>
  </Box>
);

Details.propTypes = {
  values: instanceOf(Object),
  handleChange: func,
};
