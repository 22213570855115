import { theme } from 'src/theme';

export const text = {
  tertiaryBold: {
    color: theme.colors.tertiary,
    fontWeight: 700,
  },
};

export const tableHeader = {
  title: {
    fontSize: 12,
    fontWeight: 700,
  },
  subtitle: {
    fontSize: 12,
  },
};

export const select = {
  root: {
    textTransform: 'capitalize',
    width: '100%',
    height: '40px',
    fontSize: 14,
  },
  item: {
    textTransform: 'capitalize',
    paddingX: 2,
    paddingY: 1,
  },
};

export const form = {
  button: {
    cancel: {
      marginRight: 4,
      height: '28px',
      fontWeight: '400',
    },
  },
};

export const filtersMenu = {
  button: {
    apply: {
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: 'Nunito',
      width: 69,
      height: 28,
      ml: 4,
    },
    cancel: {
      color: '#676767',
    },
  },
  root: {
    justifyContent: 'space-between',
    width: '100%',
    background: '#F1F1F2',
    padding: '16px 18px',
    boxSizing: 'border-box',
  },
  label: {
    main: {
      fontSize: 12,
      fontWeight: 700,
    },
    sub: {
      fontSize: 12,
      color: theme.colors.neutral.dark.mid,
    },
    root: {
      width: '100%',
      justifyContent: 'space-between',
      marginBottom: 4,
    },
  },
  field: {
    width: '80%',
    height: 40,
    fontSize: 14,
    minWidth: 436,
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      width: '100%',
      paddingX: 3,
    },
  },
  addressSelect: {
    width: '80%',
    height: 40,
    '& .MuiInputBase-root': {
      fontSize: 14,
      height: '100%',
    },
    '& .MuiFormControl-root': {
      height: '100%',
    },
  },
};

export const filterForm = {
  p: '1px',
  display: 'flex',
  alignItems: 'center',
  width: '584px',
  boxShadow: 'none',
  border: '1px solid #DEDEDE',
};

export const filterMenuModal = {
  position: 'absolute',
  top: '42px',
  width: '585px',
  left: '0',
  backgroundColor: 'white',
  boxShadow: '#DEDEDE 0px 0px 0px 1px',
  zIndex: '1',
};

export const openedFilterModal = {
  p: '10px',
  background: '#9B51E0',
  '&.MuiButtonBase-root.MuiIconButton-root:hover': {
    background: '#9B51E0',
  },
};

export const datePickerInput = {
  '& .MuiInputBase-input.MuiOutlinedInput-input': {
    fontSize: '14px',
    lineHeight: '20px',
    width: '376px',
    padding: '10px 14px 10px 0',
    '&::-webkit-input-placeholder': {
      opacity: 1,
      color: '#676767',
      lineHeight: '20px',
    },
  },
  '& .MuiButtonBase-root.MuiIconButton-root': {
    padding: '10px 4px 10px 8px',
    '&:hover': {
      background: 'transparent',
    },
  },
};

export const rightPanel = {
  root: {
    borderRadius: '8px',
    border: '1px solid #DEDEDE',
    paddingX: 3,
    height: '684px',
    minWidth: '384px',
    maxWidth: '384px',
  },
  checkIcon: {
    maxWidth: 'min-content',
    marginRight: 2,
  },
  select: {
    height: 28,
    mb: 2,
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
    '& .MuiSelect-select': {
      padding: 0,
      paddingLeft: 4,
    },
    '& .check-icon': {
      display: 'none',
    },
    '& .MuiFormControl-root': {
      height: '100%',
    },
    '& .MuiInputBase-root': {
      paddingY: '0 !important',
      fontSize: 14,
      height: '100%',
    },
  },
  selectItem: {
    height: 28,
    boxSizing: 'border-box',
    justifyContent: 'flex-start',
    '&.Mui-selected': {
      backgroundColor: 'transparent !important',
    },
    fontSize: 14,
  },
  button: {
    root: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      p: 3,
    },
    submit: {
      mr: 3,
      color: theme.colors.tertiary,
      fontWeight: 700,
      fontSize: 14,
      cursor: 'pointer',
    },
    cancel: {
      fontSize: 14,
      cursor: 'pointer',
    },
  },
  addressField: {
    mb: 2,
    height: 28,
    '& .MuiInputBase-root': {
      fontSize: 14,
      height: 28,
    },
  },
};

export const testerCreationForm = {
  input: {
    '& .MuiOutlinedInput-root': {
      fontSize: 14,
    },
  },
  addressSelect: {
    width: '100%',

    '& .MuiOutlinedInput-root': {
      fontSize: 14,
    },
  },
  activity: {
    root: {
      width: '100%',
      mb: 3,
    },
    label: {
      mb: 0,
      display: 'grid',
      gridTemplateColumns: '40% 60%',
      span: {
        wordBreak: 'break-word',
        maxWidth: '158px',
      },
    },
    select: {
      width: '25%',
      boxSizing: 'border-box',
      ml: 4,
    },
    button: {
      fontSize: 14,
      paddingY: 0,
    },
    wantToLabel: {
      width: '25%',
      boxSizing: 'border-box',
      ml: 4,
    },
  },
  MenuItem: {
    maxHeight: '350px',
    overflowY: 'scroll',
  },
};

export const customSelect = {
  root: {
    boxSizing: 'border-box',
    height: 40,
    fontSize: 14,
  },
};
