/* eslint-disable object-curly-newline */
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import { bool, func, instanceOf, number, oneOfType, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { DatePicker } from 'src/components/common/DatePicker';
import { formatDate } from 'src/utils/helpers';

import { CalendarIcon } from '../icons/CalendarIcon';
import { containerHover, detailSelect } from './styles';

export const DetailDatePicker = ({
  value,
  name,
  sx,
  handleChange,
  setFieldError,
  placeholder,
  submitOnValueChange = false,
}) => {
  const { initialValues, handleSubmit, isSubmitting } = useFormikContext();
  const [isEditable, setIsEditable] = useState(false);

  const toggle = () => setIsEditable((prev) => !prev);
  const onClose = () => setIsEditable(false);

  const isTheSameValue = initialValues[name] === value;

  useEffect(() => {
    if (!isTheSameValue && !isEditable && submitOnValueChange) {
      if (!isSubmitting) handleSubmit();
    }
  }, [isTheSameValue, isEditable, submitOnValueChange, isSubmitting, handleSubmit]);

  const handleSetStateValue = (fieldName, fieldValue) => {
    handleChange({
      target: {
        name: fieldName,
        value: fieldValue,
      },
    });
  };

  if (!isEditable) {
    return (
      <Flex gap={2.5} onClick={toggle} sx={containerHover}>
        <CalendarIcon />
        <Typography
          sx={[
            {
              width: '100%',
            },
            detailSelect,
          ]}
        >
          {value ? formatDate(value) : placeholder}
        </Typography>
      </Flex>
    );
  }

  return (
    <DatePicker
      sx={sx}
      placeholder={placeholder}
      name={name}
      setFieldError={setFieldError}
      setFieldValue={handleSetStateValue}
      value={value?.toString()}
      hideLabel
      onClose={onClose}
    />
  );
};

DetailDatePicker.propTypes = {
  value: oneOfType([string, number]),
  name: string,
  handleChange: func,
  setFieldError: func,
  placeholder: string,
  sx: instanceOf(Object),
  submitOnValueChange: bool,
};
