import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { func, string } from 'prop-types';
import { Flex, FooterContainer } from 'src/components/common/CommonStyledComponents';
import { theme } from 'src/theme';

import { reportStyle } from './style';

export const ModalFooter = ({ handleClickCancel, handleClickSubmit, submitButtonTitle }) => (
  <FooterContainer>
    <Typography>
      <Box
        component='span'
        sx={{
          color: theme.colors.error,
        }}
      >
        *
      </Box>
      Required
    </Typography>
    <Flex>
      <Button sx={reportStyle.button.cancel} onClick={handleClickCancel}>
        Cancel
      </Button>
      <Button
        variant='contained'
        color='primary'
        sx={[
          reportStyle.button.add,
          {
            ml: 4,
          },
        ]}
        onClick={handleClickSubmit}
      >
        {submitButtonTitle}
      </Button>
    </Flex>
  </FooterContainer>
);

ModalFooter.propTypes = {
  handleClickCancel: func,
  handleClickSubmit: func,
  submitButtonTitle: string,
};
