import PropTypes from 'prop-types';
import { OtherUserRoleNavBar } from 'src/components/navBar/OtherUserRoleNavBar';
import { TesterPages } from 'src/constants/constants';

export const TesterLayout = ({ children }) => {
  return (
    <div>
      <OtherUserRoleNavBar pages={TesterPages} />
      {children}
    </div>
  );
};

TesterLayout.propTypes = {
  children: PropTypes.node,
};
