import { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loader } from 'src/components';
import { FullScreenPageContainer } from 'src/components/common/CommonStyledComponents';
import { CreatePasswordFormWrapper } from 'src/components/login/CreatePassword/CreatePasswordFormWrapper';
import { useSetNewPassword, useToasts } from 'src/hooks';

const CreatePassword = () => {
  const navigate = useNavigate();
  const [setNewPassword, { loading }] = useSetNewPassword();
  const [searchParams] = useSearchParams();
  const passwordResetToken = useMemo(() => searchParams.get('t'), [searchParams]);
  const { SuccessMessage, ErrorMessage } = useToasts();

  const handleBackToLogin = useCallback(() => navigate('/login'), []);

  const onSubmit = ({ password }) => {
    setNewPassword({
      variables: {
        passwordResetToken,
        newPassword: password,
      },
      onCompleted: (res) => {
        if (!res?.setNewPassword.status) return new Error();

        SuccessMessage('New password successfully created');
        return handleBackToLogin();
      },
      onError: () => {
        ErrorMessage('Error! Please, try again.');
      },
    });
  };

  return (
    <FullScreenPageContainer>
      <CreatePasswordFormWrapper onSubmit={onSubmit} handleBackToLogin={handleBackToLogin} />
      <Loader loading={loading} />
    </FullScreenPageContainer>
  );
};

export default CreatePassword;
