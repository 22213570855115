import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import styled from '@mui/system/styled';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { cloneElement, useCallback } from 'react';
import { StyledPaper } from 'src/components/common/CommonStyledComponents';
import { useFilter } from 'src/hooks';

import { BottomSection } from './BottomSection';
import { TitleSection } from './TitleSection';

const StyledBox = styled(Box)({
  position: 'absolute',
  top: '101%',
  left: 0,
  right: 0,
  width: '100%',
  zIndex: 10,
});

export const FiltersMenuModal = ({ children, isOpen, toggle, schema, sx }) => {
  const { filters, clearFilters, setFilters } = useFilter();

  const onSubmit = (values) => {
    const result = {
      ...values,
      chestInches: values.chestInches?.split(','),
      chestCm: values.chestCm?.split(','),
      waistAtNavelInches: values.waistAtNavelInches?.split(','),
      waistAtNavelCm: values.waistAtNavelCm?.split(','),
    };
    delete result.search;

    Object.keys(result).forEach((key) => {
      if (!result[key] || result[key] === 'none' || result[key] === '0') {
        delete result[key];
      }

      if (Array.isArray(result[key])) {
        result[key] = result[key].map((item) => item?.value || item);
      }
    });

    setFilters({
      ...result,
    });

    toggle();
  };

  const { values, errors, handleSubmit, handleChange, setFieldValue, setFieldError, setValues } =
    useFormik({
      initialValues: filters,
      validationSchema: schema,
      onSubmit,
    });

  const handleKeyDown = useCallback((e) => {
    if (e.keyCode === 13) handleSubmit();
  }, []);

  const handleClear = useCallback(() => {
    clearFilters();
    setValues({});
  }, []);

  if (!isOpen) return null;

  return (
    <StyledBox sx={sx} role='button' tabIndex={0} onKeyDown={handleKeyDown}>
      <ClickAwayListener onClickAway={toggle}>
        <StyledPaper
          sx={{
            minWidth: 0,
          }}
        >
          <Box
            sx={{
              paddingX: 6,
            }}
          >
            <TitleSection
              title='FILTERS'
              sx={{
                marginTop: '20px',
              }}
            />
            <Divider
              flexItem
              sx={{
                marginBottom: '22px',
              }}
            />
          </Box>
          <Box
            sx={{
              overflowY: 'auto',
            }}
          >
            <Box
              sx={{
                paddingX: 6,
              }}
            >
              {cloneElement(children, {
                handleChange,
                values,
                setFieldValue,
                setFieldError,
              })}
            </Box>
          </Box>
          <BottomSection
            errors={errors}
            handleClickCancel={toggle}
            handleClickClear={handleClear}
            handleSubmit={handleSubmit}
          />
        </StyledPaper>
      </ClickAwayListener>
    </StyledBox>
  );
};

FiltersMenuModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  sx: PropTypes.instanceOf(Object),
  schema: PropTypes.instanceOf(Object),
};
