import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { styleForButton, styles } from 'src/components/login/styles';
import { Label } from 'src/components/UI';
import { CloseIcon } from 'src/components/UI/icons';
import { text } from 'src/styles';

export const ForgotPasswordStep = ({ handleBackToLogin }) => {
  const { handleChange, values, errors, resetForm } = useFormikContext();

  const isValidForm = Object.values(errors).every((x) => x === '') && values.email;

  return (
    <>
      <Typography variant='h3' mb={3}>
        Forgot Password
      </Typography>
      <Typography>Enter the email and password associated with your account</Typography>
      <Box my={7}>
        <Label
          sx={{
            marginBottom: 2,
            ...styles.label,
          }}
          label='Account Email'
        >
          <TextField
            size='small'
            name='email'
            value={values.email}
            onInput={handleChange}
            required
            error={!!errors.email}
            helperText={errors.email}
            placeholder='Enter Email'
            InputProps={{
              endAdornment: values.email && (
                <InputAdornment position='end' onClick={resetForm}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </Label>
      </Box>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            ...styleForButton,
          }}
          size='small'
          variant='contained'
          disabled={!isValidForm}
          type='submit'
        >
          Reset Password
        </Button>
        <Button onClick={handleBackToLogin}>
          <Typography sx={text.tertiaryBold}>Back to Login</Typography>
        </Button>
      </Flex>
    </>
  );
};

ForgotPasswordStep.propTypes = {
  handleBackToLogin: PropTypes.func,
};
