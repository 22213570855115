import { instanceOf } from 'prop-types';
import { Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { useCurrentUserQuery } from 'src/hooks';

const PrivateRoute = ({ route }) => {
  const { user, loading } = useCurrentUserQuery();

  const Component = route.component;
  const Layout = route.layout || Fragment;

  if (loading) return null;

  if (!user) return <Navigate to='/login' />;

  return (
    <Layout>
      <Component />
    </Layout>
  );
};

PrivateRoute.propTypes = {
  route: instanceOf(Object),
  user: instanceOf(Object),
};

export default PrivateRoute;
