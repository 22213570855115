import Container from '@mui/material/Container';
import { useState } from 'react';
import { LoginFormWrapper } from 'src/components';
import { ForgotPasswordFormWrapper } from 'src/components/login/ForgotPassword/ForgotPasswordFormWrapper';
import { styleForLoginPageContainer } from 'src/components/login/styles';

const Login = () => {
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const togglePasswordReset = () => {
    setIsPasswordReset(!isPasswordReset);
  };

  return (
    <Container sx={styleForLoginPageContainer}>
      {isPasswordReset ? (
        <ForgotPasswordFormWrapper handleBackToLogin={togglePasswordReset} />
      ) : (
        <LoginFormWrapper handleForgotPasswordClick={togglePasswordReset} />
      )}
    </Container>
  );
};

export default Login;
