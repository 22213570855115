import { useCallback, useState } from 'react';
import { StyledPageContainer } from 'src/components/common/CommonStyledComponents';

import { ControlBar, CustomerTable } from '../components';
import { CreateCustomerFormWrapper } from '../components/customers/CreateCustomerFormWrapper';

const Customers = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setModalIsOpen((prev) => !prev);
  }, []);

  return (
    <StyledPageContainer>
      <ControlBar
        header='Customers'
        addButtonLabel='Add customer'
        handleAddButtonClick={toggleModal}
      />
      <CustomerTable />
      <CreateCustomerFormWrapper open={modalIsOpen} toggleModal={toggleModal} />
    </StyledPageContainer>
  );
};

export default Customers;
