export const RefreshIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.62617 6.66288C3.62617 7.45204 3.10102 7.97719 2.31185 7.97719C1.93269 7.97719 1.56906 7.82657 1.30095 7.55846C1.03284 7.29035 0.882214 6.92672 0.882214 6.54755V5.56491C0.882214 4.63279 1.65613 2.31773 5.19306 2.31773C5.19306 2.31773 11.1899 2.34346 11.3434 2.38825V0.629797C11.4916 0.482547 11.692 0.399902 11.9009 0.399902C12.1098 0.399902 12.3103 0.482547 12.4585 0.629797L14.9108 3.22698C15.058 3.37518 15.1407 3.57562 15.1407 3.78454C15.1407 3.99345 15.058 4.19389 14.9108 4.34209L12.4585 6.79345C12.3103 6.9407 12.1098 7.02335 11.9009 7.02335C11.692 7.02335 11.4916 6.9407 11.3434 6.79345V5.11696L4.99481 5.07312C3.42697 5.07312 3.62236 6.24828 3.62236 6.24828L3.62617 6.66288ZM12.2821 9.36204C12.2821 8.57288 12.9226 8.01627 13.7117 8.01627C14.5009 8.01627 15.1414 8.57288 15.1414 9.36109V10.3437C15.1414 11.2768 14.3675 13.6043 10.8305 13.6043H4.59826L4.59636 15.3684C4.52322 15.4418 4.43632 15.5 4.34065 15.5397C4.24497 15.5795 4.14239 15.5999 4.0388 15.5999C3.9352 15.5999 3.83263 15.5795 3.73695 15.5397C3.64127 15.5 3.55437 15.4418 3.48124 15.3684L1.02988 12.9171C0.882632 12.7689 0.799988 12.5684 0.799988 12.3595C0.799988 12.1506 0.882632 11.9502 1.02988 11.802L3.48124 9.3506C3.62944 9.20335 3.82988 9.12071 4.0388 9.12071C4.24772 9.12071 4.44815 9.20335 4.59636 9.3506L4.59826 10.8365H10.8305C11.6693 10.8365 12.2411 10.6144 12.284 10.2742L12.2821 9.36204Z'
      fill='black'
    />
  </svg>
);
