import { Form, Formik } from 'formik';
import { useToasts, useUpdateUserWithRoleAdminById } from 'src/hooks';

import { AdminInfo } from './AdminInfo';

const AdminInfoFormikWrapper = ({ user, id }) => {
  const { ErrorMessage } = useToasts();
  const [updateProfile] = useUpdateUserWithRoleAdminById();

  const { email: emailData, firstName: fName, lastName: lName } = user;

  const handleUpdateProfile = (data, resetForm) =>
    updateProfile({
      variables: {
        id,
        input: data,
      },
      onError: () => {
        resetForm();
        ErrorMessage('Error. Please, try again.');
      },
    });

  const handleUpdateAdmin = (data, resetForm) => {
    const { firstName, lastName, email } = data;

    const dataVariable = {
      firstName,
      lastName,
      email,
    };

    handleUpdateProfile(dataVariable, resetForm);
  };

  const handleOnSubmit = (data, resetForm) => {
    handleUpdateAdmin(data, resetForm);
  };

  return (
    <Formik
      initialValues={{
        email: emailData,
        firstName: fName,
        lastName: lName,
      }}
      enableReinitialize={true}
      onSubmit={(values, { resetForm }) => handleOnSubmit(values, resetForm)}
    >
      <Form>
        <AdminInfo />
      </Form>
    </Formik>
  );
};

export default AdminInfoFormikWrapper;
