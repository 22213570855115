import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import {
  ScrollContainer,
  StyledLabel,
  StyledModal,
  StyledPaper,
} from 'src/components/common/CommonStyledComponents';

import { Loader } from '..';
import { BrandAutocomplete } from './BrandAutocomplete';
import FormFooter from './FormFooter';
import FormHeader from './FormHeader';

export const CreateCustomerForm = ({ loading, open, toggleModal }) => {
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
    dirty,
    isValid,
    touched,
    handleBlur,
  } = useFormikContext();

  return (
    <StyledModal open={open} onClose={toggleModal} aria-labelledby='modal-title'>
      <StyledPaper
        sx={{
          height: 'auto',
        }}
      >
        <Loader loading={loading} />
        <FormHeader />
        <Divider
          sx={{
            margin: '0 24px 32px 24px',
          }}
        />
        <ScrollContainer
          sx={{
            paddingBottom: 12,
            overflowY: 'hidden',
          }}
        >
          <Box
            sx={{
              width: '75%',
            }}
          >
            <StyledLabel label='Brand' required>
              <BrandAutocomplete
                name='brandId'
                value={values.brandId}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                error={touched.brandId && !!errors.brandId}
                helperText={touched.brandId && errors.brandId}
              />
            </StyledLabel>
            <StyledLabel label='Contact Name' required>
              <TextField
                size='small'
                name='contactName'
                onInput={handleChange}
                value={values.contactName}
                error={touched.contactName && !!errors.contactName}
                helperText={touched.contactName && errors.contactName}
                placeholder='Enter Full Name'
                onBlur={handleBlur}
              />
            </StyledLabel>
            <StyledLabel label='Email' required>
              <TextField
                size='small'
                name='email'
                onInput={handleChange}
                value={values.email}
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                placeholder='Enter Email'
                onBlur={handleBlur}
              />
            </StyledLabel>
          </Box>
        </ScrollContainer>
        <FormFooter
          handleClickSubmit={handleSubmit}
          handleClickCancel={toggleModal}
          submitButtonDisabled={!dirty || !isValid}
        />
      </StyledPaper>
    </StyledModal>
  );
};

CreateCustomerForm.propTypes = {
  open: PropTypes.bool,
  toggleModal: PropTypes.func,
  loading: PropTypes.bool,
};
