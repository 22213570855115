import { Backdrop } from '@mui/material';
import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { instanceOf } from 'prop-types';
import { useState } from 'react';
import { DetailLabel, DetailSelect } from 'src/components/index';
import { genderSelect, textCapitalize } from 'src/components/UI/detail/styles';

import { detailsTabStyles } from './backDropStyles';

export const DetailGender = ({ possibleValues }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { values = {}, handleChange, handleSubmit } = useFormikContext();

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };

  return (
    <DetailLabel label='Gender'>
      <Box onClick={changeViewBox}>
        <Backdrop open={isEditMode} invisible={false} sx={detailsTabStyles.backDropStyle} />
        <DetailSelect
          label='Gender'
          name='gender'
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          value={values.gender}
          placeholder='Select gender'
          possibleValues={possibleValues.genders}
          sxGender={genderSelect}
          menuTextCapitalize={textCapitalize}
          submitOnValueChange={true}
        />
      </Box>
    </DetailLabel>
  );
};

DetailGender.propTypes = {
  possibleValues: instanceOf(Object),
};
