import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { StyledBox } from 'src/components/login/styled';
import { Loader } from 'src/components/UI';
import { useSignIn, useUpdateProfileWhithoutMessage } from 'src/hooks';

import { LoginForm } from './LoginForm';

const initialValues = {
  email: '',
  password: '',
};

export const LoginFormWrapper = ({ handleForgotPasswordClick }) => {
  const [signIn, { loading }] = useSignIn();
  const [updateProfile] = useUpdateProfileWhithoutMessage();
  const navigate = useNavigate();

  const onSubmit = (variables, actions) => {
    signIn({
      variables,
    })
      // eslint-disable-next-line consistent-return
      .then((res) => {
        if (res?.data?.signIn?.user?.isFirstLogin === true) {
          navigate(`/profile`);
          updateProfile({
            variables: {
              input: {
                isFirstLogin: false,
              },
            },
          });
        }
        if (res?.data?.signIn?.user?.isFirstLogin === false) {
          navigate(`/`);
        }
      })
      .catch((e) => {
        actions.setErrors({
          email: e.message,
          password: e.message,
        });
      });
  };

  return (
    <StyledBox>
      <Loader loading={loading} />
      <Typography variant='h3' mb={3}>
        Log In
      </Typography>
      <Typography>Enter the email and password associated with your account</Typography>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          <LoginForm handleForgotPasswordClick={handleForgotPasswordClick} />
        </Form>
      </Formik>
    </StyledBox>
  );
};

LoginFormWrapper.propTypes = {
  handleForgotPasswordClick: PropTypes.func,
};
