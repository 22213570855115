import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

export const StatsSection = ({ stateSectionItems }) => (
  <Box
    sx={{
      display: 'flex',
      height: '20px',
      margin: '20px 0',
      padding: '0px',
    }}
  >
    {stateSectionItems.map((item) => (
      <Box
        key={item.title}
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '16px',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            color: '#676767',
          }}
        >
          {item.title}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            padding: '0 2px',
          }}
        >
          {item.value}
        </Typography>
      </Box>
    ))}
  </Box>
);

StatsSection.propTypes = {
  stateSectionItems: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ),
};
