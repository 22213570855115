import Box from '@mui/material/Box';
import { func, instanceOf } from 'prop-types';
import { DetailLabel } from 'src/components';
import { DetailDatePicker } from 'src/components/UI/detail/DetailDatePicker';

export const Shipping = ({ values, handleChange }) => (
  <Box>
    <DetailLabel label='Shipped Date'>
      <DetailDatePicker
        name='shippingDate'
        placeholder='Set Shipping Date'
        handleChange={handleChange}
        value={values.shippingDate}
      />
    </DetailLabel>
    <DetailLabel label='Expected Arrival Date'>
      <DetailDatePicker
        name='expectedArrivalDate'
        placeholder='Set Arrival Date'
        handleChange={handleChange}
        value={values.expectedArrivalDate}
      />
    </DetailLabel>
  </Box>
);

Shipping.propTypes = {
  values: instanceOf(Object),
  handleChange: func,
};
