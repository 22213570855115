import { Backdrop } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { styles } from 'src/components/testers/createTesterForm/styles';
import { testerCreationForm } from 'src/styles';
import { convertKgToLbs, convertLbsToKg } from 'src/utils/helpers';

import { detailsTabStyles } from './backDropStyles';

export const DetailsWeight = ({ testerProfile }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const {
    values = {},
    handleChange,
    setFieldValue,
    errors = {},
    handleSubmit,
  } = useFormikContext();

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      handleSubmit();
    }
  };

  const closeAndSubmit = () => {
    setIsEditMode((prev) => !prev);
    handleSubmit();
  };

  const handleChangeLbs = (e) => {
    handleChange(e);
    setFieldValue('weightKgs', convertLbsToKg(e.target.value));
  };

  const handleChangeKg = (e) => {
    handleChange(e);
    setFieldValue('weightLbs', convertKgToLbs(e.target.value));
  };

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };
  return (
    <Box>
      {isEditMode ? (
        <Box sx={styles.twoColumns}>
          <Backdrop
            open={isEditMode}
            invisible={false}
            onClick={closeAndSubmit}
            sx={detailsTabStyles.backDropStyle}
          />
          <StyledLabel
            label='Weight (lbs)'
            sx={{
              fontSize: '14px',
            }}
          >
            <TextField
              type='number'
              sx={testerCreationForm.input}
              size='small'
              name='weightLbs'
              onInput={handleChangeLbs}
              value={values.weightLbs}
              error={!!errors.weightLbs}
              helperText={errors.weightLbs}
              placeholder='Lbs'
              onKeyDown={keyDownHandler}
            />
          </StyledLabel>
          <StyledLabel
            label='Weight (kgs)'
            sx={{
              fontSize: '14px',
            }}
          >
            <TextField
              type='number'
              sx={testerCreationForm.input}
              size='small'
              name='weightKgs'
              onInput={handleChangeKg}
              value={values.weightKgs}
              error={!!errors.weightKgs}
              helperText={errors.weightKgs}
              placeholder='Kgs'
              onKeyDown={keyDownHandler}
            />
          </StyledLabel>
        </Box>
      ) : (
        <Box onClick={changeViewBox}>
          <StyledLabel
            label={
              testerProfile ? (
                <Box sx={styles.required}>
                  Weight <span>*</span>
                </Box>
              ) : (
                'Weight'
              )
            }
            sx={detailsTabStyles.styledLabelFont}
          />
          <Box sx={detailsTabStyles.styleForResultDetail}>
            {values.weightLbs !== null || values.weightKgs !== null ? (
              `${values.weightLbs || '0'}lbs
                (${values.weightKgs || '0'}kgs)`
            ) : (
              <Box color='#444444'>Select weight</Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
