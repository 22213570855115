import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
// eslint-disable-next-line object-curly-newline
import { arrayOf, func, instanceOf, node, object, oneOfType, string } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { StyledSelect } from 'src/components/common/CommonStyledComponents';
import useOnClickOutside from 'src/hooks/useClickOutside';

import { containerHover, detailSelect } from './styles';

export const DetailSelectMultiple = ({
  value,
  name,
  possibleValues,
  handleChange,
  sxGender,
  children,
  renderTextValue,
  submitOnValueChange = false,
  multiple,
  ...rest
}) => {
  const { handleSubmit, initialValues, isSubmitting } = useFormikContext();
  const [isEditable, setIsEditable] = useState(false);

  const selectRef = useRef();

  const isTheSameValue = initialValues[name] === value;

  useEffect(() => {
    if (!isTheSameValue && !isEditable && submitOnValueChange) {
      if (!isSubmitting) {
        handleSubmit();
      }
    }
  }, [isTheSameValue, isEditable, submitOnValueChange, handleSubmit, isSubmitting]);

  const toggleSelect = () => setIsEditable((prev) => !prev);

  useOnClickOutside(selectRef, toggleSelect);

  const onChange = (e) => {
    handleChange(e);
    if (!multiple) toggleSelect();
  };

  const handleMenuClose = () => {
    if (multiple) handleSubmit();
  };

  if (!isEditable) {
    return (
      <Typography onClick={toggleSelect} sx={[sxGender || detailSelect, containerHover]}>
        {renderTextValue(value, rest.placeholder)}
      </Typography>
    );
  }

  return (
    <StyledSelect
      {...rest}
      ref={selectRef}
      name={name}
      onChange={onChange}
      value={value || []}
      multiple={multiple}
      onClose={handleMenuClose}
    >
      {children}
    </StyledSelect>
  );
};

DetailSelectMultiple.propTypes = {
  value: oneOfType([arrayOf(string), arrayOf(object)]),
  name: string,
  possibleValues: instanceOf(Object),
  handleChange: func,
  label: string,
  sxGender: instanceOf(Object),
  menuTextCapitalize: instanceOf(Object),
  selectField: instanceOf(Object),
  children: node,
};
