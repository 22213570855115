import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { bool, func, shape, string } from 'prop-types';
import { useState } from 'react';
import { theme } from 'src/theme';

import { DetailInput } from './DetailInput';

export const MediaCard = ({ media, handleEditCaption, handleRemoveMedia, isEditable }) => {
  const [isShowCaptionInput, setIsShowCaptionInput] = useState(!!media.caption);

  const handleChangeCaption = ({ target }) => {
    handleEditCaption({
      testMediaId: media.id,
      caption: target.value,
    });
  };

  const toggleIsShowCaptionInput = () => setIsShowCaptionInput((prev) => !prev);

  const renderInput = () => {
    if (!isEditable) {
      return <Typography>{media.caption}</Typography>;
    }

    return isShowCaptionInput ? (
      <DetailInput
        value={media.caption}
        isEditableByDefault={!media.caption}
        handleChange={handleChangeCaption}
        multiline
      />
    ) : (
      <Button
        variant='outlined'
        onClick={toggleIsShowCaptionInput}
        sx={{
          py: 0,
        }}
      >
        Add Caption
      </Button>
    );
  };

  return (
    <Grid container spacing={12}>
      <Grid item xs={6}>
        <Box
          component='img'
          src={`${process.env.REACT_APP_DOMAIN}/${media.fileUrl}`}
          sx={{
            width: '100%',
            borderRadius: 2,
          }}
          alt=''
        />
        {isEditable && (
          <Button
            onClick={handleRemoveMedia(media.id)}
            sx={{
              p: 0,
              color: theme.colors.error,
            }}
          >
            Remove Image
          </Button>
        )}
      </Grid>
      <Grid item xs={6}>
        <Typography fontWeight={700} mb={1}>
          Caption
        </Typography>
        {renderInput()}
      </Grid>
    </Grid>
  );
};

MediaCard.propTypes = {
  media: shape({
    caption: string,
    fileUrl: string,
  }),
  handleEditCaption: func,
  handleRemoveMedia: func,
  isEditable: bool,
};
