import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import PropTypes, { instanceOf } from 'prop-types';
import { useCallback, useState } from 'react';
import {
  HeaderTitleContainer,
  StyledLabel,
  StyledModal,
  StyledSelect,
} from 'src/components/common/CommonStyledComponents';
import { useEditReport, useTestInfo } from 'src/hooks';
import { theme } from 'src/theme';
import { addReportSchema } from 'src/utils/formSchemas';

import { ModalFooter } from './ModalFooter';
import { reportStyle } from './style';

export const EditReportModal = ({
  open,
  closeModal,
  isCloseAfterPostReport,
  initialValues = {},
}) => {
  const { enrolledList: testers } = useTestInfo();
  const [visibilityTesters, setVisibilityTesters] = useState([]);

  const editReport = useEditReport();

  const onSubmit = useCallback(
    (input) => {
      const { id, ...other } = input;
      editReport({
        reportId: id,
        input: other,
      }).then(() => {
        closeModal();
      });
    },
    [isCloseAfterPostReport],
  );

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: initialValues || {},
    enableReinitialize: true,
    validationSchema: addReportSchema,
    onSubmit: (input) => {
      onSubmit({
        ...input,
        visibilityTestUsers: visibilityTesters,
      });
    },
  });

  const handleAddTester = useCallback(
    (id) => () => {
      setVisibilityTesters((prev) => {
        if (prev.includes(id)) return prev.filter((item) => item !== id);
        return [...prev, id];
      });
    },
    [],
  );

  const renderVisibilityValue = useCallback((value) => {
    const lib = {
      EVERYONE: 'Everyone',
      CUSTOMER: 'Customer',
      ALL_TESTERS: 'All testers',
      TESTER: 'Custom',
    };
    return lib[value] || 'Select Visibility';
  }, []);

  const handleChangeVisibility = useCallback((e) => {
    handleChange(e);
    if (e.target.value !== 'TESTER') setVisibilityTesters([]);
  }, []);

  return (
    <StyledModal open={open} onClose={closeModal} aria-labelledby='modal-title'>
      <Paper sx={reportStyle.modal.paper}>
        <Box
          sx={{
            padding: '20px 24px',
          }}
        >
          <HeaderTitleContainer>
            <Typography variant='modalTitle' id='modal-title'>
              EDIT REPORT
            </Typography>
          </HeaderTitleContainer>
        </Box>
        <Divider
          sx={{
            mb: 5,
          }}
        />
        <Box sx={reportStyle.modal.container}>
          <StyledLabel label='Link Name' required>
            <TextField
              size='small'
              name='linkName'
              onInput={handleChange}
              required
              value={values.linkName}
              error={!!errors.linkName}
              helperText={errors.linkName}
              placeholder='Enter Link Name'
            />
          </StyledLabel>
          <StyledLabel label='Link' required>
            <TextField
              size='small'
              name='link'
              onInput={handleChange}
              required
              value={values.link}
              error={!!errors.link}
              helperText={errors.link}
              placeholder='Add Link'
            />
          </StyledLabel>
          <StyledLabel label='Visibility' required>
            <StyledSelect
              name='visibility'
              onChange={handleChangeVisibility}
              error={!!errors.visibility}
              value={values.visibility}
              helperText={errors.visibility}
              renderValue={renderVisibilityValue}
            >
              <MenuItem value='EVERYONE' key='everyone'>
                Everyone
              </MenuItem>
              <MenuItem value='CUSTOMER' key='customer'>
                Customer
              </MenuItem>
              <MenuItem value='ALL_TESTERS' key='allTesters'>
                All Testers
              </MenuItem>
              {testers.map((item) => (
                <MenuItem
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: visibilityTesters?.includes(item.id)
                        ? theme.colors.neutral.light.extra
                        : theme.colors.neutral.white,
                    },
                  }}
                  value='TESTER'
                  key={item.id}
                  onClick={handleAddTester(item.id)}
                >
                  {item.user.contactName}
                </MenuItem>
              ))}
            </StyledSelect>
          </StyledLabel>
        </Box>
        <ModalFooter
          handleClickSubmit={handleSubmit}
          handleClickCancel={closeModal}
          submitButtonTitle='Save Report'
        />
      </Paper>
    </StyledModal>
  );
};

EditReportModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  isCloseAfterPostReport: PropTypes.bool,
  initialValues: instanceOf(Object),
};
