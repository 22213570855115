import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { useCandidatesTabPanel } from 'src/hooks/useCandidatesTabPanel';

import { SentInviteModal } from '../sentInviteModal/sentInviteModal';
import { StatsSection } from '../statsSection/statsSection';
import { CandidatesTableSection } from '../tables/candidatesTable/CandidatesTableSection';
import { BlackButton } from '../tables/styled';
import { TabPanel } from '../tapPanel/tapPanel';

export const CandidatesTabPanel = ({ candidatesStats, value }) => {
  const {
    selected,
    setSelected,
    sentInviteToAllSelectedCandidates,
    sentInviteToCandidate,
    removeAllSelectedCandidates,
    removeCandidate,
    sendInviteModalIsOpen,
    toggleModal,
    subjectText,
    bodyText,
    handleChangeSubjectText,
    handleChangeBodyText,
    handleSubmit,
    reinviteToCandidate,
    handleEnrollCandidate,
    handleRejectCandidate,
  } = useCandidatesTabPanel();

  return (
    <TabPanel value={value} index={1}>
      {selected?.length > 0 ? (
        <Box
          sx={{
            paddingTop: '4px',
            paddingBottom: '16px',
          }}
        >
          <BlackButton
            variant='contained'
            color='primary'
            onClick={sentInviteToAllSelectedCandidates}
          >
            Send Invite
          </BlackButton>
          <BlackButton
            variant='outlined'
            sx={{
              marginLeft: '15px',
            }}
            onClick={removeAllSelectedCandidates}
          >
            Remove
          </BlackButton>
        </Box>
      ) : (
        <StatsSection stateSectionItems={candidatesStats} />
      )}
      <CandidatesTableSection
        selected={selected}
        setSelected={setSelected}
        sentInviteToCandidate={sentInviteToCandidate}
        reinviteToCandidate={reinviteToCandidate}
        removeCandidate={removeCandidate}
        handleEnrollCandidate={handleEnrollCandidate}
        handleRejectCandidate={handleRejectCandidate}
      />
      <SentInviteModal
        open={sendInviteModalIsOpen}
        toggleModal={toggleModal}
        subjectText={subjectText}
        bodyText={bodyText}
        handleChangeSubjectText={handleChangeSubjectText}
        handleChangeBodyText={handleChangeBodyText}
        handleSubmit={handleSubmit}
      />
    </TabPanel>
  );
};

CandidatesTabPanel.propTypes = {
  candidatesStats: PropTypes.instanceOf(Array).isRequired,
  value: PropTypes.number.isRequired,
};
