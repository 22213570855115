import Box from '@mui/material/Box';
import { useAuthContext, useGetMyTests, useSetIsAvailableStatus } from 'src/hooks';

import { RequestHandler } from '../requestHandler/requestHandler';
import { style } from './style';
import { MyTestsTable } from './table/MyTestsTable';
import { TesterMyTestsHeader } from './testerMyTestsHeader';

export const TesterMyTestsContainer = () => {
  const { data, loading, fetchMore } = useGetMyTests();
  const [setIsAvailableStatus] = useSetIsAvailableStatus();
  const { user, refetch } = useAuthContext();

  const handleSetActiveStatus = async () => {
    await setIsAvailableStatus({
      variables: {
        isAvailable: true,
      },
    });
    refetch();
  };

  const handleSetNotActiveStatus = async () => {
    await setIsAvailableStatus({
      variables: {
        isAvailable: false,
      },
    });
    refetch();
  };

  return (
    <Box sx={style.container}>
      <RequestHandler loading={loading}>
        <TesterMyTestsHeader
          isAvailable={user?.tester?.isAvailable}
          testsCompletedCounter={data?.getMyTests?.competedTestCount || 0}
          ongoingTestsCounter={data?.getMyTests?.ongoingTestCount || 0}
          setActiveStatus={handleSetActiveStatus}
          setInactiveStatus={handleSetNotActiveStatus}
        />
        <MyTestsTable data={data} fetchMore={fetchMore} />
      </RequestHandler>
    </Box>
  );
};
