import gql from 'graphql-tag';

export const SIGN_IN = gql`
  mutation ($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      user {
        id
        email
        firstName
        lastName
        role
        isRegister
        isConfirmEmail
        isInvited
        isFirstLogin
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
      status
    }
  }
`;

export const SET_NEW_PASSWORD = gql`
  mutation setNewPassword($passwordResetToken: String!, $newPassword: String!) {
    setNewPassword(passwordResetToken: $passwordResetToken, newPassword: $newPassword) {
      status
    }
  }
`;

export const ADD_TESTER = gql`
  mutation addTester($input: TesterInput!) {
    addTester(input: $input) {
      status
    }
  }
`;

export const EDIT_TESTER = gql`
  mutation editTester($testerId: ID!, $input: editTesterInput!) {
    editTester(testerId: $testerId, input: $input) {
      status
    }
  }
`;

export const ADD_CUSTOMER = gql`
  mutation addCustomer($input: addCustomerInput) {
    addCustomer(input: $input) {
      status
    }
  }
`;

export const REINVITE_USER = gql`
  mutation reInviteUser($userId: ID!) {
    reInviteUser(userId: $userId) {
      status
    }
  }
`;

export const CREATE_BRAND = gql`
  mutation createBrand($name: String!) {
    createBrand(name: $name) {
      id
      name
    }
  }
`;

export const CREATE_TEST = gql`
  mutation createTest($input: createTest!) {
    createTest(input: $input) {
      id
    }
  }
`;

export const UPLOAD_TEST_MEDIA = gql`
  mutation uploadTestMedia($file: Upload!, $input: addTestMediaInput!) {
    uploadTestMedia(file: $file, input: $input) {
      id
    }
  }
`;

export const EDIT_TEST_MEDIA = gql`
  mutation editTestMedia($testMediaId: ID!, $input: editTestMedia) {
    editTestMedia(testMediaId: $testMediaId, input: $input) {
      status
    }
  }
`;

export const REMOVE_TEST_MEDIA = gql`
  mutation removeTestMedia($testMediaId: ID!) {
    removeTestMedia(testMediaId: $testMediaId) {
      status
    }
  }
`;

export const EDIT_TEST = gql`
  mutation editTest($testId: ID!, $input: editTestInput!) {
    editTest(testId: $testId, input: $input) {
      status
    }
  }
`;

export const CREATE_REPORT = gql`
  mutation createReport($input: createReportInput!) {
    createReport(input: $input) {
      status
    }
  }
`;

export const REMOVE_REPORT = gql`
  mutation removeReport($reportId: ID!) {
    removeReport(reportId: $reportId) {
      status
    }
  }
`;

export const EDIT_REPORT = gql`
  mutation editReport($reportId: ID!, $input: editReportInput!) {
    editReport(reportId: $reportId, input: $input) {
      status
    }
  }
`;

export const ADD_TESTERS_FROM_CSV = gql`
  mutation addTestersFromCsv($file: Upload) {
    addTestersFromCsv(file: $file) {
      successIds
      successfullyCount
      failedCount
      failedRow
    }
  }
`;

export const INVITE_USERS_BY_ID = gql`
  mutation inviteUsersById($ids: [ID!]!) {
    inviteUsersById(ids: $ids) {
      status
    }
  }
`;

export const SAVE_TESTERS_FROM_CSV = gql`
  mutation saveTestersFromCsv {
    saveTestersFromCsv {
      status
    }
  }
`;

export const REMOVE_USER = gql`
  mutation removeUser($id: ID!) {
    removeUser(id: $id) {
      status
    }
  }
`;

export const REMOVE_MY_ACCOUNT = gql`
  mutation removeMyAccount {
    removeMyAccount {
      status
    }
  }
`;

export const EDIT_USER = gql`
  mutation editUserById($id: ID!, $input: editUserInput) {
    editUserById(id: $id, input: $input) {
      status
    }
  }
`;

export const DUPLICATE_TEST = gql`
  mutation duplicateTest($testId: ID!) {
    duplicateTest(testId: $testId) {
      id
    }
  }
`;

export const REMOVE_CANDIDATES = gql`
  mutation removeCandidates($testId: ID!, $testUserIds: [ID!]!) {
    removeCandidates(testId: $testId, testUserIds: $testUserIds) {
      status
    }
  }
`;

export const INVITE_CANDIDATES = gql`
  mutation inviteCandidates($emailText: String, $emailSubject: String, $testCandidateIds: [ID!]!) {
    inviteCandidates(
      emailText: $emailText
      emailSubject: $emailSubject
      testCandidateIds: $testCandidateIds
    ) {
      status
    }
  }
`;

export const REINVITE_CANDIDATE = gql`
  mutation reInviteCandidate($testUserId: ID!) {
    reInviteCandidate(testUserId: $testUserId) {
      status
    }
  }
`;

export const ENROLL_CANDIDATE = gql`
  mutation enrollCandidate($testUserId: ID!) {
    enrollCandidate(testUserId: $testUserId) {
      status
    }
  }
`;

export const REJECT_CANDIDATE = gql`
  mutation rejectCandidate($testUserId: ID!) {
    rejectCandidate(testUserId: $testUserId) {
      status
    }
  }
`;

export const ADD_CANDIDATES = gql`
  mutation addCandidates($testId: ID!, $userIds: [ID!]!) {
    addCandidates(testId: $testId, userIds: $userIds) {
      status
    }
  }
`;

export const INVITE_USERS = gql`
  mutation inviteUsers($emails: [EmailAddress!]!, $role: Role!, $brandId: ID) {
    inviteUsers(emails: $emails, role: $role, brandId: $brandId) {
      status
    }
  }
`;

export const EDIT_TEST_TESTER = gql`
  mutation editTestTester($testUserId: ID!, $grade: String) {
    editTestTester(testUserId: $testUserId, grade: $grade) {
      status
    }
  }
`;

export const START_TESTING_TESTER = gql`
  mutation startTestingTester($testUserId: ID!) {
    startTestingTester(testUserId: $testUserId) {
      status
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($input: editProfile!) {
    updateProfile(input: $input) {
      id
    }
  }
`;

export const SET_CHOICE_INVITED_CANDIDATE = gql`
  mutation setChoiceInvitedCandidate($inviteToken: String!, $status: choiceInvitedCandidate!) {
    setChoiceInvitedCandidate(inviteToken: $inviteToken, status: $status) {
      status
    }
  }
`;

export const SIGN_UP_BY_TOKEN = gql`
  mutation signUpByToken($inviteToken: String!, $input: signUpUser) {
    signUpByToken(inviteToken: $inviteToken, input: $input) {
      status
      token
    }
  }
`;

export const UNENROLL_TEST_TESTER = gql`
  mutation unEnrollTestTester($testUserId: ID!, $unEnrollTestTester: String) {
    unEnrollTestTester(testUserId: $testUserId, unEnrollTestTester: $unEnrollTestTester) {
      status
    }
  }
`;

export const CLOSE_TEST = gql`
  mutation closeTest($testId: ID, $isPostReports: Boolean) {
    closeTest(testId: $testId, isPostReports: $isPostReports) {
      status
    }
  }
`;

export const SET_IS_AVAILABLE_STATUS = gql`
  mutation setIsAvailableStatus($isAvailable: Boolean!) {
    setIsAvailableStatus(isAvailable: $isAvailable) {
      status
    }
  }
`;

export const UPDATE_USER_WITH_ROLE_ADMIN_BY_ID = gql`
  mutation updateUserWithRoleAdminById($id: ID!, $input: editUserWithRoleAdmin!) {
    updateUserWithRoleAdminById(id: $id, input: $input) {
      id
      email
      imageProfileLink
      firstName
      lastName
    }
  }
`;
