export const ChevronBottom = (props) => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M9.54459 3.2998L6.06 6.7844L2.57541 3.2998L1.5 4.37518L6.06 8.93518L10.62 4.37518L9.54459 3.2998Z'
      fill='#676767'
    />
  </svg>
);
