import Chip from '@mui/material/Chip';
import { bool, func, string } from 'prop-types';

import { EyeClosedIcon } from '../UI';

export const ShareChip = ({ isShareCustomer, onClick, testMediaId }) => (
  <Chip
    onClick={() => onClick(isShareCustomer, testMediaId)}
    clickable
    sx={{
      borderRadius: '4px',
      height: '28px',
      width: '122px',
      marginTop: '4px',
    }}
    icon={<EyeClosedIcon color={isShareCustomer ? 'white' : '#676767'} />}
    label={isShareCustomer ? 'Shared' : 'Not Shared'}
    variant={isShareCustomer ? 'filled' : 'outlined'}
    color={isShareCustomer ? 'primary' : 'default'}
  />
);

ShareChip.propTypes = {
  isShareCustomer: bool,
  onClick: func,
  testMediaId: string,
};
