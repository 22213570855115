export const style = {
  container: {
    maxWidth: '824px',
    padding: '32px 0px',
    width: '100%',
    margin: '0 auto',
  },
  button: {
    fontSize: '14px',
    height: '30px',
    alignItems: 'center',
  },
  textCount: {
    fontSize: '14px',
    fontWeight: 700,
    marginRight: '8px',
  },
};
