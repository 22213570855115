import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { arrayOf, func, string } from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { Flex, StyledSelect } from 'src/components/common/CommonStyledComponents';
import { ShareChip } from 'src/components/common/ShareChip';
import { ExpandIcon } from 'src/components/UI/icons/ExpandIcon';
import { useEditTestMedia, useRemoveTestMedia, useTestInfo } from 'src/hooks';
import { rightPanel } from 'src/styles';

import { MediaPreview } from './MediaPreview';
import { UploadMediaSection } from './uploadMediaSection/UploadMediaSection';

const NoAddedMedia = () => (
  <Typography
    sx={{
      color: '#676767',
      fontSize: '14px',
      marginTop: 5,
    }}
  >
    No images added yet
  </Typography>
);

const CaptionInput = ({ caption, closeInput, mediaId }) => {
  const [newText, setNewText] = useState(caption || '');
  const [editTestMedia] = useEditTestMedia();

  const { useRefetchTestersData } = useTestInfo();

  const onClickOk = useCallback(async () => {
    await editTestMedia({
      variables: {
        testMediaId: mediaId,
        input: {
          caption: newText,
        },
      },
    });
    closeInput();
    useRefetchTestersData();
  }, [editTestMedia, newText]);

  return (
    <Box>
      <TextField
        sx={{
          width: '100%',
        }}
        onInput={(e) => {
          setNewText(e.target.value);
        }}
        multiline
        value={newText}
        InputProps={{
          sx: {
            minHeight: '151px',
            pb: 10,
            alignItems: 'start',
            fontSize: 14,
          },
          endAdornment: (
            <Flex sx={rightPanel.button.root}>
              <Typography role='button' onClick={onClickOk} sx={rightPanel.button.submit}>
                Ok
              </Typography>
              <Typography
                role='button'
                onClick={() => {
                  closeInput();
                }}
                sx={rightPanel.button.cancel}
              >
                Cancel
              </Typography>
            </Flex>
          ),
        }}
      />
    </Box>
  );
};

CaptionInput.propTypes = {
  caption: string,
  closeInput: func,
  mediaId: string,
};

const MediaList = ({ mediaList }) => {
  const [open, setOpen] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [shouldChangeCaption, setShouldChangeCaption] = useState(false);

  const [editTestMedia] = useEditTestMedia();

  const lastIndex = mediaList.length - 1;

  const [removeTestMedia] = useRemoveTestMedia();

  const { useRefetchTestersData, enrolledList } = useTestInfo();

  const currentMediaInfo = useMemo(
    () => ({
      fileUrl: mediaList[currentMediaIndex].fileUrl,
      id: mediaList[currentMediaIndex].id,
      isFirst: currentMediaIndex === 0,
      isLast: currentMediaIndex === lastIndex,
    }),
    [mediaList, currentMediaIndex],
  );

  const onClickNext = useCallback(() => {
    setCurrentMediaIndex((prev) => {
      if (prev === lastIndex) return prev;
      return prev + 1;
    });
  }, []);

  const onClickPrev = useCallback(() => {
    setCurrentMediaIndex((prev) => {
      if (prev === 0) return prev;
      return prev - 1;
    });
  }, []);

  const handleClickOpen = useCallback(
    (index) => () => {
      setCurrentMediaIndex(index);
      setOpen(true);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onRemoveClick = useCallback(
    (testMediaId) => async () => {
      await removeTestMedia({
        variables: {
          testMediaId,
        },
      });
      useRefetchTestersData();
    },
    [],
  );

  const onCloseInput = useCallback(() => {
    setShouldChangeCaption(false);
  }, []);

  const handleOnShareClick = async (isShareCustomer, testMediaId) => {
    const data = await editTestMedia({
      variables: {
        testMediaId,
        input: {
          isShareCustomer: !isShareCustomer,
        },
      },
    });
    if (data.data?.editTestMedia?.status) {
      useRefetchTestersData();
    }
  };

  const handleChangeSelect = async (value) => {
    const valueData = value.target.value.split(' ');
    const data = await editTestMedia({
      variables: {
        testMediaId: valueData[1],
        input: {
          testUserId: valueData[0],
        },
      },
    });
    if (data.data?.editTestMedia?.status) {
      useRefetchTestersData();
    }
  };

  const renderValue = (item) => () =>
    <Typography fontSize={14}>{item?.testTester?.user?.contactName}</Typography>;

  return (
    <Box
      sx={{
        marginTop: '20px',
      }}
    >
      {mediaList.map((item, index) => (
        <Box
          key={item.id}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          <Box
            sx={{
              flex: '0 389px',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                '&:hover': {
                  '& .expandedIcon': {
                    visibility: 'visible',
                  },
                  '& .image': {
                    transform: 'scale(1.005)',
                  },
                },
              }}
              onClick={handleClickOpen(index)}
            >
              <Box
                sx={{
                  overflow: 'hidden',
                  borderRadius: '8px',
                }}
              >
                <Box
                  component='img'
                  className='image'
                  sx={{
                    maxWidth: {
                      xs: 389,
                      md: 389,
                    },
                    borderRadius: '8px',
                  }}
                  alt='fileUrl'
                  src={`${process.env.REACT_APP_DOMAIN}/${item.fileUrl}`}
                />
              </Box>
              <ExpandIcon
                sx={{
                  visibility: 'hidden',
                  position: 'absolute',
                  left: '45%',
                  bottom: '40%',
                  backgroundColor: 'rgba(0, 0, 0, 0.75)',
                }}
              />
            </Box>
            <Button
              sx={{
                color: '#DE4141',
              }}
              onClick={onRemoveClick(item.id)}
            >
              Remove Image
            </Button>
          </Box>
          <Box
            sx={{
              flex: '1 auto',
              marginLeft: '47px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Box
              sx={{
                marginBottom: '20px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                Caption
              </Typography>
              {item.caption ? (
                <Typography
                  onClick={() => {
                    setShouldChangeCaption(true);
                  }}
                >
                  {item.caption}
                </Typography>
              ) : (
                !shouldChangeCaption && (
                  <Button
                    onClick={() => {
                      setShouldChangeCaption(true);
                    }}
                    variant='outlined'
                    sx={{
                      height: '28px',
                    }}
                  >
                    Add a Caption
                  </Button>
                )
              )}
              {/* eslint-disable-next-line max-len */}
              {shouldChangeCaption && (
                <CaptionInput mediaId={item.id} caption={item.caption} closeInput={onCloseInput} />
              )}
            </Box>
            <Box
              sx={{
                marginBottom: '20px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                Tester
              </Typography>
              <StyledSelect
                value={item?.testTester?.id ? `${item.testTester.id} ${item?.id}` : null}
                onChange={handleChangeSelect}
                sx={{
                  width: '388px',
                }}
                placeholder='Select a Tester'
                renderValue={renderValue(item)}
              >
                {enrolledList.map((itemSelect) => (
                  <MenuItem
                    key={`${itemSelect?.id} ${item?.id}`}
                    value={`${itemSelect?.id} ${item?.id}`}
                  >
                    <Typography>{itemSelect?.user?.contactName}</Typography>
                  </MenuItem>
                ))}
              </StyledSelect>
            </Box>
            <Box
              sx={{
                marginBottom: '20px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                Share with Customer
              </Typography>
              <ShareChip
                testMediaId={item.id}
                isShareCustomer={item.isShareCustomer}
                onClick={handleOnShareClick}
              />
            </Box>
          </Box>
        </Box>
      ))}
      <MediaPreview
        currentMediaInfo={currentMediaInfo}
        open={open}
        onClose={handleClose}
        onClickPrev={onClickPrev}
        onClickNext={onClickNext}
      />
    </Box>
  );
};

MediaList.propTypes = {
  mediaList: arrayOf(Object),
};

export const MediaSection = () => {
  const { mediaList } = useTestInfo();

  return (
    <Box
      sx={{
        marginTop: 10,
      }}
    >
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: '700',
          marginBottom: 5,
        }}
      >
        Media
      </Typography>
      <UploadMediaSection mediaList={mediaList} />
      {mediaList.length ? <MediaList mediaList={mediaList} /> : <NoAddedMedia />}
    </Box>
  );
};
