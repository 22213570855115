import { Form, Formik, useFormikContext } from 'formik';
import { func, instanceOf } from 'prop-types';
import { useState } from 'react';

import { DetailAddressFields } from './DetailAddressFields';

export const DetailAddressFieldsWrapper = ({ values: data = {}, setValues, testerProfile }) => {
  const [isEditable, setIsEditable] = useState(false);
  const { handleSubmit } = useFormikContext();

  const initialValues = {
    addressOne: data.addressOne,
    addressTwo: data.addressTwo,
    country: data.country,
    state: data.state,
    city: data.city,
    postalCode: data.postalCode,
  };

  const toggle = () => setIsEditable((prev) => !prev);

  const onFormSubmit = (input) => {
    setValues({
      ...data,
      ...input,
    });
    handleSubmit();
    toggle();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onFormSubmit}>
      <Form>
        <DetailAddressFields
          isEditable={isEditable}
          toggle={toggle}
          initialValues={initialValues}
          testerProfile={testerProfile}
        />
      </Form>
    </Formik>
  );
};

DetailAddressFieldsWrapper.propTypes = {
  values: instanceOf(Object),
  setValues: func,
};
