import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { DetailTabs } from 'src/components/UI/detail/index';

import { userInfoStyle } from '../styles';
import { AdminInfoTab } from './AdminInfoTab';

export const AdminInfo = () => {
  const { values = {}, handleChange } = useFormikContext();

  // eslint-disable-next-line consistent-return
  const tabs = useMemo(() => {
    return [
      {
        name: 'contact',
        children: <AdminInfoTab values={values} handleChange={handleChange} />,
      },
    ];
  }, [values, handleChange]);

  return (
    <Box sx={userInfoStyle.root}>
      <DetailTabs tabs={tabs} />
    </Box>
  );
};
