import queryString from 'query-string';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useFilter = () => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const filters = useMemo(() => {
    return queryString.parse(search);
  }, [search]);

  const setFilters = useCallback(
    (values) => {
      const queryStr = queryString.stringify(values);

      navigate(`${pathname}?${queryStr}`, {
        replace: true,
      });
    },
    [pathname, filters],
  );

  const clearFilters = useCallback(
    () =>
      navigate(pathname, {
        replace: true,
      }),
    [pathname],
  );

  return {
    filters,
    setFilters,
    clearFilters,
  };
};
