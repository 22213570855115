import { StyledPageContainer } from 'src/components/common/CommonStyledComponents';
import { TesterMyTestsContainer } from 'src/components/TesterMyTests/testerMyTestsContainer';

const TesterMyTests = () => {
  return (
    <StyledPageContainer>
      <TesterMyTestsContainer />
    </StyledPageContainer>
  );
};

export default TesterMyTests;
