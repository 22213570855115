/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import { About, Activities, Contact, Details, Notes, Testing } from './steps';

export const CreateTesterForm = ({ currentStep, handleClear }) => {
  const steps = [
    <Contact />,
    <Details handleClear={handleClear} />,
    <Activities />,
    <About />,
    <Testing />,
    <Notes />,
  ];

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {steps[currentStep]}
    </Box>
  );
};

CreateTesterForm.propTypes = {
  currentStep: PropTypes.number,
  handleClear: PropTypes.func,
};
