import React from 'react';

const EditIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='edit-icon'
    >
      <path
        d='M16.2705 7.5772C16.5977 7.25006 16.7777 6.81561 16.7777 6.35347C16.7777 5.89132 16.5977 5.45687 16.2705 5.12974L14.898 3.75715C14.5708 3.43001 14.1364 3.25 13.6742 3.25C13.2121 3.25 12.7776 3.43001 12.4514 3.75628L3.25 12.9291V16.75H7.06919L16.2705 7.5772ZM13.6742 4.98088L15.0477 6.3526L13.6716 7.72346L12.299 6.35174L13.6742 4.98088ZM4.98088 15.0191V13.6474L11.0736 7.57374L12.4462 8.94633L6.35433 15.0191H4.98088Z'
        fill='#676767'
      />
    </svg>
  );
};

export default EditIcon;
