import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import { func } from 'prop-types';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { StyledBox } from 'src/components/login/styled';
import { styles } from 'src/components/login/styles';
import { Label } from 'src/components/UI';
import { CloseIcon } from 'src/components/UI/icons';
import { theme } from 'src/theme';

export const CreatePasswordForm = ({ handleBackToLogin }) => {
  const { handleChange, values, errors, setFieldValue } = useFormikContext();

  const handleClearField = (name) => () => setFieldValue(name, '');

  const isValidForm =
    Object.values(errors).every((x) => x === '') && values.password && values.repassword;

  return (
    <StyledBox>
      <Typography variant='h3' mb={3}>
        Create New Password
      </Typography>
      <Typography>Enter a new password for your account</Typography>
      <Box my={7}>
        <Label
          sx={{
            marginBottom: 2,
            ...styles.label,
          }}
          label='Password'
        >
          <TextField
            size='small'
            name='password'
            type='password'
            onInput={handleChange}
            required
            error={!!errors.password}
            helperText={errors.password}
            placeholder='Enter Password'
            value={values.password}
            InputProps={{
              endAdornment: values.password && (
                <InputAdornment position='end' onClick={handleClearField('password')}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </Label>
        <Label label='Re-enter Password' sx={styles.label}>
          <TextField
            size='small'
            name='repassword'
            type='password'
            onInput={handleChange}
            required
            error={!!errors.repassword}
            helperText={errors.repassword}
            placeholder='Re-enter Password'
            value={values.repassword}
            InputProps={{
              endAdornment: values.repassword && (
                <InputAdornment position='end' onClick={handleClearField('repassword')}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </Label>
      </Box>
      <Flex
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Button
          sx={{
            borderRadius: 6,
            fontSize: 16,
            px: 4,
            py: 1,
          }}
          size='small'
          variant='contained'
          disabled={!isValidForm}
          type='submit'
        >
          Set Password
        </Button>
        <Button onClick={handleBackToLogin}>
          <Typography color={theme.colors.tertiary} fontWeight={700}>
            Back to Login
          </Typography>
        </Button>
      </Flex>
    </StyledBox>
  );
};

CreatePasswordForm.propTypes = {
  handleBackToLogin: func,
};
