import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { func } from 'prop-types';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DetailTabs } from 'src/components';
import { useEditTest } from 'src/hooks';
import { useGetBrands, useGetPossibleValuesTest } from 'src/hooks/useQueries';
import { rightPanel } from 'src/styles';

import { Details, Reports, Shipping, TestInfo } from './tabs';

export const RightPanel = ({ refetch }) => {
  const { values } = useFormikContext();
  const testPossibleValues = useGetPossibleValuesTest();
  const { brands } = useGetBrands();
  const { id } = useParams();

  const possibleValues = useMemo(
    () =>
      ({
        ...testPossibleValues,
        brands,
      } || {}),
    [testPossibleValues, brands],
  );

  const [editTest, { loading }] = useEditTest();

  const handleChange = ({ target }) => {
    editTest({
      variables: {
        testId: id,
        input: {
          [target.name]: target.value,
        },
      },
      onCompleted: () => {
        refetch();
      },
    });
  };

  const tabs = useMemo(
    () => [
      {
        name: 'Test Info',
        children: (
          <TestInfo
            values={values}
            handleChange={handleChange}
            testTypeOptions={testPossibleValues?.testType}
          />
        ),
      },
      {
        name: 'details',
        children: <Details values={values} handleChange={handleChange} />,
      },
      {
        name: 'shipping',
        children: <Shipping values={values} handleChange={handleChange} />,
      },
      {
        name: 'reports',
        children: <Reports values={values} />,
      },
    ],
    [possibleValues, values],
  );

  return (
    <Box
      sx={{
        ...rightPanel.root,
        minHeight: '684px',
        height: '100%',
      }}
    >
      <DetailTabs
        tabs={tabs}
        scrollBoxStyle={{
          paddingBottom: 12,
        }}
        loading={loading}
      />
    </Box>
  );
};

RightPanel.propTypes = {
  refetch: func,
};
