import PropTypes from 'prop-types';
import { NavBar } from 'src/components/navBar/NavBar';

export const MainLayout = ({ children }) => (
  <div>
    <NavBar />
    {children}
  </div>
);

MainLayout.propTypes = {
  children: PropTypes.node,
};
