export const PlusIcon = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.76561 1.36582L7.76561 1.36582L7.76561 6.23443H7.76561L7.76561 1.36582ZM7.76561 7.76585L7.76561 7.76585L7.76561 12.6345L7.76561 7.76585ZM8.16561 13.0345L8.16561 8.16585H13.0342V5.83443L8.16561 5.83443L8.16561 0.96582L5.83418 0.965822V5.83443H0.965576V8.16585H5.83418V13.0345L8.16561 13.0345Z'
      fill='black'
    />
  </svg>
);
