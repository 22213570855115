import { StyledPageContainer } from 'src/components/common/CommonStyledComponents';
import { InviteToTestResponceContainer } from 'src/components/inviteToTest/inviteToTestResponceContainer';

const InviteToTestResponce = () => {
  return (
    <StyledPageContainer>
      <InviteToTestResponceContainer />
    </StyledPageContainer>
  );
};

export default InviteToTestResponce;
