import { string } from 'prop-types';

export const EyeClosedIcon = ({ color = 'white' }) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.6471 10.0508C14.7465 10.2231 14.7735 10.4278 14.722 10.6199C14.6705 10.8121 14.5448 10.9759 14.3725 11.0754C14.2002 11.1748 13.9955 11.2018 13.8034 11.1503C13.6112 11.0988 13.4474 10.9731 13.348 10.8008L12.3306 9.03873C11.7572 9.39634 11.1404 9.67926 10.4952 9.88061L10.8196 11.72C10.8378 11.8175 10.8365 11.9176 10.8158 12.0146C10.7951 12.1115 10.7554 12.2034 10.699 12.2849C10.6425 12.3664 10.5705 12.4359 10.487 12.4894C10.4035 12.5429 10.3103 12.5793 10.2126 12.5965C10.115 12.6138 10.0149 12.6115 9.91817 12.5897C9.82143 12.568 9.72995 12.5274 9.64903 12.4701C9.56811 12.4128 9.49935 12.34 9.44673 12.256C9.39411 12.172 9.35867 12.0784 9.34246 11.9805L9.02665 10.1897C8.34271 10.2702 7.65166 10.27 6.96777 10.189L6.65209 11.9798C6.61667 12.1749 6.50546 12.348 6.34277 12.4613C6.18008 12.5746 5.97914 12.6189 5.7839 12.5844C5.58865 12.55 5.41498 12.4397 5.30086 12.2775C5.18674 12.1154 5.14145 11.9147 5.1749 11.7193L5.49934 9.87898C4.8551 9.67738 4.23922 9.39443 3.66659 9.03698L2.64259 10.8106C2.54313 10.9829 2.37931 11.1086 2.18718 11.1601C1.99504 11.2115 1.79032 11.1846 1.61805 11.0851C1.44579 10.9857 1.32009 10.8219 1.2686 10.6297C1.21712 10.4376 1.24407 10.2329 1.34352 10.0606L2.45946 8.12773C2.08324 7.78863 1.73452 7.4202 1.41659 7.02592C1.29353 6.87101 1.23666 6.67375 1.25837 6.4771C1.28009 6.28045 1.37862 6.10035 1.53251 5.97602C1.68641 5.85168 1.88319 5.7932 2.08001 5.81329C2.27683 5.83338 2.45774 5.93043 2.58334 6.08329C3.56621 7.29986 5.30365 8.74998 8.00002 8.74998C10.6963 8.74998 12.4338 7.29986 13.4166 6.08336C13.4782 6.00583 13.5546 5.94127 13.6412 5.89339C13.7279 5.84551 13.8232 5.81526 13.9216 5.8044C14.02 5.79353 14.1196 5.80226 14.2146 5.83007C14.3096 5.85789 14.3982 5.90425 14.4752 5.96647C14.5522 6.02869 14.6162 6.10555 14.6633 6.19261C14.7105 6.27966 14.74 6.37519 14.75 6.47369C14.7601 6.57219 14.7505 6.67171 14.7219 6.7665C14.6933 6.86129 14.6463 6.94948 14.5834 7.02598C14.2648 7.42113 13.9153 7.79029 13.5381 8.12998L14.6471 10.0508Z'
      fill={color}
    />
  </svg>
);

EyeClosedIcon.propTypes = {
  color: string,
};
