import { Backdrop, ListSubheader, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { instanceOf } from 'prop-types';
import { useState } from 'react';
import { DetailLabel } from 'src/components/index';
import { styles } from 'src/components/testers/createTesterForm/styles';
import { DetailSelectMultiple } from 'src/components/UI/detail/DetailSelectMultiple';
import {
  handleCommaSeparatedValues,
  handleCommaSeparatedValuesOrNone,
  handleSetMultipleValues,
} from 'src/utils/helpers';

import { detailsTabStyles } from './backDropStyles';

export const DetailsShoeWidth = ({ possibleValues, submitOnValueChange = true, testerProfile }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { values = {}, setFieldValue } = useFormikContext();
  const { shoeWidth } = possibleValues;

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };

  return (
    <DetailLabel
      label={
        testerProfile ? (
          <Box sx={styles.required}>
            Shoe Width <span>*</span>
          </Box>
        ) : (
          'Shoe Width'
        )
      }
    >
      <Box onClick={changeViewBox}>
        <Backdrop open={isEditMode} invisible={false} sx={detailsTabStyles.backDropStyle} />
        <DetailSelectMultiple
          name='shoeWidth'
          handleChange={handleSetMultipleValues(setFieldValue)}
          value={values.shoeWidth}
          placeholder='Select shoe width'
          renderTextValue={handleCommaSeparatedValuesOrNone}
          renderValue={handleCommaSeparatedValues}
          wrapItems
          multiple
          submitOnValueChange={submitOnValueChange}
          sx={{
            '& .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper ul':
              {
                paddingTop: '0px',
              },
          }}
        >
          <ListSubheader style={styles.dropdownTitleBox}>
            <div style={styles.dropdownSubTitleText}>Select all that apply</div>
          </ListSubheader>
          {shoeWidth?.map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </DetailSelectMultiple>
      </Box>
    </DetailLabel>
  );
};

DetailsShoeWidth.propTypes = {
  possibleValues: instanceOf(Object),
};
