import Box from '@mui/material/Box';
import { func, instanceOf } from 'prop-types';
import { DetailAddressFieldsWrapper, DetailInput, DetailLabel, DetailSelect } from 'src/components';
import { YesOrNotText } from 'src/constants/constants';
import { booleanToString, stringToBoolean } from 'src/utils/helpers';

export const Contact = ({ handleChange, values = {}, setValues }) => {
  const handleBoolChange = ({ target }) => {
    const { name, value } = target;

    handleChange({
      target: {
        name,
        value: stringToBoolean(value),
      },
    });
  };

  return (
    <Box>
      <DetailLabel label='Email'>
        <DetailInput
          name='email'
          handleChange={handleChange}
          value={values.email}
          placeholder='Enter Email'
          textTransform='lowercase'
          submitOnValueChange={true}
        />
      </DetailLabel>
      <DetailLabel label='Phone'>
        <DetailInput
          type='number'
          name='phoneNumber'
          handleChange={handleChange}
          value={values.phoneNumber}
          placeholder='Enter phone number'
          submitOnValueChange={true}
        />
      </DetailLabel>
      <DetailLabel label='Ok to call or text?'>
        <DetailSelect
          label='Ok to call or text?'
          name='isCallOrText'
          handleChange={handleBoolChange}
          value={booleanToString(values.isCallOrText)}
          possibleValues={YesOrNotText}
          submitOnValueChange={true}
          placeholder='Select yes or no'
        />
      </DetailLabel>
      <DetailAddressFieldsWrapper values={values} setValues={setValues} />
    </Box>
  );
};

Contact.propTypes = {
  handleChange: func,
  setValues: func,
  values: instanceOf(Object),
};
