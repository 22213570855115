import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import PropTypes, { oneOfType } from 'prop-types';
import { useCallback, useState } from 'react';
import { theme } from 'src/theme';

import { MoreIcon } from './icons';

export const OptionsMenu = ({ target, options = [], wrapperOptions }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const toggleHovered = useCallback(() => {
    setIsHovered((prev) => !prev);
  }, []);

  const handleOpenMenu = useCallback((event) => {
    setAnchorElUser(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorElUser(null);
  }, []);

  const handleClickItem = useCallback(
    (callback) => () => {
      handleCloseMenu();
      callback(target);
    },
    [],
  );

  return (
    <Box sx={wrapperOptions}>
      <Button
        onClick={handleOpenMenu}
        sx={{
          borderRadius: '30px',
          width: 35,
          height: 35,
          minWidth: 'auto',
          '&:hover': {
            backgroundColor: theme.colors.tertiary,
          },
        }}
        onMouseEnter={toggleHovered}
        onMouseLeave={toggleHovered}
      >
        <MoreIcon color={isHovered ? theme.colors.neutral.white : theme.colors.neutral.dark.mid} />
      </Button>
      <Menu
        sx={{
          mt: '45px',
        }}
        id='menu-appbar'
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseMenu}
      >
        {options.map(({ title, callback }) => (
          <MenuItem onClick={handleClickItem(callback)} key={title}>
            <Typography fontSize={14}>{title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

OptionsMenu.propTypes = {
  target: oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  options: PropTypes.instanceOf(Array),
  wrapperOptions: PropTypes.instanceOf(Object),
};
