import { StyledPageContainer } from 'src/components/common/CommonStyledComponents';
import { CustomerMyTestsContainer } from 'src/components/CustomerMyTests/customerMyTestsContainer';

const CustomerMyTests = () => {
  return (
    <StyledPageContainer>
      <CustomerMyTestsContainer />
    </StyledPageContainer>
  );
};

export default CustomerMyTests;
