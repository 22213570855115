import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { instanceOf } from 'prop-types';
import { useEffect } from 'react';
import { DetailTabs } from 'src/components/UI';
import { useGetBrands } from 'src/hooks';
import { rightPanel } from 'src/styles';

import { Details } from './tabs';

export const RightPanelContent = ({ loading }) => {
  const { brands } = useGetBrands();
  const mappedBrands = brands.map(({ id, name }) => ({
    value: id,
    title: name,
  }));

  const { values, dirty, handleChange, handleSubmit } = useFormikContext();

  useEffect(() => {
    if (dirty) {
      handleSubmit();
    }
    // values, dirty needed both to properly update ui
  }, [values, dirty]);

  const tabs = [
    {
      name: 'Details',
      children: (
        <Details
          values={values}
          possibleValues={{
            brands: mappedBrands,
          }}
          handleChange={handleChange}
        />
      ),
    },
  ];

  return (
    <Box sx={rightPanel.root}>
      <DetailTabs
        tabs={tabs}
        scrollBoxStyle={{
          paddingBottom: 12,
        }}
        loading={loading}
      />
    </Box>
  );
};

RightPanelContent.propTypes = {
  customer: instanceOf(Object),
};
