import Box from '@mui/material/Box';
import { func } from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useFilter } from 'src/hooks';

import { Flex } from '../../CommonStyledComponents';
import { FilterChip } from './FilterChip';
import { styles } from './styles';

export const FilterChips = ({ getFilterName, getFilterValue }) => {
  const { filters, setFilters, clearFilters } = useFilter();

  const [isShowingAll, setIsShowingAll] = useState(false);
  const toggleShowingAll = () => setIsShowingAll((prev) => !prev);

  const filtersArray = useMemo(() => {
    const result = [];

    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) =>
          result.push({
            key,
            value: val,
          }),
        );
      } else {
        result.push({
          key,
          value,
        });
      }
    });

    return result;
  }, [filters]);

  const handleRemoveFilter = useCallback(
    (key, value) => () => {
      if (Array.isArray(filters[key])) {
        return setFilters({
          ...filters,
          [key]: filters[key].filter((i) => i !== value),
        });
      }

      const filterCopied = {
        ...filters,
      };
      delete filterCopied[key];

      return setFilters(filterCopied);
    },
    [filters],
  );

  const filtersToRender = useMemo(
    () => (isShowingAll ? filtersArray : filtersArray.slice(0, 2)),
    [isShowingAll, filtersArray],
  );

  return (
    <Box sx={styles.filterChipsWrapper}>
      <Flex gap={3} flexWrap='wrap'>
        {filtersToRender?.map(({ key, value }) => (
          <FilterChip
            key={`${key}-${value}`}
            value={`${getFilterName(key)}: ${getFilterValue(key, value)}`}
            onRemove={handleRemoveFilter(key, value)}
          />
        ))}
        {filtersArray?.length > 2 && !isShowingAll && (
          <Flex
            onClick={toggleShowingAll}
            sx={{
              cursor: 'pointer',
            }}
          >
            <FilterChip value={`+ ${filtersArray.length - 2}`} />
          </Flex>
        )}
      </Flex>
      {Boolean(Object.keys(filters).length) && (
        <Box sx={styles.clearAll} onClick={clearFilters}>
          Clear All
        </Box>
      )}
    </Box>
  );
};

FilterChips.propTypes = {
  getFilterName: func,
  getFilterValue: func,
};
