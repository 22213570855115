import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import PropTypes from 'prop-types';
import {
  FooterContainer,
  HeaderTitleContainer,
  ScrollContainer,
  StyledModal,
  StyledPaper,
} from 'src/components/common/CommonStyledComponents';
import { RequestHandler } from 'src/components/requestHandler/requestHandler';
import { useCallbackWithLoader } from 'src/hooks';

import { BlackButton } from '../tables/styled';

const StyledCancelButton = styled(Button)`
  margin-right: 16px;
  height: 28px;
  font-weight: 400;
`;

const HelperText = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 8px;
`;

const Header = () => (
  <Box
    sx={{
      padding: '20px 24px',
    }}
  >
    <HeaderTitleContainer>
      <Typography variant='modalTitle' id='modal-title'>
        Preview Invite
      </Typography>
    </HeaderTitleContainer>
  </Box>
);

const Footer = ({ handleClickCancel, handleClickSubmit }) => {
  const { loading, callback: handleSubmit } = useCallbackWithLoader(handleClickSubmit);

  return (
    <RequestHandler loading={loading}>
      <FooterContainer>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <StyledCancelButton onClick={handleClickCancel}>Cancel</StyledCancelButton>
          <BlackButton
            sx={{
              height: '28px',
            }}
            variant='contained'
            color='primary'
            onClick={handleSubmit}
            disabled={loading}
          >
            Send
          </BlackButton>
        </Box>
      </FooterContainer>
    </RequestHandler>
  );
};

Footer.propTypes = {
  handleClickCancel: PropTypes.func,
  handleClickSubmit: PropTypes.func,
};

export const SentInviteModal = ({
  open,
  toggleModal,
  subjectText,
  bodyText,
  handleChangeSubjectText,
  handleChangeBodyText,
  handleSubmit,
}) => (
  <StyledModal open={open} onClose={toggleModal} aria-labelledby='modal-title'>
    <StyledPaper>
      <Header />
      <Divider
        sx={{
          margin: '0 24px 32px 24px',
        }}
      />
      <ScrollContainer>
        <Box
          sx={{
            width: '100%',
            paddingBottom: '23px',
          }}
        >
          <HelperText>Email Subject</HelperText>
          <TextField
            fullWidth
            size='small'
            onInput={handleChangeSubjectText}
            value={subjectText}
            placeholder='Email Subject'
            sx={{
              marginBottom: '20px',
            }}
          />
          <HelperText>Email Body</HelperText>
          <TextField
            fullWidth
            multiline
            size='medium'
            onInput={handleChangeBodyText}
            value={bodyText}
            placeholder='Email Body'
          />
        </Box>
      </ScrollContainer>
      <Footer handleClickSubmit={handleSubmit} handleClickCancel={toggleModal} />
    </StyledPaper>
  </StyledModal>
);

SentInviteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleChangeSubjectText: PropTypes.func.isRequired,
  handleChangeBodyText: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  subjectText: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
};
