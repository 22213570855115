import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import { Flex, StyledPaper } from 'src/components/common/CommonStyledComponents';
import { theme } from 'src/theme';

export const ConfirmStyledPaper = styled(StyledPaper)({
  borderRadius: '12px',
  height: 'auto',
  width: '435px',
  minWidth: '435px',
});

export const WrapperBox = styled(Box)({
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const BoxContainer = styled(Box)({
  margin: '12px',
});

export const BoxContainerStyled = styled(Box)({
  display: 'flex',
  borderRadius: '12px',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '24px',
  background: 'black',
});

export const LabelText = styled(Typography)({
  color: 'white',
  textAlign: 'center',
  fontSize: '14px',
});

export const FlexStyled = styled(Flex)({
  marginTop: '27px',
  minWidth: 'auto',
  background: 'black',
  flexDirection: 'row',
  justifyContent: 'center',
});

export const ButtonStyled = styled(Button)({
  height: '29px',
  fontWeight: '400',
  fontSize: '14px',
  color: '#676767',
});

export const style = {
  button: {
    default: {
      background: 'black',
      color: theme.colors.neutral.white,
      '&:hover': {
        backgroundColor: theme.colors.neutral.white,
        color: 'black',
        opacity: 0.8,
      },
    },
    error: {
      backgroundColor: theme.colors.error,
      color: theme.colors.neutral.black,
      '&:hover': {
        backgroundColor: theme.colors.error,
        opacity: 0.8,
      },
    },
  },
};
