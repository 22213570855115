import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { Ages, UserRoles } from 'src/constants/constants';
import { useGetBrands, useGetTesterValues } from 'src/hooks';
import { useAuthContext } from 'src/hooks/useAuth';

import { DetailTabs } from '../index';
import { Details } from '../testerDetail/tabs';
import { AdminInfoTab } from './adminTabs/AdminInfoTab';
import { Contact as CustomerContact } from './customerTabs';
import { userInfoStyle } from './styles';
import { Activities, Contact, Overview } from './testerTabs';

export const UserInfo = ({ loading }) => {
  const { user } = useAuthContext();
  const { TESTER, CUSTOMER, ADMIN } = UserRoles;

  const { values = {}, handleChange, setFieldError, setValues, setFieldValue } = useFormikContext();

  const { brands, loading: getBrandsLoading } = useGetBrands();

  const testerPossibleValues = useGetTesterValues();

  const possibleValues = useMemo(() => {
    return (
      {
        ...testerPossibleValues,
        Ages,
      } || {}
    );
  }, [testerPossibleValues]);

  const mappedBrands = useMemo(
    () =>
      brands.map(({ id, name }) => ({
        value: id,
        title: name,
      })),
    [brands],
  );

  // eslint-disable-next-line consistent-return
  const tabs = useMemo(() => {
    if (user.role === TESTER) {
      return [
        {
          name: 'about',
          children: (
            <Overview
              values={values}
              possibleValues={possibleValues}
              handleChange={handleChange}
              setFieldError={setFieldError}
            />
          ),
        },
        {
          name: 'activities',
          children: (
            <Activities
              possibleValues={possibleValues}
              activities={possibleValues.activities}
              otherActivities={user?.tester?.otherActivities}
              isWorkForAnOutdoorCompany={user?.tester?.isWorkForAnOutdoorCompany}
              isHaveDog={user?.tester?.isHaveDog}
              isRecreateWithKids={user?.tester?.isRecreateWithKids}
              values={values}
              frequencyValues={possibleValues.frequencyValues}
              handleChange={handleChange}
            />
          ),
        },
        {
          name: 'details',
          children: (
            <Details
              values={values}
              possibleValues={possibleValues}
              shoeSizeAdditionalInfo={user?.tester?.shoeSizeAdditionalInfo}
              clothingSizeAdditionalInfo={user?.tester?.clothingSizeAdditionalInfo}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              testerProfile={true}
            />
          ),
        },
        {
          name: 'contact',
          children: (
            <Contact
              values={values}
              handleChange={handleChange}
              setValues={setValues}
              setFieldError={setFieldError}
            />
          ),
        },
      ];
    }
    if (user.role === CUSTOMER) {
      return [
        {
          name: 'contact',
          children: (
            <CustomerContact
              values={values}
              handleChange={handleChange}
              possibleValues={{
                brands: mappedBrands,
              }}
            />
          ),
        },
      ];
    }
    if (user.role === ADMIN) {
      return [
        {
          name: 'contact',
          children: <AdminInfoTab values={values} handleChange={handleChange} />,
        },
      ];
    }
  }, [possibleValues, values, mappedBrands]);

  return (
    <Box sx={userInfoStyle.root}>
      <DetailTabs tabs={tabs} loading={loading || getBrandsLoading} />
    </Box>
  );
};
