export const detailsTabStyles = {
  backDropStyle: {
    backgroundColor: 'rgba(248, 248, 248, 0)',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: '0px',
  },

  backDropStyleProfile: {
    backgroundColor: 'rgba(248, 248, 248, 0)',
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
  },

  styledLabelFont: {
    fontSize: '14px',
    marginBottom: '4px',
  },

  styleForResultDetail: {
    marginBottom: '20px',
    fontSize: '14px',
  },
};
