import PropTypes from 'prop-types';

export const SearchIcon = ({ size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.8797 13.9549L11.8697 9.94488C12.5113 8.98247 12.8321 7.69932 12.8321 6.4161C12.8321 2.88725 9.94487 0 6.41604 0C2.88722 0 0 2.88725 0 6.4161C0 9.94496 2.88722 12.8322 6.41604 12.8322C7.69925 12.8322 8.82205 12.5112 9.94486 11.8696L13.9549 15.8797C14.1153 16.0401 14.2757 16.0401 14.4361 15.8797L15.8797 14.4362C16.0401 14.2758 16.0401 14.1153 15.8797 13.9549ZM2.08519 6.57627C2.08519 4.17023 4.01003 2.24548 6.41604 2.24548C8.82206 2.24548 10.7469 4.17023 10.7469 6.57627C10.7469 8.98231 8.82206 10.9071 6.41604 10.9071C4.17043 10.9071 2.08519 8.98231 2.08519 6.57627Z'
      fill='#676767'
    />
  </svg>
);

SearchIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
