import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import { theme } from 'src/theme';

export const DateRangePickerStyledContainer = styled(Box)({
  position: 'absolute',
  left: 0,
  backgroundColor: theme.colors.neutral.white,
  padding: '0 20px 24px 20px',
  zIndex: 100,
  boxSizing: 'border-box',
  boxShadow: `0px 8px 12px rgba(0, 0, 0, 0.2)`,
  borderRadius: '8px',
  width: '336px',
  height: '328px',
  overflow: 'hidden',
});
