import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { TestStatus } from 'src/constants/constants';
import { TestInfoProvider, useCloseTest, useDuplicateTest } from 'src/hooks';
import { useGetTest } from 'src/hooks/useQueries';
import { PageNotMatch } from 'src/pages/PageNotMatch';
import { isEmpty } from 'src/utils/helpers';

import { Loader } from '../UI';
import { MediaSection } from './mediaSection/MediaSection';
import { RightPanelTestWrapper } from './rightPanel/RightPanelTestWrapper';
import { TestersNav } from './testersNav/testersNav';
import { TitleBar } from './titleBar/titleBar';

const StyledBox = styled(Box)`
  max-width: 1440px !important;
  padding: 0 44px;
  margin: 0 auto;
`;

export const TestOverview = () => {
  const { id } = useParams();
  const { testData, loading, refetch } = useGetTest();

  const [duplicate, { loading: duplicateLoading }] = useDuplicateTest();

  const closeTest = useCloseTest();

  const handleDuplicate = useCallback(async () => {
    await duplicate({
      variables: {
        testId: id,
      },
    });
  }, [id]);

  const handleCloseTest = () => {
    if (testData?.status !== TestStatus.CLOSED) {
      closeTest();
    }
  };

  if (loading || duplicateLoading) return <Loader loading />;
  if (isEmpty(testData)) return <PageNotMatch />;

  return (
    <TestInfoProvider>
      <StyledBox
        sx={{
          paddingBottom: '40px',
        }}
      >
        <TitleBar
          title={`${testData.brand?.name} - ${testData.styleDescription}`}
          status={testData.status}
          closeTest={handleCloseTest}
          id={testData?.id}
          duplicateTest={handleDuplicate}
          navigateStep={1}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: '57.22%',
              marginLeft: '88px',
            }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: '700',
                marginBottom: '26px',
              }}
            >
              Testers
            </Typography>
            <Box>
              <TestersNav />
              <MediaSection />
            </Box>
          </Box>
          <Box
            sx={{
              width: '26.8%',
              marginRight: '67px',
            }}
          >
            <RightPanelTestWrapper initialValues={testData} refetch={refetch} />
          </Box>
        </Box>
      </StyledBox>
    </TestInfoProvider>
  );
};
