import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';
import { NoteIcon, OptionsMenu, StyledTable } from 'src/components/UI';
import { TableHeader } from 'src/components/UI/tables';
import { EmptyTableBanner } from 'src/components/UI/tables/EmptyTableBanner';
import { useTestInfo, useUnenrollTestTester } from 'src/hooks';
import { formatDataEnUs } from 'src/utils/helpers';

import { UnenrollModal } from '../../unenrollModal/unenrollModal';
import { TesterTableStatus } from '../testerTableStatus/testerTableStatus';

const COLUMNS = [
  {
    title: 'Name',
    sortKey: 'NAME',
  },
  {
    title: 'Images',
    sortKey: 'IMAGES_COUNT',
  },
  {
    title: 'Grade',
    sortKey: 'GRADE',
  },
];

export const EnrolledTableSection = () => {
  const [unenrollModalIsOpen, setUnenrollModalIsOpen] = useState(false);
  const [enrolled, setEnrolled] = useState(null);
  const [orderBy, setOrderBy] = useState('NAME');
  const [order, setOrder] = useState('DESC');

  const { enrolledList, useRefetchTestersData } = useTestInfo();
  const [unenrollTestTester] = useUnenrollTestTester(useRefetchTestersData);

  useEffect(() => {
    useRefetchTestersData({
      sort: {
        type: order,
        field: orderBy,
      },
    });
  }, [order, orderBy]);

  const handleRequestSort = useCallback(
    (property) => () => {
      const isAsc = orderBy === property && order === 'ASC';
      setOrder(isAsc ? 'DESC' : 'ASC');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const toggleUnenrollUser = (_enrolled) => {
    setUnenrollModalIsOpen((prev) => !prev);
    setEnrolled(_enrolled);
  };

  const handleUnenrollUser = useCallback(
    (text) => {
      unenrollTestTester({
        variables: {
          testUserId: enrolled.id,
          unEnrollTestTester: text,
        },
      });
    },
    [enrolled],
  );

  const options = [
    {
      title: 'Unenroll',
      callback: toggleUnenrollUser,
    },
  ];

  if (enrolledList?.length === 0 || !enrolledList) {
    return <EmptyTableBanner text='Add dem testers, gurl' />;
  }

  return (
    <StyledTable>
      <TableHeader
        columns={COLUMNS}
        orderBy={orderBy}
        order={order}
        createSortHandler={handleRequestSort}
        sxTableRow={{
          '& .MuiTableCell-root:nth-of-type(3)': {
            width: '60px',
          },
        }}
      />
      <TableBody>
        {enrolledList?.map((_enrolled) => (
          <TableRow key={_enrolled.id}>
            <TableCell>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <NoteIcon />
                <Box
                  sx={{
                    paddingLeft: '10px',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: '700',
                      color: '#000000',
                    }}
                  >
                    {_enrolled.user.contactName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                    }}
                  >
                    {_enrolled.testerStatus === 'START_TESTING' ? (
                      <Typography
                        sx={{
                          fontSize: '12px',
                        }}
                      >
                        Started {formatDataEnUs(_enrolled.startedDate)}
                      </Typography>
                    ) : (
                      'Not started'
                    )}
                  </Typography>
                </Box>
              </Box>
            </TableCell>
            <TableCell>{_enrolled.imagesCount}</TableCell>
            <TableCell>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <TesterTableStatus tester={_enrolled} />
                <OptionsMenu
                  target={_enrolled}
                  options={options}
                  wrapperOptions={{
                    marginLeft: '12px',
                  }}
                />
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <UnenrollModal
        user={enrolled?.user}
        open={unenrollModalIsOpen}
        toggleModal={toggleUnenrollUser}
        callback={handleUnenrollUser}
      />
    </StyledTable>
  );
};
