import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import { Flex } from '../common/CommonStyledComponents';
import { style } from './style';

export const InviteToTestHeader = ({ text, inviteAccept, inviteReject }) => {
  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        paddingBottom: '20px',
      }}
    >
      <Typography
        sx={{
          ...style.headerText,
          marginBottom: '20px',
        }}
      >
        {text}
      </Typography>
      <Flex
        sx={{
          marginBottom: '20px',
          '@media(max-width: 390px)': {
            flexDirection: 'column',
          },
        }}
      >
        <Button
          variant='contained'
          color='primary'
          sx={{
            ...style.button,
            '@media(max-width: 390px)': {
              width: '100%',
            },
          }}
          onClick={inviteAccept}
        >
          {'Sign Me Up!'}
        </Button>
        <Button
          variant='outlined'
          sx={{
            ...style.button,
            marginLeft: '12px',
            '@media(max-width: 390px)': {
              width: '100%',
              margin: '10px 0px 0px 0px',
            },
          }}
          onClick={inviteReject}
        >
          {'Sorry, Can’t This Time'}
        </Button>
      </Flex>
    </Flex>
  );
};

InviteToTestHeader.propTypes = {
  text: PropTypes.string.isRequired,
  inviteAccept: PropTypes.func.isRequired,
  inviteReject: PropTypes.func.isRequired,
};
