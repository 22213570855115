import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { Flex, StyledPageContainer } from 'src/components/common/CommonStyledComponents';

export const PageNotMatch = () => (
  <StyledPageContainer
    sx={{
      py: 10,
    }}
  >
    <Flex
      gap={5}
      sx={{
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Typography variant='h1'>404</Typography>
      <Typography>Page Not Found</Typography>
      <NavLink to='/'>Back to Home</NavLink>
    </Flex>
  </StyledPageContainer>
);
