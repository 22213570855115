import { Backdrop } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { Flex, StyledLabel } from 'src/components/common/CommonStyledComponents';
import { styles } from 'src/components/userProfile/testerTabs/styles';
import { testerCreationForm } from 'src/styles';
import { convertCmToFeetInches, convertFeetInchesToCm } from 'src/utils/helpers';

import { detailsTabStyles } from './backDropStyles';

export const DetailsHeight = ({ testerProfile }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const {
    values = {},
    handleChange,
    setFieldValue,
    errors = {},
    handleSubmit,
  } = useFormikContext();

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      handleSubmit();
    }
  };

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };

  const handleChangeFeet = (e) => {
    handleChange(e);
    setFieldValue(
      'heightMetric',
      convertFeetInchesToCm({
        feet: e.target.value,
        inches: values.heightImperialInch,
      }),
    );
  };

  const handleChangeInches = (e) => {
    handleChange(e);
    setFieldValue(
      'heightMetric',
      convertFeetInchesToCm({
        feet: values.heightImperialFeet,
        inches: e.target.value,
      }),
    );
  };

  const closeAndSubmit = () => {
    setIsEditMode((prev) => !prev);
    handleSubmit();
  };

  const handleChangeCmToFeetInches = (e) => {
    handleChange(e);

    const data = convertCmToFeetInches({
      cm: e.target.value,
    });

    setFieldValue('heightImperialFeet', data.feet);
    setFieldValue('heightImperialInch', data.inches);
  };

  return (
    <Box>
      {isEditMode ? (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '10px',
          }}
        >
          <Backdrop
            open={isEditMode}
            invisible={false}
            onClick={closeAndSubmit}
            sx={detailsTabStyles.backDropStyle}
          />
          <StyledLabel
            label='Height (Imperial)'
            sx={{
              fontSize: '14px',
            }}
          >
            <Flex gap={1}>
              <TextField
                type='number'
                name='heightImperialFeet'
                size='small'
                sx={testerCreationForm.input}
                onInput={handleChangeFeet}
                value={values.heightImperialFeet}
                error={!!errors.heightImperialFeet}
                helperText={errors.heightImperialFeet}
                placeholder='Feet'
                onKeyDown={keyDownHandler}
              />
              <TextField
                type='number'
                name='heightImperialInch'
                size='small'
                sx={testerCreationForm.input}
                onInput={handleChangeInches}
                value={values.heightImperialInch}
                error={!!errors.heightImperialInch}
                helperText={errors.heightImperialInch}
                placeholder='Inches'
                onKeyDown={keyDownHandler}
              />
            </Flex>
          </StyledLabel>
          <StyledLabel
            label='Height (Metric)'
            sx={{
              fontSize: '14px',
            }}
          >
            <TextField
              type='number'
              name='heightMetric'
              size='small'
              sx={testerCreationForm.input}
              onInput={handleChangeCmToFeetInches}
              value={values.heightMetric}
              error={!!errors.heightMetric}
              helperText={errors.heightMetric}
              placeholder='cm'
              onKeyDown={keyDownHandler}
            />
          </StyledLabel>
        </Box>
      ) : (
        <Box onClick={changeViewBox}>
          <StyledLabel
            label={
              testerProfile ? (
                <Box sx={styles.required}>
                  Height <span>*</span>
                </Box>
              ) : (
                'Height'
              )
            }
            sx={detailsTabStyles.styledLabelFont}
          />
          <Box sx={detailsTabStyles.styleForResultDetail}>
            {values.heightImperialFeet !== null ||
            values.heightImperialInch !== null ||
            values.heightMetric !== null ? (
              `${values.heightImperialFeet || '0'}'
                ${values.heightImperialInch || '0'}"
                (${values.heightMetric || '0'}cm)`
            ) : (
              <Box color='#444444'>Select height</Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
