import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
// eslint-disable-next-line prettier/prettier
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { rightPanel } from 'src/styles';

import * as styles from './styles';

export const DetailInput = ({
  value,
  name,
  handleChange,
  multiline = false,
  type = 'text',
  InputProps = {},
  adornment,
  units = '',
  textTransform = '',
  textStyle,
  isEditableByDefault,
  renderTextValue,
  submitOnValueChange = false,
  placeholder = 'none',
}) => {
  const { initialValues, handleSubmit, isSubmitting, setFieldTouched } = useFormikContext();
  const [isEditable, setIsEditable] = useState(isEditableByDefault);
  const [stateValue, setStateValue] = useState({
    target: {
      value,
    },
  });
  const isTheSameValue = initialValues[name] === value;

  useEffect(() => {
    if (!isTheSameValue && !isEditable && submitOnValueChange) {
      if (!isSubmitting) handleSubmit();
    }
  }, [isTheSameValue, isEditable, submitOnValueChange, isSubmitting]);

  const toggle = useCallback(() => {
    setIsEditable((prev) => !prev);
    setStateValue(null);
    setFieldTouched(name, !isEditable);
  }, [isEditable]);

  const textValue = useMemo(() => {
    if (typeof renderTextValue === 'function') {
      return renderTextValue(value, units);
    }

    return value ? `${value} ${units}` : <span>{placeholder}</span>;
  }, [renderTextValue, value, units, placeholder]);

  if (!isEditable) {
    return (
      <Flex
        onClick={toggle}
        sx={[
          {
            padding: '3px 0',
          },
          styles.textHover,
        ]}
      >
        {adornment}
        <Typography
          sx={[
            {
              overflowWrap: 'anywhere',
              ml: adornment ? 3 : 0,
              textTransform: textTransform || 'unset',
              fontSize: '14px',
              lineHeight: '20px',
              whiteSpace: 'pre-wrap',
              color: '#000000',
              span: {
                color: '#444444',
              },
            },
            textStyle,
          ]}
        >
          {textValue}
        </Typography>
      </Flex>
    );
  }

  const handleClickOk = () => {
    if (stateValue) handleChange(stateValue);
    toggle();
  };

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <TextField
        sx={{
          width: '100%',
        }}
        name={name}
        onChange={setStateValue}
        type={type}
        multiline={multiline}
        defaultValue={value}
        InputProps={{
          sx: {
            minHeight: multiline ? '151px' : 0,
            pb: multiline ? 10 : 5,
            alignItems: 'start',
            fontSize: 14,
          },
          ...InputProps,
        }}
      />

      <Flex sx={rightPanel.button.root}>
        <Typography role='button' onClick={handleClickOk} sx={rightPanel.button.submit}>
          Ok
        </Typography>
        <Typography role='button' onClick={toggle} sx={rightPanel.button.cancel}>
          Cancel
        </Typography>
      </Flex>
    </Box>
  );
};

DetailInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  handleChange: PropTypes.func,
  renderTextValue: PropTypes.func,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  InputProps: PropTypes.instanceOf(Object),
  adornment: PropTypes.node,
  units: PropTypes.string,
  textTransform: PropTypes.string,
  textStyle: PropTypes.instanceOf(Object),
  isEditableByDefault: PropTypes.bool,
  submitOnValueChange: PropTypes.bool,
};
