import { Backdrop } from '@mui/material';
import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { instanceOf } from 'prop-types';
import { useState } from 'react';
import { DetailLabel } from 'src/components/index';
import { DetailSelectMultiple } from 'src/components/UI/detail/DetailSelectMultiple';
import {
  handleSetMultipleValues,
  handleShoeSizeValue,
  handleShoeSizeValueOrFallback,
} from 'src/utils/helpers';

import { getShoeSizeOptions } from '../getShoeSizeOptions';
import { detailsTabStyles } from './backDropStyles';

export const DetailsHikingBootSize = ({ possibleValues, submitOnValueChange = true }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { values = {}, setFieldValue } = useFormikContext();
  const { shoeSizeOptions } = possibleValues;

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };

  if (!shoeSizeOptions) return null;

  return (
    <DetailLabel label='Hiking Boot Size'>
      <Box onClick={changeViewBox}>
        <Backdrop open={isEditMode} invisible={false} sx={detailsTabStyles.backDropStyle} />
        <DetailSelectMultiple
          name='hikingShoeSizes'
          handleChange={handleSetMultipleValues(setFieldValue)}
          value={values.hikingShoeSizes}
          placeholder='Select hiking boot size'
          renderTextValue={handleShoeSizeValueOrFallback(
            shoeSizeOptions,
            'Select hiking boot size',
          )}
          renderValue={handleShoeSizeValue(shoeSizeOptions)}
          wrapItems
          multiple
          submitOnValueChange={submitOnValueChange}
        >
          {getShoeSizeOptions({
            possibleValues,
          })}
        </DetailSelectMultiple>
      </Box>
    </DetailLabel>
  );
};

DetailsHikingBootSize.propTypes = {
  possibleValues: instanceOf(Object),
};
