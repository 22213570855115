import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { matchRoutes, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { LogoIcon } from 'src/components/UI/icons/LogoIcon';
import { useAuthContext } from 'src/hooks/useAuth';
import { theme } from 'src/theme';

import { styles } from './style';

export const OtherUserRoleNavBar = ({ pages }) => {
  const { isAuth, handleLogout, user } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  const handleToProfile = () => {
    navigate('/profile');
  };

  const routes = useMemo(
    () =>
      pages?.map((route) => ({
        path: route?.navTo,
      })),
    [pages],
  );

  const showInitials = useMemo(() => {
    if (user?.firstName && user?.lastName) {
      return `${user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}`;
    }
    return user?.email[0].toUpperCase();
  }, [user?.lastName, user?.firstName, user?.email]);

  const useCurrentPath = () => {
    const route = matchRoutes(routes, location);

    return route ? route[0].route.path : null;
  };

  const isMenuVisiable = useMemo(
    () => pages?.some((item) => useCurrentPath(location.pathname) === item?.navTo),
    [pages, location.pathname],
  );

  return (
    <Box>
      <AppBar position='static' sx={styles.appBar}>
        <Box sx={styles.appBarContent}>
          <Toolbar>
            <Box sx={styles.headerContainer}>
              <Box sx={styles.headerContentLeft}>
                <Box sx={styles.navIcon}>
                  <LogoIcon />
                </Box>
                {isAuth && (
                  <List sx={styles.navMenu}>
                    {pages.map((page) => (
                      <ListItem key={page.navTo}>
                        <Box sx={styles.navLink}>
                          <NavLink
                            to={page.navTo}
                            className={(navData) => (navData.isActive ? 'activeMenuLink' : '')}
                          >
                            {page?.title}
                          </NavLink>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>

              {isAuth && (
                <Box>
                  {isMenuVisiable ? (
                    <IconButton onClick={handleToProfile} sx={styles.userBtn}>
                      <Avatar
                        sx={{
                          backgroundColor: theme.colors.blue,
                        }}
                        alt='avatar'
                        src={`${process.env.REACT_APP_DOMAIN}/${user.imageProfileLink}`}
                      >
                        {showInitials}
                      </Avatar>
                    </IconButton>
                  ) : (
                    <Button variant='text' color='secondary' onClick={handleLogout}>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 700,
                        }}
                      >
                        Sign Out
                      </Typography>
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </Box>
  );
};

OtherUserRoleNavBar.propTypes = {
  pages: PropTypes.instanceOf(Array),
};
