/* eslint-disable object-curly-newline */
import Box from '@mui/material/Box';
import { func, instanceOf } from 'prop-types';
import { DetailLabel, DetailSelect } from 'src/components';
import { YesOrNotText } from 'src/constants/constants';
import { booleanToString, stringToBoolean } from 'src/utils/helpers';

import { ActivitiesTypeGear } from './ActivitiesTypeGear';

export const Activities = ({
  activities = [],
  otherActivities = [],
  frequencyValues = [],
  handleChange,
  handleSubmit,
  possibleValues,
  values = {},
}) => {
  const handleBoolChange = (e) => {
    const {
      target: { value, name },
    } = e;

    handleChange({
      target: {
        name,
        value: stringToBoolean(value),
      },
    });
  };
  return (
    <Box>
      <DetailLabel label='Do you work for an outdoor company?'>
        <DetailSelect
          label='if you work for an outdoor company'
          name='isWorkForAnOutdoorCompany'
          handleChange={handleBoolChange}
          value={booleanToString(values.isWorkForAnOutdoorCompany)}
          possibleValues={YesOrNotText}
          submitOnValueChange={true}
          placeholder='Select yes or no'
        />
      </DetailLabel>
      <DetailLabel label='Do you have a dog?'>
        <DetailSelect
          label='if you have a dog'
          name='isHaveDog'
          handleChange={handleBoolChange}
          value={booleanToString(values.isHaveDog)}
          possibleValues={YesOrNotText}
          submitOnValueChange={true}
          placeholder='Select yes or no'
        />
      </DetailLabel>
      <DetailLabel label='Do you recreate with kids?'>
        <DetailSelect
          label='if you recreate with kids'
          name='isRecreateWithKids'
          handleChange={handleBoolChange}
          value={booleanToString(values.isRecreateWithKids)}
          possibleValues={YesOrNotText}
          submitOnValueChange={true}
          placeholder='Select yes or no'
        />
      </DetailLabel>
      <ActivitiesTypeGear
        possibleValues={possibleValues}
        label='What type of gear would you like to test?'
      />
      {activities.map(({ title, value }) => (
        <DetailLabel label={title} key={value}>
          <DetailSelect
            label={title.toLowerCase()}
            name={value}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            value={values[value]}
            possibleValues={frequencyValues}
            submitOnValueChange={true}
          />
        </DetailLabel>
      ))}
      {otherActivities.map(({ title, value }, index) => (
        <DetailLabel label={title} key={value}>
          <DetailSelect
            label={title.toLowerCase()}
            name={`otherActivities.${index}.value`}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            value={values.otherActivities?.[index].value}
            possibleValues={frequencyValues}
            submitOnValueChange={true}
          />
        </DetailLabel>
      ))}
    </Box>
  );
};

Activities.propTypes = {
  activities: instanceOf(Array),
  otherActivities: instanceOf(Array),
  frequencyValues: instanceOf(Array),
  handleChange: func,
  handleSubmit: func,
  values: instanceOf(Object),
};
