import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { instanceOf } from 'prop-types';
import { useCallback, useState } from 'react';
import { PenIcon } from 'src/components';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { TestStatus } from 'src/constants/constants';
import { useCloseTest, useGetTest } from 'src/hooks';
import { theme } from 'src/theme';

import { AddReportModal, EditReportModal, ReportRemoveModal } from '../reports';
import { reportStyle } from '../reports/style';

export const Reports = ({ values }) => {
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [removeReportId, setRemoveReportId] = useState(null);
  const [editReport, setEditReport] = useState(null);
  const toggleAddModal = useCallback(() => setAddModalIsOpen((prev) => !prev), []);
  const closeTest = useCloseTest();

  const { testData } = useGetTest();

  const visibilityMap = useCallback(
    ({ visibility, id }) => {
      const report = values.reports.find((item) => item.id === id);
      const users = report.visibilityTestUsers.map(({ user }) => user.contactName);
      const tester = users?.join(', ') || 'Custom';

      return {
        EVERYONE: 'Everyone',
        CUSTOMER: 'Customer',
        ALL_TESTERS: 'All Testers',
        TESTER: tester,
      }[visibility];
    },
    [values.reports],
  );

  const openRemoveModal = useCallback(
    (reportId) => () => {
      setRemoveReportId(reportId);
    },
    [],
  );
  const closeRemoveModal = useCallback(() => setRemoveReportId(null), []);

  const openEditModal = useCallback(
    (report) => () => {
      setEditReport(report);
    },
    [],
  );
  const closeEditModal = useCallback(() => setEditReport(null), []);

  const hanndlePostReportsAndCloseTest = () => {
    closeTest({
      isPostReports: true,
    });
  };

  return (
    <Flex sx={reportStyle.root.container}>
      <Flex
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant='contained'
          color='primary'
          sx={reportStyle.button.add}
          onClick={toggleAddModal}
          disabled={testData.status === TestStatus.CLOSED}
        >
          Add Report
        </Button>
        <Button
          sx={reportStyle.button.add}
          variant='contained'
          onClick={hanndlePostReportsAndCloseTest}
          disabled={!values.reports.length || testData.status === TestStatus.CLOSED}
        >
          Post Report &amp; Close Test
        </Button>
      </Flex>
      <Box
        mt={7}
        sx={{
          width: '100%',
        }}
      >
        {values.reports?.map((report) => (
          <Box
            key={report.id}
            sx={{
              mt: 3,
            }}
          >
            <Typography fontSize={14} fontWeight={700} color={theme.colors.tertiary}>
              {report.linkName}
            </Typography>
            <Typography
              fontSize={14}
              sx={{
                mt: 2,
              }}
            >
              {`Visibility: ${visibilityMap(report)}`}
            </Typography>
            <Flex
              sx={{
                mt: 2,
              }}
            >
              <Flex
                sx={{
                  cursor: 'pointer',
                }}
                onClick={openEditModal(report)}
              >
                <PenIcon />
                <Typography sx={reportStyle.button} mr={4} ml={1}>
                  Edit
                </Typography>
              </Flex>
              <Typography
                sx={reportStyle.button}
                onClick={openRemoveModal(report.id)}
                color={theme.colors.error}
              >
                Remove
              </Typography>
            </Flex>
          </Box>
        ))}
      </Box>
      <AddReportModal open={addModalIsOpen} toggleModal={toggleAddModal} />
      <EditReportModal open={!!editReport} initialValues={editReport} closeModal={closeEditModal} />
      <ReportRemoveModal
        open={!!removeReportId}
        reportId={removeReportId}
        closeModal={closeRemoveModal}
      />
    </Flex>
  );
};
Reports.propTypes = {
  values: instanceOf(Object),
};
