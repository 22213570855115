import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { CloseIcon } from 'src/components/UI/icons';
import { useCreateBrand, useGetBrands } from 'src/hooks';
import { useToasts } from 'src/hooks/useToasts';

import styles from './styles';

const filter = createFilterOptions();

export const BrandAutocomplete = ({
  name,
  sx,
  value,
  setFieldValue,
  onBlur,
  error,
  helperText,
}) => {
  const { getBrands, brands } = useGetBrands();
  const [createBrand] = useCreateBrand();
  const { addToast } = useToasts();

  const valueObject = useMemo(() => brands.find(({ id }) => id === value), [value, brands]);

  const handleInput = useCallback(({ target }) => {
    getBrands({
      variables: {
        search: target.value,
      },
    });
  }, []);

  const filterOptions = (values, params) => {
    const filtered = filter(values, params);

    const { inputValue } = params;
    const isExisting = values.some(
      (option) => inputValue.toLowerCase() === option.name.toLowerCase(),
    );
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        name: 'Add New Brand',
      });
    }

    return filtered;
  };

  const handleClearButton = (event, newValue, reason) => {
    if (reason === 'clear') {
      getBrands({
        variables: {
          search: null,
        },
      });
    }
  };

  const onChange = useCallback(
    (event, newValue) => {
      if (typeof newValue === 'string') {
        setFieldValue(name, {
          name: newValue.id,
        });
      } else if (newValue?.inputValue) {
        createBrand({
          variables: {
            name: newValue.inputValue,
          },
          onCompleted: (res) => {
            setFieldValue(name, res.createBrand.id);
          },
          onError: () => {
            addToast({
              message: 'Brand Error. Try again.',
              timer: 6000,
              variant: 'error',
            });
          },
        });
      } else {
        // handling null also
        setFieldValue(name, newValue?.id || newValue);
      }
    },
    [name, setFieldValue],
  );

  const getOptionLabel = useCallback((option) => {
    if (typeof option === 'string') {
      return option;
    }

    if (option.inputValue) {
      return option.inputValue;
    }

    return option.name;
  }, []);

  const renderOption = useCallback((props, option) => {
    if (option.name === 'Add New Brand') {
      return (
        <MenuItem {...props} key={option.id} sx={styles.addBrand}>
          {option.name}
        </MenuItem>
      );
    }
    return (
      <MenuItem {...props} key={option.id}>
        {option.name}
      </MenuItem>
    );
  }, []);

  return (
    <Autocomplete
      onInputChange={handleClearButton}
      name={name}
      value={valueObject}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      options={brands}
      onChange={onChange}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      onBlur={onBlur}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          placeholder='Enter Brand'
          onInput={handleInput}
          error={error}
          helperText={helperText}
        />
      )}
      clearIcon={<CloseIcon />}
      sx={[styles.autocomplete, sx]}
      componentsProps={{
        paper: {
          sx: styles.autocompleteMenu,
        },
      }}
    />
  );
};

BrandAutocomplete.propTypes = {
  sx: PropTypes.instanceOf(Object),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  setFieldValue: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
