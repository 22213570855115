export const style = {
  container: {
    maxWidth: '784px',
    padding: '32px 0px',
    width: '100%',
    margin: '0 auto',
  },
  button: {
    fontSize: '14px',
    height: '32px',
    borderRadius: '24px',
    whiteSpace: 'nowrap',
  },
  headerText: {
    fontSize: '16px',
    fontWeight: 700,
  },
  headerTextRecponce: {
    fontSize: '44px',
    fontWeight: 700,
    paddingBottom: '16px',
  },
  textRecponce: {
    fontSize: '24px',
    paddingBottom: '16px',
  },
};
