import Box from '@mui/material/Box';
import { func, instanceOf } from 'prop-types';
import { DetailInput, DetailLabel, DetailSelect } from 'src/components';
import { text } from 'src/styles';

export const Details = ({ values = {}, handleChange, possibleValues }) => (
  <Box>
    <DetailLabel label='Brand'>
      <DetailSelect
        label='Brand'
        name='brandId'
        handleChange={handleChange}
        value={values.brandId}
        placeholder='Select Brand'
        possibleValues={possibleValues.brands}
      />
    </DetailLabel>
    <DetailLabel label='Email'>
      <DetailInput
        textStyle={text.tertiaryBold}
        name='email'
        handleChange={handleChange}
        value={values.email}
        placeholder='Enter Email'
      />
    </DetailLabel>
  </Box>
);

Details.propTypes = {
  values: instanceOf(Object),
  handleChange: func,
  possibleValues: instanceOf(Object),
};
