import dayjs from 'dayjs';

export const daysInMonth = (date) => {
  const fomattedDate = date?.replaceAll?.('-', '/');
  const year = new Date(fomattedDate || null).getFullYear();
  const month = new Date(fomattedDate || null).getMonth();

  const numberOfDaysInMonth = new Date(year, month + 1, 0).getDate();

  const arrDays = Array.from(Array(numberOfDaysInMonth + 1).keys()).map((item) => {
    if (item < 10) {
      return `0${item}`;
    }
    return item.toString();
  });

  arrDays.shift();

  return arrDays;
};

export const getValueDay = (numberOfDay) => {
  const date = dayjs(numberOfDay).get('date');
  return date < 10 ? `0${date}` : date;
};
