import './loader.css';

import styled from '@mui/system/styled';
import { bool } from 'prop-types';

const Wrapper = styled('div')`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 28px;
  padding: 22px;
  width: 200px;
  height: 200px;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

export const Loader = ({ loading }) =>
  loading ? (
    <Wrapper>
      <div className='dot1' />
      <div className='dot2' />
      <div className='dot3' />
    </Wrapper>
  ) : null;

Loader.propTypes = {
  loading: bool,
};
