import { useCallback, useEffect, useState } from 'react';
import { Loader, TablePagination } from 'src/components/UI';
import { PaginationSection } from 'src/components/UI/tables';
import { useFilter } from 'src/hooks';
import { useGetTesterById, useGetTestersQuery } from 'src/hooks/useQueries';
import { prepareFilterData } from 'src/utils/helpers';

import { FormikWrapperCreateTester } from './FormikWrapperCreateTester';
import { TableUI } from './TableUI';

export const TestersTable = () => {
  const { filters } = useFilter();
  const { tester, getTester } = useGetTesterById();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(16);
  const [orderBy, setOrderBy] = useState('contactName');
  const [order, setOrder] = useState('DESC');

  const { search, ...other } = filters;

  const queryOptions = {
    limit: rowsPerPage,
    offset: rowsPerPage * (currentPage - 1),
    testerFilter: prepareFilterData(other),
    search,
    Sort: {
      type: order,
      field: orderBy,
    },
  };

  useEffect(() => {
    // We need to reset current page to its initial value every time filters change
    setCurrentPage(1);
  }, [filters]);

  const { testers, testersCount, loading, fetchMore } = useGetTestersQuery({
    variables: {
      options: queryOptions,
    },
  });

  const isTestersTableEmpty = !testers?.length || !testers;

  const [duplicationModal, setDuplicationModal] = useState(false);

  const toggleDuplicationModal = () => setDuplicationModal((prev) => !prev);

  const handleChangePage = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleChangeRowsPerPage = useCallback((value) => {
    setRowsPerPage(value);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    fetchMore({
      variables: {
        options: queryOptions,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          getTesters: fetchMoreResult.getTesters,
        };
      },
    });
  }, [queryOptions]);

  if (loading) return <Loader loading />;

  return (
    <>
      <TablePagination
        count={testersCount}
        currentPageRowsCount={testers.length}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <TableUI
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        getTester={getTester}
        toggleDuplicationModal={toggleDuplicationModal}
      />
      <FormikWrapperCreateTester
        open={duplicationModal}
        tester={tester}
        duplicate
        toggleModal={toggleDuplicationModal}
      />

      {isTestersTableEmpty ? null : (
        <PaginationSection
          page={currentPage}
          count={testersCount && Math.ceil(testersCount / rowsPerPage)}
          onChange={handleChangePage}
        />
      )}
    </>
  );
};
