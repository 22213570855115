import Container from '@mui/material/Container';
import Pagination from '@mui/material/Pagination';
import styled from '@mui/system/styled';
import { func, number } from 'prop-types';
import { useCallback } from 'react';

const StyledContainer = styled(Container)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 34px;
  margin-bottom: 34px;
  max-width: 1376px;
`;

export const PaginationSection = ({ count, onChange, page }) => {
  const handleChange = useCallback((evt, value) => {
    evt.stopPropagation();
    onChange(value);
  }, []);

  return (
    <StyledContainer>
      <Pagination
        page={page}
        count={count}
        onChange={handleChange}
        shape='rounded'
        color='primary'
      />
    </StyledContainer>
  );
};

PaginationSection.propTypes = {
  count: number,
  onChange: func,
  page: number,
};
