import { ControlBar } from '../UI';
import { AdminsTable } from './adminsTable/AdminsTable';

export const AdminsContainer = () => {
  return (
    <>
      <ControlBar header='MERG Admins' isButtonVisiable={false} />
      <AdminsTable />
    </>
  );
};
