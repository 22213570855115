import styled from '@mui/system/styled';
import PropTypes from 'prop-types';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const Span = styled('span')(({ theme }) => ({
  color: theme.colors.neutral.dark.extra,
  fontWeight: 700,
}));

export const DetailLabel = ({ label = '', children }) => (
  <Wrapper
    sx={{
      mb: 5,
    }}
  >
    <Span
      sx={{
        mb: 1,
        fontSize: 14,
      }}
    >
      {label}
    </Span>
    {children}
  </Wrapper>
);

DetailLabel.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};
