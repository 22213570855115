import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import { bool, func } from 'prop-types';
import { useEffect, useMemo } from 'react';
import { AddressSelect, DetailLabel } from 'src/components';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { styles } from 'src/components/userProfile/testerTabs/styles';
import { useGeo } from 'src/hooks/useGeo';
import { rightPanel } from 'src/styles';

import { addressFieldsStyle } from './styles';

export const DetailAddressFields = ({ isEditable, toggle, testerProfile }) => {
  const { values, handleChange, handleSubmit, setFieldValue, initialValues } = useFormikContext();

  const { countries, states, setCountryId } = useGeo();

  useEffect(() => {
    setCountryId(values.country?.id);
  }, [values.country]);

  useEffect(() => {
    if (values.country === initialValues.country?.id) return false;
    if (initialValues.country?.id !== values.country?.id) {
      setFieldValue('state', '');
    }
    return true;
  }, [values.country]);

  const isAllAddressesShown = useMemo(() => {
    const addressData = Object.entries(values);
    const missingAllAddressInfo = addressData.every(([, addressValue]) => !addressValue);

    return missingAllAddressInfo ? (
      <Typography onClick={toggle} sx={addressFieldsStyle.noneField}>
        Enter address
      </Typography>
    ) : (
      addressData.map(([key, value]) => (
        <Typography key={key} sx={addressFieldsStyle.description} onClick={toggle}>
          {value?.name || value || <Box sx={addressFieldsStyle.emptyAddress} />}
        </Typography>
      ))
    );
  }, [values]);

  return (
    <DetailLabel
      label={
        testerProfile ? (
          <Box sx={styles.required}>
            Address <span>*</span>
          </Box>
        ) : (
          'Address'
        )
      }
      sx={addressFieldsStyle.description}
    >
      {isEditable ? (
        <Flex
          sx={{
            position: 'relative',
            flexWrap: 'wrap',
            paddingBottom: 6,
            '& *': {
              flexBasis: '100%',
            },
          }}
        >
          <TextField
            sx={rightPanel.addressField}
            name='addressOne'
            onChange={handleChange}
            value={values.addressOne || ''}
            placeholder='Enter Address 1'
          />
          <TextField
            sx={rightPanel.addressField}
            name='addressTwo'
            onChange={handleChange}
            value={values.addressTwo || ''}
            placeholder='Enter Address 2'
          />
          <AddressSelect
            sx={rightPanel.select}
            label='Country'
            value={values.country || ''}
            name='country'
            possibleValues={countries}
            handleChange={setFieldValue}
            placeholder='Select Country'
          />
          <AddressSelect
            sx={rightPanel.select}
            label='State'
            value={values.state || ''}
            name='state'
            possibleValues={states}
            handleChange={setFieldValue}
            placeholder='Select State'
          />
          <TextField
            sx={rightPanel.addressField}
            name='city'
            placeholder='Enter City'
            onChange={handleChange}
            value={values.city || ''}
          />
          <TextField
            sx={rightPanel.addressField}
            name='postalCode'
            placeholder='Enter Postal Code'
            onChange={handleChange}
            value={values.postalCode || ''}
          />
          <Flex
            sx={[
              rightPanel.button.root,
              {
                p: 0,
              },
            ]}
          >
            <Box role='button' type='submit' sx={rightPanel.button.submit} onClick={handleSubmit}>
              Ok
            </Box>
            <Typography role='button' onClick={toggle} sx={rightPanel.button.cancel}>
              Cancel
            </Typography>
          </Flex>
        </Flex>
      ) : (
        <>{isAllAddressesShown}</>
      )}
    </DetailLabel>
  );
};

DetailAddressFields.propTypes = {
  isEditable: bool,
  toggle: func,
};
