import { theme } from 'src/theme';

const removeModal = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 'max-content',
    border: `16px solid ${theme.colors.neutral.white}`,
    borderRadius: 2,
    overflow: 'hidden',
    textAlign: 'center',
  },
  container: {
    borderRadius: 2,
    overflow: 'hidden',
    padding: 6,
    backgroundColor: theme.colors.neutral.black,
  },
  button: {
    cancel: {
      color: theme.colors.neutral.white,
      mr: 4,
    },
    submit: {
      backgroundColor: theme.colors.error,
      py: 1,
      color: theme.colors.neutral.black,
      '&:hover': {
        backgroundColor: theme.colors.error,
        opacity: 0.8,
      },
    },
  },
};

export default removeModal;
