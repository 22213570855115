import SvgIcon from '@mui/material/SvgIcon';

export const File = () => (
  <SvgIcon
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19.0842 7.21276L14.4728 2.60133C14.3442 2.47276 14.1707 2.3999 13.9885 2.3999H4.88567C4.50638 2.3999 4.19995 2.70633 4.19995 3.08562V20.9142C4.19995 21.2935 4.50638 21.5999 4.88567 21.5999H18.6C18.9792 21.5999 19.2857 21.2935 19.2857 20.9142V7.69919C19.2857 7.51704 19.2128 7.34133 19.0842 7.21276ZM17.7042 8.01419H13.6714V3.98133L17.7042 8.01419ZM17.7428 20.057H5.74281V3.94276H12.2142V8.57133C12.2142 8.81003 12.3091 9.03894 12.4778 9.20773C12.6466 9.37651 12.8755 9.47133 13.1142 9.47133H17.7428V20.057ZM12.4285 11.1428C12.4285 11.0485 12.3514 10.9713 12.2571 10.9713H11.2285C11.1342 10.9713 11.0571 11.0485 11.0571 11.1428V13.457H8.74281C8.64852 13.457 8.57138 13.5342 8.57138 13.6285V14.657C8.57138 14.7513 8.64852 14.8285 8.74281 14.8285H11.0571V17.1428C11.0571 17.237 11.1342 17.3142 11.2285 17.3142H12.2571C12.3514 17.3142 12.4285 17.237 12.4285 17.1428V14.8285H14.7428C14.8371 14.8285 14.9142 14.7513 14.9142 14.657V13.6285C14.9142 13.5342 14.8371 13.457 14.7428 13.457H12.4285V11.1428Z'
      fill='#676767'
    />
  </SvgIcon>
);
