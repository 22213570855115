import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BackButton,
  FormikWrapperCreateTester,
  Loader,
  OptionsMenu,
  RemoveModal,
  Status,
  TestersTestsTable,
} from 'src/components';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { FormikWrapperRightPanelTesterDetail } from 'src/components/testerDetail';
import {
  useGetTesterById,
  useGetTesterValues,
  useGetTestsByUserId,
  useRemoveUser,
} from 'src/hooks';
import { theme } from 'src/theme';

import { PageNotMatch } from './PageNotMatch';

const StyledBox = styled(Flex)({
  padding: '40px 48px',
  margin: '0 auto',
  maxWidth: 1360,
  flexDirection: 'column',
  alignItems: 'center',
});

const Grid = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  gap: 77,
  maxWidth: 1285,
  width: '100%',
  marginTop: 40,
});

const TesterDetail = () => {
  const { tester, loading } = useGetTesterById();
  const { data: userTests, refetch } = useGetTestsByUserId();
  const { testerTestStatuses } = useGetTesterValues();
  const [removedUser, setRemovedUser] = useState(null);
  const [duplicationModal, setDuplicationModal] = useState(false);

  const toggleDuplicationModal = () => setDuplicationModal((prev) => !prev);
  const remove = useRemoveUser();
  const navigate = useNavigate();
  const { id } = useParams();
  const toggleModal = useCallback(() => {
    setRemovedUser((prev) => !prev);
  }, []);
  const acceptRemoveUser = useCallback(() => {
    remove({
      id: removedUser,
    }).then(() => {
      navigate('/testers');
    });
  }, [navigate, remove, removedUser]);

  const options = useMemo(
    () => [
      {
        title: 'Duplicate',
        callback: toggleDuplicationModal,
      },
      {
        title: 'Remove user',
        callback: () => {
          setRemovedUser(id);
        },
      },
    ],
    [id],
  );

  const showInitials = useMemo(() => {
    if (tester?.firstName && tester?.lastName) {
      return `${tester.firstName[0].toUpperCase() + tester.lastName[0].toUpperCase()}`;
    }
    return tester?.email[0].toUpperCase();
  }, [tester?.lastName, tester?.firstName, tester?.email]);

  if (loading) return <Loader loading />;
  if (!tester) return <PageNotMatch />;

  return (
    <StyledBox>
      <Flex
        sx={{
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <BackButton navigateStep={1} />
        <Flex>
          <Box
            sx={{
              mr: 3,
            }}
          >
            <Status status={tester?.tester?.testStatus} statuses={testerTestStatuses} />
          </Box>
          <OptionsMenu options={options} />
        </Flex>
      </Flex>
      <Grid>
        <Box>
          <Flex
            sx={{
              alignItems: 'center',
              mb: 6,
            }}
          >
            <Avatar
              sx={{
                width: 60,
                height: 60,
                backgroundColor: theme.colors.blue,
              }}
              alt='avatar'
              src={`${process.env.REACT_APP_DOMAIN}/${tester.imageProfileLink}`}
            >
              {showInitials}
            </Avatar>
            <Typography
              variant='h3'
              sx={{
                ml: 5,
              }}
            >
              {tester.contactName}
            </Typography>
          </Flex>
          <Flex justifyContent='space-between' mb={6}>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              My Tests
            </Typography>
            <Flex>
              <Flex>
                <Typography
                  sx={{
                    fontWeight: 700,
                    mr: 2,
                  }}
                >
                  Tests Completed
                </Typography>
                <Typography>{userTests?.getTestsByUserId?.competedTestCount}</Typography>
              </Flex>
              <Flex
                sx={{
                  ml: 5,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    mr: 2,
                  }}
                >
                  Ongoing Tests
                </Typography>
                <Typography>{userTests?.getTestsByUserId?.ongoingTestCount}</Typography>
              </Flex>
            </Flex>
          </Flex>
          <TestersTestsTable data={userTests?.getTestsByUserId?.rows} refetch={refetch} />
        </Box>
        <Box>
          <FormikWrapperRightPanelTesterDetail testerData={tester} />
        </Box>
      </Grid>
      <FormikWrapperCreateTester
        open={duplicationModal}
        tester={tester}
        duplicate
        toggleModal={toggleDuplicationModal}
      />
      <RemoveModal
        title='Are you sure you want to remove this user?'
        submitLabel='Yes, Remove User'
        open={!!removedUser}
        callback={acceptRemoveUser}
        closeModal={toggleModal}
        itemId={removedUser}
      />
    </StyledBox>
  );
};

export default TesterDetail;
