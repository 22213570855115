import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { number, string } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Chevron } from 'src/components';

export const BackButton = ({ title = '', navigateStep }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-navigateStep);
  };

  return (
    <Button
      onClick={handleClick}
      sx={{
        minWidth: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Chevron size='18' />
      <Typography ml={4} fontWeight={700} fontSize={16}>
        {title}
      </Typography>
    </Button>
  );
};

BackButton.propTypes = {
  title: string,
  navigateStep: number,
};
