import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { arrayOf } from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { styled } from './styled';

const EnrolledHoverView = ({ testers }) => {
  const navigate = useNavigate();

  const handleTesterClick = (id) => () => {
    navigate(`/testers/${id}`);
  };

  return (
    <Box className='enrolledHoverView' sx={styled.styledBox}>
      <Typography sx={styled.labalTest}>Name</Typography>
      {testers?.map((tester) => (
        <Typography
          sx={styled.test}
          key={tester.user.id}
          onClick={handleTesterClick(tester.user.id)}
        >
          {tester.user.contactName}
        </Typography>
      ))}
    </Box>
  );
};
EnrolledHoverView.propTypes = {
  testers: arrayOf(Object),
};

export const EnrolledCell = ({ testers }) => (
  <TableCell sx={styled.hoverTableCell}>
    <Typography className='enrolledHoverViewText' sx={styled.testLengthSyle}>
      {testers.length}
    </Typography>
    {testers.length ? <EnrolledHoverView testers={testers} /> : null}
  </TableCell>
);

EnrolledCell.propTypes = {
  testers: arrayOf(Object),
};
