import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import { theme } from 'src/theme';

import { Flex } from '../../common/CommonStyledComponents';

export const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiPaper-root': {
    borderWidth: '0px',
    outline: 'transparent',
    boxShadow: 'none',
  },
});

export const Container = styled(Flex)({
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: 40,
  marginBottom: 45,
});

export const Footer = styled(Flex)({
  backgroundColor: theme.colors.neutral.light.extra,
  padding: '18px 16px',
  justifyContent: 'space-between',
  gap: 4,
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
});

export const PromtMessage = styled(Typography)({
  marginTop: 28,
  fontSize: 14,
  maxWidth: 160,
  textAlign: 'center',
  color: theme.colors.neutral.dark.mid,
});

export const styles = {
  removeBtn: {
    color: '#DE4141',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
  },
  cancelBtn: {
    color: '#676767',
  },
};
