import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetAvailableTestersQuery, useGetTestPageInfoQuery } from 'src/hooks/useQueries';

const TestersListContext = createContext();

export const TestInfoProvider = ({ children }) => {
  const { id } = useParams();

  const { data: testersData, refetch, loading, called, previousData } = useGetTestPageInfoQuery(id);

  const useRefetchTestersData = useCallback(
    (variables) =>
      refetch({
        ...variables,
        testId: id,
      }),
    [id],
  );

  const candidatesList = useMemo(
    () => testersData?.getCandidatesToTest?.rows || [],
    [testersData?.getCandidatesToTest?.rows],
  );

  const enrolledList = useMemo(
    () => testersData?.getTestersToTest?.rows || [],
    [testersData?.getTestersToTest?.rows],
  );

  const mediaList = useMemo(
    () => testersData?.getTestMediaByTestId?.rows || [],
    [testersData?.getTestMediaByTestId?.rows],
  );
  // TODO Ждать обновлений бэка

  /*  const excludeUserIds = [
    ...candidatesList.map((item) => item.user.id),
    ...enrolledList.map((item) => item.user.id),
  ]; */

  const candidatesStats = useMemo(
    () => [
      {
        title: 'Invited',
        value: testersData?.getCandidatesToTest?.invitedCount,
      },
      {
        title: 'Accepted',
        value: testersData?.getCandidatesToTest?.acceptedCount,
      },
      {
        title: 'Declined',
        value: testersData?.getCandidatesToTest?.declinedCount,
      },
      {
        title: 'Rejected',
        value: testersData?.getCandidatesToTest?.rejectedCount,
      },
      {
        title: 'Enrolled',
        value: testersData?.getCandidatesToTest?.enrolledCount,
      },
    ],
    [testersData?.getCandidatesToTest],
  );

  const enrolledStats = useMemo(
    () => [
      {
        title: 'Not Started',
        value: testersData?.getTestersToTest?.notStartedCount,
      },
      {
        title: 'Started',
        value: testersData?.getTestersToTest?.startedCount,
      },
    ],
    [testersData?.getTestersToTest],
  );

  const [getAvailableTesters, { data: availableTestersData }] = useGetAvailableTestersQuery({
    variables: {
      options: {
        doNotShowUnregistered: true,
      },
    },
  });

  const availableTestersInfo = useMemo(
    () => availableTestersData?.getTesters || {},
    [availableTestersData?.getTesters],
  );

  const value = useMemo(
    () => ({
      availableTestersInfo,
      candidatesList,
      enrolledList,
      candidatesStats,
      enrolledStats,
      getAvailableTesters,
      mediaList,
      useRefetchTestersData,
      loading,
      called,
      previousData,
    }),
    [
      availableTestersInfo,
      candidatesList,
      enrolledList,
      candidatesStats,
      enrolledStats,
      getAvailableTesters,
      mediaList,
      useRefetchTestersData,
      loading,
      called,
      previousData,
    ],
  );

  return <TestersListContext.Provider value={value}>{children}</TestersListContext.Provider>;
};

export const useTestInfo = () => {
  const context = useContext(TestersListContext);
  if (!context) {
    throw new Error('useTestInfo must be used within a TestInfoProvider');
  }
  return context;
};

TestInfoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
