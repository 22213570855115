import PropTypes from 'prop-types';
import { createContext, useContext, useMemo } from 'react';
import Cookies from 'universal-cookie';

import { useCurrentUserQuery } from './useQueries';

const AuthContext = createContext();
const cookies = new Cookies();

export const AuthProvider = ({ children }) => {
  const { user, refetch, client, loading } = useCurrentUserQuery();

  const handleSuccessAuth = () => {
    refetch();
  };

  const handleLogout = async () => {
    cookies.remove('token', {
      path: '/',
    });
    await client.resetStore();
  };

  const value = useMemo(
    () => ({
      isAuth: !!user,
      user,
      loading,
      refetch,
      handleSuccessAuth,
      handleLogout,
    }),
    [user, loading],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
