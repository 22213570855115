import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from '@mui/system/styled';
import PropTypes from 'prop-types';
import { theme } from 'src/theme';

export const StyledTableHead = styled(TableHead)({
  backgroundColor: theme.colors.neutral.light.superExtra,
  '& .MuiTableCell-root': {
    backgroundColor: theme.colors.neutral.light.superExtra,
    zIndex: 0,
  },
});

export const StyledTable = styled(Table)({
  boxShadow: `${theme.colors.neutral.light.normal} 0px 0px 0px 1px`,
  borderRadius: 4,
});

export const StyledTableRow = styled(TableRow)({
  '&:hover': {
    backgroundColor: theme.colors.neutral.light.superExtra,
  },
  '&:last-child .MuiTableCell-root': {
    borderBottom: 'none',
  },
});

export const StyledSubmitButton = ({ children, disabled, onClick }) => (
  <Button
    sx={{
      height: '28px',
      background: disabled ? theme.colors.neutral.light.normal : theme.colors.neutral.black,
      color: disabled ? theme.colors.neutral.black : theme.colors.neutral.white,
    }}
    onClick={onClick}
    disabled={disabled}
    type='submit'
  >
    {children}
  </Button>
);

StyledSubmitButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
