import { bool } from 'prop-types';

export const ArrowLeft = ({ changeOpacity = false }) => (
  <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M41.6001 46.1783L27.6617 32.24L41.6001 18.3016L37.2986 14L19.0586 32.24L37.2986 50.48L41.6001 46.1783Z'
      fill='white'
      fillOpacity={changeOpacity ? '0.25' : '1'}
    />
  </svg>
);

ArrowLeft.propTypes = {
  changeOpacity: bool,
};
