import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { Flex, StyledLabel, StyledSelect } from 'src/components/common/CommonStyledComponents';
import { styles } from 'src/components/userProfile/testerTabs/styles';
import { months, years } from 'src/constants/constants';
import { daysInMonth } from 'src/utils/daysInMonth';

import { detailsTabStyles } from './backDropStyles';

export const DetailsBirthday = ({ testerProfile }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const { values = {}, setFieldValue, errors = {}, handleSubmit } = useFormikContext();

  const [birthDay, setBirthDay] = useState(values?.birthdate);
  const [birthMonth, setBirthMonth] = useState(values?.birthdate);
  const [birthYear, setBirthYear] = useState(values?.birthdate);

  const getMonthValue = () => {
    return birthMonth && dayjs(birthMonth)?.get('month').toString();
  };

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };

  const handleMonth = (e) => {
    const index = months.findIndex((item) => item.value === e.target.value);

    let newDate;

    if (values?.birthdate) {
      newDate = dayjs(values?.birthdate).set('month', index).toDate();
    } else {
      newDate = dayjs().set('month', index).toDate();
    }

    setFieldValue('birthdate', format(newDate, 'MM/dd/yyyy'));
    setBirthMonth(format(newDate, 'MM/dd/yyyy'));
    setBirthDay('');
    setBirthYear('');
  };
  const renderMonthValue = (val) => {
    if (val?.length) {
      return months.find((item) => item.value === val)?.label;
    }
    return val;
  };

  const handleDay = (e) => {
    let newDate;

    if (values?.birthdate) {
      newDate = dayjs(values.birthdate).set('date', e.target.value).toDate();
    } else {
      newDate = dayjs().set('date', e.target.value).toDate();
    }

    setFieldValue('birthdate', format(newDate, 'MM/dd/yyyy'));
    setBirthDay(format(newDate, 'MM/dd/yyyy'));
    setBirthYear('');
  };

  const handleYear = (e) => {
    let newDate;

    if (values?.birthdate) {
      newDate = dayjs(values.birthdate).set('year', e.target.value).toDate();
    } else {
      newDate = dayjs().set('year', e.target.value).toDate();
    }

    setFieldValue('birthdate', format(newDate, 'MM/dd/yyyy'));
    setBirthYear(format(newDate, 'MM/dd/yyyy'));
  };

  const closeAndSubmit = () => {
    if (!birthDay || !birthMonth || !birthYear) setFieldValue('birthdate', '');
    setIsEditMode((prev) => !prev);
    handleSubmit();
  };

  const formatBirthday = (value) => {
    if (!value) return '';
    return dayjs(value).format('MM/DD/YYYY');
  };

  // eslint-disable-next-line consistent-return
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      closeAndSubmit();
    }
  };

  return (
    <StyledLabel
      label={
        testerProfile ? (
          <Box sx={styles.required}>
            Birthdate <span>*</span>
          </Box>
        ) : (
          'Birthdate'
        )
      }
      sx={detailsTabStyles.styledLabelFont}
      onKeyDown={handleKeyDown}
    >
      {isEditMode ? (
        <Box>
          <Backdrop
            open={isEditMode}
            invisible={false}
            sx={detailsTabStyles.backDropStyleProfile}
            onClick={closeAndSubmit}
          />
          <Flex gap={1} marginBottom='20px'>
            <StyledSelect
              name='birthdate'
              onChange={handleMonth}
              error={!!errors.gender}
              helperText={errors.gender}
              placeholder='MM'
              value={getMonthValue()}
              renderValue={renderMonthValue}
            >
              {months.map((item) => (
                <MenuItem
                  value={item.value}
                  key={item.option}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  {item.option}
                </MenuItem>
              ))}
            </StyledSelect>
            <StyledSelect
              name='birthdate'
              onChange={handleDay}
              error={!!errors.gender}
              helperText={errors.gender}
              placeholder='DD'
              value={dayjs(birthDay)?.get('date')}
            >
              {daysInMonth(values?.birthdate)?.map((date) => (
                <MenuItem
                  value={date}
                  key={date}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  {date}
                </MenuItem>
              ))}
            </StyledSelect>
            <StyledSelect
              name='birthdate'
              onChange={handleYear}
              error={!!errors.gender}
              helperText={errors.gender}
              placeholder='YYYY'
              value={dayjs(birthYear)?.get('year')}
            >
              {years.map((item) => (
                <MenuItem
                  value={item}
                  key={item}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </StyledSelect>
          </Flex>
        </Box>
      ) : (
        <Box onClick={changeViewBox} marginBottom='20px'>
          <Box>
            {formatBirthday(values?.birthdate)}
            {values?.age !== null ? (
              <Box>{values.age} yrs</Box>
            ) : (
              <Box color='#444444'>Select birthdate</Box>
            )}
          </Box>
        </Box>
      )}
    </StyledLabel>
  );
};
