import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { select } from 'src/styles';
import { theme } from 'src/theme';

import { Chevron } from '../icons';

const ArrowButton = styled('button')({
  background: 'none',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 30,
  height: 30,
  borderRadius: '50%',
  cursor: 'pointer',
  '&:hover': {
    background: theme.colors.neutral.light.normal,
  },
});

const Wrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const Text = styled(Typography)((props) => ({
  color: props?.showing !== 0 ? theme.colors.neutral.dark.mid : '#676767',
}));

const rowsPerPageValues = [16, 32, 64, 128];

export const TablePagination = ({
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  currentPageRowsCount,
}) => {
  const nextPage = () => {
    if (rowsPerPage * page < count) onPageChange(page + 1);
  };

  const prevPage = () => {
    if (page > 1) onPageChange(page - 1);
  };

  const handleChangeRowsPerPage = ({ target }) => {
    onRowsPerPageChange(target.value);
  };

  const showing = useMemo(
    () => rowsPerPage * (page - 1) + currentPageRowsCount,
    [rowsPerPage, currentPageRowsCount, page],
  );

  return (
    <Wrapper>
      <Flex>
        <ArrowButton onClick={prevPage}>
          <Chevron fill={showing !== 0 ? 'black' : '#F1F1F2'} />
        </ArrowButton>
        <ArrowButton
          onClick={nextPage}
          sx={{
            marginLeft: 3,
          }}
        >
          <Chevron fill={showing !== 0 ? 'black' : '#F1F1F2'} direction='right' />
        </ArrowButton>
        <Text
          showing={showing}
          sx={{
            marginLeft: 6,
          }}
        >
          {`Showing ${showing} of ${count}`}
        </Text>
      </Flex>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Text
          sx={{
            marginRight: 3,
            fontSize: '12px',
            whiteSpace: 'nowrap',
          }}
        >
          Results per page
        </Text>
        <Select sx={select.root} value={rowsPerPage} onChange={handleChangeRowsPerPage}>
          {rowsPerPageValues.map((item) => (
            <MenuItem key={item} value={item}>
              <Text
                sx={{
                  fontSize: '14px',
                  paddingRight: '6px',
                }}
              >
                {item}
              </Text>
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Wrapper>
  );
};

TablePagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  currentPageRowsCount: PropTypes.number,
};
