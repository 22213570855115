import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useAddCustomer } from 'src/hooks';
import { createCustomerSchema } from 'src/utils/formSchemas';

import { CreateCustomerForm } from './CreateCustomerForm';

const initialValues = {
  brandId: '',
  email: '',
  contactName: '',
};

export const CreateCustomerFormWrapper = ({ open, toggleModal }) => {
  const { addCustomer, loading } = useAddCustomer();

  const onSubmit = async (input, { resetForm }) => {
    await addCustomer({
      input,
    });
    toggleModal();
    resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={createCustomerSchema}
    >
      <Form>
        <CreateCustomerForm loading={loading} open={open} toggleModal={toggleModal} />
      </Form>
    </Formik>
  );
};

CreateCustomerFormWrapper.propTypes = {
  open: PropTypes.bool,
  toggleModal: PropTypes.func,
};
