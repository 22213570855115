import { ListSubheader, MenuItem } from '@mui/material';

import { styles } from '../../testers/createTesterForm/styles';

export const getShoeSizeOptions = ({ possibleValues = {} }) => {
  return [
    <ListSubheader key='0' style={styles.label}>
      Select all that apply
    </ListSubheader>,
    <ListSubheader key='1' style={styles.dropdownTitleBox}>
      <div style={styles.dropdownTitleText}>Women’s</div>
    </ListSubheader>,
    [
      possibleValues.shoeSizeOptions?.woman?.map((size) => (
        <MenuItem value={size.id} key={size.id}>
          {size.name}
        </MenuItem>
      )),
    ],

    <ListSubheader key='2' style={styles.dropdownTitleBox}>
      <div style={styles.dropdownTitleText}>Men’s</div>
    </ListSubheader>,
    [
      possibleValues.shoeSizeOptions?.man?.map((size) => (
        <MenuItem value={size.id} key={size.id}>
          {size.name}
        </MenuItem>
      )),
    ],
  ];
};
