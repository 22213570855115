import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { func, shape, string } from 'prop-types';
import { DownloadIcon } from 'src/components';
import { Flex } from 'src/components/common/CommonStyledComponents';

export const MediaCard = ({ media, handleDownload }) => {
  const imageUrl = `${process.env.REACT_APP_DOMAIN}/${media.fileUrl}`;
  return (
    <Grid container spacing={12}>
      <Grid item xs={6}>
        <Box
          component='img'
          src={imageUrl}
          sx={{
            width: '100%',
            borderRadius: 2,
          }}
          alt=''
        />
      </Grid>
      <Grid item xs={6}>
        <Flex
          mb={3.5}
          sx={{
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography fontWeight={700} mb={1}>
              Tester
            </Typography>
            <Typography>
              {media?.testTester?.user?.firstName} {media?.testTester?.user?.lastName?.slice(0, 1)}.
            </Typography>
          </Box>
          <Button
            onClick={handleDownload(imageUrl, media.fileUrl)}
            sx={{
              p: 0,
            }}
          >
            <DownloadIcon />
          </Button>
        </Flex>
        {media.caption && (
          <>
            <Typography fontWeight={700} mb={1}>
              Caption
            </Typography>
            <Typography>{media.caption}</Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

MediaCard.propTypes = {
  media: shape({
    caption: string,
    fileUrl: string,
  }),
  handleDownload: func,
};
