import { useCallback } from 'react';
import { FilterChips } from 'src/components/common/filtersMenu/filterChips/FilterChips';
import { useGetBrands } from 'src/hooks';

const filterNames = {
  brandsId: 'Brand',
  testTypes: 'Test Type',
  startDate: 'Start Date',
  endDate: 'End Date',
  testStatus: 'Test Status',
  dateCreate: 'Created',
  testerStatusExternals: 'Tester Status External',
  testerStatusInternals: 'Tester Status Internal',
};

export const TestChips = () => {
  const { brands } = useGetBrands();

  const getFilterName = useCallback((key) => filterNames[key] || key, []);
  const getFilterValue = useCallback(
    (key, value) => {
      const valuesToMap = {
        brandsId: () => brands?.find(({ id }) => id === value)?.name,
      };

      return valuesToMap[key]?.() || value;
    },
    [brands],
  );

  return <FilterChips getFilterValue={getFilterValue} getFilterName={getFilterName} />;
};
