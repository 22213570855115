import 'src/index.css';

import { ApolloProvider } from '@apollo/client';
import ThemeProvider from '@mui/system/ThemeProvider';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import client from 'src/graphql/client';
import { Navigation } from 'src/navigation';
import { theme } from 'src/theme';

import { AuthProvider, ToastProvider } from './hooks';

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <BrowserRouter>
          <AuthProvider>
            <Navigation />
          </AuthProvider>
        </BrowserRouter>
      </ToastProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);
