import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  colors: {
    neutral: {
      dark: {
        extra: '#201D1D',
        mid: '#676767',
        gray: '#444444',
      },
      light: {
        normal: '#DEDEDE',
        extra: '#F1F1F2',
        superExtra: '#F8F8F8',
      },
      white: '#ffffff',
      black: '#000000',
    },
    tertiary: '#9B51E0',
    blue: '#2F80ED',
    error: '#DE4141',
    success: '#90E16A',
  },
  typography: {
    fontFamily: [
      'Nunito',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h2: {
      fontSize: 44,
      fontWeight: 700,
    },
    h3: {
      fontSize: 28,
      fontWeight: 700,
    },
    h4: {
      fontSize: 24,
      fontWeight: 700,
    },
    h5: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
      letterSpacing: '0em',
      color: '#201D1D',
    },
    span: {
      fontize: 14,
    },
    modalTitle: {
      fontSize: 14,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#000000',
      contrastText: '#ffffff',
    },
    received: {
      main: '#F1F1F2',
      contrastText: '#000000',
    },
    open: {
      main: '#90E16A',
      contrastText: '#000000',
    },
    closed: {
      main: '#444444',
      contrastText: '#FFFFFF',
    },
  },

  spacing: (value) => value * 4,
});
