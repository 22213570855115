import { theme } from 'src/theme';

export const styled = {
  styledBox: {
    width: '284px',
    boxShadow: '0 10px 10px 1px #dedede',
    position: 'absolute',
    borderRadius: '4px',
    zIndex: 1,
    display: 'none',
    right: '60px',
  },
  labalTest: {
    background: '#f8f8f8',
    padding: '16px',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    border: '0.5px solid #dedede',
    borderRadius: '4px 4px 0px 0px',
  },
  emptyText: {
    color: '#000000',
    fontSize: '24px',
    padding: '16px 0px 12px',
  },
  test: {
    background: '#ffffff',
    padding: '20px 16px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    border: '0.5px solid #dedede',
    color: '#000000',
    cursor: 'pointer',
    '&:last-child': {
      borderRadius: '0px 0px 4px 4px',
    },
  },
  testLengthSyle: {
    color: theme.colors.tertiary,
    fontWeight: 700,
    width: 'min-content',
  },
  hoverTableCell: {
    position: 'relative',
    '&:hover': {
      '& .enrolledHoverView': {
        display: 'inline',
      },
      '& .enrolledHoverViewText': {
        textDecoration: 'underline',
        background: 'rgba(0, 0, 0, 0.08)',
      },
    },
  },
};
