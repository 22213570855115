export const styles = {
  label: {
    fontSize: '14px',
    lineHeight: '20px',
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input::-webkit-input-placeholder': {
      color: '#676767',
      opacity: 1,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DEDEDE',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      minHeight: '40px',
      '& svg': {
        cursor: 'pointer',
        visibility: 'hidden',
      },
      '&:hover': {
        '& svg': {
          cursor: 'pointer',
          visibility: 'visible',
        },
      },
    },
  },
};

export const styleForButton = {
  borderRadius: 6,
  fontSize: 16,
  px: 4,
  py: 1,
};

export const styleForLoginPageContainer = {
  height: 'calc(100vh - 130px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
