import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { Flex } from '../common/CommonStyledComponents';
import { style } from './style';

export const CustomerMyTestsHeader = ({ testsCompletedCounter }) => (
  <Flex
    sx={{
      justifyContent: 'flex-end',
      paddingBottom: '20px',
    }}
  >
    <Flex>
      <Typography sx={style.textCount}>{'Tests Completed'}</Typography>
      <Typography>{testsCompletedCounter}</Typography>
    </Flex>
  </Flex>
);

CustomerMyTestsHeader.propTypes = {
  testsCompletedCounter: PropTypes.number.isRequired,
};
