import { AdminsContainer } from 'src/components/Admins/AdminsContainer';
import { StyledPageContainer } from 'src/components/common/CommonStyledComponents';

const Admins = () => {
  return (
    <StyledPageContainer>
      <AdminsContainer />
    </StyledPageContainer>
  );
};

export default Admins;
