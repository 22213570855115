import PropTypes from 'prop-types';

import { StatsSection } from '../statsSection/statsSection';
import { EnrolledTableSection } from '../tables/enrolledTable/EnrolledTableSection';
import { TabPanel } from '../tapPanel/tapPanel';

export const EnrolledTabPanel = ({ enrolledStats, value }) => {
  return (
    <TabPanel value={value} index={0}>
      <StatsSection stateSectionItems={enrolledStats} />
      <EnrolledTableSection />
    </TabPanel>
  );
};

EnrolledTabPanel.propTypes = {
  enrolledStats: PropTypes.instanceOf(Array).isRequired,
  value: PropTypes.number.isRequired,
};
