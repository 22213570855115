import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';

export const MoreIcon = ({ color }) => (
  <SvgIcon viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.0001 11.6667C21.841 11.6667 23.3334 10.1743 23.3334 8.33333C23.3334 6.49238 21.841 5 20.0001 5C18.1591 5 16.6667 6.49238 16.6667 8.33333C16.6667 10.1743 18.1591 11.6667 20.0001 11.6667Z'
      fill={color}
    />
    <path
      d='M20.0001 23.3333C21.841 23.3333 23.3334 21.8409 23.3334 20C23.3334 18.159 21.841 16.6667 20.0001 16.6667C18.1591 16.6667 16.6667 18.159 16.6667 20C16.6667 21.8409 18.1591 23.3333 20.0001 23.3333Z'
      fill={color}
    />
    <path
      d='M23.3334 31.6667C23.3334 33.5076 21.841 35 20.0001 35C18.1591 35 16.6667 33.5076 16.6667 31.6667C16.6667 29.8257 18.1591 28.3333 20.0001 28.3333C21.841 28.3333 23.3334 29.8257 23.3334 31.6667Z'
      fill={color}
    />
  </SvgIcon>
);

MoreIcon.propTypes = {
  color: PropTypes.string,
};
