import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { ChevronBottom } from 'src/components';
import { tableHeader } from 'src/styles';

import { StyledTableHead } from './StyledComponents';

export const TableHeader = ({
  columns = [],
  order,
  orderBy,
  createSortHandler,
  sxTableRow,
  sxTableCell,
  children,
}) => (
  <StyledTableHead>
    <TableRow sx={sxTableRow}>
      {children}
      {columns.map((item) => (
        <TableCell key={`${item.title}${item.subtitle}`} sx={sxTableCell}>
          {item.sortKey ? (
            <TableSortLabel
              active={orderBy === item.sortKey}
              direction={order?.toLowerCase()}
              onClick={item.sortKey && createSortHandler(item.sortKey)}
              IconComponent={ChevronBottom}
            >
              <Box>
                <Typography sx={tableHeader.title}>{item.title}</Typography>
                <Typography sx={tableHeader.subtitle}>{item.subtitle}</Typography>
              </Box>
            </TableSortLabel>
          ) : (
            <Box>
              <Typography sx={tableHeader.title}>{item.title}</Typography>
              <Typography sx={tableHeader.subtitle}>{item.subtitle}</Typography>
            </Box>
          )}
        </TableCell>
      ))}
    </TableRow>
  </StyledTableHead>
);

TableHeader.propTypes = {
  columns: PropTypes.instanceOf(Array),
  order: PropTypes.string,
  orderBy: PropTypes.string,
  createSortHandler: PropTypes.func,
  sxTableCell: PropTypes.instanceOf(Object),
  sxTableRow: PropTypes.instanceOf(Object),
  children: PropTypes.node,
};
