import {
  ApolloClient,
  ApolloLink,
  /*
  HttpLink,
*/
  concat,
  InMemoryCache,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

/*
const httpLink = new HttpLink({ uri: 'https://merg.demo2.malevich.com.ua/graphql' });
*/

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_DOMAIN}/graphql`,
});

const authMiddleware = new ApolloLink(async (operation, forward) => {
  const token = await cookies.get('token');

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  // link: ApolloLink.from([authMiddleware, httpLink, uploadLink]),
  link: concat(authMiddleware, uploadLink),
});

export default client;
