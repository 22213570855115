import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { TestChips } from 'src/components/common/filtersMenu/filterChips';
import { StatusChip } from 'src/components/common/StatusChip';
import { ConfirmModal } from 'src/components/testOverview/confirmModal/confirmModal';
import { EmptyTableBanner, Loader, OptionsMenu } from 'src/components/UI';
import {
  PaginationSection,
  StyledTable,
  StyledTableRow,
  TablePagination,
} from 'src/components/UI/tables';
import { useCloseTest, useDuplicateTest, useFilter, useGetTests } from 'src/hooks';
import { convertStringToDateRange, formatDate, formatDateTime } from 'src/utils/helpers';

import { EnrolledCell } from './EnrolledCell';
import { TableHeader } from './TableHeader';

const columns = [
  {
    title: 'Brand',
    sortKey: 'brand',
  },
  {
    title: 'Style',
    sortKey: 'styleDescription',
  },
  {
    title: 'Test Type',
    sortKey: 'testType',
  },
  {
    title: 'Duration',
    sortKey: 'duration',
  },
  {
    title: 'Start Date',
    sortKey: 'startDate',
  },
  {
    title: 'End Date',
    sortKey: 'endDate',
  },
  {
    title: 'Enrolled',
    sortKey: 'enrolledTester',
  },
  {
    title: 'Last Updated',
  },
  {
    title: 'Status',
  },
];

export const TestsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(16);
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState('DESC');
  const [duplicateModalIsOpen, setDuplicateModalIsOpen] = useState(false);
  const [closeTestModalIsOpen, setCloseTestModalIsOpen] = useState(false);
  const [testId, setTestId] = useState(null);

  const [getTests, { data, refetch }] = useGetTests();
  const tests = data?.getTests?.rows || [];

  const { filters } = useFilter();
  const navigate = useNavigate();

  const closeTest = useCloseTest();
  const [duplicate, { loading: DublecateLoading }] = useDuplicateTest();

  const queryOptions = useMemo(() => {
    const {
      search,
      startDate,
      endDate,
      dateCreate,
      testTypes,
      brandsId,
      testerStatusInternals,
      testerStatusExternals,
      testStatus,
    } = filters;

    return {
      limit: rowsPerPage,
      offset: rowsPerPage * (currentPage - 1),
      search,
      startDate: convertStringToDateRange(startDate),
      endDate: convertStringToDateRange(endDate),
      dateCreate: convertStringToDateRange(dateCreate),
      testTypes,
      brandsId,
      testerStatusInternals,
      testerStatusExternals,
      testStatus,
      sort: {
        type: order,
        field: orderBy,
      },
    };
  }, [currentPage, rowsPerPage, filters, order, orderBy]);

  useEffect(() => {
    getTests({
      variables: {
        options: queryOptions,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          getTests: fetchMoreResult.getTests,
        };
      },
    });
  }, [getTests, queryOptions]);

  const handleChangePage = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleChangeRowsPerPage = useCallback((value) => {
    setRowsPerPage(value);
    setCurrentPage(1);
  }, []);

  const handleItemClick = (id) => (evt) => {
    const { tagName } = evt.target;

    if (tagName === 'TR' || tagName === 'TD') {
      navigate(`/tests/${id}`);
    }
  };

  const handleRequestSort = useCallback(
    (property) => () => {
      const isAsc = orderBy === property && order === 'ASC';
      setOrder(isAsc ? 'DESC' : 'ASC');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const toggleModalDuplicate = (_testId) => {
    setDuplicateModalIsOpen((prev) => !prev);
    setTestId(_testId);
  };

  const toggleModalCloseTest = (_testId) => {
    setCloseTestModalIsOpen((prev) => !prev);
    setTestId(_testId);
  };

  const handleDuplicate = useCallback(async () => {
    await duplicate({
      variables: {
        testId,
      },
    }).then((res) => {
      if (res.data?.duplicateTest?.status) {
        refetch({
          options: queryOptions,
        });
      }
    });
  }, [duplicate, testId, refetch, queryOptions]);

  const handleCloseTest = () => {
    closeTest({
      testId,
    });
  };

  const getOptions = (status) => {
    if (status === 'CLOSED') {
      return [
        {
          title: 'Duplicate',
          callback: toggleModalDuplicate,
        },
      ];
    }

    return [
      {
        title: 'Duplicate',
        callback: toggleModalDuplicate,
      },
      {
        title: 'Close Test',
        callback: toggleModalCloseTest,
      },
    ];
  };

  return (
    <>
      <TablePagination
        page={currentPage}
        rowsPerPage={rowsPerPage}
        count={data?.getTests?.count}
        currentPageRowsCount={tests.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Box mt={4} mb={6}>
        <TestChips />
      </Box>
      {!tests?.length ? (
        <EmptyTableBanner text='You don’t have any tests available yet' />
      ) : (
        <>
          <StyledTable>
            <TableHeader
              columns={columns}
              orderBy={orderBy}
              order={order}
              createSortHandler={handleRequestSort}
            />
            <TableBody>
              {tests?.map((test) => (
                <StyledTableRow key={test.id} onClick={handleItemClick(test.id)}>
                  <TableCell>{test.brand.name}</TableCell>
                  <TableCell
                    sx={{
                      minWidth: 200,
                    }}
                  >
                    {test.styleDescription}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: 100,
                    }}
                  >
                    {test.testType}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: 100,
                    }}
                  >
                    {test.duration}
                  </TableCell>
                  <TableCell>{formatDate(test.startDate)}</TableCell>
                  <TableCell>{formatDate(test.endDate)}</TableCell>
                  <EnrolledCell testers={test.testers} />
                  <TableCell
                    sx={{
                      minWidth: 100,
                    }}
                  >
                    {formatDateTime(test.lastUpdated)}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: 150,
                    }}
                  >
                    <Flex
                      sx={{
                        justifyContent: 'space-between',
                      }}
                    >
                      <StatusChip status={test.status} />
                      <OptionsMenu target={test.id} options={getOptions(test.status)} />
                    </Flex>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
            <ConfirmModal
              open={duplicateModalIsOpen}
              toggleModal={toggleModalDuplicate}
              callback={handleDuplicate}
              labalTest='Are you sure you want to duplicate this test?'
              agreeButtonTex='Yes, Duplicate it'
            />
            <ConfirmModal
              open={closeTestModalIsOpen}
              toggleModal={toggleModalCloseTest}
              callback={handleCloseTest}
              labalTest='Are you sure you want to close and archive this test?'
              agreeButtonTex='Yes, Close Test'
            />
            <Loader loading={DublecateLoading} />
          </StyledTable>
          <PaginationSection
            page={currentPage}
            count={data?.getTests?.count && Math.ceil(data.getTests.count / rowsPerPage)}
            onChange={handleChangePage}
          />
        </>
      )}
    </>
  );
};
