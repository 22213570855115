export const CandidateStatus = {
  ADDED: 'ADDED',
  INVITED: 'INVITED',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  UN_ENROLLED: 'UN_ENROLLED',
};

export const TesterTestStatus = {
  IN_TEST: 'IN_TEST',
  AVAILABLE: 'AVAILABLE',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
};

export const TesterStaus = {
  ENROLLED: 'ENROLLED',
  START_TESTING: 'START_TESTING',
};

export const UserRoles = {
  ADMIN: 'ADMIN',
  CUSTOMER: 'CUSTOMER',
  TESTER: 'TESTER',
};

export const Ages = ['18-24', '25-34', '35-44', '45-54', '55-64', '65-74', '75+'];
export const TestStatus = {
  CLOSED: 'CLOSED',
  RECEIVED: 'RECEIVED ',
  OPEN: 'OPEN ',
};

export const InviteStatus = {
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  LEARN_MORE: 'LEARN_MORE',
};

export const TesterPages = [
  {
    title: 'My Tests',
    navTo: '/tests',
  },
  {
    navTo: '/tests/:id',
  },
];

export const CustomerPages = [
  {
    title: 'My Tests',
    navTo: '/tests',
  },
  {
    navTo: '/tests/:id',
  },
];

export const AdminsPages = [
  {
    title: 'My Tests',
    navTo: '/tests',
  },
  {
    navTo: '/tests/:id',
  },
];

export const YesOrNotText = [
  {
    title: 'Yes',
    value: 'true',
  },
  {
    title: 'No',
    value: 'false',
  },
];

export const months = [
  {
    option: '01 - January',
    value: '0',
    label: '01',
  },
  {
    option: '02 - February',
    value: '1',
    label: '02',
  },
  {
    option: '03 - March',
    value: '2',
    label: '03',
  },
  {
    option: '04 - April',
    value: '3',
    label: '04',
  },
  {
    option: '05 - May',
    value: '4',
    label: '05',
  },
  {
    option: '06 - June',
    value: '5',
    label: '06',
  },
  {
    option: '07 - July',
    value: '6',
    label: '07',
  },
  {
    option: '08 - August',
    value: '7',
    label: '08',
  },
  {
    option: '09 - September',
    value: '8',
    label: '09',
  },
  {
    option: '10 - October',
    value: '9',
    label: '10',
  },
  {
    option: '11 - November',
    value: '10',
    label: '11',
  },
  {
    option: '12 - December',
    value: '11',
    label: '12',
  },
];

export const years = [...Array(100)].map((_, index) => new Date().getFullYear() - index);

export const TABLE_UI_TESTERS_COLUMNS = [
  {
    title: 'Name',
    sortKey: 'contactName',
  },
  {
    title: 'Group',
    sortKey: 'testerGroup',
  },
  {
    title: 'Status',
    subtitle: 'Internal',
    sortKey: 'testerStatusInternal',
  },
  {
    title: 'Status',
    subtitle: 'External',
    sortKey: 'testerStatusExternal',
  },
  {
    title: 'Email',
    sortKey: 'email',
  },
  {
    title: 'Tests Completed',
    sortKey: 'testCompletedCount',
  },
  {
    title: 'In Test?',
    sortKey: 'testStatus',
  },
];
