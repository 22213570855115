import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { styleForButton, styles } from 'src/components/login/styles';
import { Label } from 'src/components/UI';
import { theme } from 'src/theme';

export const LoginForm = ({ handleForgotPasswordClick }) => {
  const { handleChange, values, errors, touched, handleBlur } = useFormikContext();

  const isValidForm = Object.values(errors).every((x) => x === '') && values.email;

  return (
    <>
      <Box my={7}>
        <Label
          sx={{
            marginBottom: 2,
            ...styles.label,
          }}
          label='Account Email'
        >
          <TextField
            size='small'
            name='email'
            onInput={handleChange}
            required
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            placeholder='Enter Email'
            onBlur={handleBlur}
          />
        </Label>
        <Label label='Password' sx={styles.label}>
          <TextField
            size='small'
            name='password'
            type='password'
            onInput={handleChange}
            required
            error={touched.password && !!errors.password}
            helperText={touched.password && errors.password}
            placeholder='Enter Password'
            onBlur={handleBlur}
          />
        </Label>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            ...styleForButton,
          }}
          size='small'
          variant='contained'
          disabled={!isValidForm}
          type='submit'
        >
          Log In
        </Button>
        <Button onClick={handleForgotPasswordClick}>
          <Typography color={theme.colors.tertiary} fontWeight={700}>
            Forgot Password?
          </Typography>
        </Button>
      </Box>
    </>
  );
};

LoginForm.propTypes = {
  handleForgotPasswordClick: PropTypes.func,
};
