import { useMemo } from 'react';
import { useTestInfo } from 'src/hooks';

import { useEditTestTester, useStartTestingTester } from './useMutations';

export const useEnrolledTabPanel = () => {
  const [editTestTester] = useEditTestTester();
  const [startTestingTester] = useStartTestingTester();

  const { useRefetchTestersData } = useTestInfo();

  const handleEditTestTester = async (testerID, grade) => {
    const data = await editTestTester({
      variables: {
        testUserId: testerID,
        grade,
      },
    });
    if (data.data?.editTestTester?.status) {
      useRefetchTestersData();
    }
  };

  const handleStartTestingTester = async (testerID) => {
    const data = await startTestingTester({
      variables: {
        testUserId: testerID,
      },
    });
    if (data.data?.startTestingTester?.status) {
      useRefetchTestersData();
    }
  };

  const value = useMemo(
    () => ({
      handleEditTestTester,
      handleStartTestingTester,
    }),
    [handleEditTestTester, handleStartTestingTester],
  );

  return value;
};
