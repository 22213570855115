import Button from '@mui/material/Button';
import styled from '@mui/system/styled';
import { theme } from 'src/theme';

export const StyledButton = styled(Button)((props) => ({
  color: '#676767',
  display: 'block',
  height: '80px',
  padding: '0',
  minWidth: 'auto',
  borderBottom:
    props.activeTab === props.index &&
    (props.location.pathname === '/tests' || props.location.pathname === '/reports')
      ? '4px solid #FBC15C'
      : '',
}));

export const styles = {
  appBar: {
    background: '#F8F8F8',
    boxShadow: 'none',
    '& .MuiToolbar-root': {
      minHeight: '80px',
    },
  },
  appBarContent: {
    maxWidth: '1352px',
    width: '100%',
    margin: '0px auto',
    padding: '0px 0px 0px 4px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  headerContentLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  userBtn: {
    '&.MuiButtonBase-root.MuiIconButton-root, & .MuiAvatar-root': {
      width: '32px',
      height: '32px',
      padding: 0,
      '&.MuiAvatar-root': {
        backgroundColor: theme.colors.blue,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
      },
    },
  },
  navIcon: {
    marginRight: '40px',
    maxWidth: '56px',
    maxHeight: '56px',
  },
  navMenu: {
    '&.MuiList-root': {
      display: 'flex',
      padding: 0,
    },
    '& .MuiListItem-root': {
      width: 'auto',
      marginBottom: 0,
      padding: 0,
      marginRight: '20px',
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  navLink: {
    position: 'relative',
    '& a': {
      color: theme.colors.neutral.dark.mid,
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 700,
      textDecoration: 'none',
      '&.MuiButton-root': {
        textTransform: 'none',
      },
      '&.activeMenuLink': {
        color: `${theme.colors.neutral.black} !important`,
        '&::after': {
          content: '""',
          border: '2px solid #FBC15C',
          backgroundColor: '#FBC15C',
          position: 'absolute',
          bottom: '-28px',
          left: 0,
          right: 0,
        },
      },
    },
  },
};
