import { number, oneOfType, string } from 'prop-types';

export const CloseIcon = ({ width = 18, height = 18, color = '#9C9C9C' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.97662 9.00012L2.69999 14.2767L3.72337 15.3001L8.99999 10.0235L14.2766 15.3001L15.3 14.2767L10.0234 9.00012L15.3 3.72349L14.2766 2.70012L8.99999 7.97674L3.72337 2.70012L2.69999 3.72349L7.97662 9.00012Z'
    />
    <path
      fill={color}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.97662 9.00012L2.69999 3.72349L3.72337 2.70012L8.99999 7.97674L14.2766 2.70012L15.3 3.72349L10.0234 9.00012L15.3 14.2767L14.2766 15.3001L8.99999 10.0235L3.72337 15.3001L2.69999 14.2767L7.97662 9.00012ZM8.99999 10.6599L3.72337 15.9365L2.0636 14.2767L7.34022 9.00012L2.0636 3.72349L3.72337 2.06372L8.99999 7.34035L14.2766 2.06372L15.9364 3.72349L10.6598 9.00012L15.9364 14.2767L14.2766 15.9365L8.99999 10.6599Z'
    />
  </svg>
);

CloseIcon.propTypes = {
  width: oneOfType([string, number]),
  height: oneOfType([string, number]),
  color: string,
};
