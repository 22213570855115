import { bool, func, string } from 'prop-types';
import { useCallback } from 'react';
import { RemoveModal } from 'src/components/UI';
import { useRemoveReport } from 'src/hooks';

export const ReportRemoveModal = ({ open, reportId, closeModal }) => {
  const removeReport = useRemoveReport();

  const handleRemoveReport = useCallback(
    (id) => {
      removeReport({
        reportId: id,
      }).then(() => {
        closeModal();
      });
    },
    [reportId],
  );

  return (
    <RemoveModal
      title='Are you sure you want to remove this report?'
      open={open}
      callback={handleRemoveReport}
      closeModal={closeModal}
      itemId={reportId}
    />
  );
};

ReportRemoveModal.propTypes = {
  open: bool,
  reportId: string,
  closeModal: func,
};
