export default {
  autocomplete: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
    },
    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      color: '#000000',
      padding: '10px 16px 10px 12px',
      '&::placeholder': {
        color: '#676767',
      },
    },
    '& .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator': {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
      right: '10px',
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DEDEDE',
      borderRadius: '3px',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderColor: '#DEDEDE',
        borderWidth: '1px',
      },
    '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input::-webkit-input-placeholder': {
      opacity: 1,
    },
  },
  autocompleteMenu: {
    '&': {
      boxShadow: ' 0px 8px 12px 0px #00000033',
      marginTop: '2px',
    },
    '& .MuiButtonBase-root.MuiMenuItem-root': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      color: '#000000',
    },
  },
  addBrand: {
    fontWeight: '700 !important',
    color: '#9B51E0 !important',
  },
};
