import Box from '@mui/material/Box';
import { func, instanceOf } from 'prop-types';
import { DetailInput, DetailLabel } from 'src/components';

export const About = ({ handleChange, handleSubmit, values = {} }) => (
  <Box>
    <DetailLabel label='Tell us about yourself, your recreation and travel, as it relates to outdoor product testing'>
      <DetailInput
        name='relatesOutdoorProductTestingDescription'
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        value={values.relatesOutdoorProductTestingDescription}
        placeholder='Tell us about yourself'
        multiline
        submitOnValueChange={true}
      />
    </DetailLabel>
    <DetailLabel label='How did you hear about MERG?'>
      <DetailInput
        name='didYouHearAboutUs'
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        value={values.didYouHearAboutUs}
        placeholder='Enter Description'
        multiline
        submitOnValueChange={true}
      />
    </DetailLabel>
  </Box>
);

About.propTypes = {
  handleChange: func,
  handleSubmit: func,
  values: instanceOf(Object),
};
