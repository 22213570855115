import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import {
  Flex,
  FooterContainer,
  HeaderTitleContainer,
  StyledModal,
} from 'src/components/common/CommonStyledComponents';
import { File } from 'src/components/UI';
import { ErrorIcon, SuccessIcon } from 'src/components/UI/icons';
import { useAddTestersFromCsv, useInviteUsersById, useToasts } from 'src/hooks';

import { uploadModalStyles } from './styles';

export const UploadCsvModal = ({ open, toggleModal, file }) => {
  const addTesters = useAddTestersFromCsv();
  const [inviteUsersById] = useInviteUsersById();
  const [uploadResult, setUploadResult] = useState({});
  const [successIds, setSuccessIds] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const { SuccessMessage } = useToasts();

  const handleUploadConfirm = useCallback(() => {
    addTesters({
      file,
    }).then(({ data }) => {
      setUploadResult(data.addTestersFromCsv);
      setCurrentStep(1);
      setSuccessIds(data?.addTestersFromCsv.successIds);
    });
  }, [file]);

  const handleFinish = useCallback(() => {
    inviteUsersById({
      variables: {
        ids: successIds,
      },
    });
    toggleModal();
    SuccessMessage('Testers added');
  }, [successIds]);

  const steps = useMemo(
    () => [
      <Flex>
        <File />
        <Typography
          sx={{
            marginLeft: 2,
          }}
        >
          {file.name}
        </Typography>
      </Flex>,
      <>
        {uploadResult?.successfullyCount ? (
          <Flex>
            <SuccessIcon />
            <Typography sx={uploadModalStyles.uploadResultRow}>
              Successfully uploaded {uploadResult.successfullyCount} records
            </Typography>
          </Flex>
        ) : (
          ''
        )}
        {uploadResult?.failedCount ? (
          <>
            <Flex>
              <ErrorIcon />
              <Typography sx={uploadModalStyles.uploadResultRow}>
                Failed to upload {uploadResult.failedCount} records
              </Typography>
            </Flex>
            <Typography sx={uploadModalStyles.failedRows}>
              Unable to upload rows {uploadResult.failedRow.join(', ')}
            </Typography>
          </>
        ) : (
          ''
        )}
      </>,
    ],
    [file, uploadResult],
  );

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    toggleModal();
  };

  return (
    <StyledModal open={open} onClose={handleClose} aria-labelledby='modal-title'>
      <Paper
        sx={{
          minWidth: 400,
          boxShadow: 'none',
        }}
      >
        <HeaderTitleContainer sx={uploadModalStyles.headerTitle}>
          <Typography variant='modalTitle' id='modal-title'>
            Upload csv
          </Typography>
        </HeaderTitleContainer>
        <Box
          sx={{
            padding: 6,
          }}
        >
          {steps[currentStep]}
        </Box>
        <FooterContainer
          sx={{
            justifyContent: 'flex-end',
          }}
        >
          {currentStep === 0 && (
            <>
              <Button onClick={toggleModal} mr={4}>
                Cancel
              </Button>
              <Button
                onClick={handleUploadConfirm}
                variant='contained'
                sx={{
                  py: 0,
                }}
              >
                Upload
              </Button>
            </>
          )}
          {currentStep === 1 && (
            <Button
              onClick={handleFinish}
              variant='contained'
              sx={{
                py: 0,
              }}
            >
              Finish and Notify Testers
            </Button>
          )}
        </FooterContainer>
      </Paper>
    </StyledModal>
  );
};

UploadCsvModal.propTypes = {
  open: PropTypes.bool,
  toggleModal: PropTypes.func,
  file: PropTypes.instanceOf(Object),
};
