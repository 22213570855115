import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import styled from '@mui/system/styled';
// eslint-disable-next-line object-curly-newline
import { arrayOf, func, instanceOf, node, number, object, oneOfType, string } from 'prop-types';
import { forwardRef } from 'react';
import { Label } from 'src/components/UI';

import { selectMenuWrap } from '../UI/detail/styles';
import Chevron from './Chevron';

const sxStyledSelect = {
  height: '40px',
  width: '100%',
  textTransform: 'inherit',
  fontSize: '14px',
  '& .MuiMenuItem-root': {
    fontSize: '14px',
  },
  '& .chevronWrapper': {
    position: 'absolute',
    right: '14px',
    top: 0,
    bottom: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  '& .Mui-selected': {
    backgroundColor: 'rgb(155, 81, 224)',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#9B51E0',
    },
  },
};

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  keepMounted: true,
  disablePortal: true,
  PaperProps: {
    sx: selectMenuWrap,
  },
};

export const StyledPageContainer = styled(Box)`
  max-width: 1440px;
  padding: 0 44px;
  margin: 0 auto;
`;

export const StyledLabel = ({ children, width, sx, className, ...props }) => (
  <Label
    sx={{
      marginBottom: 4,
      width,
      justifyContent: 'space-between',
      ...sx,
      '& .MuiInputBase-input.MuiOutlinedInput-input': {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
      },
      '& .MuiInputBase-input.MuiOutlinedInput-input::-webkit-input-placeholder': {
        color: '#676767',
        opacity: 1,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#DEDEDE',
      },
    }}
    {...props}
    className={className}
  >
    {children}
  </Label>
);
// Спробувати реалізувати селекти для фільтрів через компонент нижче.
export const StyledSelect = forwardRef(
  (
    {
      children,
      sx,
      value,
      handleBlur,
      placeholder,
      renderValue,
      wrapItems,
      multiple,
      onChange,
      onClose,
      ...props
    },
    ref,
  ) => {
    return (
      <Select
        ref={ref}
        multiple={multiple}
        sx={{
          color: !value || !value?.length || value === 'none' ? '#676767' : '#000000',
          ...sxStyledSelect,
          ...sx,
        }}
        value={value || ''}
        onClose={onClose}
        onChange={onChange}
        onBlur={handleBlur}
        displayEmpty={!!placeholder}
        renderValue={(val) => {
          if (!val || val.length === 0) return placeholder;
          if (typeof renderValue === 'function') {
            return renderValue(val);
          }
          return val;
        }}
        IconComponent={Chevron}
        MenuProps={wrapItems && menuProps}
        {...props}
      >
        {children}
      </Select>
    );
  },
);

StyledSelect.propTypes = {
  children: node,
  placeholder: string,
  sx: instanceOf(Object),
  value: oneOfType([arrayOf(string), arrayOf(object), string]),
  renderValue: func,
  handleBlur: func,
};

StyledLabel.propTypes = {
  children: node,
  width: oneOfType([string, number]),
  sx: instanceOf(Object),
  className: string,
};

export const FooterContainer = styled(Box)(({ theme: { colors } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 18px',
  backgroundColor: colors.neutral.light.extra,
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  bottom: 0,
  width: '100%',
  boxSizing: 'border-box',
}));

export const StyledPaper = styled(Paper)({
  outline: 'none',
  minWidth: 636,
  maxWidth: 636,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

export const ScrollContainer = styled(Box)({
  padding: '0 48px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflowY: 'scroll',
  marginRight: '8px',
});

export const StyledModal = styled(Modal)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  marginTop: '5%',
  marginBottom: '5%',
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const HeaderTitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StepsContainer = styled(Box)(
  ({ theme: { colors } }) => `
  display: flex;
  margin-top: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${colors.neutral.light.normal};
`,
);

export const Flex = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const FullScreenPageContainer = styled(Container)({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
