import { useCallback, useState } from 'react';
import { ControlBar, FormikWrapperCreateTester, TestersTable } from 'src/components';
import { StyledPageContainer } from 'src/components/common/CommonStyledComponents';
import { TestersFilters } from 'src/components/testers';

const Testers = () => {
  const [createTesterModalIsOpen, setCreateTesterModalIsOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setCreateTesterModalIsOpen((prev) => !prev);
  }, []);

  return (
    <StyledPageContainer>
      <ControlBar
        header='Testers'
        addButtonLabel='Add tester'
        handleAddButtonClick={toggleModal}
        filterComponent={<TestersFilters />}
      />
      <TestersTable />
      <FormikWrapperCreateTester open={createTesterModalIsOpen} toggleModal={toggleModal} />
    </StyledPageContainer>
  );
};

export default Testers;
