import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  HeaderTitleContainer,
  StyledLabel,
  StyledModal,
  StyledSelect,
} from 'src/components/common/CommonStyledComponents';
import { useCreateReport, useTestInfo } from 'src/hooks';
import { theme } from 'src/theme';
import { addReportSchema } from 'src/utils/formSchemas';

import { ModalFooter } from './ModalFooter';
import { reportStyle } from './style';

const initialValues = {
  linkName: '',
  link: '',
  visibility: 'none',
};

const lib = {
  EVERYONE: 'Everyone',
  CUSTOMER: 'Customer',
  ALL_TESTERS: 'All testers',
  TESTER: 'Custom',
};

export const AddReportModal = ({ open, toggleModal }) => {
  const { enrolledList: testers } = useTestInfo();
  const [visibilityTesters, setVisibilityTesters] = useState([]);
  const { id: testId } = useParams();

  const createReport = useCreateReport();

  const onSubmit = useCallback(
    (input, resetForm) => {
      createReport({
        testId,
        input,
      }).then((response) => {
        if (response?.data?.createReport?.status === true) {
          resetForm();
          toggleModal();
        }
      });
    },
    [testId],
  );

  const { handleSubmit, handleChange, values, errors, touched, handleBlur, resetForm } = useFormik({
    initialValues,
    validationSchema: addReportSchema,
    onSubmit: (input) => {
      onSubmit(
        {
          ...input,
          visibilityTestUsers: visibilityTesters,
        },
        resetForm,
      );
    },
  });

  const handleAddTester = useCallback(
    (id) => () => {
      setVisibilityTesters((prev) => {
        if (prev.includes(id)) return prev.filter((item) => item !== id);
        return [...prev, id];
      });
    },
    [],
  );

  const renderVisibilityValue = useCallback((value) => lib[value] || 'Select Visibility', []);

  const handleChangeVisibility = useCallback((e) => {
    handleChange(e);
    if (e.target.value !== 'TESTER') setVisibilityTesters([]);
  }, []);

  return (
    <StyledModal open={open} onClose={toggleModal} aria-labelledby='modal-title'>
      <Paper sx={reportStyle.modal.paper}>
        <Box
          sx={{
            padding: '20px 24px',
          }}
        >
          <HeaderTitleContainer>
            <Typography variant='modalTitle' id='modal-title'>
              ADD REPORT
            </Typography>
          </HeaderTitleContainer>
        </Box>
        <Divider
          sx={{
            mb: 5,
          }}
        />
        <Box sx={reportStyle.modal.container}>
          <StyledLabel label='Link Name' required>
            <TextField
              size='small'
              name='linkName'
              onInput={handleChange}
              required
              value={values.linkName}
              error={touched.linkName && !!errors.linkName}
              helperText={touched.linkName && errors.linkName}
              placeholder='Enter Link Name'
              onBlur={handleBlur}
            />
          </StyledLabel>
          <StyledLabel label='Link' required>
            <TextField
              size='small'
              name='link'
              onInput={handleChange}
              required
              value={values.link}
              error={touched.link && !!errors.link}
              helperText={touched.link && errors.link}
              placeholder='Add Link'
              onBlur={handleBlur}
            />
          </StyledLabel>
          <StyledLabel label='Visibility' required>
            <StyledSelect
              name='visibility'
              onChange={handleChangeVisibility}
              error={touched.visibility && !!errors.visibility}
              value={values.visibility}
              helperText={touched.visibility && errors.visibility}
              renderValue={renderVisibilityValue}
              handleBlur={handleBlur}
            >
              <MenuItem value='EVERYONE' key='everyone'>
                Everyone
              </MenuItem>
              <MenuItem value='CUSTOMER' key='customer'>
                Customer
              </MenuItem>
              <MenuItem value='ALL_TESTERS' key='allTesters'>
                All Testers
              </MenuItem>
              {testers.map((item) => (
                <MenuItem
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: visibilityTesters?.includes(item.id)
                        ? theme.colors.neutral.light.extra
                        : theme.colors.neutral.white,
                    },
                  }}
                  value='TESTER'
                  key={item.id}
                  onClick={handleAddTester(item.id)}
                >
                  {item.user.contactName}
                </MenuItem>
              ))}
            </StyledSelect>
          </StyledLabel>
        </Box>
        <ModalFooter
          handleClickSubmit={handleSubmit}
          handleClickCancel={toggleModal}
          submitButtonTitle='Add Report'
        />
      </Paper>
    </StyledModal>
  );
};

AddReportModal.propTypes = {
  open: PropTypes.bool,
  toggleModal: PropTypes.func,
};
