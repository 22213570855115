import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledTable, StyledTableRow, TableHeader } from 'src/components/UI/tables';
import { EmptyTableBanner } from 'src/components/UI/tables/EmptyTableBanner';
import { formatDate } from 'src/utils/helpers';

const COLUMNS = [
  {
    title: 'Style',
  },
  {
    title: 'Started',
  },
  {
    title: 'Completed',
  },
];

function areEqual(prevProps, nextProps) {
  return prevProps?.data === nextProps?.data;
}

export const MyTestsTable = React.memo(({ data }) => {
  const navigate = useNavigate();

  const tests = data?.getMyTests?.rows || [];

  if (tests?.length === 0 || !tests) {
    return <EmptyTableBanner text='You don’t have any reports available yet' />;
  }

  const handleItemClick = (id) => (evt) => {
    const { tagName } = evt.target;

    if (tagName === 'TR' || tagName === 'TD') {
      navigate(`${id}`);
    }
  };

  return (
    <StyledTable
      sx={{
        marginTop: 6,
      }}
    >
      <TableHeader columns={COLUMNS} />
      <TableBody>
        {tests?.map((test) => (
          <StyledTableRow key={test.id} onClick={handleItemClick(test.id)}>
            <TableCell
              sx={{
                minWidth: 200,
              }}
            >
              {test.styleDescription}
            </TableCell>
            <TableCell>{formatDate(test.startDate)}</TableCell>
            <TableCell>{formatDate(test.endDate)}</TableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
}, areEqual);

MyTestsTable.propTypes = {
  data: PropTypes.instanceOf(Object),
};
