export const styles = {
  selectContainer: {
    width: '100%',
    height: '40px',
    cursor: 'pointer',
    padding: '10px 33px 10px 12px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    maxHeight: '40px',
    borderRadius: '3px',
    maxWidth: '400px',
    boxSizing: 'border-box',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#676767',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    '& svg': {
      marginLeft: '33px',
      minWidth: '12px',
      minHeight: '12px',
      position: 'absolute',
      right: '12px',
      top: '13px',
    },
  },
  selectOpen: {
    backgroundColor: '#F1F1F2',
  },
  transformChevron: {
    '& svg': {
      transform: 'rotate(180deg)',
    },
  },
  selectBtnContainer: {
    backgroundColor: '#201D1D',
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    '& button.MuiButtonBase-root.MuiButton-root ': {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
      padding: '8px 0px 8px 16px',
      color: '#ffffff',
      minWidth: 'unset',
      '&:last-child': {
        color: '#DEDEDE',
      },
    },
  },
  menuListContainer: {
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '392px',
    maxHeight: '308px',
  },
  wrapItem: {
    border: 'solid 1px black',
    margin: 1,
    borderRadius: '4px',
    padding: '2px 12px 2px 12px',
  },
  paperContainer: {
    minWidth: '392px',
    maxHeight: '300px',
    position: 'absolute',
    borderRadius: '4px',
    marginTop: '2px',
  },
  label: {
    width: '100%',
    padding: 0,
    marginLeft: '5px',
    height: '25px',
    border: 'none',
    textTransform: 'initial',
  },
  twoColumns: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',

    svg: {
      cursor: 'pointer',
    },
  },
  dropdownTitleBox: {
    width: '100%',
    border: 'none',
    padding: 0,
  },
  dropdownTitleText: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#000',
  },
  dropdownSubTitleText: {
    fontSize: '12px',
    color: '#000',
    lineHeight: '16px',
  },
  required: {
    span: {
      color: '#DE4141',
    },
  },
};
