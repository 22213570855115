import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { matchRoutes, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { LogoIcon } from 'src/components/UI/icons/LogoIcon';
import { useAuthContext } from 'src/hooks';
import { theme } from 'src/theme';

import { InviteDialogSection } from './inviteDialog/InviteDialogSection';
import { styles } from './style';

const pages = [
  {
    title: 'Tests',
    navTo: '/tests',
  },
  {
    title: 'Testers',
    navTo: '/testers',
  },
  {
    title: 'Customers',
    navTo: '/customers',
  },
  {
    title: 'Admins',
    navTo: '/admins',
  },
];

export const NavBar = () => {
  const { handleLogout, user, isAuth } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  const routes = useMemo(
    () =>
      pages?.map((route) => ({
        path: route?.navTo,
      })),
    [pages],
  );
  const useCurrentPath = () => {
    const route = matchRoutes(routes, location);

    return route ? route[0].route.path : null;
  };
  const isMenuVisiable = useMemo(
    () => pages?.some((item) => useCurrentPath(location.pathname) === item?.navTo),
    [pages, location.pathname],
  );

  const handleToProfile = () => {
    navigate('/profile');
  };

  const showInitials = useMemo(() => {
    if (user?.firstName && user?.lastName) {
      return `${user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}`;
    }
    return user?.email[0].toUpperCase();
  }, [user?.lastName, user?.firstName, user?.email]);

  // const handleOpenUserMenu = useCallback((event) => {
  //   handleToProfile();
  //   setAnchorElUser(event.currentTarget);
  // }, []);
  //
  // const handleCloseUserMenu = useCallback(() => {
  //   setAnchorElUser(null);
  // }, []);

  return (
    <Box>
      <AppBar position='static' sx={styles.appBar}>
        <Box sx={styles.appBarContent}>
          <Toolbar>
            <Box sx={styles.headerContainer}>
              <Box sx={styles.headerContentLeft}>
                <Box sx={styles.navIcon}>
                  <LogoIcon />
                </Box>
                <List sx={styles.navMenu}>
                  {pages.map((page) => (
                    <ListItem key={page.navTo}>
                      <Box sx={styles.navLink}>
                        <NavLink
                          to={page.navTo}
                          className={(navData) => (navData.isActive ? 'activeMenuLink' : '')}
                        >
                          {page.title}
                        </NavLink>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Box>

              {isAuth && (
                <Box>
                  {isMenuVisiable ? (
                    <>
                      <InviteDialogSection />

                      <IconButton onClick={handleToProfile} sx={styles.userBtn}>
                        <Avatar
                          sx={{
                            backgroundColor: theme.colors.blue,
                          }}
                          alt='avatar'
                          src={`${process.env.REACT_APP_DOMAIN}/${user.imageProfileLink}`}
                        >
                          {showInitials}
                        </Avatar>
                      </IconButton>
                    </>
                  ) : (
                    <Button variant='text' color='secondary' onClick={handleLogout}>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 700,
                        }}
                      >
                        Sign Out
                      </Typography>
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </Box>
  );
};
