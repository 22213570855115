import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { TesterChips } from 'src/components/common/filtersMenu/filterChips';
import { TABLE_UI_TESTERS_COLUMNS } from 'src/constants/constants';
import {
  useChangeTesterStatus,
  useFilter,
  useGetTestersQuery,
  useGetTesterValues,
  useRemoveUser,
} from 'src/hooks';
import { prepareFilterData } from 'src/utils/helpers';

import {
  EmptyTableBanner,
  OptionsMenu,
  RemoveModal,
  ResendInviteButton,
  Status,
  StatusSelect,
  StyledTable,
  StyledTableRow,
  TableHeader,
} from '../UI';

export const TableUI = ({
  rowsPerPage,
  currentPage,
  order,
  orderBy,
  setOrderBy,
  setOrder,
  getTester,
  toggleDuplicationModal,
}) => {
  const { filters } = useFilter();
  const [removedUser, setRemovedUser] = useState(null);
  const removeUser = useRemoveUser();

  const { testerTestStatuses } = useGetTesterValues();
  const navigate = useNavigate();
  const changeTesterStatus = useChangeTesterStatus();
  const { search, ...other } = filters;

  const queryOptions = useMemo(() => {
    return {
      limit: rowsPerPage,
      offset: rowsPerPage * (currentPage - 1),
      testerFilter: prepareFilterData(other),
      search,
      Sort: {
        type: order,
        field: orderBy,
      },
    };
  }, [currentPage, order, orderBy, other, rowsPerPage, search]);

  const { testers, refetch } = useGetTestersQuery({
    variables: {
      options: queryOptions,
    },
  });

  const isTestersTableEmpty = !testers?.length || !testers;

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };

  const handleItemClick = (id) => (evt) => {
    const { tagName } = evt.target;

    if (tagName === 'TR' || tagName === 'TD') {
      navigate(`/testers/${id}`);
    }
  };

  const handleChangeStatus = (testerId) => async (e) => {
    const testerStatusInternal = e.target.value?.toUpperCase();
    const result = await changeTesterStatus({
      testerId,
      testerStatusInternal,
    });

    if (result.data?.editTester?.status) {
      refetch({
        options: queryOptions,
      });
    }
  };

  const duplicate = async (item) => {
    await getTester({
      variables: {
        testerId: item.id,
      },
      skip: false,
    });
    toggleDuplicationModal();
  };

  const closeModal = useCallback(() => {
    setRemovedUser(null);
  }, []);

  const acceptRemoveUser = useCallback(() => {
    removeUser({
      id: removedUser,
    }).then((res) => {
      if (res?.data?.removeUser?.status) {
        refetch(queryOptions);
      }

      return closeModal();
    });
  }, [closeModal, queryOptions, refetch, removeUser, removedUser]);

  const handleRemoveUser = ({ id }) => {
    setRemovedUser(id);
  };

  const options = [
    {
      title: 'Duplicate',
      callback: duplicate,
    },
    {
      title: 'Remove User',
      callback: handleRemoveUser,
    },
  ];

  return (
    <>
      <Box mt={4} mb={6}>
        <TesterChips />
      </Box>
      {isTestersTableEmpty ? (
        <EmptyTableBanner text='You don’t have any testers available yet' />
      ) : (
        <StyledTable>
          <TableHeader
            columns={TABLE_UI_TESTERS_COLUMNS}
            orderBy={orderBy}
            order={order}
            createSortHandler={handleRequestSort}
          />
          <TableBody>
            {testers?.map((item) => (
              <StyledTableRow key={item.id} onClick={handleItemClick(item.id)}>
                <TableCell
                  sx={{
                    minWidth: 150,
                  }}
                >
                  <Typography variant='span'>{item.contactName}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='span'>{item.tester.testerGroup}</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    minWidth: 100,
                  }}
                >
                  <StatusSelect
                    value={item.tester.testerStatusInternal}
                    onChange={handleChangeStatus(item.id)}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  <Typography variant='span'>
                    {item.tester?.testerStatusExternal?.toLowerCase()}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant='span'
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    {item.email}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='span'>{item.testCompletedCount || 0}</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    width: 200,
                  }}
                >
                  <Flex justifyContent='space-between'>
                    {!item.isRegister ? (
                      <ResendInviteButton
                        visible={!item.isRegister}
                        isInvited={item.isInvited}
                        userId={item.id}
                      />
                    ) : (
                      <Status status={item.tester?.testStatus} statuses={testerTestStatuses} />
                    )}
                    <OptionsMenu options={options} target={item} />
                  </Flex>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      )}
      <RemoveModal
        title='Are you sure you want to remove this user?'
        submitLabel='Yes, Remove User'
        open={!!removedUser}
        callback={acceptRemoveUser}
        closeModal={closeModal}
        itemId={removedUser}
      />
    </>
  );
};
