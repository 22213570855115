import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { bool, func, instanceOf } from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UploadIcon } from 'src/components/UI/icons';
import { StyledSubmitButton } from 'src/components/UI/tables/StyledComponents';
import { useToasts, useUpdateProfile } from 'src/hooks';
import { theme } from 'src/theme';

import { Flex } from '../../common/CommonStyledComponents';
import { Container, Footer, PromtMessage, StyledModal, styles } from './styles';

export const ChangeAvatarModal = ({ user, open, onClose }) => {
  const [image, setImage] = useState(null);
  const [updateProfile] = useUpdateProfile();
  const { ErrorMessage } = useToasts();

  const imageLink = useMemo(
    () =>
      image
        ? URL.createObjectURL(image)
        : `${process.env.REACT_APP_DOMAIN}/${user.imageProfileLink}`,
    [user, image],
  );

  useEffect(() => {
    if (!open) setImage(null);
  }, [open]);

  const handleClickSave = () => {
    updateProfile({
      variables: {
        input: {
          image,
        },
      },
    })
      .catch(() => ErrorMessage('Error. Please, try again.'))
      .finally(() => onClose());
  };

  const handleUploadAvatar = useCallback(({ target }) => {
    const [file] = target.files;
    if (file.type.includes('image')) {
      setImage(file);
    } else {
      ErrorMessage('Error. Invalid file type.');
    }
    // eslint-disable-next-line no-param-reassign
    target.value = null;
  }, []);

  const handleRemoveImage = () => {
    updateProfile({
      variables: {
        input: {
          image: null,
        },
      },
    })
      .catch(() => ErrorMessage('Error. Please, try again.'))
      .finally(() => onClose());
  };

  const showInitials = useMemo(() => {
    if (user?.firstName && user?.lastName) {
      return `${user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}`;
    }
    return user?.email[0].toUpperCase();
  }, [user?.lastName, user?.firstName, user?.email]);

  return (
    <StyledModal open={open} onClose={onClose}>
      <Paper
        sx={{
          width: 400,
        }}
      >
        <Box py={5} px={6}>
          <Typography
            sx={{
              textTransform: 'capitalize',
            }}
          >
            Change Photo
          </Typography>
        </Box>
        <Divider />
        <Container>
          <Avatar
            sx={{
              width: 214,
              height: 214,
              backgroundColor: theme.colors.blue,
            }}
            alt='avatar'
            src={imageLink}
          >
            <Typography fontSize={67}>{showInitials}</Typography>
          </Avatar>
          <Flex gap={2} mt={5}>
            <Typography
              component='label'
              role='button'
              sx={{
                cursor: 'pointer',
              }}
              fontSize={14}
            >
              <Flex gap={2}>
                <UploadIcon />
                Upload Image
              </Flex>
              <Input
                type='file'
                onChange={handleUploadAvatar}
                accept='image/*'
                sx={{
                  display: 'none',
                }}
              />
            </Typography>
          </Flex>
          <PromtMessage>Image should be square and at least 200 x 200px</PromtMessage>
        </Container>
        <Footer>
          <Button onClick={handleRemoveImage} sx={styles.removeBtn}>
            Remove Image
          </Button>
          <Box>
            <Button onClick={onClose} sx={styles.cancelBtn}>
              Cancel
            </Button>
            <StyledSubmitButton disabled={!image} onClick={handleClickSave}>
              Save
            </StyledSubmitButton>
          </Box>
        </Footer>
      </Paper>
    </StyledModal>
  );
};

ChangeAvatarModal.propTypes = {
  user: instanceOf(Object),
  onClose: func,
  open: bool,
};
