import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { StyledSelect } from 'src/components/common/CommonStyledComponents';
import { TesterStaus } from 'src/constants/constants';
import { useGetPossibleValuesTest } from 'src/hooks';
import { useEnrolledTabPanel } from 'src/hooks/useEnrolledTabPanel';

import { BlackButton } from '../styled';

export const TesterTableStatus = ({ tester }) => {
  const { handleEditTestTester, handleStartTestingTester } = useEnrolledTabPanel();
  const possibleValues = useGetPossibleValuesTest();

  const onChangeSelect = (value) => {
    handleEditTestTester(tester?.id, `${value.target.value}`);
  };

  const handleStartTestClick = () => {
    handleStartTestingTester(tester?.id);
  };

  const renderContent = useCallback(() => {
    switch (tester?.testerStatus) {
      case TesterStaus.ENROLLED:
        return (
          <BlackButton variant='contained' color='primary' onClick={handleStartTestClick}>
            Start Test
          </BlackButton>
        );

      case TesterStaus.START_TESTING:
        return (
          <StyledSelect
            name='grade'
            placeholder='--'
            value={tester.grade}
            onChange={onChangeSelect}
            sx={{
              width: '100px',
            }}
          >
            {possibleValues.testTesterGrade?.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </StyledSelect>
        );

      default:
        return null;
    }
  }, [tester]);

  return <Box>{renderContent()}</Box>;
};

TesterTableStatus.propTypes = {
  tester: PropTypes.instanceOf(Object).isRequired,
};
