import { Form, Formik } from 'formik';
import { func, instanceOf } from 'prop-types';

import { RightPanel } from './RightPanel';

export const RightPanelTestWrapper = ({ initialValues, refetch }) => {
  return (
    <Formik initialValues={initialValues} enableReinitialize={true}>
      <Form>
        <RightPanel refetch={refetch} />
      </Form>
    </Formik>
  );
};

RightPanelTestWrapper.propTypes = {
  initialValues: instanceOf(Object),
  refetch: func,
};
