export const autocomplete = {
  height: 28,
  '& .MuiOutlinedInput-root': {
    height: 28,
    '& .MuiOutlinedInput-input': {
      paddingY: 0,
    },
  },
};

export const detailSelect = {
  textTransform: 'unset',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  padding: '3px 0',
  color: '#000000',
};

export const genderSelect = {
  textTransform: 'inherit',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
};

export const textCapitalize = {
  '&.MuiButtonBase-root.MuiMenuItem-root': {
    textTransform: 'capitalize',
  },
  '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select': {
    textTransform: 'capitalize',
  },
};

export const selectMenu = {
  '&.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper': {
    marginTop: '7px',
  },
};

export const addressFieldsStyle = {
  description: {
    '& .MuiTypography-root, &.MuiTypography-root': {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  emptyAddress: {
    width: '100px',
    height: '0px',
  },

  noneField: {
    fontSize: '14px',
    color: '#444444',
  },
};

export const detailTab = {
  container: {
    borderBottom: 1,
    borderColor: 'divider',
  },
  tap: {
    textTransform: 'capitalize',
    minWidth: 0,
    p: 0,
    mr: 3,
  },
};

export const textHover = {
  '&:hover': {
    backgroundColor: 'rgba(248, 248, 248, 1)',
    padding: '3px 0',
  },
};

export const containerHover = {
  '&:hover': {
    backgroundColor: 'rgba(248, 248, 248, 1)',
  },
};

export const selectMenuWrap = {
  '&.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper': {
    maxWidth: '393px',
  },
  '&.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper ul': {
    marginTop: '7px',
    display: 'flex',
    width: '100%',
    maxHeight: '380px',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  '&.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper ul li': {
    border: 'solid 1px black',
    margin: 1,
    borderRadius: '4px',
    padding: '2px 12px 2px 12px',
  },
};
