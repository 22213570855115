import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { func } from 'prop-types';
import { useState } from 'react';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { styles } from 'src/components/testers/createTesterForm/styles';
import { testerCreationForm } from 'src/styles';

import { detailsTabStyles } from './backDropStyles';

export const DetailsChestSize = ({ handleChangeInchesToCm, handleChangeCm }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { values = {}, errors = {}, handleSubmit } = useFormikContext();

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };

  const closeAndSubmit = () => {
    setIsEditMode((prev) => !prev);
    handleSubmit();
  };

  return (
    <Box>
      {isEditMode ? (
        <Box sx={styles.twoColumns}>
          <Backdrop
            open={isEditMode}
            invisible={false}
            onClick={closeAndSubmit}
            sx={detailsTabStyles.backDropStyle}
          />
          <StyledLabel label='Chest Size (in)' sx={detailsTabStyles.styledLabelFont}>
            <TextField
              type='number'
              sx={testerCreationForm.input}
              size='small'
              name='chestInches'
              onInput={handleChangeInchesToCm('chestCm')}
              value={values.chestInches}
              error={!!errors.chestInches}
              helperText={errors.chestInches}
              placeholder='Inches'
              onKeyDown={keyDownHandler}
            />
          </StyledLabel>
          <StyledLabel label='Chest Size (cm)' sx={detailsTabStyles.styledLabelFont}>
            <TextField
              type='number'
              sx={testerCreationForm.input}
              size='small'
              name='chestCm'
              onInput={handleChangeCm('chestInches')}
              value={values.chestCm}
              error={!!errors.chestCm}
              helperText={errors.chestCm}
              placeholder='cms'
              onKeyDown={keyDownHandler}
            />
          </StyledLabel>
        </Box>
      ) : (
        <Box onClick={changeViewBox}>
          <StyledLabel label='Chest Size' sx={detailsTabStyles.styledLabelFont} />
          <Box sx={detailsTabStyles.styleForResultDetail}>
            {values.chestInches !== null || values.chestCm !== null ? (
              `${values.chestInches || '0'}''
                (${values.chestCm || '0'}cm)`
            ) : (
              <Box color='#444'>Enter chest size</Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

DetailsChestSize.propTypes = {
  handleChangeInchesToCm: func,
  handleChangeCm: func,
};
