import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { func, instanceOf, oneOfType, string } from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { BrandAutocomplete } from 'src/components/customers/BrandAutocomplete';
import { useGetBrands } from 'src/hooks/useQueries';

import * as styles from './styles';

export const DetailBrand = ({ value, name, handleChange }) => {
  const { brands } = useGetBrands();
  const [isEditable, setIsEditable] = useState(false);

  const brandName = useMemo(
    () => brands.find(({ id }) => id === value)?.name || 'none',
    [value, brands],
  );

  const toggle = useCallback(() => {
    setIsEditable((prev) => !prev);
  }, []);

  const setFieldValue = useCallback(
    (fieldName, fieldValue) => {
      handleChange({
        target: {
          name: fieldName,
          value: fieldValue,
        },
      });
      toggle();
    },
    [handleChange, toggle],
  );

  if (!isEditable) {
    return (
      <Flex
        onClick={toggle}
        sx={[
          {
            padding: '7px 0',
          },
          styles.textHover,
        ]}
      >
        <Typography
          sx={[
            {
              overflowWrap: 'anywhere',
              fontSize: 14,
            },
          ]}
        >
          {brandName ? `${value.name}` : 'none'}
        </Typography>
      </Flex>
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <BrandAutocomplete
        brands={brands}
        sx={styles.autocomplete}
        name={name}
        value={value}
        setFieldValue={setFieldValue}
      />
    </Box>
  );
};

DetailBrand.propTypes = {
  value: oneOfType([string, instanceOf(Object)]),
  name: string,
  handleChange: func,
};
