import Box from '@mui/material/Box';
import { func, instanceOf } from 'prop-types';
import { DetailInput, DetailLabel, DetailSelect } from 'src/components/UI';

export const Contact = ({ values, handleChange, possibleValues }) => {
  return (
    <Box>
      <DetailLabel label='Company'>
        <DetailSelect
          label='company'
          name='brandId'
          handleChange={handleChange}
          value={values?.brandId}
          placeholder='Select Brand'
          possibleValues={possibleValues.brands}
          submitOnValueChange
        />
      </DetailLabel>
      <DetailLabel label='Email'>
        <DetailInput
          name='email'
          handleChange={handleChange}
          value={values.email}
          placeholder='Enter Email'
          textTransform='lowercase'
          submitOnValueChange
        />
      </DetailLabel>
    </Box>
  );
};

Contact.propTypes = {
  values: instanceOf(Object),
  handleChange: func,
  possibleValues: instanceOf(Object),
};
