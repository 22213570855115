import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { node, string } from 'prop-types';
import { DetailLabel } from 'src/components';
import { Flex } from 'src/components/common/CommonStyledComponents';

export const DetailFieldReadOnly = ({ label, value, icon, link, textTransform = '' }) => (
  <DetailLabel label={label}>
    <Flex gap={2.5}>
      {icon}
      {link ? (
        <Link target='_blank' href={link} underline='none' fontSize={14}>
          {value || 'none'}
        </Link>
      ) : (
        <Typography
          sx={{
            textTransform,
            whiteSpace: 'break-spaces',
          }}
          fontSize={14}
        >
          {value || 'none'}
        </Typography>
      )}
    </Flex>
  </DetailLabel>
);

DetailFieldReadOnly.propTypes = {
  label: string,
  value: string,
  icon: node,
  link: string,
  textTransform: string,
};
