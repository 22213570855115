import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { instanceOf } from 'prop-types';
import { useParams } from 'react-router-dom';
import { GET_TESTER_BY_ID } from 'src/graphql/queries';
import { useEditTester } from 'src/hooks';

import { booleanToString, stringToBoolean } from '../../utils/helpers';
import { RightPanelTesterDetailContent } from './RightPanelTesterDetailContent';

const getIds = (data) => data.map((item) => item.id);

export const FormikWrapperRightPanelTesterDetail = ({ testerData }) => {
  const [editTester, { loading }] = useEditTester({
    refetchQueries: [GET_TESTER_BY_ID],
  });
  const { id: testerId } = useParams();

  const { email: emailData, firstName: fName, lastName: lName, tester } = testerData;

  const initialValues = {
    email: emailData,
    firstName: fName,
    lastName: lName,
    ...tester,
    generalShoeSizes: getIds(tester.generalShoeSizes),
    runningShoeSizes: getIds(tester.runningShoeSizes),
    hikingShoeSizes: getIds(tester.hikingShoeSizes),
    sandalShoeSizes: getIds(tester.sandalShoeSizes),
    bottomSizes: getIds(tester.bottomSizes),
    topSizes: getIds(tester.topSizes),
    isWorkForAnOutdoorCompany: booleanToString(tester.isWorkForAnOutdoorCompany),
    isHaveDog: booleanToString(tester.isHaveDog),
    isRecreateWithKids: booleanToString(tester.isRecreateWithKids),
    isCallOrText: booleanToString(tester.isCallOrText),
  };

  const onSubmitForm = (input, { resetForm, setSubmitting }) => {
    const {
      firstName,
      lastName,
      heightMetric,
      heightImperialInch,
      heightImperialFeet,
      weightLbs,
      weightKgs,
      chestInches,
      chestCm,
      waistAtNavelInches,
      waistAtNavelCm,
      waistAtPantsInches,
      waistAtPantsCm,
      hipsInches,
      hipsCm,
      inseamInches,
      inseamCm,
      age,
      birthdate,
      email,
      phoneNumber,
      country,
      state,
      otherActivities,
      ...testerInfo
    } = input;

    const formattedActivities = otherActivities.map(({ title, ...item }) => ({
      ...item,
      name: title,
    }));

    editTester({
      variables: {
        testerId,
        input: {
          firstName,
          lastName,
          email,
          testerInfo: {
            ...testerInfo,
            isWorkForAnOutdoorCompany: stringToBoolean(testerInfo.isWorkForAnOutdoorCompany),
            isRecreateWithKids: stringToBoolean(testerInfo.isRecreateWithKids),
            isHaveDog: stringToBoolean(testerInfo.isHaveDog),
            isCallOrText: stringToBoolean(testerInfo.isCallOrText),
            otherActivities: formattedActivities,
            heightMetric: heightMetric?.toString(),
            heightImperialInch: heightImperialInch?.toString(),
            heightImperialFeet: heightImperialFeet?.toString(),
            weightLbs: weightLbs?.toString(),
            weightKgs: weightKgs?.toString(),
            chestInches: chestInches?.toString(),
            chestCm: chestCm?.toString(),
            waistAtNavelInches: waistAtNavelInches?.toString(),
            waistAtNavelCm: waistAtNavelCm?.toString(),
            waistAtPantsInches: waistAtPantsInches?.toString(),
            waistAtPantsCm: waistAtPantsCm?.toString(),
            hipsInches: hipsInches?.toString(),
            hipsCm: hipsCm?.toString(),
            inseamInches: inseamInches?.toString(),
            inseamCm: inseamCm?.toString(),
            birthdate: birthdate ? dayjs(birthdate).format('YYYY-MM-DD') : null,
            countryId: country?.id,
            stateId: state?.id,
            phoneNumber: phoneNumber?.toString(),
          },
        },
      },
      onError: () => {
        resetForm();
      },
    }).finally(() => {
      setSubmitting(false);
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitForm} enableReinitialize>
      <Form>
        <RightPanelTesterDetailContent
          testerData={testerData}
          loading={loading}
          onSubmitForm={onSubmitForm}
        />
      </Form>
    </Formik>
  );
};

FormikWrapperRightPanelTesterDetail.propTypes = {
  testerData: instanceOf(Object),
};
