import { Backdrop, ListSubheader, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { instanceOf } from 'prop-types';
import { useState } from 'react';
import { DetailLabel } from 'src/components/index';
import { styles } from 'src/components/testers/createTesterForm/styles';
import { DetailSelectMultiple } from 'src/components/UI/detail/DetailSelectMultiple';
import {
  handleSetMultipleValues,
  handleShoeSizeValue,
  handleShoeSizeValueOrFallback,
} from 'src/utils/helpers';

import { detailsTabStyles } from './backDropStyles';

export const DetailsTopSize = ({ possibleValues, submitOnValueChange = true }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { values = {}, setFieldValue } = useFormikContext();
  const { topSizeOptions } = possibleValues;

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };

  if (!topSizeOptions) {
    return null;
  }

  return (
    <DetailLabel label='Top Size'>
      <Box onClick={changeViewBox}>
        <Backdrop open={isEditMode} invisible={false} sx={detailsTabStyles.backDropStyle} />
        <DetailSelectMultiple
          name='topSizes'
          handleChange={handleSetMultipleValues(setFieldValue)}
          value={values.topSizes}
          placeholder='Select top size'
          renderTextValue={handleShoeSizeValueOrFallback(topSizeOptions, 'Select top size')}
          renderValue={handleShoeSizeValue(topSizeOptions)}
          wrapItems
          multiple
          submitOnValueChange={submitOnValueChange}
        >
          <ListSubheader style={styles.label}>Select all that apply</ListSubheader>
          <ListSubheader style={styles.dropdownTitleBox}>
            <div style={styles.dropdownTitleText}>Women’s</div>
          </ListSubheader>
          {topSizeOptions.woman?.map((size) => {
            return (
              <MenuItem value={size.id} key={size.id}>
                {size.name}
              </MenuItem>
            );
          })}

          <ListSubheader style={styles.dropdownTitleBox}>
            <div style={styles.dropdownTitleText}>Men’s</div>
          </ListSubheader>
          {topSizeOptions.man?.map((size) => {
            return (
              <MenuItem value={size.id} key={size.id}>
                {size.name}
              </MenuItem>
            );
          })}
        </DetailSelectMultiple>
      </Box>
    </DetailLabel>
  );
};

DetailsTopSize.propTypes = {
  possibleValues: instanceOf(Object),
};
