import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { StyledBox } from 'src/components/login/styled';
import { Loader } from 'src/components/UI';
import { useResetPassword } from 'src/hooks';
import { useToasts } from 'src/hooks/useToasts';
import { forgotPasswordSchema } from 'src/utils/formSchemas';

import { ForgotPasswordForm } from './ForgotPasswordForm';

const initialValues = {
  email: '',
};

export const ForgotPasswordFormWrapper = ({ handleBackToLogin }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [resetPassword, { loading }] = useResetPassword();
  const { addToast } = useToasts();

  const onSubmit = ({ email }) => {
    resetPassword({
      variables: {
        email,
      },
      onCompleted: (res) => {
        if (!res?.resetPassword.status) {
          return addToast({
            message: 'Error! Please, try again.',
            variant: 'error',
            timer: 6000,
          });
        }

        return setCurrentStep(1);
      },
      onError: ({ message }) => {
        addToast({
          message,
          variant: 'error',
          timer: 6000,
        });
      },
    });
  };

  return (
    <StyledBox>
      <Loader loading={loading} />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={forgotPasswordSchema}
      >
        <Form>
          <ForgotPasswordForm currentStep={currentStep} handleBackToLogin={handleBackToLogin} />
        </Form>
      </Formik>
    </StyledBox>
  );
};

ForgotPasswordFormWrapper.propTypes = {
  handleBackToLogin: PropTypes.func,
};
