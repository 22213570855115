import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { RequestHandler } from 'src/components/requestHandler/requestHandler';
import { CandidateStatus } from 'src/constants/constants';
import { useCallbackWithLoader } from 'src/hooks';
import { formatDate } from 'src/utils/helpers';

import { BlackButton, DateText, DefaultButton, DefaultText } from '../styled';

export const CandidateTableStatus = ({
  isCityVisible,
  candidateStatus,
  updateDate,
  sentInviteToCandidate,
  candidateId,
  reinviteToCandidate,
  removeCandidate,
  handleEnrollCandidate,
  handleRejectCandidate,
}) => {
  const { loading, callback: rejectCandidate } = useCallbackWithLoader(handleRejectCandidate);
  const renderContent = useCallback(() => {
    switch (candidateStatus) {
      case CandidateStatus.ADDED:
        return (
          <>
            <DefaultButton onClick={() => removeCandidate(candidateId)}>Remove</DefaultButton>
            <BlackButton
              variant='contained'
              color='primary'
              sx={{
                marginLeft: '15px',
              }}
              onClick={() => sentInviteToCandidate(candidateId)}
            >
              Send Invite
            </BlackButton>
          </>
        );

      case CandidateStatus.INVITED:
        return (
          <>
            <DefaultButton onClick={() => removeCandidate(candidateId)}>Remove</DefaultButton>
            <DefaultButton
              sx={{
                marginLeft: '15px',
              }}
              onClick={() => reinviteToCandidate(candidateId)}
            >
              Resend
            </DefaultButton>
          </>
        );

      case CandidateStatus.ACCEPTED:
        return (
          <RequestHandler loading={loading}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  marginRight: '60px',
                }}
              >
                <DefaultText>Accepted</DefaultText>
                <DateText>{formatDate(updateDate)}</DateText>
              </Box>
              <BlackButton
                onClick={() => handleEnrollCandidate(candidateId)}
                variant='contained'
                color='primary'
              >
                Enroll
              </BlackButton>
              <BlackButton
                onClick={() => rejectCandidate(candidateId)}
                variant='outlined'
                sx={{
                  marginLeft: '15px',
                }}
                disabled={loading}
              >
                Reject
              </BlackButton>
            </Box>
          </RequestHandler>
        );

      case CandidateStatus.DECLINED:
        return (
          <Box
            sx={{
              marginLeft: '102px',
            }}
          >
            <DefaultText>Declined</DefaultText>
            <DateText>{formatDate(updateDate)}</DateText>
          </Box>
        );

      case CandidateStatus.UN_ENROLLED:
        return (
          <Box
            sx={{
              marginLeft: isCityVisible ? '102px' : '0px',
            }}
          >
            <DefaultText>Unenrolled</DefaultText>
            <DateText>{formatDate(updateDate)}</DateText>
          </Box>
        );

      default:
        return null;
    }
  }, [candidateStatus, loading, rejectCandidate]);

  return <Box>{renderContent()}</Box>;
};

CandidateTableStatus.propTypes = {
  candidateStatus: PropTypes.string.isRequired,
  updateDate: PropTypes.string,
  sentInviteToCandidate: PropTypes.func.isRequired,
  reinviteToCandidate: PropTypes.func.isRequired,
  removeCandidate: PropTypes.func.isRequired,
  handleEnrollCandidate: PropTypes.func.isRequired,
  handleRejectCandidate: PropTypes.func.isRequired,
  candidateId: PropTypes.string.isRequired,
  isCityVisible: PropTypes.bool.isRequired,
};
