import Button from '@mui/material/Button';
import { arrayOf, func, objectOf, string } from 'prop-types';
import { useMemo } from 'react';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { filtersMenu } from 'src/styles';

export const BottomSection = ({ handleClickCancel, handleClickClear, handleSubmit, errors }) => {
  const shouldDisableButton = useMemo(() => {
    if (!errors) return false;
    return Object.keys(errors).length !== 0;
  }, [errors]);

  return (
    <Flex sx={filtersMenu.root}>
      <Button sx={filtersMenu.button.cancel} onClick={handleClickClear}>
        Clear All
      </Button>
      <Flex>
        <Button sx={filtersMenu.button.cancel} onClick={handleClickCancel}>
          Cancel
        </Button>
        <Button
          disabled={shouldDisableButton}
          variant='contained'
          sx={filtersMenu.button.apply}
          onClick={handleSubmit}
          type='submit'
        >
          Apply
        </Button>
      </Flex>
    </Flex>
  );
};

BottomSection.propTypes = {
  handleClickCancel: func,
  handleClickClear: func,
  handleSubmit: func,
  errors: objectOf(arrayOf(string)),
};
