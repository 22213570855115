import { Form, Formik } from 'formik';
import { instanceOf } from 'prop-types';
import { useParams } from 'react-router-dom';
import { GET_TEST_BY_USER_ID } from 'src/graphql/queries';
import { useEditUser } from 'src/hooks';

import { RightPanelContent } from './RightPanelContent';

export const FormikWrapperRightPanel = ({ customer = {} }) => {
  const { editUser, loading } = useEditUser({
    refetchQueries: [GET_TEST_BY_USER_ID],
  });
  const { id: customerId } = useParams();

  const getInitialValues = () => {
    if (customer.customer) {
      const {
        customer: { brand },
        email,
      } = customer;

      return {
        brandId: brand?.id,
        email,
      };
    }
    return {};
  };

  const onSubmitForm = async (input, { resetForm }) => {
    const { brandId, email } = input;

    const response = await editUser({
      id: customerId,
      input: {
        email,
        customerInfo: {
          brandId,
        },
      },
    });

    return !response.data?.editUserById?.status && resetForm();
  };

  return (
    <Formik initialValues={getInitialValues()} onSubmit={onSubmitForm}>
      <Form>
        <RightPanelContent loading={loading} />
      </Form>
    </Formik>
  );
};

FormikWrapperRightPanel.propTypes = {
  customer: instanceOf(Object),
};
