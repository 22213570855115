import styled from '@mui/system/styled';
import { func, string } from 'prop-types';
import { CloseIcon } from 'src/components/UI/icons';
import { theme } from 'src/theme';

import { Flex } from '../../CommonStyledComponents';

const Chip = styled(Flex)({
  border: `1px solid ${theme.colors.neutral.light.normal}`,
  padding: '4px 12px',
  borderRadius: 5,
  fontSize: 14,
  lineHeight: '20px',
  gap: 3,
  '& svg': {
    marginLeft: '13px',
    '& path': {
      fill: '#676767',
    },
  },
});

export const FilterChip = ({ value, onRemove }) => {
  return (
    <Chip
      sx={{
        textTransform: 'capitalize',
      }}
    >
      {value}
      {onRemove && (
        <Flex
          onClick={onRemove}
          sx={{
            cursor: 'pointer',
          }}
        >
          <CloseIcon width={9.5} height={9.5} />
        </Flex>
      )}
    </Chip>
  );
};

FilterChip.propTypes = {
  value: string,
  onRemove: func,
};
