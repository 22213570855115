import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';

export const Chevron = ({ direction = 'left', size = 16, fill = 'black' }) => {
  const stylesByDirection = {
    left: {
      height: size,
    },
    right: {
      transform: 'rotate(180deg)',
      height: size,
    },
    bottom: {
      transform: 'rotate(270deg)',
      width: size,
    },
    top: {
      transform: 'rotate(90deg)',
      width: size,
    },
  };

  return (
    <SvgIcon
      sx={{
        ...stylesByDirection[direction],
        cursor: 'pointer',
      }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox={`0 0 ${size} ${size}`}
    >
      <path
        d='M10.2002 14.203L4.10216 8.105L10.2002 2.00697L8.31829 0.125L0.338282 8.105L8.31829 16.085L10.2002 14.203Z'
        fill={fill}
      />
    </SvgIcon>
  );
};

Chevron.propTypes = {
  direction: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
