import Typography from '@mui/material/Typography';
import { number, oneOfType, string } from 'prop-types';
import { CalendarIcon } from 'src/components/UI/icons/CalendarIcon';
import { formatDate } from 'src/utils/helpers';

import { Flex } from '../../common/CommonStyledComponents';

export const DetailDate = ({ value }) => {
  return (
    <Flex>
      <CalendarIcon />
      <Typography
        sx={{
          textTransform: 'capitalize',
          paddingLeft: '10px',
        }}
      >
        {formatDate(value)}
      </Typography>
    </Flex>
  );
};

DetailDate.propTypes = {
  value: oneOfType([string, number]),
};
