import { string } from 'prop-types';

export const CheckIconDefault = ({ color = '#F1F1F2' }) => {
  return (
    <svg fill='none' width='14' height='10' viewBox='0 0 14 10' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={color}
        d='M13.2752 0.341671C13.5161 0.569353 13.6566 0.883361 13.666 1.21465C13.6754 1.54594 13.5528 1.8674 13.3252 2.10834L6.2419 9.60834C6.1271 9.72967 5.98914 9.82677 5.83618 9.89389C5.68323 9.96101 5.51837 9.99679 5.35134 9.99912C5.18432 10.0015 5.01853 9.97028 4.86376 9.90746C4.70898 9.84463 4.56837 9.75142 4.45023 9.63334L0.700232 5.88334C0.479432 5.64638 0.359227 5.33297 0.364941 5.00913C0.370655 4.6853 0.501841 4.37632 0.730863 4.1473C0.959885 3.91828 1.26886 3.78709 1.59269 3.78138C1.91653 3.77567 2.22994 3.89587 2.4669 4.11667L5.30857 6.95667L11.5086 0.391671C11.7362 0.150833 12.0503 0.0102738 12.3815 0.000897634C12.7128 -0.00847855 13.0343 0.114096 13.2752 0.341671Z'
      />
    </svg>
  );
};

CheckIconDefault.propTypes = {
  color: string,
};
