import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { RequestHandler } from 'src/components/requestHandler/requestHandler';
import { ConfirmModal } from 'src/components/testOverview/confirmModal/confirmModal';
import { OptionsMenu, ResendInviteButton } from 'src/components/UI';
import {
  PaginationSection,
  StyledTable,
  StyledTableRow,
  TablePagination,
} from 'src/components/UI/tables';
import { EmptyTableBanner } from 'src/components/UI/tables/EmptyTableBanner';
import { useFilter, useGetAdmins, useRemoveUser } from 'src/hooks';
import { stringToBoolean } from 'src/utils/helpers';

import { TableHeader } from './TableHeader';

const COLUMNS = [
  {
    title: 'Name',
    sortKey: 'contactName',
  },
  {
    title: 'Email',
    sortKey: 'email',
  },
  {
    title: '',
  },
];

function areEqual(prevProps, nextProps) {
  return prevProps?.data === nextProps?.data;
}

export const AdminsTable = React.memo(() => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(16);
  const [orderBy, setOrderBy] = useState('contactName');
  const [order, setOrder] = useState('DESC');
  const [removeModalIsOpen, setRemoveModalIsOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const { filters } = useFilter();
  const navigate = useNavigate();

  const { data, fetchMore, refetch, loading } = useGetAdmins({
    variables: {
      limit: rowsPerPage,
      offset: rowsPerPage * (currentPage - 1),
      search: filters.search,
      sortType: {
        type: order,
        field: orderBy,
      },
    },
  });
  const admins = data?.getUsersWithRoleAdmin?.rows || [];
  const remove = useRemoveUser();

  useEffect(() => {
    fetchMore({
      variables: {
        limit: rowsPerPage,
        offset: rowsPerPage * (currentPage - 1),
        search: filters.search,
        sortType: {
          type: order,
          field: orderBy,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          getUsersWithRoleAdmin: fetchMoreResult.getUsersWithRoleAdmin,
        };
      },
    });
  }, [currentPage, rowsPerPage, filters, order, orderBy]);

  const handleChangePage = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleChangeRowsPerPage = useCallback((value) => {
    setRowsPerPage(value);
    setCurrentPage(1);
  }, []);

  const handleRequestSort = useCallback(
    (property) => () => {
      const isAsc = orderBy === property && order === 'ASC';
      setOrder(isAsc ? 'DESC' : 'ASC');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const toggleRemoveUser = (_userId) => {
    setRemoveModalIsOpen((prev) => !prev);
    setUserId(_userId);
  };

  const handleRemoveUser = useCallback(() => {
    remove({
      id: userId,
    }).then((res) => res.data?.removeUser?.status && refetch());
  }, [userId]);

  const options = [
    {
      title: 'Remove User',
      callback: toggleRemoveUser,
    },
  ];

  if (admins?.length === 0 || !admins) {
    return <EmptyTableBanner text='You don’t have any admins available yet' />;
  }

  const handleItemClick = (id) => (evt) => {
    const { tagName } = evt.target;

    if (tagName === 'TR' || tagName === 'TD') {
      navigate(`/admins/${id}`);
    }
  };

  return (
    <RequestHandler loading={loading}>
      <TablePagination
        page={currentPage}
        rowsPerPage={rowsPerPage}
        count={data?.getUsersWithRoleAdmin?.count}
        currentPageRowsCount={admins.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <StyledTable
        sx={{
          marginTop: 6,
        }}
      >
        <TableHeader
          columns={COLUMNS}
          orderBy={orderBy}
          order={order}
          createSortHandler={handleRequestSort}
        />
        <TableBody>
          {admins?.map((item) => (
            <StyledTableRow key={item.id} onClick={handleItemClick(item.id)}>
              <TableCell>
                <Typography fontSize={14}>{item.firstName}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={14} color='#9B51E0'>
                  {item.email}
                </Typography>
              </TableCell>
              <TableCell>
                <Flex justifyContent='flex-end'>
                  <Flex mr={5}>
                    <ResendInviteButton
                      visible={!stringToBoolean(item.isRegister)}
                      isInvited={stringToBoolean(item.isInvited)}
                      userId={item.id}
                    />
                  </Flex>
                  <OptionsMenu target={item.id} options={options} />
                </Flex>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <ConfirmModal
          isError
          open={removeModalIsOpen}
          toggleModal={toggleRemoveUser}
          callback={handleRemoveUser}
          labalTest='Are you sure you want to remove this Admin?'
          agreeButtonTex='Yes, Remove them'
        />
      </StyledTable>
      <PaginationSection
        page={currentPage}
        count={
          data?.getUsersWithRoleAdmin?.count &&
          Math.ceil(data.getUsersWithRoleAdmin.count / rowsPerPage)
        }
        onChange={handleChangePage}
      />
    </RequestHandler>
  );
}, areEqual);

AdminsTable.propTypes = {};
