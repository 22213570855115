import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { saveAs } from 'file-saver';
import { instanceOf } from 'prop-types';
import { useCallback } from 'react';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { mediaSectionStyles } from 'src/components/myTest/styles';
import { theme } from 'src/theme';

import { MediaCard } from './MediaCard';

export const CustomerMediaSection = ({ mediaList }) => {
  const handleDownload = useCallback(
    (url) => () => {
      const filename = url.substring(url.lastIndexOf('/') + 1);
      saveAs(url, filename);
    },
    [mediaList],
  );

  const handleDownloadAllImages = useCallback(() => {
    mediaList.forEach((media) => {
      const filename = media.fileUrl?.substring(media.fileUrl.lastIndexOf('/') + 1);
      saveAs(`${process.env.REACT_APP_DOMAIN}/${media.fileUrl}`, filename);
    });
  }, [mediaList]);

  return (
    <Box>
      {mediaList?.length ? (
        <>
          <Flex sx={mediaSectionStyles.header}>
            <Button sx={mediaSectionStyles.button.upload} onClick={handleDownloadAllImages}>
              Download Images
            </Button>
          </Flex>
          <Flex sx={mediaSectionStyles.mediaList}>
            {mediaList
              .map((media) => (
                <MediaCard key={media.id} media={media} handleDownload={handleDownload} />
              ))
              .reverse()}
          </Flex>
        </>
      ) : (
        <Typography fontSize={14} color={theme.colors.neutral.dark.mid}>
          There is no images
        </Typography>
      )}
    </Box>
  );
};

CustomerMediaSection.propTypes = {
  mediaList: instanceOf(Array),
};
